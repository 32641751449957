import { ErrorCategory } from '../enum/ErrorCategory';
import { ErrorCode } from '../enum/ErrorCode';
import { PlayerError } from '../error/PlayerError';
import { StrAnyDict } from '../iface/StrAnyDict';
import { isObject } from '../util/Type';
// API decorator factories

export type Api<T = StrAnyDict> = { api: T, destroy: () => void };

function apiMember(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
	if (!isObject(target.__apiDescriptors)) {
		target.__apiDescriptors = {};
	}

	target.__apiDescriptors[propertyKey] = descriptor;
}

export function apiMethod() {
	return apiMember;
}

export function apiAccessor(readonly: boolean = false) {
	return apiMember;
}

export function apiCollection<T = StrAnyDict>(id: string, target: any): Api<T> {
	const api: StrAnyDict = {};
	const destroyed: StrAnyDict = {};
	let base = target;

	const destroy = () => {
		base = destroyed;
		target.__apiDescriptors = null;
	};

	const destroyHandler = (key: string) => () => {
		throw new PlayerError(
			ErrorCode.CALL_AFTER_DESTROY,
			`Calling "${key}" on player "${id}" after it has been destroyed`,
			undefined,
			true,
			ErrorCategory.API,
		);
	};

	for (const key in target.__apiDescriptors) {
		const descriptor = target.__apiDescriptors[key];
		const handler = destroyHandler(key);

		if (descriptor.value) {
			api[key] = function () {
				// eslint-disable-next-line prefer-spread, prefer-rest-params
				return base[key].apply(base, arguments);
			};
			destroyed[key] = handler;
		}
		else {
			Object.defineProperty(api, key, {
				get: descriptor.get ? () => base[key] : undefined,
				set: descriptor.set ? (value: any) => base[key] = value : undefined,
			});

			Object.defineProperty(destroyed, key, {
				get: descriptor.get ? handler : undefined,
				set: descriptor.set ? handler : undefined,
			});
		}
	}

	return { api: api as T, destroy };
}

export { apiAccessor as delegateAccessor, apiCollection as delegateApi, apiMethod as delegateMethod };
