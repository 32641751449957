import { PlaybackState } from '../../enum/PlaybackState';
import { QualityMode } from '../../enum/QualityMode';
import { StreamType } from '../../enum/StreamType';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { Validator } from '../vo/Validator';


export const content_playback_state_config: StrAnyDict = {

	qualities: {
		type: 'object',
		defaultValue: [],
	},

	quality: {
		type: 'object',
		defaultValue: null,
	},

	qualityMode: {
		type: 'string',
		defaultValue: QualityMode.AUTO,
	},

	qualityCappedToScreenSize: {
		type: 'boolean',
		defaultValue: true,
	},

	audioTracks: {
		type: 'object',
		defaultValue: [],
	},

	audioTrack: {
		type: 'object',
		defaultValue: null,
	},

	textTracks: {
		type: 'object',
		defaultValue: [],
	},

	textTrack: {
		type: 'object',
		defaultValue: null,
	},

	streamType: {
		type: 'string',
		defaultValue: StreamType.VOD,
	},

	liveStreamInfo: {
		type: 'object',
		defaultValue: null,
	},

	bufferLength: {
		type: 'number',
		defaultValue: 0,
	},

	cdn: {
		type: 'string',
		defaultValue: null,
		validator: Validator.notEmpty,
	},

	drmType: {
		type: 'string',
		defaultValue: null,
		validator: Validator.notEmpty,
	},

	contentSegment: {
		type: 'number',
		defaultValue: NaN,
	},

	maxBandwidth: {
		type: 'number',
		defaultValue: NaN,
	},

	time: {
		type: 'number',
		defaultValue: 0,
	},

	state: {
		type: 'string',
		defaultValue: PlaybackState.IDLE,
	},

	duration: {
		type: 'number',
		defaultValue: NaN,
	},

	bitrate: {
		type: 'number',
		defaultValue: NaN,
	},

	started: {
		type: 'boolean',
		defaultValue: false,
	},

	averageDroppedFps: {
		type: 'number',
		defaultValue: NaN,
	},

	framerate: {
		type: 'number',
		defaultValue: NaN,
	},
};
