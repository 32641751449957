import { Api, delegateApi, delegateMethod } from '../app/ApiDecorators';
import { AppResources } from '../app/AppResources';
import { ActionKeyContext } from '../enum/ActionKeyContext';
import { AdBreakType } from '../enum/AdBreakType';
import { ErrorCategory } from '../enum/ErrorCategory';
import { ErrorCode } from '../enum/ErrorCode';
import { ErrorMessage } from '../enum/ErrorMessage';
import { LogLevel } from '../enum/LogLevel';
import { MediatorName } from '../enum/MediatorName';
import { NotificationName } from '../enum/NotificationName';
import { PlaybackState } from '../enum/PlaybackState';
import { StreamType } from '../enum/StreamType';
import { PlayerError } from '../error/PlayerError';
import { PlayerEvent } from '../events/PlayerEvent';
import { NotificationInterface, PresentationMediatorInterface } from '../iface';
import { AdAdapterDelegateInterface } from '../iface/AdAdapterDelegateInterface';
import { AdAdapterInterface } from '../iface/AdAdapterInterface';
import { AdBreakScheduleItemInterface } from '../iface/AdBreakScheduleItemInterface';
import { AdCuePointInterface } from '../iface/AdCuePointInterface';
import { AdItemInterface } from '../iface/AdItemInterface';
import { Debounced } from '../iface/Debounced';
import { ErrorInfoInterface } from '../iface/ErrorInfoInterface';
import { PlayerHookMap } from '../iface/PlayerHookMap';
import { TimeRangeInterface } from '../iface/TimeRangeInterface';
import { debounce } from '../util/FunctionUtil';
import { clampValue } from '../util/NumberUtil';
import { System } from '../util/System';
import { ActivityMediator } from './ActivityMediator';
import { AppMediator } from './AppMediator';
import { CommonPresentationMediator } from './CommonPresentationMediator';


export class AdPresentationMediator extends CommonPresentationMediator implements PresentationMediatorInterface, AdAdapterDelegateInterface {

	private pAdAdapter: AdAdapterInterface = null;
	private adAdapterStarted: boolean = false;
	private contentSegmentStarted: boolean = false;
	private contentStartReleased: boolean = false;
	private contentCompleteReleased: boolean = false;
	private fatalContentErrorReceived: boolean = false;
	private hasMidRolls: boolean = false;
	private pendingSeekTime: number = null;
	private currentAd: AdItemInterface = null;
	private currentBreak: AdBreakScheduleItemInterface = null;
	private breakSchedule: AdBreakScheduleItemInterface[];
	private pointerLockDebouncer: Debounced;
	private pointerLocked: boolean = false;
	private isPreloading: boolean = false;
	private cancelTimeout: any;
	private delegate: Api<AdAdapterDelegateInterface>;
	private playingTimeout: any;

	constructor(name: string, viewControl?: any) {
		super(name, viewControl);
		this.preloadContent = false;
	}

	set adAdapter(adapter: AdAdapterInterface) {
		this.pAdAdapter = adapter;
	}

	get adAdapter(): AdAdapterInterface {
		return this.pAdAdapter;
	}

	get adInProgress(): boolean {
		return this.presoModel.isTrackingAd;
	}

	protected get appMediator(): AppMediator {
		return this.facade.retrieveMediator(MediatorName.APPLICATION) as AppMediator;
	}

	protected override destroyAdapters(): Promise<void> {
		return Promise.resolve()
			.then(() => this.adAdapter?.destroy())
			.then(() => super.destroyAdapters());
	}

	override onRemove(): void {
		this.pAdAdapter?.destroy?.();
		this.pAdAdapter = null;
		this.cancelPointerLock();

		this.pointerLockDebouncer.cancel();
		clearTimeout(this.cancelTimeout);

		clearTimeout(this.playingTimeout);

		this.monitorActivity(false);

		this.delegate.destroy();
		this.delegate = null;

		super.onRemove();
	}

	override closeAds(): void {
		this.domProxy?.showAdContainer(false);
		this.adAdapter?.destroy();
		this.pAdAdapter = null;
	}

	override beforePlayOnUserGesture() {
		this.adAdapter?.playClicked();
	}

	override playOnUserGesture(): void {
		if (this.hasContent) {
			super.playOnUserGesture();
		}
		else {
			this.play();
		}
	}

	override start(): void {
		const contentStartTime = this.resourceProxy.playback.startTime;
		const showPreRoll = this.resourceProxy.ad.showPrerollOnNonZeroStart;
		const nonZeroStart = !isNaN(contentStartTime) && contentStartTime > 0;

		super.start();

		this.mute(this.presoModel.isMuted);

		if (nonZeroStart && showPreRoll) {
			this.pendingSeekTime = contentStartTime;
		}

		if (this.isClickToPlay) {
			this.setForClickToPlay();
		}
		else {
			const r = this.resourceProxy.resource;
			if (r.ad?.csai?.adCallUrl && !r.location?.mediaUrl) {
				this.play();
			}
			else {
				this.notify(NotificationName.VIDEO_LOAD_START);
				this.prepareForPlayback();
			}
		}
	}

	/** override */
	override getAdBreakTimes(): AdCuePointInterface[] {
		const aci: AdCuePointInterface[] = [];
		this.breakSchedule?.forEach(b => {
			aci.push({
				start: b.startTime,
				streamTimeStart: b.streamStartTime,
				end: b.endTime,
				streamTimeEnd: b.streamStartTime + b.duration,
				played: b.hasPlayed,
				...b,
			});
		});

		return aci;
	}

	createDelegate(id: string) {
		this.delegate = delegateApi<AdAdapterDelegateInterface>(`${id}.adAdapterDelegate`, this);
	}

	getDelegate(): AdAdapterDelegateInterface {
		return this.delegate.api;
	}

	override play(): Promise<void> {
		if (!this.presoModel.started) {
			this.presoModel.started = true;
			this.adAdapterStarted = true;

			return Promise.resolve(this.adAdapter.start());
		}
		else {
			if (this.adInProgress && this.adAdapter?.resume) {
				return Promise.resolve(this.adAdapter.resume());
			}

			return this.playVideo();
		}
	}

	override pause() {
		if (this.adInProgress && this.adAdapter?.pause) {
			this.adAdapter.pause();

			return;
		}

		this.pauseVideo();
	}

	override getBuffered(asStreamTime: boolean = false): TimeRangeInterface[] {
		const b = super.getBuffered(asStreamTime);

		if (asStreamTime === true) {
			return b;
		}

		return b.map(r => {
			return {
				startTime: this.adAdapter.contentTimeForStreamTime?.(r.startTime) || r.startTime,
				endTime: this.adAdapter.contentTimeForStreamTime?.(r.endTime) || r.endTime,
			};
		});
	}

	// override
	override mute(flag: boolean) {
		this.adAdapter?.setMuteState(flag);
		super.mute(flag);
	}

	override setVolume(value: number) {
		this.adAdapter?.setVolume(value);
		super.setVolume(value);
	}

	// override
	override seek(position: number): Promise<void> {
		const streamSeekTimeRequested = this.streamTimeForContentTime(position);
		const permittedStreamSeekTime = this.getPermittedSeekTime(position);

		if (this.hasMidRolls && this.adAdapter && permittedStreamSeekTime !== streamSeekTimeRequested) {
			this.pendingSeekTime = streamSeekTimeRequested;
			this.notify(PlayerEvent.SEEK_REDIRECT_START, {
				requestedSeekTime: position,
				actualSeekTime: this.contentTimeForStreamTime(permittedStreamSeekTime),
			});
		}

		if (this.contentComplete && !this.contentSegmentStarted) {
			this.contentSegmentStarted = true;
		}

		return super.seek(permittedStreamSeekTime);
	}

	skipAd() {
		this.adAdapter?.skipAd();
	}
	///////////////////////////////////////////////////////////
	// Delegate interface
	///////////////////////////////////////////////////////////

	@delegateMethod()
	pauseContent() {
		this.pauseVideo();
		this.domProxy?.showAdContainer(true);
	}

	@delegateMethod()
	resumeContent() {
		this.currentAd = null;
		this.currentBreak = null;
		this.domProxy?.showAdClickElement(false);
		this.domProxy?.showAdContainer(false);

		if (this.fatalContentErrorReceived) {
			return;
		}

		if (!this.hasContent || this.contentComplete) {
			super.respondToVideoEnd();
			return;
		}

		if (!this.contentStartReleased) {
			const contentStartTime = this.resourceProxy.playback.startTime,
				nonZeroStart = !isNaN(contentStartTime) && contentStartTime > 0;

			if (nonZeroStart) {
				const breaks = this.getAdBreakTimes();
				const adjustedStart = this.adjustStartTimeForAdBreakProximity(contentStartTime, breaks);

				this.resourceProxy.playback.startTime = adjustedStart;
			}

			this.load()
				.then(() => {
					this.resumeContentPlayComplete();
				})
				.catch(this.onLoadError);

			return;
		}

		this.resumeContentPlayComplete();
	}

	@delegateMethod()
	streamIdAvailable(id: string): void {
		this.notify(PlayerEvent.STREAM_ID_AVAILABLE, { streamId: id });
	}

	@delegateMethod()
	override applyHook<T extends keyof PlayerHookMap, V = PlayerHookMap[T]['value'], M = PlayerHookMap[T]['metadata']>(type: T, value: V, metadata: M): Promise<V | null> {
		return super.applyHook(type, value, metadata);
	}

	@delegateMethod()
	adSkippableStateChanged() {
		this.notify(PlayerEvent.AD_SKIPPABLE_STATE_CHANGE);
	}

	@delegateMethod()
	adSegmentStarted() {
		this.endContentSegment();
		this.presoModel.adSegmentEntered = true;
		this.notify(PlayerEvent.AD_SEGMENT_START);
	}

	@delegateMethod()
	adSegmentReentered() {
		this.notify(PlayerEvent.AD_SEGMENT_REENTERED);
	}

	@delegateMethod()
	adSegmentEnded() {
		this.contentSegmentStarted = false;
		if (this.presoModel) {
			this.presoModel.isTrackingAd = false;
			this.presoModel.adSegmentEntered = false;
		}
		this.notify(PlayerEvent.AD_SEGMENT_END);
	}

	@delegateMethod()
	adBreaksAvailable(adBreaks: AdBreakScheduleItemInterface[]): void {
		this.hasMidRolls = adBreaks.some(b => b.type === AdBreakType.MID);
		this.breakSchedule = adBreaks;
		const times = adBreaks.map(b => b.startTime);
		this.notify(PlayerEvent.AD_CUEPOINTS_AVAILABLE, {
			cuepoints: times,
			adBreaks,
		});
	}

	@delegateMethod()
	seekToStreamTime(t: number) {
		this.seekVideo(t);
	}

	@delegateMethod()
	adBreakStart(): void {
		this.endContentSegment();
		this.updateSize();
		this.domProxy?.showAdContainer(true);
		this.presoModel.isTrackingAd = true;
		if (this.timeSpent.startTime === null) {
			this.startSession();
		}

		this.enterAdActionKeyContext(true);
		this.notify(PlayerEvent.AD_BREAK_START);
	}

	@delegateMethod()
	adBreakMetadata(breakInfo: AdBreakScheduleItemInterface): void {
		this.currentBreak = breakInfo;
		this.notify(PlayerEvent.AD_BREAK_METADATA, { adBreakInfo: breakInfo });
	}

	@delegateMethod()
	adLoaded(): void {
		// no impl
	}

	@delegateMethod()
	adStart(adData: AdItemInterface): void {
		this.currentAd = adData;
		this.respondToPlaybackStateChange(PlaybackState.PLAYING);
		this.notify(PlayerEvent.AD_START, { adInfo: adData });
	}

	@delegateMethod()
	adProgress(currentTime: number, duration: number, breakTime?: number, breakDuration?: number): void {
		const { streamTime, streamDuration } = this.presoModel;

		this.presoModel.adTime = currentTime;
		this.presoModel.adDuration = duration;
		this.presoModel.breakTime = breakTime;
		this.presoModel.breakDuration = breakDuration;

		this.notify(PlayerEvent.AD_PROGRESS, {
			currentTime,
			duration,
			breakTime,
			breakDuration,
			streamTime,
			streamDuration,
		});
		this.checkPreload(currentTime, duration);
	}

	@delegateMethod()
	adFirstQuartile(): void {
		this.notify(PlayerEvent.AD_FIRST_QUARTILE);
	}

	@delegateMethod()
	adMidpoint(): void {
		this.notify(PlayerEvent.AD_MIDPOINT);
	}

	@delegateMethod()
	adThirdQuartile(): void {
		this.notify(PlayerEvent.AD_THIRD_QUARTILE);
	}

	@delegateMethod()
	adComplete(): void {
		this.currentAd = null;
		this.notify(PlayerEvent.AD_COMPLETE);
	}

	@delegateMethod()
	adBreakComplete(): void {
		this.currentBreak = null;
		this.currentAd = null;
		this.isPreloading = false;
		this.domProxy?.showAdContainer(false);
		this.domProxy?.showAdClickElement(false);
		this.presoModel.isTrackingAd = false;
		this.presoModel.adSegmentEntered = false;
		this.notify(PlayerEvent.AD_BREAK_COMPLETE);

		this.enterAdActionKeyContext(false);

		if (this.isContentComplete()) {
			super.respondToVideoEnd();

			return;
		}

		if (this.pendingSeekTime && this.contentStartReleased) {
			const t = this.pendingSeekTime;
			this.pendingSeekTime = null;
			this.contentPlaybackStateProxy.model.started && this.notify(PlayerEvent.SEEK_REDIRECT_COMPLETE);

			this.seekVideo(t);
		}
	}

	@delegateMethod()
	adClicked(url: string = ''): void {
		const cp = this.contentPlaybackStateProxy?.model?.state;
		if (cp === PlaybackState.PLAYING) {
			this.pause();
		}

		this.notify(PlayerEvent.AD_CLICK, { clickThroughUrl: url });
	}

	@delegateMethod()
	adError(info: ErrorInfoInterface): void {
		this.currentAd = null;
		if (!info.message) {
			info.message = AppResources.messages.UNSPECIFIED_ERROR;
		}
		this.notify(NotificationName.AD_ERROR, info);
	}

	@delegateMethod()
	adPaused(): void {
		this.respondToPlaybackStateChange(PlaybackState.PAUSED);
		this.notify(PlayerEvent.AD_PAUSED);
	}

	@delegateMethod()
	adResumed(): void {
		this.notify(PlayerEvent.RESOURCE_BUFFERING, { buffering: false });
		this.respondToPlaybackStateChange(PlaybackState.PLAYING);
		this.notify(PlayerEvent.AD_PLAYING);
	}

	@delegateMethod()
	adStalled(adInfo: AdItemInterface): void {
		this.logger.dir(LogLevel.WARN, adInfo, 'Stalled Ad Info');
		this.notify(PlayerEvent.AD_STALLED, new PlayerError(
			ErrorCode.AD_STALLED,
			ErrorMessage.AD_STALLED,
			adInfo,
			false,
			ErrorCategory.AD,
		));
	}

	@delegateMethod()
	adBreakDiscarded(): void {
		this.notify(PlayerEvent.AD_BREAK_DISCARDED);
	}

	@delegateMethod()
	adSkipped(): void {
		this.notify(PlayerEvent.AD_SKIPPED);
	}

	@delegateMethod()
	allAdsComplete(): void {
		// implementation optional
	}

	@delegateMethod()
	adBuffering(state: boolean): void {
		// TODO
	}

	@delegateMethod()
	displayAdClickElement(flag: boolean): void {
		this.domProxy?.showAdClickElement(flag);
	}

	@delegateMethod()
	setContentDuration(duration: number): void {
		if (duration && !isNaN(duration) && duration > 0) {
			this.contentPlaybackStateProxy.model.duration = duration;
			this.releaseContentDuration(duration);
		}
	}

	///////////////////////////////////////////////////////////
	// END Delegate interface
	///////////////////////////////////////////////////////////

	private enterAdActionKeyContext(flag: boolean) {
		if (flag) {
			this.appMediator.enterActionKeyContext(ActionKeyContext.AD);
		}
		else {
			this.appMediator.exitActionKeyContext(ActionKeyContext.AD);
		}
	}

	private async resumeContentPlayComplete() {
		if (this.pendingSeekTime && this.contentStartReleased) {
			await this.seekVideo(this.pendingSeekTime).catch(error => {
				this.logger.warn('APM#resumeContentPlayComplete; A seek() call failed.');
				this.logger.debug(error, 'Error');
			});
			this.notify(PlayerEvent.SEEK_REDIRECT_COMPLETE);
			this.pendingSeekTime = null;
		}

		this.play();
	}

	// Hides cursor during ad play in fullscreen if mouse is idle
	private isFullScreenAd() {
		return this.presoModel.isFullscreen && this.adInProgress;
	}

	private cancelPointerLock = () => {
		document.removeEventListener('mousemove', this.cancelPointerLock);
		document.exitPointerLock?.();
		this.pointerLocked = false;
	};

	private setPointerState = async () => {
		if (this.pointerLocked && !this.isFullScreenAd()) {
			this.cancelPointerLock();

			return;
		}
		else if (!this.pointerLocked && this.isFullScreenAd()) {
			const element = this.domProxy?.getMain();

			if (!element || !element.requestPointerLock) {
				return;
			}

			// Chrome/Edge's implementation of requestPointerLock returns a promise, which sometimes rejects.
			// Use async/await to wrap calls in a promise to ensure all errors can be handled properly.
			try {
				this.pointerLocked = true;

				await element.requestPointerLock();

				// need to use a timeout for firefox
				this.cancelTimeout = setTimeout(() => document?.addEventListener('mousemove', this.cancelPointerLock), 100);
			}
			catch (error) {
				this.pointerLocked = false;
				this.logger.warn(error);
			}
		}
	};

	private hMovement = (e: Event) => {
		if (this.presoModel.isFullscreen) {
			this.pointerLockDebouncer();
		}
	};

	private monitorActivity(flag: boolean = false) {
		if (!System.isDesktop) {
			return;
		}
		const verb = flag ? 'addEventListener' : 'removeEventListener';
		this.domProxy?.getMain()?.[verb]('mousemove', this.hMovement);
	}

	///////////////////////////////////////////////////////////
	// Respond to playback commands and adapter events
	// +  calls to adapter
	override handleNotification(notification: NotificationInterface): void {
		const { name, body } = notification;
		let fsEvent = false;

		switch (name) {
			case PlayerEvent.FULLSCREEN_CHANGE:
				this.adAdapter?.setFullscreenState(body.fullscreen);
				fsEvent = true;
				break;

			case PlayerEvent.QUALITY_CHANGE:
				const br = body.quality?.bitrate ? Math.round(body.quality.bitrate * 0.001) : -1;
				this.adAdapter?.setContentBitrate(br);
				break;
		}

		super.handleNotification(notification);

		if (fsEvent) {
			this.monitorActivity(body.fullscreen);
		}
	}

	protected updateSize() {
		const dimensions = this.domProxy?.getPresentationRect();
		if (dimensions) {
			this.adAdapter?.updateSize(dimensions);
		}
	}

	protected override respondToSizeChange(): void {
		this.updateSize();
		super.respondToSizeChange();
	}

	protected override respondToId3Data(d: any): void {
		this.adAdapter.handleTimedMetadata(d);
		super.respondToId3Data(d);
	}

	protected override respondToVideoPaused(): void {
		super.respondToVideoPaused();

		if (this.adInProgress) {
			this.notify(PlayerEvent.AD_PAUSED);
		}
		else {
			this.respondToPlaybackStateChange(PlaybackState.PAUSED);
			this.notify(PlayerEvent.CONTENT_PAUSED);
		}
	}

	protected override respondToPlaybackStateChange(state: PlaybackState) {
		if (this.isPreloading && state === PlaybackState.WAITING) {
			return;
		}

		super.respondToPlaybackStateChange(state);
	}

	protected override respondToVideoPlaying(): void {
		super.respondToVideoPlaying();

		this.signalPlaying();
	}

	// Call plugin.setStreamTime()
	protected override respondToVideoTimeUpdate(streamTime: number): void {
		const cps = this.contentPlaybackStateProxy.model;
		const validStreamTime = streamTime > 0.75;

		this.presoModel.streamTime = streamTime;
		this.adAdapter?.setStreamTime(streamTime);

		cps.time = this.contentTimeForStreamTime(streamTime);

		if (this.adInProgress) {
			this.updateContentProgress(streamTime);

			return;
		}

		if (this.fatalContentErrorReceived) {
			return;
		}

		if (validStreamTime && !this.presoModel.adSegmentEntered && !this.contentSegmentStarted) {
			this.startContentSegment();
		}

		if (validStreamTime && this.contentSegmentStarted || this.presoModel.adSegmentEntered) {
			super.respondToVideoTimeUpdate(streamTime);
		}

		if (cps.streamType !== StreamType.LIVE && (cps.time >= cps.duration) && !this.contentCompleteReleased) {
			this.signalContentComplete();
		}
	}

	protected endContentSegment() {
		if (this.contentSegmentStarted) {
			this.contentSegmentStarted = false;
			if (this.hasMidRolls || this.isLiveOrLts()) {
				this.notify(PlayerEvent.CONTENT_SEGMENT_END);
			}
		}
	}

	protected isLiveOrLts() {
		const { streamType } = this.contentPlaybackStateProxy.model;

		return streamType === StreamType.LIVE || streamType === StreamType.LTS;
	}

	protected override respondToVideoEnd(): void {
		if (!this.contentCompleteReleased) {
			this.signalContentComplete();
		}

		this.adAdapter?.contentComplete();

		const hasPost = this.breakSchedule?.find(b => b.type === AdBreakType.POST);

		if (!hasPost) {
			super.respondToVideoEnd();
		}
	}

	protected override respondToDurationChange(duration: number): void {
		if (duration && !isNaN(duration) && duration > 0) {

			this.adAdapter.setStreamDuration(duration);
			this.presoModel.streamDuration = duration;

			const contentDur = this.contentTimeForStreamTime(duration);

			this.contentPlaybackStateProxy.model.duration = contentDur;

			if (!this.contentDurationReleased) {
				this.releaseContentDuration(duration);
			}
		}
	}

	protected override respondToError(data: ErrorInfoInterface) {
		this.fatalContentErrorReceived = data.fatal;
		super.respondToError(data);
	}

	protected override isContentComplete() {
		return this.contentComplete;
	}

	protected override checkVideoBuffering(count: number): void {
		super.checkVideoBuffering(count);
		this.adAdapter?.setBuffering?.(this.contentIsBuffering);
	}

	///////////
	// PRIVATE
	protected override startContentSegment(): void {
		const cps = this.contentPlaybackStateProxy.model;
		this.contentSegmentStarted = true;
		cps.started = true;
		this.domProxy?.showAdClickElement(false);
		this.domProxy?.showAdContainer(false);

		if (!this.contentStartReleased) {
			this.signalContentStart();
		}

		if (this.hasMidRolls || cps.streamType === StreamType.LIVE || cps.streamType === StreamType.LTS) {
			this.notify(PlayerEvent.CONTENT_SEGMENT_START);
		}
	}

	private checkPreload(currentTime: number, duration: number): void {
		if (this.isPreloading) {
			return;
		}

		const preload = this.resourceProxy?.resource?.ad?.csai?.preloadContentAtEndOfPreRoll === true;
		if (!preload) {
			return;
		}

		const lastAd = this.currentAd?.adPosition === this.currentBreak?.adTotal;
		if (lastAd && !this.system.isIos) {
			const threshold = clampValue(Math.ceil(duration / 4), 2, 10);
			const time = Math.round(currentTime);
			if (time >= threshold) {
				this.isPreloading = true;
				this.load().catch(this.onLoadError);
			}
		}
	}

	private async signalPlaying(): Promise<void> {
		clearTimeout(this.playingTimeout);

		if (!this.adAdapterStarted) {
			this.adAdapterStarted = true;
			await this.adAdapter.start();
		}

		if (this.adInProgress) {
			this.notify(PlayerEvent.AD_PLAYING);
		}
		else {
			const t = this.contentPlaybackStateProxy.model.time;
			if (t < 0.5) {
				this.playingTimeout = setTimeout(() => this.respondToVideoPlaying(), (0.5 - t) * 1000);
				return;
			}

			this.notify(PlayerEvent.CONTENT_PLAYING);
		}
	}

	private signalContentStart(): void {
		this.contentStartReleased = true;
		this.notify(PlayerEvent.CONTENT_START);
	}

	private signalContentComplete(): void {
		this.contentComplete = true;
		if (this.contentSegmentStarted) {
			this.endContentSegment();
		}
		this.contentCompleteReleased = true;
		this.notify(PlayerEvent.CONTENT_COMPLETE);
	}

	private contentTimeForStreamTime(time: number) {
		return this.adAdapter?.contentTimeForStreamTime ? this.adAdapter.contentTimeForStreamTime(time) : time;
	}

	private streamTimeForContentTime(time: number) {
		return this.adAdapter?.streamTimeForContentTime ? this.adAdapter.streamTimeForContentTime(time) : time;
	}

	private getPermittedSeekTime(time: number) {
		return this.adAdapter?.getPermittedSeekTime ? this.adAdapter.getPermittedSeekTime(time) : time;
	}

	override listNotificationInterests(): string[] {
		return super.listNotificationInterests().concat([
			PlayerEvent.FULLSCREEN_CHANGE,
			PlayerEvent.QUALITY_CHANGE,
		]);
	}

	override onRegister() {
		super.onRegister();

		const { idleDelay } = this.facade.retrieveMediator(MediatorName.ACTIVITY) as ActivityMediator;
		this.pointerLockDebouncer = debounce(this.setPointerState, idleDelay);

		this.domProxy?.showAdContainer(false);
		this.domProxy?.showAdClickElement(false);
	}
}
