import { ModelName } from '../../enum/ModelName';
import { ProxyName } from '../../enum/ProxyName';
import { NotificationInterface } from '../../iface';
import { PlayerDomInterface } from '../../iface/PlayerDomInterface';
import { PlayerOptionsInterface } from '../../iface/PlayerOptionsInterface';
import { AdapterProxy } from '../../model/AdapterProxy';
import { LocalizationProxy } from '../../model/LocalizationProxy';
import { ModelCollectionProxy } from '../../model/ModelCollectionProxy';
import { PerformanceProxy } from '../../model/PerformanceProxy';
import { PlayerDomProxy } from '../../model/PlayerDomProxy';
import { TextTrackProxy } from '../../model/TextTrackProxy';
import { VideoProxy } from '../../model/VideoProxy';
import { ModelCollection } from '../../model/vo/ModelCollection';
import { isUndefined } from '../../util/Type';
import { LogAwareSimpleCommand } from '../LogAwareSimpleCommand';


export class PrepModelCommand extends LogAwareSimpleCommand {

	execute(notification: NotificationInterface): void {
		const sys = this.system;
		const mdlColl = new ModelCollection();
		const mdlCollProxy = new ModelCollectionProxy(ProxyName.ModelCollectionProxy, mdlColl);
		const playerOptions = notification.body.playerOptions as PlayerOptionsInterface;

		// Model collection Proxy
		this.facade.registerProxy(mdlCollProxy);
		this.setPlayerOptions(mdlCollProxy, playerOptions);

		// Adapter Proxy
		this.facade.registerProxy(new AdapterProxy());

		// Performance Proxy
		this.facade.registerProxy(new PerformanceProxy(ProxyName.PerformanceProxy));

		// Localization Proxy
		const loc = new LocalizationProxy(ProxyName.LocalizationProxy);
		this.facade.registerProxy(loc);

		// Text Track Proxy
		this.facade.registerProxy(new TextTrackProxy(ProxyName.TextTrackProxy, { native: playerOptions.renderTextTrackNatively, language: playerOptions.textLanguage }));

		// Video Proxy
		this.facade.registerProxy(new VideoProxy(ProxyName.VideoProxy, { video: null }));

		// DOM Proxy
		sys.isBrowser && this.createDomProxy(mdlCollProxy, playerOptions);

		this.logger.debug('Model prepared');
	}

	private setPlayerOptions(proxy: ModelCollectionProxy, options: PlayerOptionsInterface): void {
		const model = proxy.getModel(ModelName.PlayerOptions) as PlayerOptionsInterface;

		for (const q in options) {
			// @ts-ignore
			!isUndefined(model[q]) && (model[q] = options[q]);
		}
	}

	private createDomProxy(proxy: ModelCollectionProxy, playerOptions: PlayerOptionsInterface): void {
		const mdl = proxy.getModel(ModelName.PlayerDom) as PlayerDomInterface,
			domCollProxy = new PlayerDomProxy(ProxyName.PlayerDomProxy, mdl, playerOptions);

		this.facade.registerProxy(domCollProxy);
	}
}
