import screenfull from 'screenfull';
import { Browser } from '../enum/Browser';
import { Device } from '../enum/Device';
import { Os } from '../enum/Os';
import { Platform } from '../enum/Platform';
import { VersionInfoInterface } from '../iface';
import { BrowserInfoInterface } from '../iface/BrowserInfoInterface';
import { PlatformInfoInterface } from '../iface/PlatformInfoInterface';
import { SupportedMediaInterface } from '../iface/SupportedMediaInterface';
import { SystemInfoInterface } from '../iface/SystemInfoInterface';
import { SystemInterface } from '../iface/SystemInterface';
import { MediaTypes } from './MediaTypes';
import { merge } from './ObjectUtil';
import { isFunction, isObject, isString } from './Type';

const systemGlobal: any = typeof window === 'object' ? window : null;

// detect LINUX
function isLinux(userAgent: string, platformInfo: PlatformInfoInterface): boolean {
	const regex = new RegExp(Platform.LINUX, 'i');
	// @ts-ignore
	const uaData = navigator.userAgentData;
	const platform = navigator.platform || uaData?.platform;
	const isArm = /Linux armv/.test(platform);
	const fvpre = /FVC\/(\d+\.\d+)/i;
	const os = userAgent.indexOf(Os.ANDROID) >= 0 ? Os.ANDROID : Platform.LINUX;
	const VODAFONE = 'VODAFONE';
	const YOUVIEW = 'YouView';

	if (regex.test(userAgent) || userAgent.indexOf(Os.ANDROID) >= 0 || userAgent.indexOf(VODAFONE) >= 0 || userAgent.indexOf(YOUVIEW) >= 0 || fvpre.test(userAgent)) {

		platformInfo.platform = Platform.LINUX;
		platformInfo.device = Device.UNKNOWN;

		if (userAgent.indexOf(Os.CHROMIUM) >= 0) {
			/* eslint-disable-next-line*/
			const re = /Chromium\/([\d+\.]*)\s/;
			const test = re.exec(userAgent);

			platformInfo.os = Os.CHROMIUM;
			platformInfo.osVersion = (test && test[1]) || '';
		}
		else if (fvpre.test(userAgent)) {
			platformInfo.os = os;
			platformInfo.device = Device.FREEVIEWPLAY;
		}
		else if (userAgent.indexOf(YOUVIEW) >= 0) {
			platformInfo.os = os;
			platformInfo.device = Device.YOUVIEW;
		}
		else if (userAgent.indexOf(Os.ANDROID) >= 0) {
			const re = / Android ([^\s]+);/;
			const test = re.exec(userAgent);

			platformInfo.os = Os.ANDROID;
			platformInfo.osVersion = (test && test[1]) || '';
			platformInfo.device = Device.ANDROID_PHONE;
		}
		else if (userAgent.indexOf(Os.WEBOS) >= 0) {
			platformInfo.os = Os.WEBOS;
			platformInfo.osVersion = 'n/a';
			platformInfo.device = Device.LG_SMART_TV;
		}
		else if (userAgent.indexOf(Os.SMARTCAST) >= 0) {
			platformInfo.os = Os.SMARTCAST;
			platformInfo.osVersion = 'n/a';
			platformInfo.device = Device.VIZIO_SMART_TV;
		}
		else if (userAgent.indexOf(Os.TIZEN) >= 0) {
			const re = /Tizen ([^\s]+)(;|\))/;
			const test = re.exec(userAgent);
			platformInfo.os = Os.TIZEN;
			platformInfo.osVersion = (test && test[1]) || '';
			platformInfo.device = Device.SAMSUNG_SMART_TV;
		}
		else if (userAgent.indexOf('Sky') >= 0) {
			platformInfo.os = Platform.LINUX;
			platformInfo.osVersion = 'n/a';
			platformInfo.device = Device.SKY_Q;
		}
		else if (userAgent.indexOf('Foxtel') >= 0) {
			platformInfo.os = Platform.LINUX;
			platformInfo.osVersion = 'n/a';
			platformInfo.device = Device.FOXTEL;
		}
		else if (userAgent.indexOf('VirginMedia') >= 0) {
			platformInfo.os = Platform.LINUX;
			platformInfo.osVersion = 'n/a';
			platformInfo.device = Device.VIRGIN_MEDIA;
		}
		else if (userAgent.indexOf('Philips') >= 0) {
			platformInfo.os = Platform.LINUX;
			platformInfo.osVersion = 'n/a';
			platformInfo.device = Device.PHILLIPS_SMART_TV;
		}
		else if (userAgent.indexOf('SOPOpenBrowser') >= 0) {
			platformInfo.os = Platform.LINUX;
			platformInfo.osVersion = 'n/a';
			platformInfo.device = Device.ORANGE;
		}
		else if (userAgent.indexOf('Swisscom') >= 0) {
			platformInfo.os = Os.ANDROID;
			const version = userAgent.match(/IP(\d+)/);
			platformInfo.osVersion = version ? `IP ${version[1]}` : 'n/a';
			platformInfo.device = Device.SWISSCOM;
		}
		else if (userAgent.indexOf('Vestel') >= 0) {
			platformInfo.os = Os.TIVO;
			const version = userAgent.match(/TiVoOS\/([\d.]+)/);
			platformInfo.osVersion = version ? version[1] : 'n/a';
			platformInfo.device = Device.VESTEL;
		}
		else if (userAgent.indexOf(VODAFONE) >= 0) {
			platformInfo.os = os;
			platformInfo.osVersion = 'n/a';
			platformInfo.device = Device.VODAFONE;
		}
		//@ts-ignore
		else if (typeof $badger !== 'undefined' || / Xfinity/.test(userAgent) || / WPE/.test(userAgent)) {
			platformInfo.os = Platform.LINUX;
			platformInfo.osVersion = 'n/a';
			platformInfo.device = Device.COMCAST_X1;
		}
		else if (isArm) {
			platformInfo.os = Platform.LINUX;
			platformInfo.osVersion = 'n/a';
			platformInfo.device = Device.EMBEDDED;
		}
		return true;
	}

	return false;
}

// detect Mac OS
function isMac(userAgent: string, platformInfo: PlatformInfoInterface): boolean {
	if (userAgent.indexOf(Platform.MAC) >= 0) { // OSX desktop OR iPad
		platformInfo.platform = Platform.MAC;

		const iPad = userAgent.match(/(iPad)/) || navigator.maxTouchPoints > 1;

		if (iPad) {
			platformInfo.device = Device.IPAD;
			platformInfo.os = Os.IOS;
		}
		else if (userAgent.indexOf(Os.OSX) >= 0) {
			platformInfo.device = Device.DESKTOP;
			platformInfo.os = Os.OSX;
		}

		const test = /Mac OS X ([\d.]+)/.exec(userAgent);
		platformInfo.osVersion = (test && test[1]) ? test[1] : null;

		return true;
	}
	else if (userAgent.indexOf('like Mac OS X') !== -1) {
		// iOS; 'like Mac...' is used for non-OSX Mac devices
		platformInfo.platform = Platform.MAC;
		platformInfo.os = Os.IOS;
		platformInfo.device = Device.UNKNOWN;

		const re = /\sOS\s([^\s]*)\s/;
		const version = re.exec(userAgent);

		if (version && version[1]) {
			platformInfo.osVersion = version[1];
		}

		const devices = [/\(iPhone;/, /\(iPad;/, /\(iPod;/];
		const names = [Device.IPHONE, Device.IPAD, Device.IPOD];
		let i = devices.length;

		while (i--) {
			if (devices[i].test(userAgent)) {
				platformInfo.device = names[i];

				return true;
			}
		}

		return true;
	}

	return false;
}

// detect Windows
function isWin(userAgent: string, platformInfo: PlatformInfoInterface): boolean {
	/* eslint-disable */
	let re = /(Windows Phone |Windows Phone OS )([^\s]+);/,
		reNt = /Windows NT ([^\s]+)[;\)]/,
		win = re.exec(userAgent);
	/* eslint-enable */
	if (win && win[2]) {
		platformInfo.platform = Platform.WINDOWS;
		platformInfo.os = Os.WINDOWS_PHONE;
		platformInfo.osVersion = win[2];
		platformInfo.device = Device.WINDOWS_PHONE;

		return true;
	}
	else {
		const test = reNt.exec(userAgent);

		if (test && test[1]) {
			platformInfo.device = Device.DESKTOP;
			platformInfo.platform = Platform.WINDOWS;
			platformInfo.os = Os.WINDOWS;

			const osVersionNbr = test[1];

			if (/Xbox; Xbox One/.test(userAgent)) {
				platformInfo.device = Device.XBOX_ONE;
			}

			if (parseInt(osVersionNbr) === 10) {
				platformInfo.os = Os.WINDOWS10;
				platformInfo.osVersion = osVersionNbr;
			}
			else {
				switch (osVersionNbr) {
					// MS version number/os designations
					case '6.0':
						platformInfo.os = Os.WINDOWS_VISTA;
						break;
					case '6.1':
						platformInfo.os = Os.WINDOWS7;
						platformInfo.osVersion = '7';
						break;
					case '6.2':
						platformInfo.os = Os.WINDOWS8;
						platformInfo.osVersion = '8';
						break;
				}
			}

			return true;
		}
	}

	return false;
}

// detect MS BROWSER
function getMsBrowser(reEdge: RegExp, reIe: RegExp, userAgent: string) {
	// Trident (IE rendering engine) checked last to distinguish IE and Edge
	const tri = /Trident.[^\s]+;.*rv:([^\s]+)[);]/;

	if (reIe.test(userAgent)) {
		return { browser: Browser.MSIE, re: reIe };
	}
	else if (reEdge.test(userAgent)) {
		return { browser: Browser.EDGE, re: reEdge };
	}
	else if (tri.test(userAgent)) {
		return { browser: Browser.MSIE, re: tri };
	}

	return { browser: Browser.UNKNOWN, re: null };
}

// detect SAFARI
function getSafariBrowser(re: RegExp, userAgent: string, platformInfo: PlatformInfoInterface): Browser | null {
	const test = re.test(userAgent),
		browserName = platformInfo.os === Os.ANDROID ? Browser.ANDROID : Browser.SAFARI;

	if (test && platformInfo.os !== 'Unknown' && /MacIntel|iPhone|iPad/.test(navigator.platform)) {
		return browserName;
	}

	return null;
}

function getBrowserInfo(userAgent: string, platformInfo: PlatformInfoInterface): BrowserInfoInterface {
	const re: Record<string, any> = {
		[Browser.CHROME]: /Chrome\/(\S+)/,
		[Browser.CHROME_IOS]: /CriOS\/(\S+)/,
		[Browser.FIREFOX_IOS]: /FxiOS\/(\S+)/,
		[Browser.EDGE]: / Edge?\/([^\s]+)/,
		[Browser.FIREFOX]: /Firefox\/(\S+)/,
		[Browser.OPERA]: /Opera|OPR\/(\S+)/,
		[Browser.SAFARI]: /Version\/(\S+).*Safari/,
		[Browser.MSIE]: /MSIE ([^\s]+)[);] Windows/,
		[Browser.ANDROID]: /Android/,
		[Browser.SILK]: /Silk\/(\S+)/,
		[Browser.PLAYSTATION_4_WEBMAF]: /Playstation 4 (WebMAF) (\S+)/,
		[Browser.PLAYSTATION_4_MSE]: /WebMAF\/v3/,
		[Browser.PLAYSTATION_5]: /PlayStation 5/,
		[Browser.WEBKIT]: / WPE/,
		[Browser.UNKNOWN]: null,
	};
	const isChromeIos = re[Browser.CHROME_IOS].test(userAgent);
	const isChrome = (
		(re[Browser.CHROME].test(userAgent) || isChromeIos) &&
		!(re[Browser.OPERA].test(userAgent)) &&
		!(re[Browser.EDGE].test(userAgent))
	);
	const assembleInfo = (bName: Browser | string, re: RegExp | null, ua: string): BrowserInfoInterface => {
		const browser: any = re && re.exec(ua),
			out = {
				userAgent,
				browser: bName,
				browserVersion: '0',
				browserMajorVersion: 0,
			};

		if (browser && browser.length && browser.length > 1) {
			out.browserVersion = browser[1] || '';

			if (out.browserVersion.indexOf('.')) {
				const ar = out.browserVersion.split('.');

				if (Array.isArray(ar) && ar.length) {
					const mv = parseInt(ar[0]);
					!isNaN(mv) && (out.browserMajorVersion = mv);
				}
			}
			else {
				const mv = parseInt(out.browserVersion);
				!isNaN(mv) && (out.browserMajorVersion = mv);
			}
		}

		return out;
	};

	// checked for Chrome first above - it has a majority
	if (isChrome) {
		const name = isChromeIos ? Browser.CHROME_IOS : Browser.CHROME;

		return assembleInfo(name, re[name], userAgent);
	}

	const b = getMsBrowser(re[Browser.EDGE], re[Browser.MSIE], userAgent);
	if (b.browser !== Browser.UNKNOWN) {
		return assembleInfo(b.browser, b.re, userAgent);
	}

	// non-MS browsers
	for (const q in re) {
		switch (q) {
			case Browser.FIREFOX: // fall thru
			case Browser.FIREFOX_IOS: // fall thru
			case Browser.SILK: // fall thru
			case Browser.ANDROID: // fall thru
				if (re[q].test(userAgent)) {
					if (q === Browser.SILK) {
						platformInfo.device = Device.KINDLE;
					}
					return assembleInfo(q, re[q], userAgent);
				}
				break;

			case Browser.WEBKIT:
				return assembleInfo(Browser.WEBKIT, re[Browser.WEBKIT], userAgent);

			case Browser.PLAYSTATION_4_WEBMAF:
			case Browser.PLAYSTATION_4_MSE:
			case Browser.PLAYSTATION_5:
				if (re[q].test(userAgent)) {
					const isPS5: boolean = re[Browser.PLAYSTATION_5].test(userAgent);
					let browser: Browser;

					if (isPS5) {
						browser = Browser.PLAYSTATION_5;
					}
					else {
						browser = re[Browser.PLAYSTATION_4_MSE].test(userAgent) ? Browser.PLAYSTATION_4_MSE : Browser.PLAYSTATION_4_WEBMAF;
					}

					platformInfo.device = isPS5 ? Device.PLAYSTATION_5 : Device.PLAYSTATION_4;
					platformInfo.platform = isPS5 ? Device.PLAYSTATION_5 : Device.PLAYSTATION_4;
					platformInfo.os = isPS5 ? Os.PROSPERO : Os.ORBIS;

					return assembleInfo(browser, re[browser], userAgent);
				}
				break;

			case Browser.SAFARI:
				/*eslint-disable-next-line*/
				let b = getSafariBrowser(re[q], userAgent, platformInfo);
				if (b) {
					return assembleInfo(q, re[q], userAgent);
				}
				break;

			case Browser.OPERA:
				/*eslint-disable-next-line*/
				let test = re[q].exec(userAgent);

				if (test) {
					const browser = (
						test.indexOf('Opera Mini/') >= 0 ||
						test.indexOf('Opera Mobi') >= 0
					) ? Browser.OPERA_MOBILE : Browser.OPERA;

					return assembleInfo(browser, re[q], userAgent);
				}
				break;

			case Browser.UNKNOWN:
				break;

		}
	}

	return assembleInfo(Browser.UNKNOWN, re[Browser.UNKNOWN], userAgent);
}

function getPlatform(userAgent: string): PlatformInfoInterface {
	const ual = userAgent.toLowerCase();
	const out = {
		userAgent,
		os: Os.UNKNOWN,
		platform: Platform.UNKNOWN,
		osVersion: '',
		device: Device.UNKNOWN,
	};

	// Firefox on iOS
	if (ual.indexOf('fxios') >= 0) {
		out.os = Os.IOS;
		out.platform = Platform.MAC;
		if (ual.indexOf('iphone') >= 0) {
			out.device = Device.IPHONE;
		}
		else { // iPad
			out.device = Device.IPAD;
		}

		return out;
	}

	// Chromecast
	if (ual.indexOf('crkey') >= 0) {
		out.platform = Platform.LINUX;
		out.device = Device.CHROMECAST;

		return out;
	}

	const re = /\s(\([^)]+\))/;
	const test = re.exec(userAgent);

	if (test && test.length && test.length > 1) {
		const pStr = test[1].replace(/_/gi, '.'); // 'platform string'
		const tests = [
			(str: string, obj: PlatformInfoInterface) => isLinux(str, obj),
			(str: string, obj: PlatformInfoInterface) => isMac(str, obj),
			(str: string, obj: PlatformInfoInterface) => isWin(str, obj),
		];

		let i = tests.length;

		while (i--) {
			const ti = tests[i];
			if (ti(i === 0 ? userAgent : pStr, out)) {
				break;
			}
		}
	}

	return out;
}

function parseVerStr(str: string): VersionInfoInterface {
	const out: VersionInfoInterface = {
		majorVersion: null,
		minorVersion: null,
		versionString: null,
	};

	if (isString(str)) {
		out.versionString = str;

		const spl = str.split('.');
		const mj = parseInt(spl[0], 10);
		const mn = parseInt(spl[1], 10);

		!isNaN(mj) && (out.majorVersion = mj);
		!isNaN(mn) && (out.minorVersion = mn);
	}

	return out;
}

function update(global: any, systemInfo: SystemInfoInterface): SystemInterface {
	const document = global.document || null;
	const { os, platform, osVersion, device, browser, browserVersion, userAgent } = systemInfo;

	const osVersionInfo = parseVerStr(osVersion);
	const browserVersionInfo = parseVerStr(browserVersion);

	const isTv = (() => {
		switch (device) {
			case Device.LG_SMART_TV:
			case Device.VIZIO_SMART_TV:
			case Device.SAMSUNG_SMART_TV:
			case Device.PHILLIPS_SMART_TV:
			case Device.COMCAST_X1:
			case Device.SKY_Q:
			case Device.SWISSCOM:
			case Device.FOXTEL:
			case Device.VIRGIN_MEDIA:
			case Device.VESTEL:
			case Device.VODAFONE:
			case Device.ORANGE:
			case Device.YOUVIEW:
			case Device.FREEVIEWPLAY:
				return true;

			default:
				return false;
		}
	})();

	const isDesktop = device === Device.DESKTOP;

	const isConsole = (() => {
		switch (device) {
			case Device.PLAYSTATION_4:
			case Device.PLAYSTATION_5:
			case Device.XBOX_ONE:
				return true;

			default:
				return false;
		}
	})();

	const isReactNative = navigator.product === 'ReactNative';

	// Media capabilities not requiring use of MediaCapabilities API
	const isWebkit = !!(global.WebKitMediaSource);
	const hasMediaSource = !!(global.MediaSource || isWebkit);

	const isTypeSupported = (codec: string) => {
		let supported: boolean = false;

		if (isWebkit) {
			supported = global.WebKitMediaSource.isTypeSupported(codec);
		}
		else if (hasMediaSource) {
			supported = global.MediaSource.isTypeSupported(codec);
		}

		if (!supported && document) {
			//final check for codec support.
			const element = document.createElement('video');
			if (isObject(element) && isFunction(element.canPlayType)) {
				if (element.canPlayType(codec) === 'probably' || element.canPlayType(codec) === 'maybe') {
					supported = true;
				}
			}
		}

		return supported;
	};

	const supportsUnrestrictedAutoplay = isTv || isConsole || isReactNative;
	const isWebMaf = !!global['WM_videoPlayer'];

	const supportedMedia: SupportedMediaInterface = {
		supportsHevc: isTypeSupported(MediaTypes.video.HEVC),
		supportsHevcSdr: isTypeSupported(MediaTypes.video.HEVC_SDR),
		supportsDolbyDigitalPlus: isTypeSupported(MediaTypes.audio.DOLBY_DIGITAL_PLUS),
		supportsDolbyVision: isTypeSupported(MediaTypes.video.DOLBY_VISION),
	};

	return {
		isBrowser: browser !== Browser.NONE && !isWebMaf && !!document && !isReactNative,
		userAgent,
		browser,
		browserVersionInfo,
		device,
		os,
		osVersionInfo,
		platform,
		isAndroid: device === Device.ANDROID_PHONE || os === Os.ANDROID,
		isChromecast: device === Device.CHROMECAST,
		isDesktop,
		isEmbedded: device === Device.EMBEDDED,
		isIos: os === Os.IOS,
		isMobile: !isDesktop,
		isTizen: device === Device.SAMSUNG_SMART_TV,
		isTv,
		isConsole,
		isWebkit,
		isWebMaf,
		isWebOs: os === Os.WEBOS,
		isXboxOne: device === Device.XBOX_ONE,
		hasFullscreenApi: screenfull.isEnabled,
		hasMediaSource: hasMediaSource,
		hasEncryptedMediaSource: !!(global.MediaKeys || global.WebKitMediaKeys || global.MSMediaKeys),
		supportsHevc: isTypeSupported(MediaTypes.video.HEVC),
		supportsMediaDecodeConfigurations: !!navigator.mediaCapabilities,
		supportsNativeHls: isTypeSupported(MediaTypes.video.HLS),
		supportsRequestMediaKeySystemAccess: !!(global.MediaKeySystemAccess),
		supportsUnrestrictedAutoplay,
		supportedMedia,
	} as SystemInterface;
}

const systemInfo: SystemInfoInterface = {};

function createSystem(): SystemInterface {
	// not a browser
	if (!systemGlobal) {
		return { global: systemGlobal, isBrowser: false } as SystemInterface;
	}

	const navigator = systemGlobal.navigator || { userAgent: '' };
	const userAgent = navigator.userAgent || '';
	const platformInfo = getPlatform(userAgent);
	const browserInfo = getBrowserInfo(userAgent, platformInfo);

	merge(systemInfo, { ...platformInfo, ...browserInfo });

	return Object.assign(update(systemGlobal, systemInfo), {
		appNamespace: null,
		document: systemGlobal.document,
		global: systemGlobal,
	});
}

export const System = createSystem();

/**
 * Update the system information object with new values.
 *
 * @param systemInfo - the new system information
 */
export function updateSystem(systemInfoUpdate: SystemInfoInterface) {
	merge(System, update(systemGlobal, Object.assign(systemInfo, systemInfoUpdate)));
}
