import { AppResources } from '../app/AppResources';
import { Browser } from '../enum/Browser';
import { Device } from '../enum/Device';
import { Os } from '../enum/Os';
import { ServiceName } from '../enum/ServiceName';
import { AutoplayInfoInterface } from '../iface/AutoplayInfoInterface';
import { System } from '../util/System';
import { ServiceBase } from './ServiceBase';


export class AutoplayCapabilitiesService extends ServiceBase {

	private static instance: AutoplayCapabilitiesService;

	static getInstance(): AutoplayCapabilitiesService {
		if (!this.instance) {
			this.instance = new AutoplayCapabilitiesService();
		}

		return this.instance;
	}

	private info: AutoplayInfoInterface;
	private testDom: HTMLElement = null;
	private internalCallback: () => void;
	private checkCompleted: boolean = false;
	private testVideoSrc: string = AppResources.blankDataUrl;
	private timeout: any;
	private detecting: Promise<AutoplayInfoInterface>;
	private readonly isMobile: boolean | null = null;

	constructor() {
		super(ServiceName.AutoplayCapabilities);

		this.info = {
			supportsUnmutedAutoplay: null,
			supportsMutedAutoplay: null,
		};

		if (System.supportsUnrestrictedAutoplay) {
			this.setInfo(true, true);
			this.checkCompleted = true;
		}
	}

	get autoplayInfo(): AutoplayInfoInterface {
		return this.info;
	}

	override destroy() {
		clearTimeout(this.timeout);
		super.destroy();
	}

	// Promise-based determination: Check unmuted first, then muted autoplay
	detectCapabilities(url?: string): Promise<AutoplayInfoInterface> {
		if (this.detecting) {
			return this.detecting;
		}

		if (url) {
			this.testVideoSrc = url;
		}

		return this.detecting = new Promise(resolve => {
			if (!this.checkCompleted) {
				this.createTestDom();
				this.internalCallback = () => resolve(this.info);
				this.checkUnmutedAutoplay();
			}
			else {
				resolve(this.info);
			}
		});
	}

	///////////
	// PRIVATE
	private finishCheck(unmutedOk: boolean, mutedOk: boolean): void {
		this.setInfo(unmutedOk, mutedOk);
		this.checkCompleted = true;
		this.internalCallback();
		this.timeout = setTimeout(() => this.cleanUp(), 100);
	}

	private setInfo(unmutedOk: boolean, mutedOk: boolean): void {
		this.info.supportsUnmutedAutoplay = unmutedOk;
		this.info.supportsMutedAutoplay = mutedOk;
	}

	private cleanUp() {
		this.testDom && this.testDom.parentNode.removeChild(this.testDom);
		this.testDom = null;
	}

	private checkUnmutedAutoplay(): void {
		const v = this.createVideoElement(false);

		if (!v) {
			// if not in a DOM or don't have a video element,
			// assume autoplay is supported
			this.finishCheck(true, true);

			return;
		}

		v.load();

		const promise = v.play();

		if (promise === undefined) {
			if (this.isMobile) {
				this.detectMobileCapabilities();
			}
			else {
				this.finishCheck(true, true);
			}
		}
		else {
			promise
				.then(() => {
					this.finishCheck(true, true);
				})
				.catch((e: Error) => {
					this.checkMutedAutoplay();
				});
		}
	}

	private checkMutedAutoplay(): void {
		// Disable autoplay for Safari versions < 11.0.3
		// if only muted autoplay is permitted - i.e., treat
		// 'Stop Media with Sound' same as 'No Autoplay'.
		if (this.isEarlySafari11()) {
			this.finishCheck(false, false);

			return;
		}

		const v = this.createVideoElement(true);

		v.load();
		v.play() // if we're here, we know that Promise is supported
			.then(() => {
				this.finishCheck(false, true);
			})
			.catch((e) => {
				if (v.error && this.testVideoSrc !== AppResources.blankDataUrl) {
					this.testVideoSrc = AppResources.blankDataUrl;
					this.checkUnmutedAutoplay();

					return;
				}

				this.finishCheck(false, false);
			});
	}

	private isEarlySafari11(): boolean {
		const isS = System.browser === Browser.SAFARI;
		const isD = isS && System.device === Device.DESKTOP;
		const maj = isD ? System.browserVersionInfo.majorVersion : null;
		const min = isD ? System.browserVersionInfo.minorVersion : null;
		const majMinMatch = maj === 11 && min === 0;
		const verStr = System.browserVersionInfo.versionString;
		const verArr = verStr ? verStr.split('.') : null;

		// return true for Safari < 11.0.3
		return majMinMatch && (!verArr[2] || parseInt(verArr[2]) < 3);
	}


	// mobile checks where no Promise available
	private detectMobileCapabilities(): void {
		const unmutedOk = false;
		const mutedOk = this.isAutoplayEligibleAndroid() || this.isAutoplayEligibleIos();

		this.finishCheck(unmutedOk, mutedOk);
	}

	private isAutoplayEligibleAndroid(): boolean {
		const isA = System.os === Os.ANDROID;
		const isOkChrome = isA && System.browser === Browser.CHROME && this.isChromeAutoplayEligible();

		return isOkChrome;
	}

	private isAutoplayEligibleIos(): boolean {
		const is10 = System.os === Os.IOS && System.browserVersionInfo.majorVersion >= 10;
		const isOkSafari = is10 && System.browser === Browser.SAFARI;
		const isOkChrome = is10 && !isOkSafari &&
			(System.browser === Browser.CHROME_IOS || System.browser === Browser.CHROME) &&
			this.isChromeAutoplayEligible();

		return isOkSafari || isOkChrome;
	}

	private isChromeAutoplayEligible(): boolean {
		// reported variously as v53 or v54; using >= 54 for safety
		return System.browserVersionInfo.majorVersion >= 54;
	}

	// util
	private createVideoElement(muted: boolean): HTMLVideoElement | null {
		const doc = this.document;
		if (!doc) {
			return null;
		}

		const v = doc.createElement('video');

		if (muted) {
			v.muted = true;
			v.playsInline = true;
		}

		v.volume = 0.005;
		v.style.width = '100%';
		v.style.height = '100%';

		v.src = this.testVideoSrc;

		this.testDom.appendChild(v);

		return v;
	}

	private createTestDom(): HTMLElement {
		const doc = this.document;
		if (!this.document) {
			return null;
		}

		const d = doc.createElement('div'),
			ds = d.style, p = '-1000px';

		ds.position = 'absolute';
		ds.top = p;
		ds.left = p;
		ds.width = '400px';
		ds.height = '225px';

		doc.body.appendChild(d);

		this.testDom = d;

		return d;
	}

	private get document(): Document | null {
		return System.document || null;
	}
}
