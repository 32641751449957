import { FileExtensionToMimeType } from '../enum/FileExtensionToMimeType';
import { MimeType } from '../enum/MimeType';


/**
 * Extract file extension from URI.
 */
export function getFileExtension(uri: string): string {
	return uri.replace(/\?.*/, '').replace(/#.*/, '').split('.').pop();
}

/**
 * Determine mime type from file extension.
 */
export function getMimeType(uri: string): MimeType {
	if (!uri) {
		return MimeType.UNKNOWN;
	}

	const ext = getFileExtension(uri).toUpperCase();

	return FileExtensionToMimeType[ext];
}
