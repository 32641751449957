import { EmitterInterface } from '../iface/EmitterInterface';
import { EventInterface } from '../iface/EventInterface';

export class CoreEvent implements EventInterface {

	timeStamp: number;
	detail: any;
	target: EmitterInterface;
	type: string;
	defaultPrevented_: boolean = false;

	/**
		 * Custom event class
		 */
	constructor(type: string, target: EmitterInterface, detail: any) {
		this.timeStamp = Date.now();
		this.detail = detail;
		this.target = target;
		this.type = type;
	}

	get defaultPrevented(): boolean {
		return this.defaultPrevented_;
	}

	preventDefault(): void {
		this.defaultPrevented_ = true;
	}
}
