import { ModelName } from '../../enum/ModelName';
import { PlayerDom } from '../../enum/PlayerDom';
import { ProxyName } from '../../enum/ProxyName';
import { NotificationInterface, PlayerDomProxyInterface } from '../../iface';
import { DomReference } from '../../iface/DomReference';
import { PlayerDomInterface } from '../../iface/PlayerDomInterface';
import { PlayerOptionsInterface } from '../../iface/PlayerOptionsInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { createPlayerDom } from '../../util/PlayerDom';
import { LogAwareSimpleCommand } from '../LogAwareSimpleCommand';


export class CreateDomCommand extends LogAwareSimpleCommand {

	execute(notification: NotificationInterface): void {
		const cfg: StrAnyDict = notification.body.playerOptions;
		const container: DomReference = cfg.container;
		const sys = this.system;
		const playerOpts = this.getModel(ModelName.PlayerOptions) as PlayerOptionsInterface;

		if (!sys.isBrowser || !container) {
			return;
		}

		const c = (
			typeof container === 'string' ? sys.document.querySelector(container) : container
		) as HTMLElement;

		let skipEls: PlayerDom[] = [];
		if (playerOpts.useNativeControls) {
			// Skip creation of these containers (not needed in a native control display)
			skipEls = [PlayerDom.CC_CONTAINER];
		}

		const domColl: PlayerDomInterface = createPlayerDom(c, skipEls, playerOpts, this.facade.appId);
		const domProxy = this.facade.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxyInterface;

		domProxy.setModel(domColl);
		domProxy.showAdContainer(false);

		this.logger.info('Player DOM created.');
	}
}
