import { Logger } from '../core/Logger';
import { PlayerEvent } from '../events/PlayerEvent';

/**
 * This is a collection of deprecated functionality
 *
 * @deprecated deprecated methods
 */
export class Deprecated {

	static events: string[] = [
		PlayerEvent.HOTKEY_ACTION,
	];

	static playerOptions: Record<string, string[]> = {
		old: ['keyboardScope', 'networkTimeout', 'networkErrorRecovery'],
		new: ['keyboard.scope', 'network.timeout', 'network.retryAttempts'],
	};

	/**
	 * @deprecated deprecation warning
	 */
	static warn(msg: string) {
		Logger.deprecated(msg);
	}

	/**
	 * @deprecated The callback argument of the registerPlugins function is deprecated. Please use the promise returned by registerPlugins instead. Access to the info property will be removed July 1st, 2024.
	 */
	static registerPlugins() {
		Deprecated.warn('The callback argument of the registerPlugins function is deprecated. Please use the promise returned by registerPlugins instead. Access to the info property will be removed July 1st, 2024.');
	}

	static checkEventName(name: string) {
		if (Deprecated.events.includes(name)) {
			Deprecated.warn(`The "${name}" event has been deprecated. This check will be removed July 1st, 2024`);
		}
	}

	static checkPlayerOptions(options: any) {
		const playerOptions = Deprecated.playerOptions;
		Object.keys(options).forEach(key => {
			const index = playerOptions.old.indexOf(key);
			if (index > -1) {
				Deprecated.warn(`The "${key}" player option has been deprecated. Please use "${playerOptions.new[index]}". This check will be removed July 1st, 2024`);
			}
		});
	}
}
