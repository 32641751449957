import { getConfiguration } from '../../app/Configuration';
import { KeyboardScope } from '../../enum/KeyboardScope';
import { MediatorName } from '../../enum/MediatorName';
import { ProxyName } from '../../enum/ProxyName';
import { ApplicationInterface, FacadeInterface, NotificationInterface, PlayerDomProxyInterface } from '../../iface';
import { PlayerOptionsInterface } from '../../iface/PlayerOptionsInterface';
import { SystemInterface } from '../../iface/SystemInterface';
import { merge } from '../../util/ObjectUtil';
import { ActionKeyMediator } from '../../view/ActionKeyMediator';
import { ActivityMediator } from '../../view/ActivityMediator';
import { AppMediator } from '../../view/AppMediator';
import { FullscreenMediator } from '../../view/FullscreenMediator';
import { LogAwareSimpleCommand } from '../LogAwareSimpleCommand';


export class PrepViewCommand extends LogAwareSimpleCommand {

	execute(notification: NotificationInterface) {
		const facade: FacadeInterface = this.facade;
		const app: ApplicationInterface = notification.body.app;

		facade.registerMediator(new AppMediator(MediatorName.APPLICATION, app));

		const system = this.system;
		if (!system.isBrowser) {
			this.logger.info('Skipping view creation');
			return;
		}

		// fullscreen
		if (!system.isTv) {
			facade.registerMediator(new FullscreenMediator(MediatorName.FULLSCREEN));
		}

		const { playerOptions } = notification.body;
		const domProxy = this.facade.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxyInterface;

		// key command mediator; set key handling scope
		const scope = this.getScope(system, playerOptions);
		const actionKeyMediator = new ActionKeyMediator(MediatorName.KEY_COMMAND, scope);
		facade.registerMediator(actionKeyMediator);
		this.logger.info(`Key handling scope is "${scope}"`);

		const target = actionKeyMediator.getKeyEventTarget();
		const container = domProxy.getMain();
		this.facade.registerMediator(new ActivityMediator(MediatorName.ACTIVITY, {
			container: target && target !== system.document ? target : container,
			document: system.document,
			idleDelay: playerOptions.idleDelay,
		}));

		this.logger.debug('View prepared');
	}

	getScope(system: SystemInterface, playerOptions: PlayerOptionsInterface): KeyboardScope {
		let playerScopeObj = {};

		if (playerOptions.keyboard?.scope) {
			playerScopeObj = playerOptions.keyboard;
		}
		else if (playerOptions.keyboardScope) {
			playerScopeObj = { scope: playerOptions.keyboardScope };
		}

		const { keyboard } = getConfiguration();
		const { scope } = merge({}, keyboard || {}, playerScopeObj);

		if (!system.isBrowser) {
			return KeyboardScope.NONE;
		}

		// default to DOCUMENT (changed 12/2023)
		return scope || KeyboardScope.DOCUMENT;
	}

}
