import { HdrCodec } from '../enum/HdrCodec';
import { QualityCategory } from '../enum/QualityCategory';
import { SdrCodec } from '../enum/SdrCodec';
import { DimensionsInterface } from '../iface/DimensionsInterface';
import { QualityInterface } from '../iface/QualityInterface';
import { entries, values } from './ObjectUtil';

export type QualitySizeCategories = Exclude<QualityCategory, QualityCategory.AUTO | QualityCategory.HDR | QualityCategory.SDR>;

export const QualityMap: Record<QualitySizeCategories, number> = {
	[QualityCategory.UHD_8K]: 4320,
	[QualityCategory.UHD_4K]: 2160,
	[QualityCategory.UHD]: 1440,
	[QualityCategory.HD]: 720,
	[QualityCategory.SD]: 0,
};

export function getMinAndMaxValuesForCategory(list: QualityInterface[], category: string) {
	let minIndex = Infinity;
	let maxIndex = 0;
	let minBitrate = Infinity;
	let maxBitrate = 0;

	list.forEach(q => {
		if (q.category.indexOf(category) >= 0) {
			maxIndex = Math.max(maxIndex, q.index);
			minIndex = Math.min(minIndex, q.index);
			maxBitrate = Math.max(maxBitrate, q.bitrate);
			minBitrate = Math.min(minBitrate, q.bitrate);
		}
	});

	return { maxIndex, minIndex, minBitrate, maxBitrate };
}

export function getQualityCategoryForVideoHeight(height: number): QualityCategory {
	return entries(QualityMap).find(map => height >= map[1])?.[0] || QualityCategory.SD;
}

export function getMaxHeightForQualityCategory(category: QualityCategory): number {
	if (!category || category === QualityCategory.AUTO) {
		return Infinity;
	}

	const item = entries(QualityMap).find((map, index, arr) => arr[index + 1]?.[0] === category);
	return item?.[1] - 1 || Infinity;
}

export function getMaxIndexForHeight(qualities: { bitrate: number; height: number; }[], maxHeight: number) {
	return qualities.reduce((max, level, index) => {
		if (level.height <= maxHeight) {
			const current = qualities[max];
			if (isNaN(max)) {
				max = index;
			}
			else if (level.height > current.height) {
				max = index;
			}
			else if (level.height === current.height && level.bitrate > current.bitrate) {
				max = index;
			}
		}

		return max;
	}, NaN);
}

export function getDynamicRange(quality: QualityInterface) {
	const { codec } = quality;

	if (!codec) {
		return '';
	}

	if (codec.startsWith(SdrCodec.AVC)) {
		return QualityCategory.SDR;
	}

	return values(HdrCodec).some(val => codec.startsWith(val)) ? QualityCategory.HDR : QualityCategory.SDR;
}

export function processQuality(quality: QualityInterface): QualityInterface {
	const category = [
		getQualityCategoryForVideoHeight(quality.height),
		`${quality.height}p`,
		`${Math.round(quality.bitrate / 1000)} kbps`,
		getDynamicRange(quality),
	];
	return { ...quality, category };
}

/**
 * Finds the closest bitrate without going below the target. If all items are
 * under the target, the last item is returned.
 *
 * @param qualities - The list of bitrates
 * @param target -    The target bitrate in bits
 *
 * @returns
 */
export function getMinBitrateIndex(qualities: { bitrate: number; }[], target: number) {
	/*
	 * | --400--- | --800--- | --1000--- | --1500--- | --2500--- |  should result in 1000
	 *               <----  900 is bitrate ------->
	 */
	const max = qualities.length - 1;
	if (target >= qualities[max].bitrate) {
		return max;
	}

	for (let i = max - 1; i > 0; i--) {
		const { bitrate } = qualities[i];
		if (target === bitrate) {
			return i;
		}
		else if (target > bitrate) {
			return i + 1;
		}
	}

	return 0;
}

/**
 * Finds the closest bitrate without going over the target. If all items are
 * over the target, the first item is returned.
 *
 * @param qualities - The list of bitrates
 * @param target -    The target bitrate in bits
 *
 * @returns
 */
export function getMaxBitrateIndex(qualities: { bitrate: number; }[], target: number) {
	/*
	 *               <----  900 is bitrate ------->
	 * | --400--- | --800--- | --1000--- | --1500--- | --2500--- | should result in 800
	 */
	if (target <= qualities[0].bitrate) {
		return 0;
	}

	const max = qualities.length - 1;
	for (let i = 1; i < max; i++) {
		const { bitrate } = qualities[i];
		if (target === bitrate) {
			return i;
		}
		else if (target < bitrate) {
			return i - 1;
		}
	}

	return max;
}

export function validateQuality(quality: { width: number, height: number, bitrate: number; }, minBitrate: number, maxBitrate: number, maxHeight?: number, bounds?: DimensionsInterface) {
	let valid = true;

	if (Number.isFinite(minBitrate)) {
		valid &&= quality.bitrate >= minBitrate;
	}

	if (Number.isFinite(maxBitrate)) {
		valid &&= quality.bitrate <= maxBitrate;
	}

	if (Number.isFinite(maxHeight)) {
		valid &&= quality.height <= maxHeight;
	}

	if (bounds) {
		const { width, height } = bounds;
		valid &&= (quality.width <= width || quality.height <= height);
	}

	return valid;
}

export function validateQualities(qualities: QualityInterface[], minBitrate: number, maxBitrate: number, maxHeight?: number, bounds?: DimensionsInterface) {
	return qualities.some(quality => quality.enabled && validateQuality(quality, minBitrate, maxBitrate, maxHeight, bounds));
}

export function toKbits(bitrate: number) {
	return Math.round(bitrate / 1000);
}
