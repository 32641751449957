import { buildInfo } from '../core/BuildInfo';
import { ExtensionContextInterface } from '../iface/ExtensionContextInterface';
import { ExtensionFactory } from '../iface/ExtensionFactory';
import { System } from '../util/System';
import { logger } from './Logger';

/**
 * The extension context.
 */
export const extensionContext: ExtensionContextInterface = {
	get avia() {
		return System.appNamespace;
	},
	logger,
	buildInfo,
	system: System,
};

/**
 * Apply code to the SDK globally.
 *
 * @param extension - A SDK extension function or list of functions.
 */
export async function use(extension: ExtensionFactory | ExtensionFactory[]): Promise<void> {
	if (!Array.isArray(extension)) {
		extension = [extension];
	}

	for (const ext of extension) {
		await ext(extensionContext);
	}
}
