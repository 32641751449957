import { Html5Adapter } from '../adapter/playback/Html5Adapter';
import { AdapterRole } from '../enum/AdapterRole';
import { ErrorCode } from '../enum/ErrorCode';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { ProxyName } from '../enum/ProxyName';
import { PlayerError } from '../error/PlayerError';
import { AdapterConfigInterface } from '../iface/AdapterConfigInterface';
import { AdapterContextInterface } from '../iface/AdapterContextInterface';
import { AdapterDelegateFactory } from '../iface/AdapterDelegateFactory';
import { AdapterDelegateMap } from '../iface/AdapterDelegateMap';
import { AdapterMap } from '../iface/AdapterMap';
import { ExtensionConfigInterface } from '../iface/ExtensionConfigInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { Proxy } from '../mvc/Proxy';
import { merge, values } from '../util/ObjectUtil';
import { PluginMediator } from '../view/PluginMediator';
import { ModelCollectionProxy } from './ModelCollectionProxy';
import { PerformanceProxy } from './PerformanceProxy';
import { TextTrackProxy } from './TextTrackProxy';


export class AdapterProxy extends Proxy {

	private adapterMap: Record<string, AdapterConfigInterface<any, any>> = {};
	private defaultAdapters: Record<string, AdapterConfigInterface<any, any>> = {
		[AdapterRole.PLAYBACK]: Html5Adapter.config,
	};

	constructor(data?: any) {
		super(ProxyName.AdapterProxy, data);
	}

	override onRemove() {
		this.adapterMap = null;
		super.onRemove();
	}

	private cloneConfig<A, D>(adapterConfig: AdapterConfigInterface<A, D>): AdapterConfigInterface<A, D> {
		return Object.assign({
			isSupported() {
				return true;
			},
		}, adapterConfig);
	}

	registerDefaultAdapter<A, D>(role: AdapterRole, adapterConfig: AdapterConfigInterface<A, D>): void {
		this.defaultAdapters[role] = this.cloneConfig(adapterConfig);
	}

	registerAdapter<A, D>(adapterConfig: AdapterConfigInterface<A, D>): void {
		const { id } = adapterConfig;
		this.adapterMap[id] = this.cloneConfig(adapterConfig);
	}

	retrieveAdapter<A, D>(id: string): AdapterConfigInterface<A, D> {
		return this.adapterMap[id];
	}

	removeAdapter(id: string): void {
		delete this.adapterMap[id];
	}

	getAdaptersByRole<A, D>(role: AdapterRole): AdapterConfigInterface<A, D>[] {
		const adapters = values<AdapterConfigInterface<A, D>>(this.adapterMap).filter(config => config.role.includes(role));
		const dflt = this.defaultAdapters[role];
		if (dflt) {
			adapters.push(dflt);
		}

		return adapters;
	}

	private createContext<D>(config: ExtensionConfigInterface, resource: ResourceConfigurationInterface, getDelegate: AdapterDelegateFactory<D>): AdapterContextInterface<D> {
		const services = (this.facade.retrieveMediator(MediatorName.PLUGIN_MEDIATOR) as PluginMediator).createPluginServices(config);
		const { dom, system } = services;
		const modelCollection = this.facade.retrieveProxy(ProxyName.ModelCollectionProxy) as ModelCollectionProxy;
		const presentationState = modelCollection.getModel(ModelName.PresentationState) as PresentationStateInterface;
		const textTrackProxy = this.facade.retrieveProxy(ProxyName.TextTrackProxy) as TextTrackProxy;
		const performanceProxy = this.facade.retrieveProxy(ProxyName.PerformanceProxy) as PerformanceProxy;

		return Object.assign(services, {
			resource,
			video: (system.isWebMaf) ? system.webMafPlayer : dom.video,
			container: dom.main,
			autoplay: presentationState.isAutoplay,
			muted: presentationState.isMuteAtPlayStart,
			performanceSettings: performanceProxy,
			textTrackSettings: textTrackProxy,
			getDelegate,
		});
	}

	async createAdapter<R extends keyof AdapterMap, A = AdapterMap[R], D = AdapterDelegateMap[R]>(role: R, resource: ResourceConfigurationInterface, delegateFactory: AdapterDelegateFactory<D>): Promise<A> {
		const adapters = this.getAdaptersByRole<A, D>(role);
		const adapterConfig = (resource as any)[role]?.adapter || {};
		const { id, options = {} } = adapterConfig;
		const list = (id) ? adapters.filter(adapter => adapter.id === id) : adapters;

		for (const config of list) {
			const context = this.createContext<D>(config, resource, delegateFactory);
			const opts = merge({}, config.options, options, (adapterConfig as any)[config.id]);
			if (!id && !(await config.isSupported(context, opts))) {
				continue;
			}

			const adapter = await config.factory(context, opts);
			if (adapter) {
				this.facade.logger.info(`${config.id} adapter created`);
				return adapter;
			}
		}

		let message = `Could not find adapter for role: ${role}`;
		if (id) {
			message += `, with id: ${id}`;
		}

		throw new PlayerError(ErrorCode.ADAPTER_NOT_FOUND, message);
	}
}
