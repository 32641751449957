import { QueryString } from '../core/QueryString';
import { RequestCredentials } from '../enum/RequestCredentials';
import { ResponseType } from '../enum/ResponseType';
import { AsyncDataRequestOptionsInterface } from '../iface/AsyncDataRequestOptionsInterface';
import { RequestOptionsInterface } from '../iface/RequestOptionsInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { StrStrDict } from '../iface/StrStrDict';
import { XhrResponseInterface } from '../iface/XhrResponseInterface';
import { entries } from '../util/ObjectUtil';
import { isString } from '../util/Type';
import { AsyncDataRequestOptions } from './AsyncDataRequestOptions';
import { RecoveryEnabledRequest } from './RecoveryEnabledRequest';


export class AsyncDataRequest extends RecoveryEnabledRequest {

	static event: StrStrDict = {
		COMPLETE: 'complete',
	};

	static statusMessages: StrStrDict = {
		'0': 'Request failed; blocked or timed out',
		'400': 'Bad Request',
		'500': 'Server error encountered.',
		'404': 'Resource not found.',
		'403': 'Access denied.',
	};

	static load(options: RequestOptionsInterface): Promise<StrAnyDict> {
		return new Promise((resolve, reject) => {
			const requestOptions = options as AsyncDataRequestOptionsInterface;
			if (requestOptions.credentials) {
				requestOptions.withCredentials = requestOptions.credentials === RequestCredentials.INCLUDE;
			}

			if (requestOptions.body) {
				requestOptions.data = requestOptions.body;
			}

			requestOptions.onComplete = ({ detail }: StrAnyDict) => {
				if (!detail || detail.error) {
					reject(detail);
				}
				else {
					resolve(detail);
				}
			};
			new AsyncDataRequest(requestOptions);
		});
	}

	private requestOptions: RequestOptionsInterface;

	constructor(options: AsyncDataRequestOptionsInterface) {
		super(AsyncDataRequestOptions.create(options));

		this.requestOptions = this.opts as RequestOptionsInterface;

		if (this.opts.url) {
			this.createXhr();
		}
	}

	createXhr(): void {
		this.request(this.requestOptions)
			.then((request: XMLHttpRequest) => {
				const { response } = request;
				const contentType = request.getResponseHeader('content-type');
				let data: any = response;

				if (this.requestOptions.responseType === ResponseType.TEXT && contentType) {
					if (contentType.includes('application/smil') || contentType.includes('application/xml')) {
						const parser = new DOMParser();
						data = parser.parseFromString(data, 'application/xml');
					}
					else if (contentType?.includes('application/json')) {
						data = JSON.parse(data);
					}
				}

				if (request.status !== 204 && data == null) {
					this.emitCompleteWithError('Unable to parse response.');
				}
				else {
					// This is on a timeout to avoid triggering the catch clause
					// if a downstream exception occurs in response handling code.
					setTimeout(() => {
						this.emitComplete({ contentType, data }); 
					}, 1);
				}
			})
			.catch((error) => {
				if (this.shouldRetry()) {
					this.incrementAttempts();
					setTimeout(() => {
						this.createXhr();
					}, this.retryInterval);
				}
				else {
					const msg = this.getErrorMessage(error);
					this.emitCompleteWithError(msg, error.status);
				}
			});
	}

	private getErrorMessage(error: XMLHttpRequest): string {
		if (typeof error.response === 'string') {
			return error.response;
		}
		else if (typeof error.response?.message === 'string') {
			return error.response.message;
		}

		return AsyncDataRequest.statusMessages[error.status] || AsyncDataRequest.statusMessages['0'];
	}

	private request(options: RequestOptionsInterface) {
		return new Promise((resolve, reject) => {
			if (options.query) {
				options.url = QueryString.append(options.url, options.query);
			}

			const xhr = new XMLHttpRequest();
			xhr.open(options.method, options.url);
			xhr.onload = () => {
				if (options.checkStatus !== false && xhr.status > 399) {
					reject(xhr);
				}

				resolve(xhr);
			};
			xhr.onerror = (event) => {
				reject(xhr);
			};
			xhr.withCredentials = options.withCredentials;
			if (options.responseType != null) {
				try {
					xhr.responseType = options.responseType;
				}
				catch (error) {
					// no-op
				}
			}
			if (options.headers != null) {
				const headers = options.headers;
				entries(headers).forEach(([key, value]) => {
					if (key != null && value != null) {
						xhr.setRequestHeader(key, value);
					}
				});
			}

			return xhr.send(options.data as any);
		});
	}

	private emitComplete(res: any) {
		this.emit(AsyncDataRequest.event.COMPLETE, {
			response: res.data,
			contentType: res.contentType,
			error: false,
		});
	}

	private emitCompleteWithError(errResponse: XhrResponseInterface | string, status?: string): void {
		const msg = isString(errResponse) ? errResponse : null;
		const res = !msg ? errResponse as XhrResponseInterface : null;

		this.emit(AsyncDataRequest.event.COMPLETE, {
			status: status || (res && res.status),
			error: true,
			url: this.requestOptions.url || 'not available',
			message: msg || this.getMsg(res?.status || 0, res?.data || null),
		});
	}

	private getMsg(stat: number, errorData: any): string {
		let m = AsyncDataRequest.statusMessages['' + stat];

		if (!m) {
			m = isString(errorData) ? errorData : 'Unspecified error';
		}

		return 'XhrDataRequest error: ' + m;
	}
}
