/**
 * Standard key codes for keyboard events.
 *
 * @enum
 */
export const KeyCode = {
	ALT: 18,
	BACKSPACE: 8,
	CAPS_LOCK: 20,
	CONTROL: 17,
	END: 35,
	ENTER: 13,
	TAB: 9,
	SHIFT: 16,
	ESCAPE: 27,
	PAGE_UP: 33,
	PAGE_DOWN: 34,
	LEFT_ARROW: 37,
	UP_ARROW: 38,
	RIGHT_ARROW: 39,
	DOWN_ARROW: 40,
	INSERT: 45,
	DELETE: 46,
	SPACE: 32,
	'0': 48,
	'1': 49,
	'2': 50,
	'3': 51,
	'4': 52,
	'5': 53,
	'6': 54,
	'7': 55,
	'8': 56,
	'9': 57,
	A: 65,
	B: 66,
	C: 67,
	D: 68,
	E: 69,
	F: 70,
	G: 71,
	H: 72,
	I: 73,
	J: 74,
	K: 75,
	L: 76,
	M: 77,
	N: 78,
	O: 79,
	P: 80,
	Q: 81,
	R: 82,
	S: 83,
	T: 84,
	U: 85,
	V: 86,
	W: 87,
	X: 88,
	Y: 89,
	Z: 90,
	NUM_0: 96,
	NUM_1: 97,
	NUM_2: 98,
	NUM_3: 99,
	NUM_4: 100,
	NUM_5: 101,
	NUM_6: 102,
	NUM_7: 103,
	NUM_8: 104,
	NUM_9: 105,
	MULTIPLY: 106,
	ADD: 107,
	SUBTRACT: 109,
	DECIMAL: 110,
	DIVIDE: 111,
	SEMICOLON: 186,
	EQUAL: 187,
	COMMA: 188,
	DASH: 189,
	PERIOD: 190,
	FORWARD_SLASH: 191,
	BACKSLASH: 220,
	GRAVE_ACCENT: 192,
	OPEN_BRACKET: 219,
	CLOSE_BRACKET: 221,
	SINGLE_QUOTE: 222,
	MINUS: 173,
	PAUSE: 19,
	MEDIA_PLAY_PAUSE: 179,
	MEDIA_STOP: 178,
	MEDIA_INCREASE_VOLUME: 175,
	MEDIA_INCREASE_VOLUME_ALT: 183,
	MEDIA_DECREASE_VOLUME: 174,
	MEDIA_DECREASE_VOLUME_ALT: 182,
	MEDIA_TRACK_NEXT: 176,
	MEDIA_TRACK_PREVIOUS: 177,

	// Pseudo key codes
	MEDIA_REWIND: 9000,
	MEDIA_FAST_FORWARD: 9001,
	MEDIA_RECORD: 9002,
	MEDIA_PLAY: 9003,
	MEDIA_PAUSE: 9004,
	MEDIA_CAPTIONS: 9005,
	MEDIA_INFO: 9006,
	MEDIA_LIVE: 9007,
};
