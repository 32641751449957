import { ThumbnailTrackSurface } from '../adapter/playback/surface/ThumbnailTrackSurface';
import { ProxyName } from '../enum/ProxyName';
import { QualityCategory } from '../enum/QualityCategory';
import { QualityMode } from '../enum/QualityMode';
import { AudioTrackInterface } from '../iface/AudioTrackInterface';
import { QualityInterface } from '../iface/QualityInterface';
import { TextTrackInterface } from '../iface/TextTrackInterface';
import { ThumbnailCueInterface } from '../iface/ThumbnailCueInterface';
import { Proxy } from '../mvc/Proxy';
import { freeze } from '../util/ObjectUtil';
import { processQuality } from '../util/Quality';
import { ModelCollectionProxy } from './ModelCollectionProxy';
import { ContentPlaybackState } from './vo/ContentPlaybackState';


export class ContentPlaybackStateProxy extends Proxy {

	isReady: boolean = false;
	contentPlaybackState!: ContentPlaybackState;
	thumbnailTrack: ThumbnailTrackSurface;

	private pUserQualityCategory: QualityCategory = QualityCategory.AUTO;

	override onRegister(): void {

		super.onRegister();

		const mcp = this.facade.retrieveProxy(ProxyName.ModelCollectionProxy) as ModelCollectionProxy;
		this.contentPlaybackState = mcp.getModel(ContentPlaybackState.modelName);

		this.isReady = true;
	}

	override onRemove(): void {
		this.contentPlaybackState = null;
		this.thumbnailTrack = null;
		super.onRemove();
	}

	processQualityProfile(qualities: QualityInterface[]): void {
		qualities = freeze(qualities.map(quality => processQuality(quality)));
		this.contentPlaybackState.qualities = qualities;
		if (this.qualitySwitchingMode !== QualityMode.MANUAL) {
			this.qualitySwitchingMode = qualities.length ? QualityMode.AUTO : QualityMode.UNAVAILABLE;
		}
	}

	addTextTracks(tracks: TextTrackInterface[]) {
		this.contentPlaybackState.textTracks = tracks;
	}

	getTextTrackById(id: string): TextTrackInterface {
		return this.contentPlaybackState.textTracks.find(track => track.id === id);
	}

	getThumbnail(time: number): ThumbnailCueInterface {
		return this.thumbnailTrack?.getCueAt(time);
	}

	get textTracks(): TextTrackInterface[] {
		return this.contentPlaybackState.textTracks;
	}

	set textTrack(value: TextTrackInterface) {
		this.contentPlaybackState.textTrack = value;
	}

	get textTrack(): TextTrackInterface {
		return this.contentPlaybackState.textTrack;
	}

	get isAbrSwitchingAvailable(): boolean {
		return this.qualities ? this.qualities.length > 1 : false;
	}

	set userQualityCategory(q: QualityCategory) {
		this.pUserQualityCategory = q;
	}

	get userQualityCategory() {
		return this.pUserQualityCategory;
	}

	set qualitySwitchingMode(mode: QualityMode) {
		this.contentPlaybackState.qualityMode = mode;
	}

	get qualitySwitchingMode(): QualityMode {
		return this.contentPlaybackState.qualityMode;
	}

	set qualities(value: QualityInterface[]) {
		this.contentPlaybackState.qualities = value;
	}

	get qualities(): QualityInterface[] {
		return this.contentPlaybackState.qualities;
	}

	set maxBandwidth(value: number) {
		this.contentPlaybackState.maxBandwidth = isNaN(this.contentPlaybackState.maxBandwidth)
			? value : Math.max(value, this.contentPlaybackState.maxBandwidth);
	}

	get model(): ContentPlaybackState {
		return this.contentPlaybackState;
	}

	get minIndex(): number {
		return this.contentPlaybackState.qualities.findIndex(quality => quality.enabled);
	}

	get maxIndex(): number {
		return this.contentPlaybackState.qualities.reduce((result, quality, index) => {
			return quality.enabled ? index : result;
		}, 0);
	}

	get minBitrate(): number {
		return this.contentPlaybackState.qualities[this.minIndex].bitrate;
	}

	get maxBitrate(): number {
		return this.contentPlaybackState.qualities[this.maxIndex].bitrate;
	}

	get quality(): QualityInterface {
		return this.contentPlaybackState.quality;
	}

	set quality(value: QualityInterface) {
		this.contentPlaybackState.quality = value;
	}

	set qualityCappedToScreenSize(value: boolean) {
		this.contentPlaybackState.qualityCappedToScreenSize = value;
	}

	get qualityCappedToScreenSize(): boolean {
		return this.contentPlaybackState.qualityCappedToScreenSize;
	}

	get audioTracks(): AudioTrackInterface[] {
		return this.contentPlaybackState.audioTracks;
	}

	set audioTracks(value: AudioTrackInterface[]) {
		this.contentPlaybackState.audioTracks = value;
	}

	get audioTrack(): AudioTrackInterface {
		return this.contentPlaybackState.audioTrack;
	}

	set audioTrack(value: AudioTrackInterface) {
		this.contentPlaybackState.audioTrack = value;
	}
}
