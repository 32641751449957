import { buildInfo } from '../core/BuildInfo';
import { CoreEvent } from '../core/CoreEvent';
import { Metric } from '../core/Metric';
import { Deprecated } from '../deprecated/Deprecated';
import { ActionKeyContext } from '../enum/ActionKeyContext';
import { AdapterRole } from '../enum/AdapterRole';
import { ApiMetric } from '../enum/ApiMetric';
import { AudioTrackType } from '../enum/AudioTrackType';
import { ErrorCategory } from '../enum/ErrorCategory';
import { ErrorCode } from '../enum/ErrorCode';
import { LoggerEvent } from '../enum/LoggerEvent';
import { Measurement } from '../enum/Measurement';
import { MediatorName } from '../enum/MediatorName';
import { MetricType } from '../enum/MetricType';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { PlaybackState } from '../enum/PlaybackState';
import { PlayerHookType } from '../enum/PlayerHookType';
import { ProxyName } from '../enum/ProxyName';
import { QualityCategory } from '../enum/QualityCategory';
import { QualityMode } from '../enum/QualityMode';
import { StreamType } from '../enum/StreamType';
import { InvalidArgumentError } from '../error/InvalidArgumentError';
import { PlayerError } from '../error/PlayerError';
import { PlayerEvent } from '../events/PlayerEvent';
import { PlayerEventInterface } from '../events/PlayerEventInterface';
import { PlayerEventMap } from '../events/PlayerEventMap';
import { ApplicationOptionsInterface, NotificationInterface, PlayerDomProxyInterface, VideoPlayerApplicationOptions, VideoProxyInterface } from '../iface';
import { AdCuePointInterface } from '../iface/AdCuePointInterface';
import { AdapterConfigInterface } from '../iface/AdapterConfigInterface';
import { AdapterMap } from '../iface/AdapterMap';
import { AudioTrackInterface } from '../iface/AudioTrackInterface';
import { DestroyInterface } from '../iface/DestroyInterface';
import { DimensionsInterface } from '../iface/DimensionsInterface';
import { EventHandler } from '../iface/EventHandler';
import { EventInterface } from '../iface/EventInterface';
import { HookInterface } from '../iface/HookInterface';
import { LiveStreamInfoInterface } from '../iface/LiveStreamInfoInterface';
import { LocaleData } from '../iface/LocaleData';
import type { LocalizationData } from '../iface/LocalizationData';
import { LocalizationInterface } from '../iface/LocalizationInterface';
import { LoggerInterface } from '../iface/LoggerInterface';
import { PlayerHookMap } from '../iface/PlayerHookMap';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { PluginConfigInterface } from '../iface/PluginConfigInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { QualityInterface } from '../iface/QualityInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { TextTrackInterface } from '../iface/TextTrackInterface';
import { ThumbnailDataInterface } from '../iface/ThumbnailDataInterface';
import { TimeRangeInterface } from '../iface/TimeRangeInterface';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';
import { AdapterProxy } from '../model/AdapterProxy';
import { ContentPlaybackStateProxy } from '../model/ContentPlaybackStateProxy';
import { HookProxy } from '../model/HookProxy';
import { LocalizationProxy } from '../model/LocalizationProxy';
import { PlayerDomProxy } from '../model/PlayerDomProxy';
import { TextTrackProxy } from '../model/TextTrackProxy';
import { eventsToPromise, waitForEvent, waitForTime } from '../util/Async';
import { inRange } from '../util/NumberUtil';
import { clone } from '../util/ObjectUtil';
import { findDefaultTrack, findLanguageTracks } from '../util/TimedText';
import { isBoolean, isEmpty, isNumber, isString } from '../util/Type';
import { PluginMediator } from '../view/PluginMediator';
import { AbstractApplication } from './AbstractApplication';
import { Api, apiAccessor, apiCollection, apiMethod } from './ApiDecorators';
import { AppResources } from './AppResources';
import { CommandMap } from './CommandMap';

export class VideoPlayer extends AbstractApplication implements VideoPlayerInterface {
	private pIsReady: boolean = false;
	private delegate: Api<VideoPlayerInterface>;
	private logger: LoggerInterface;
	private seekTime: number = NaN;
	private seekPending: Promise<void>;

	/**
	 * @internal
	 */
	constructor(options: VideoPlayerApplicationOptions) {
		super(Object.assign({
			commandMap: CommandMap,
			id: options.playerOptions.id || null,
		}, options) as ApplicationOptionsInterface);

		this.delegate = apiCollection<VideoPlayerInterface>(this.appId, this);
		this.target = this.delegate.api;
		this.logger = options.logger;

		this.init(options);
	}

	initialize(): Promise<VideoPlayerInterface> {
		const options = this.modelCollectionProxy.getModel<PlayerOptionsInterface>(ModelName.PlayerOptions);
		const plugins = (options.plugins || []) as PluginConfigInterface[];
		const api = this.getApi();
		const note = { name: NotificationName.CHANGE_LANGUAGE, body: { language: options.localizationLanguage }, type: NotificationType.INTERNAL };

		this.on(PlayerEvent.SEEK_REDIRECT_START, this.hSeekRedirect);
		this.on(PlayerEvent.SEEK_REDIRECT_COMPLETE, this.hSeekRedirect);

		return api.registerPlugins(plugins)
			.then(() => {
				this.localization.registerLocalizationData(options.localization);
				return this.sendAsyncNotification(note, [PlayerEvent.LANGUAGE_CHANGE]);
			})
			.then(() => {
				this.pIsReady = true;
				this.sendNotification(PlayerEvent.READY, api);

				return api;
			})
			.catch(error => {
				this.logger.error(error);

				throw error;
			});
	}

	/**
	 * @internal
	 */
	override destroy(): Promise<void> {
		this.off(PlayerEvent.SEEK_REDIRECT_START, this.hSeekRedirect);
		this.off(PlayerEvent.SEEK_REDIRECT_COMPLETE, this.hSeekRedirect);
		this.disableKeyCommands();

		const destroy = super.destroy.bind(this);
		const logger = this.logger;

		return this.killCurrentResource()
			.then(() => this.plugins.destroy())
			.then(() => {
				destroy();
				this.delegate.destroy();
				this.delegate = null;
			})
			.catch(e => {
				logger.error(e);
				throw e;
			})
			.then(() => {
				logger.destroy();
			});
	}

	/**
	 * @internal
	 */
	getApi(): VideoPlayerInterface {
		return this.delegate.api;
	}

	/**
	* @internal
	*/
	killCurrentResource(): Promise<void> {
		return this.appMediator.killCurrentResource();
	}

	//////////////////////
	// BEGIN PUBLIC API //
	//////////////////////

	////////////////
	// Inherited
	////////////////
	@apiMethod()
	override hasListenerFor(type: PlayerEvent): boolean {
		if (!isString(type)) {
			throw this.invalidArgException('hasListenerFor(type)', type);
		}
		return super.hasListenerFor(type);
	}

	@apiMethod()
	override on<N extends keyof PlayerEventMap>(type: N, func: EventHandler<PlayerEventMap[N]>): void {
		if (!isString(type) || typeof func !== 'function') {
			throw this.invalidArgException('on(type, func)', type, func);
		}
		Deprecated.checkEventName(type);
		super.on(type, func);
	}

	@apiMethod()
	override once<N extends keyof PlayerEventMap>(type: N, func: EventHandler<PlayerEventMap[N]>): void {
		if (!isString(type) || typeof func !== 'function') {
			throw this.invalidArgException('on(type, func)', type, func);
		}

		super.once(type, func);
	}

	@apiMethod()
	override off<N extends keyof PlayerEventMap>(type: N, func?: EventHandler<PlayerEventMap[N]>): void {
		if (!isString(type)) {
			throw this.invalidArgException('on(type, func)', type, func);
		}
		super.off(type, func);
	}

	////////////////
	// Accessors
	////////////////
	@apiAccessor()
	get isReady(): boolean {
		return this.pIsReady;
	}

	@apiAccessor()
	get id(): string {
		return this.appId;
	}

	@apiAccessor()
	get dimensions(): DimensionsInterface | null {
		return this.appMediator.getDimensions() || null;
	}

	@apiAccessor()
	get video(): HTMLVideoElement | null {
		return this.videoProxy.getVideo();
	}

	@apiAccessor()
	get isAutoplay(): boolean {
		return this.presentationState.isAutoplay;
	}

	@apiAccessor()
	get isFullscreen(): boolean {
		return this.presentationState.isFullscreen;
	}

	@apiAccessor()
	get isAd(): boolean {
		const { isTrackingAd, adSegmentEntered } = this.presentationState;

		return isTrackingAd || adSegmentEntered;
	}

	@apiAccessor()
	get muted(): boolean {
		return this.appMediator.getMuteState();
	}
	set muted(value: boolean) {
		if (typeof value !== 'boolean') {
			throw this.invalidArgException('player.muted = boolean', value);
		}
		if (this.muted !== value) {
			this.transmitExtRequest(PlayerEvent.MUTE_CHANGE, { muted: value });
		}
	}

	@apiAccessor()
	get options(): PlayerOptionsInterface {
		const mdl = (this.modelCollectionProxy.getModel(ModelName.PlayerOptions) as any)?.model;

		return mdl ? clone(mdl) as PlayerOptionsInterface : null;
	}

	@apiAccessor()
	get volume(): number {
		return this.appMediator.getVolume();
	}
	set volume(value: number) {
		if (!isNumber(value) || !inRange(value, 0, 1)) {
			throw this.invalidArgException('player.volume = number [0,1]', value);
		}
		if (this.volume !== value) {
			this.transmitExtRequest(PlayerEvent.VOLUME_CHANGE, { volume: value });
		}
	}

	@apiAccessor()
	get language(): string {
		return this.localization.language;
	}

	@apiAccessor()
	get contentTime(): number {
		if (!isNaN(this.seekTime)) {
			return this.seekTime;
		}

		return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.model.time : 0;
	}

	@apiAccessor()
	get sessionTime(): number {
		const t = this.appMediator.getSessionTime();

		return t == null ? 0 : t;
	}

	@apiAccessor()
	get playbackTime(): number {
		const t = this.appMediator.getPlaybackTime();

		return t == null ? 0 : t;
	}

	@apiAccessor()
	get contentDuration(): number {
		return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.model.duration : NaN;
	}

	@apiAccessor()
	get streamTime(): number {
		return this.presentationState.streamTime;
	}

	@apiAccessor()
	get streamDuration(): number {
		return this.presentationState.streamDuration;
	}

	@apiAccessor()
	get adTime(): number {
		return this.presentationState.adTime;
	}

	@apiAccessor()
	get adDuration(): number {
		return this.presentationState.adDuration;
	}

	@apiAccessor()
	get breakTime(): number {
		return this.presentationState.breakTime;
	}

	@apiAccessor()
	get breakDuration(): number {
		return this.presentationState.breakDuration;
	}

	@apiAccessor()
	get playbackState(): PlaybackState {
		return this.contentPlaybackStateProxy?.model.state || PlaybackState.IDLE;
	}

	@apiAccessor()
	get autoQualitySwitching(): boolean {
		return this.contentPlaybackStateProxy?.qualitySwitchingMode === QualityMode.AUTO || false;
	}
	set autoQualitySwitching(value: boolean) {
		if (!isBoolean(value)) {
			throw this.invalidArgException('player.autoQualitySwitching = boolean', value);
		}
		if (this.contentPlaybackStateReady) {
			this.transmitExtRequest(NotificationName.AUTO_QUALITY_SWITCHING, { value: value });
		}
		else {
			this.logger.debug(AppResources.messages.CONTENT_PLAYBACK_SETTING_IGNORED);
		}
	}

	@apiAccessor()
	get quality(): QualityInterface {
		return this.contentPlaybackStateProxy?.quality || null;
	}

	@apiAccessor()
	get qualities(): QualityInterface[] {
		return this.contentPlaybackStateProxy?.qualities || [];
	}

	@apiAccessor()
	get qualityMode(): QualityMode {
		return this.contentPlaybackStateProxy?.qualitySwitchingMode || QualityMode.AUTO;
	}

	@apiAccessor()
	get qualityCappedToScreenSize(): boolean {
		return this.contentPlaybackStateProxy?.qualityCappedToScreenSize === true;
	}
	set qualityCappedToScreenSize(value: boolean) {
		if (!isBoolean(value)) {
			throw this.invalidArgException('player.qualityCappedToScreenSize = boolean', value);
		}
		if (!this.contentPlaybackStateProxy) {
			this.logger.debug(AppResources.messages.CONTENT_PLAYBACK_SETTING_IGNORED);
			return;
		}

		this.contentPlaybackStateProxy.qualityCappedToScreenSize = value;
		this.getAdapter(AdapterRole.PLAYBACK)?.setQualityCappedToScreenSize(value);
	}

	@apiAccessor()
	get bitrate(): number {
		const quality = this.quality || { bitrate: NaN };
		const bitrate = (quality.bitrate != null) ? quality.bitrate : NaN;
		return bitrate;
	}
	set bitrate(bitrate: number) {
		if (!isNumber(bitrate)) {
			throw this.invalidArgException('player.bitrate = number', bitrate);
		}
		if (this.contentPlaybackStateReady) {
			this.transmitExtRequest(NotificationName.SWITCH_BITRATE, { value: bitrate });
		}
		else {
			this.logger.debug(AppResources.messages.CONTENT_PLAYBACK_SETTING_IGNORED);
		}
	}

	@apiAccessor()
	get qualityCategory(): QualityCategory {
		return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.userQualityCategory : null;
	}
	set qualityCategory(category: QualityCategory) {
		if (!isString(category)) {
			throw this.invalidArgException('player.qualityCategory = string', category);
		}
		if (this.contentPlaybackStateReady) {
			this.transmitExtRequest(NotificationName.SWITCH_QUALITY_CATEGORY, { value: category });
		}
		else {
			this.logger.debug(AppResources.messages.CONTENT_PLAYBACK_SETTING_IGNORED);
		}
	}

	@apiAccessor()
	get minBitrate(): number {
		return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.minBitrate : NaN;
	}
	set minBitrate(value: number) {
		if (!isNumber(value)) {
			throw this.invalidArgException('player.minBitrate = number', value);
		}
		if (this.contentPlaybackStateReady) {
			this.transmitExtRequest(NotificationName.MIN_BITRATE, { value: value });
		}
		else {
			this.logger.debug(AppResources.messages.CONTENT_PLAYBACK_SETTING_IGNORED);
		}
	}

	@apiAccessor()
	get maxBitrate(): number {
		return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.maxBitrate : NaN;
	}
	set maxBitrate(value: number) {
		if (!isNumber(value)) {
			throw this.invalidArgException('player.maxBitrate = number', value);
		}
		if (this.contentPlaybackStateReady) {
			this.transmitExtRequest(NotificationName.MAX_BITRATE, { value: value });
		}
		else {
			this.logger.debug(AppResources.messages.CONTENT_PLAYBACK_SETTING_IGNORED);
		}
	}

	@apiAccessor()
	get audioTracks(): AudioTrackInterface[] {
		return this.contentPlaybackStateProxy?.audioTracks || [];
	}

	@apiAccessor()
	get audioTrack(): AudioTrackInterface {
		return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.audioTrack : null;
	}
	set audioTrack(track: AudioTrackInterface) {
		if (!track) {
			throw this.invalidArgException('player.audioTrack = AudioTrackInterface', track);
		}
		if (this.contentPlaybackStateReady) {
			this.transmitExtRequest(NotificationName.SWITCH_AUDIO_TRACK, { value: track });
		}
		else {
			this.logger.debug(AppResources.messages.CONTENT_PLAYBACK_SETTING_IGNORED);
		}
	}

	@apiMethod()
	attachResource(resource: Partial<ResourceConfigurationInterface>): Promise<ResourceConfigurationInterface> {
		this.logger.time(Measurement.RESOURCE_ATTACHMENT);

		return this.stop()
			.then(() => {
				if (!resource || isEmpty(resource)) {
					throw this.invalidArgException('attachResource(resource)', resource);
				}
			})
			.then(() => {
				return this.sendAsyncNotification({
					name: NotificationName.PREP_RESOURCE_COLLECTION,
					body: { resource: resource, start: true },
				}, [PlayerEvent.RESOURCE_START]);
			})
			.then(() => {
				this.logger.timeEnd(Measurement.RESOURCE_ATTACHMENT);
				return this.resource;
			})
			.catch(event => {
				throw PlayerError.eventToError(event, ErrorCategory.RESOURCE);
			});
	}

	@apiAccessor()
	get resource(): ResourceConfigurationInterface {
		return this.appMediator.getCurrentResource();
	}

	@apiAccessor()
	get textTrackEnabled(): boolean {
		return (this.facade.retrieveProxy(ProxyName.TextTrackProxy) as TextTrackProxy).enabled;
	}
	set textTrackEnabled(value: boolean) {
		if (!isBoolean(value)) {
			throw this.invalidArgException('player.textTrackEnabled = boolean', value);
		}
		this.transmitExtRequest(NotificationName.SWITCH_TEXT_MODE, { value: value });
	}

	@apiAccessor()
	get textTrack(): TextTrackInterface {
		return this.contentPlaybackStateProxy?.textTrack || null;
	}
	set textTrack(value: TextTrackInterface) {
		if (!value || !value.kind) {
			throw this.invalidArgException('player.textTrack = TextTrackInterface', value);
		}
		if (this.contentPlaybackStateReady) {
			this.transmitExtRequest(NotificationName.SWITCH_TEXT_TRACK, { value: value });
		}
		else {
			this.logger.debug(AppResources.messages.CONTENT_PLAYBACK_SETTING_IGNORED);
		}
	}

	@apiAccessor()
	get textTracks(): TextTrackInterface[] {
		return this.contentPlaybackStateProxy?.textTracks || [];
	}

	@apiAccessor()
	get streamType(): StreamType {
		return this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.model.streamType : null;
	}

	@apiAccessor()
	get isPlayingLive(): boolean {
		const lsi = this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.model.liveStreamInfo : null;
		return lsi?.isPlayingLive === true;
	}

	@apiAccessor()
	get liveStreamUtcStart(): number {
		const lsi: LiveStreamInfoInterface = this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.model.liveStreamInfo : null;
		return lsi ? lsi.absoluteStart : NaN;
	}

	@apiAccessor()
	get liveStreamUtcTime(): number {
		const lsi: LiveStreamInfoInterface = this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.model.liveStreamInfo : null;
		return lsi ? lsi.absoluteTime : NaN;
	}

	@apiAccessor()
	get liveStreamUtcDuration(): number {
		const lsi: LiveStreamInfoInterface = this.contentPlaybackStateProxy ? this.contentPlaybackStateProxy.model.liveStreamInfo : null;
		return lsi ? lsi.absoluteDuration : NaN;
	}

	@apiAccessor()
	get isSuspended(): boolean {
		return this.appMediator.isPlaybackSuspended();
	}

	@apiAccessor()
	get fullscreenElement(): HTMLElement {
		return this.appMediator.getFullscreenElement();
	}
	set fullscreenElement(value: HTMLElement) {
		if (!value || !/element/.test(value.constructor?.name?.toLowerCase())) {
			throw this.invalidArgException('player.fullscreenElement = HTMLElement', value);
		}
		this.appMediator.setFullscreenElement(value);
	}

	@apiAccessor()
	get pausable(): boolean {
		const pausable = this.opts.playerOptions.overrides?.liveStreamPausable !== false;
		return pausable ? pausable : this.streamType !== StreamType.LIVE;
	}

	// end accessors

	// methods
	@apiMethod()
	updateDimensions() {
		this.appMediator.updateDimensions();
	}

	@apiMethod()
	enterFullscreen(): void {
		this.transmitExtRequest(NotificationName.ENTER_FULLSCREEN_REQUEST);
	}

	@apiMethod()
	exitFullscreen(): void {
		this.transmitExtRequest(NotificationName.EXIT_FULLSCREEN_REQUEST);
	}

	@apiMethod()
	play(): Promise<void> {
		this.recordMetric(MetricType.API, ApiMetric.PLAY);

		if (this.appMediator.isPlaybackSuspended()) {
			this.transmitExtRequest(NotificationName.RESUME_PLAYBACK);
		}

		const note = { name: NotificationName.PLAY, type: NotificationType.EXTERNAL };
		return this.sendAsyncNotification(note, [PlayerEvent.CONTENT_PLAYING, PlayerEvent.AD_PLAYING]);
	}

	@apiMethod()
	pause(): Promise<void> {
		this.recordMetric(MetricType.API, ApiMetric.PAUSE);

		if (!this.pausable) {
			this.logger.debug(AppResources.messages.PAUSING_LINEAR_LIVE_STREAM_NOT_ALLOWED);
			return this.stop();
		}
		else {
			if (this.playbackState === PlaybackState.PAUSED) {
				return Promise.resolve();
			}

			const note = { name: NotificationName.PAUSE, type: NotificationType.EXTERNAL };
			return this.sendAsyncNotification(note, [PlayerEvent.CONTENT_PAUSED, PlayerEvent.AD_PAUSED]);
		}
	}

	@apiMethod()
	togglePlayPause(): Promise<void> {
		return this.playbackState === PlaybackState.PLAYING ? this.pause() : this.play();
	}

	@apiMethod()
	toggleMuted(): boolean {
		return this.muted = !this.muted;
	}

	@apiMethod()
	toggleFullscreen(): void {
		this.isFullscreen ? this.exitFullscreen() : this.enterFullscreen();
	}

	@apiMethod()
	toggleTextTrack(): boolean {
		return this.textTrackEnabled = !this.textTrackEnabled;
	}

	@apiMethod()
	seek(position: number): Promise<void> {
		this.recordMetric(MetricType.API, ApiMetric.SEEK, position);

		return this.hooks.applyHook(PlayerHookType.SEEK, {
			position,
			contentTime: this.contentTime,
			contentDuration: this.contentDuration,
			streamTime: this.streamTime,
			streamDuration: this.streamDuration,
		})
			.then(result => {
				if (result == null) {
					return undefined;
				}

				const value = this.appMediator.validateSeek(result.position, this.contentDuration);
				if (value == null) {
					return Promise.reject(this.invalidArgException('seek(position)', result));
				}

				return this.seekInternal(value);
			});
	}

	@apiMethod()
	jump(increment: number) {
		return this.seek(this.contentTime + increment);
	}

	@apiMethod()
	getAdBreakTimes(): AdCuePointInterface[] {
		return this.appMediator.getAdBreakTimes() || [];
	}

	@apiMethod()
	grabFrame(): HTMLImageElement {
		return this.appMediator.grabFrame();
	}

	@apiMethod()
	getThumbnails(time: number): Promise<ThumbnailDataInterface[]> {
		if (!isNumber(time)) {
			throw this.invalidArgException('getThumbnails(time)', time);
		}

		return Promise.resolve(this.getAdapter(AdapterRole.PLAYBACK)?.getThumbnails(time))
			.then(thumbs => thumbs || [])
			.then(thumbs => {
				const thumb = this.contentPlaybackStateProxy?.getThumbnail(time);
				if (thumb) {
					thumbs.push(thumb.data);
				}
				return thumbs;
			});
	}

	@apiMethod()
	getContainerRect(): DOMRect | null {
		return this.appMediator.getContainerRect();
	}

	@apiMethod()
	registerPlugins(plugins: PluginConfigInterface[], callback?: (error?: Error) => void): Promise<void> {
		if (!Array.isArray(plugins)) {
			return Promise.reject(this.invalidArgException('registerPlugins(cfgArray, callback?)', plugins, callback));
		}

		if (callback) {
			Deprecated.registerPlugins();
		}

		return new Promise((resolve, reject) => {
			this.transmitExtRequest(NotificationName.LOAD_PLUGINS, {
				plugins,
				callback: (error: any) => {
					if (typeof callback === 'function') {
						callback(error);
					}

					return (error) ? reject(error) : resolve();
				},
			});
		});
	}

	@apiMethod()
	removePlugin(name: string): void {
		if (!isString(name)) {
			throw this.invalidArgException('removePlugin(name)', name);
		}
		this.transmitExtRequest(NotificationName.REMOVE_PLUGIN, {
			name: name,
		});
	}

	@apiMethod()
	registerAdapter<A extends DestroyInterface, D>(adapterConfig: AdapterConfigInterface<A, D>): void {
		if (isEmpty(adapterConfig)) {
			throw this.invalidArgException('registerAdapter(adapterConfig)', adapterConfig);
		}
		return this.adapters.registerAdapter(adapterConfig);
	}

	@apiMethod()
	retrieveAdapter<A extends DestroyInterface, D>(id: string): AdapterConfigInterface<A, D> {
		if (!isString(id)) {
			throw this.invalidArgException('retrieveAdapter(id)', id);
		}
		return this.adapters.retrieveAdapter(id);
	}

	@apiMethod()
	removeAdapter(id: string): void {
		if (!isString(id)) {
			this.invalidArgException('removeAdapter(id)', id);
		}
		return this.adapters.removeAdapter(id);
	}

	@apiMethod()
	registerHook<T extends keyof PlayerHookMap>(type: T, hook: HookInterface<PlayerHookMap[T]>): void {
		if (!isString(type) || typeof hook !== 'function') {
			throw this.invalidArgException('registerHook(type, hook)', type, hook);
		}
		return this.hooks.registerHook(type, hook);
	}

	@apiMethod()
	removeHook<T extends keyof PlayerHookMap>(type: T, hook: HookInterface<PlayerHookMap[T]>): void {
		if (!isString(type) || typeof hook !== 'function') {
			throw this.invalidArgException('removeHook(type, hook)', type, hook);
		}
		return this.hooks.removeHook(type, hook);
	}

	@apiMethod()
	getPlugin(name: string): any {
		if (!isString(name)) {
			throw this.invalidArgException('getPlugin(name)', name);
		}
		return this.appMediator.getPlugin(name);
	}

	@apiMethod()
	getAdapter<T extends keyof AdapterMap>(role: T): AdapterMap[T] | null {
		const presentationMediator = this.presentationMediator;

		if (!isString(role)) {
			throw this.invalidArgException('getAdapter(role)', role);
		}

		if (!presentationMediator) {
			return null;
		}

		switch (role) {
			case AdapterRole.AD:
				return presentationMediator.adAdapter || null;

			case AdapterRole.PLAYBACK:
				return presentationMediator.adapter || null;

			case AdapterRole.VIDEO:
				return this.videoProxy.getAdapter() as any || null;

			default:
				return null;
		}
	}

	@apiMethod()
	stop(): Promise<void> {
		if (!this.contentPlaybackStateProxy?.model || !this.presentationMediator) {
			return Promise.resolve();
		}

		return this.appMediator.killCurrentResource();
	}

	@apiMethod()
	goLive(): Promise<any> {
		this.recordMetric(MetricType.API, ApiMetric.GO_LIVE);

		if (this.streamType && this.streamType !== StreamType.VOD) {
			return this.seekInternal(this.contentDuration);
		}

		return Promise.reject(`goLive() can not be invoked on streams of type ${this.streamType}`);
	}

	@apiMethod()
	skipAd(): void {
		this.appMediator.skipAd();
	}

	@apiMethod()
	focus(options?: { preventScroll: boolean; }): void {
		const main = this.dom?.getMain();
		if (main) {
			main.focus(options);
		}
	}

	@apiMethod()
	blur(): void {
		const main = this.dom.getMain();
		if (main) {
			main.blur();
		}
	}

	@apiMethod()
	getKeyEventTarget(): Node | null {
		return this.appMediator.getKeyEventTarget();
	}

	@apiMethod()
	disableKeyCommands(flag?: boolean) {
		this.appMediator.disableKeyCommands(flag);
	}

	@apiMethod()
	enterActionKeyContext(context: ActionKeyContext | string) {
		this.appMediator.enterActionKeyContext(context);
	}

	@apiMethod()
	getActionKeyContext(): (ActionKeyContext | string)[] {
		return this.appMediator.getActionKeyContext();
	}

	@apiMethod()
	exitActionKeyContext(context: ActionKeyContext | string) {
		this.appMediator.exitActionKeyContext(context);
	}

	@apiMethod()
	getConfigAsJson(spacing?: number): string {
		return this.appMediator.getConfigAsJson(spacing);
	}

	@apiMethod()
	suspendPlayback(): void {
		if (!this.appMediator.isPlaybackSuspended()) {
			this.pause().then(() => {
				this.transmitExtRequest(NotificationName.SUSPEND_PLAYBACK);
			});
		}
	}

	@apiMethod()
	resumePlayback(): void {
		this.play();
	}

	@apiMethod()
	recordMetric(type: string, key: string, value?: any): void {
		if (!isString(type) || !isString(key)) {
			throw this.invalidArgException('recordMetric(type, key, value?)', type, key, value);
		}
		this.sendNotification(PlayerEvent.METRIC, { metric: new Metric(key, value, type) });
	}

	@apiMethod()
	offsetTimedText(pixelOffset: number) {
		if (!isNumber(pixelOffset)) {
			throw this.invalidArgException('offsetTimedText(pixelOffset)', pixelOffset);
		}
		this.appMediator.offsetTimedText(pixelOffset);
	}

	// intentionally undocumented
	@apiMethod()
	primeVideoElement(): Promise<void> {
		return this.appMediator.primeVideoElement();
	}

	@apiMethod()
	selectAudioLanguage(language: string, type?: AudioTrackType): Promise<AudioTrackInterface | null> {
		if (!isString(language)) {
			throw this.invalidArgException('player.selectAudioLanguage(language)', language);
		}

		const playerOptions: PlayerOptionsInterface = this.modelCollectionProxy.getModel(ModelName.PlayerOptions);
		playerOptions.audioLanguage = language;

		if (!this.contentPlaybackStateReady) {
			return Promise.resolve(null);
		}

		const matches = findLanguageTracks(this.audioTracks, language).filter(track => type === undefined || track.type === type);
		const audioTrack = matches[0] || findDefaultTrack(this.audioTracks, language);

		if (!audioTrack || audioTrack === this.audioTrack) {
			return Promise.resolve(audioTrack);
		}

		const note = { name: NotificationName.SWITCH_AUDIO_TRACK, body: { value: audioTrack }, type: NotificationType.EXTERNAL };
		return this.sendAsyncNotification(note, [PlayerEvent.AUDIO_TRACK_CHANGE])
			.then((data: any) => {
				return audioTrack;
			});
	}

	@apiMethod()
	selectTextLanguage(language: string, type?: TextTrackKind): Promise<TextTrackInterface | null> {
		if (!isString(language)) {
			throw this.invalidArgException('player.selectTextLanguage(language)', language);
		}

		const playerOptions: PlayerOptionsInterface = this.modelCollectionProxy.getModel(ModelName.PlayerOptions);
		playerOptions.textLanguage = language;

		(this.facade.retrieveProxy(ProxyName.TextTrackProxy) as TextTrackProxy).language = language;

		if (!this.contentPlaybackStateReady) {
			return Promise.resolve(null);
		}

		const textTrack = findDefaultTrack(this.textTracks, language);

		if (!textTrack || textTrack === this.textTrack) {
			return Promise.resolve(textTrack);
		}

		const note = { name: NotificationName.SWITCH_TEXT_TRACK, body: { value: textTrack }, type: NotificationType.EXTERNAL };
		return this.sendAsyncNotification(note, [PlayerEvent.TEXT_TRACK_CHANGE])
			.then((data: any) => {
				return textTrack;
			});
	}

	@apiMethod()
	selectLocalizationLanguage(language: string): Promise<LocaleData> {
		if (!isString(language)) {
			throw this.invalidArgException('player.selectLocalizationLanguage(language)', language);
		}

		const playerOptions: PlayerOptionsInterface = this.modelCollectionProxy.getModel(ModelName.PlayerOptions);
		playerOptions.language = language;

		const note = { name: NotificationName.CHANGE_LANGUAGE, body: { language }, type: NotificationType.EXTERNAL };
		return this.sendAsyncNotification(note, [PlayerEvent.LANGUAGE_CHANGE])
			.then((data: any) => {
				return data.localeData;
			});
	}

	@apiMethod()
	waitForEvent<T extends PlayerEventInterface>(success: PlayerEvent, fail: string | number, timeout?: number): Promise<T | null> {
		return waitForEvent(this, success, fail, timeout);
	}

	@apiMethod()
	waitForTime(time: number, prop?: string): Promise<void> {
		return waitForTime(this, time, prop);
	}

	@apiMethod()
	getBuffered(asStreamTime: boolean = false): TimeRangeInterface[] {
		return this.appMediator.getBuffered(asStreamTime) || [];
	}

	@apiMethod()
	localize(key: string, context?: any, language?: string): string {
		return this.localization.localize(key, context, language);
	}

	@apiMethod()
	registerLocalizationData(data: LocalizationData): void {
		return this.localization.registerLocalizationData(data);
	}

	// end methods
	////////////////////
	// END PUBLIC API //
	////////////////////

	/**
	 * @internal
	 */
	sendEvent(name: string, data: Record<string, any> = {}): void {
		if (data instanceof CoreEvent) {
			this.dispatchEvt(data);
		}
		else {
			this.emit(name, data);
		}
	}

	private get contentPlaybackStateProxy(): ContentPlaybackStateProxy {
		return this.facade.retrieveProxy(ProxyName.ContentPlaybackStateProxy) as ContentPlaybackStateProxy;
	}

	private get contentPlaybackStateReady(): boolean {
		return this.contentPlaybackStateProxy?.isReady || false;
	}

	private get localization(): LocalizationInterface {
		return this.facade.retrieveProxy(ProxyName.LocalizationProxy) as LocalizationProxy;
	}

	private get dom(): PlayerDomProxyInterface {
		return this.facade.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxy;
	}

	private get videoProxy(): VideoProxyInterface {
		return this.facade.retrieveProxy(ProxyName.VideoProxy) as VideoProxyInterface;
	}

	private get adapters(): AdapterProxy {
		return this.facade.retrieveProxy(ProxyName.AdapterProxy) as AdapterProxy;
	}

	private get plugins(): PluginMediator {
		return this.facade.retrieveMediator(MediatorName.PLUGIN_MEDIATOR) as PluginMediator;
	}

	private get presentationState(): PresentationStateInterface {
		return this.modelCollectionProxy.getModel(ModelName.PresentationState) as PresentationStateInterface;
	}

	private get presentationMediator(): any {
		return this.facade.retrieveMediator(MediatorName.PRESENTATION_MEDIATOR) as any;
	}

	private get hooks(): HookProxy {
		return this.facade.retrieveProxy(ProxyName.HookProxy) as HookProxy;
	}

	private transmitExtRequest(name: NotificationName | PlayerEvent, data?: any): void {
		this.sendNotification(name, data, NotificationType.EXTERNAL);
	}

	private init(options: VideoPlayerApplicationOptions): void {
		const hookProxy = new HookProxy(this.getApi());
		this.facade.registerProxy(hookProxy);

		const gServices = this.opts.globalServices;

		this.logger.info(`${buildInfo.playerName}@${buildInfo.playerVersion}  ${buildInfo.buildTime}`);

		this.registerGlobalServices(gServices);

		delete this.opts.globalServices;

		this.sendNotification(NotificationName.BOOT_APP, {
			...this.opts,
			app: this,
		}, NotificationType.INTERNAL);

		this.logger.on(LoggerEvent.TIME_EVENT, (event: EventInterface) => {
			const { id, label, item } = event.detail;
			const name = `${label}:start`;
			this.recordMetric(MetricType.MARK, name, { id, name, startTime: item.startTime });
		});

		this.logger.on(LoggerEvent.TIME_END_EVENT, (event: EventInterface) => {
			const { id, label, item } = event.detail;
			const { startTime, duration } = item;
			const name = `${label}:end`;
			this.recordMetric(MetricType.MARK, name, { id, name, startTime: startTime + duration });
			this.recordMetric(MetricType.MEASURE, label, { id, name: label, startTime, duration });
		});
	}

	private sendAsyncNotification(notification: NotificationInterface, events: string[]): Promise<any> {
		const promise = eventsToPromise(
			[{ target: this, events }],
			[{ target: this, events: [PlayerEvent.ERROR] }],
		);

		this.sendNotification(notification.name, notification.body, notification.type);
		return promise;
	}

	private invalidArgException(name: string, ...rest: any) {
		const rm = rest?.map((i: any) => i === undefined ? 'undefined' : i);
		const value = rm?.toString() || null;

		const msg = `${name}; ${AppResources.messages.API_INVALID_ARG}; supplied arg(s): ${value}`;
		this.logger.debug(msg);

		return new InvalidArgumentError(msg);
	}

	private hSeekRedirect = (e: EventInterface) => {
		if (e.type === PlayerEvent.SEEK_REDIRECT_START && !isNaN(e.detail.actualSeekTime)) {
			this.seekTime = e.detail.actualSeekTime;
		}
		else if (e.type === PlayerEvent.SEEK_REDIRECT_COMPLETE) {
			this.seekTime = NaN;
		}
	};

	private seekInternal(value: number): Promise<void> {
		this.seekTime = value;

		// If the player is already seeking, wait for the current seek to complete
		if (this.seekPending) {
			return this.seekPending.then(() => {
				// Multiple seeks could have been triggered while waiting for the previous
				// seek to complete. Only execute the last one.
				return (this.seekTime === value) ? this.seekInternal(this.seekTime) : undefined;
			});
		}

		const note = { name: NotificationName.SEEK, body: { value }, type: NotificationType.EXTERNAL };
		this.seekPending = this.sendAsyncNotification(note, [PlayerEvent.SEEK_COMPLETE]);

		return this.seekPending
			.then(() => {
				const diff = Math.abs(value - this.seekTime);
				if (diff <= 1) {
					this.seekTime = NaN;
				}
				this.seekPending = null;
			})
			.catch(e => {
				this.seekPending = null;
				throw new PlayerError(
					ErrorCode.UNEXPECTED_CONDITION,
					e?.message || `An error occurred on seek to ${value}`,
					e || null,
					false,
					ErrorCategory.API,
				);
			})
			.then(() => {
				this.seekPending = null;
			});
	}
}
