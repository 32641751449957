export enum ActionKeyContext {
	/**
	 * The default context for an action keys.
	 */
	DEFAULT = 'default',

	/**
	 * The context for hotkey action keys.
	 */
	HOTKEY = 'hotkey',

	/**
	 * The context for ad play.
	 */
	AD = 'ad',
}
