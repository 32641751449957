import { Device } from '../enum/Device';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { ProxyName } from '../enum/ProxyName';
import { PlayerEvent } from '../events/PlayerEvent';
import { NotificationInterface, PlayerDomProxyInterface, PresentationMediatorInterface, VideoProxyInterface } from '../iface';
import { ContentPlaybackStateInterface } from '../iface/ContentPlaybackStateInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { TextTrackInterface } from '../iface/TextTrackInterface';
import { ContentPlaybackStateProxy } from '../model/ContentPlaybackStateProxy';
import { ResourceProxy } from '../model/ResourceProxy';
import { TextTrackProxy } from '../model/TextTrackProxy';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';


export class PlaybackCommand extends LogAwareSimpleCommand {

	execute(notification: NotificationInterface) {
		const presoMediator = this.facade.retrieveMediator(MediatorName.PRESENTATION_MEDIATOR) as PresentationMediatorInterface;
		const resourceProxy = this.facade.retrieveProxy(ProxyName.ResourceProxy) as ResourceProxy;
		const resource = resourceProxy?.resource;
		const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy?.model;

		if (!cps || !resource) {
			this.logger.debug('play() can not be invoked in this context.');
			return;
		}

		switch (notification.name) {

			case NotificationName.PLAY:
				const hasPreso = !!presoMediator,
					started = hasPreso && (this.getModel(ModelName.PresentationState) as PresentationStateInterface).started;

				if (hasPreso && notification.type === NotificationType.EXTERNAL && !started) {
					this.invokePlayOnUserGesture(presoMediator);
				}
				else if (started) {
					presoMediator?.play();
				}
				break;

			case NotificationName.PAUSE:
				presoMediator?.pause();
				break;

			case NotificationName.SEEK:
				presoMediator.seek(notification.body.value);

				break;

			case NotificationName.SWITCH_AUDIO_TRACK:
				presoMediator.adapter.setAudioTrack(notification.body.value);
				break;

			case NotificationName.SWITCH_TEXT_TRACK:
				const track = notification.body.value as TextTrackInterface;
				if (track) {
					(this.facade.retrieveProxy(ProxyName.TextTrackProxy) as TextTrackProxy).language = track.language;
					const textTrack = this.contentPlaybackStateProxy.getTextTrackById(track.id);
					presoMediator.adapter.setTextTrack(textTrack);
				}
				break;

			case NotificationName.SWITCH_TEXT_MODE:
				const enabled = notification.body.value;
				const textTrackProxy = this.facade.retrieveProxy(ProxyName.TextTrackProxy) as TextTrackProxy;
				textTrackProxy.enabled = enabled;
				if (presoMediator?.adapter) {
					presoMediator.adapter.setTextTrackMode(textTrackProxy.mode);
				}
				break;

			case PlayerEvent.MUTE_CHANGE:
				presoMediator.mute(notification.body.muted);
				break;

			case PlayerEvent.VOLUME_CHANGE:
				const value = notification.body.volume;
				!isNaN(value) && presoMediator.setVolume(value);
				break;
		}
	}

	get contentPlaybackStateProxy(): ContentPlaybackStateProxy {
		return this.facade.retrieveProxy(ProxyName.ContentPlaybackStateProxy) as ContentPlaybackStateProxy;
	}

	invokePlayOnUserGesture(presoMediator: PresentationMediatorInterface): void {
		const domProxy = this.facade.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxyInterface;
		const playerOpts = this.getModel(ModelName.PlayerOptions) as PlayerOptionsInterface;
		const overrides = playerOpts.overrides;
		const sys = this.system;
		const shouldSkipPriming = !domProxy || sys.supportsUnrestrictedAutoplay || overrides?.skipPriming === true;
		presoMediator.beforePlayOnUserGesture();

		if (!shouldSkipPriming) {
			const p: Promise<any> = domProxy.primeVideo(overrides?.blankVideoUrl);
			p.then(() => {
				if (sys.device === Device.IPHONE) {
					const ev = 'timeupdate';
					const v = (this.facade.retrieveProxy(ProxyName.VideoProxy) as VideoProxyInterface).getVideo(),
						h = (e: Event) => {
							v.removeEventListener(ev, h);
							setTimeout(() => presoMediator.playOnUserGesture(), 200);
						};
					v.addEventListener(ev, h);
				}
				else {
					presoMediator.playOnUserGesture();
				}
			}).catch((error) => {
				this.logger.error(`ERROR - PlaybackCommand: Priming FAILED.  ${error.message}`);
				this.facade.sendNotification(PlayerEvent.AUTOPLAY_BLOCKED, error, NotificationType.INTERNAL);
			});
		}
		else {
			presoMediator && presoMediator.playOnUserGesture();
		}
	}
}
