import { Api, apiAccessor, apiCollection, apiMethod } from '../app/ApiDecorators';
import { localization } from '../app/Localization';
import { ProxyName } from '../enum/ProxyName';
import { LanguageTagInterface } from '../iface/LanguageTagInterface';
import { LocaleData } from '../iface/LocaleData';
import { LocalizationData } from '../iface/LocalizationData';
import { LocalizationInterface } from '../iface/LocalizationInterface';
import { Proxy } from '../mvc/Proxy';


export class LocalizationProxy extends Proxy implements LocalizationInterface {
	private localization: LocalizationInterface;
	private delegate: Api<LocalizationInterface>;

	constructor(name: ProxyName) {
		super(name, {});
	}

	override onRegister(): void {
		this.localization = localization.create({ logger: this.facade.logger });
		this.delegate = apiCollection<LocalizationInterface>(`${this.facade.appId}.localization`, this);
	}

	override onRemove() {
		// @ts-ignore
		this.localization.destroy();
		this.localization = null;
		this.delegate.destroy();
		this.delegate = null;
	}

	getApi(): LocalizationInterface {
		return this.delegate.api;
	}

	@apiAccessor(true)
	get defaultLanguage(): string {
		return this.localization.defaultLanguage;
	}

	@apiAccessor(true)
	get language(): string {
		return this.localization.language;
	}

	@apiMethod()
	get languageTag(): LanguageTagInterface {
		return this.localization.languageTag;
	}

	@apiMethod()
	changeLanguage(language: string): Promise<LocaleData> {
		return this.localization.changeLanguage(language);
	}

	@apiMethod()
	registerLocalizationData(data: LocalizationData): void {
		return this.localization.registerLocalizationData(data);
	}

	@apiMethod()
	localize(messageId: string, context?: any, language?: string): string {
		return this.localization.localize(messageId, context, language);
	}
}
