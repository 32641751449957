import { HookConfigInterface } from '../iface/HookConfigInterface';
import { HookInterface } from '../iface/HookInterface';
import { HookMap } from '../iface/HookMap';
import { HookManager } from '../model/HookManager';
import { sdk_hook_config } from '../model/hook_configs';
import { extensionContext } from './Extensions';

const hookManager = new HookManager(sdk_hook_config, extensionContext);

/**
 * Define a custom hook.
 *
 * @param type - The custom hook type.
 * @param config - The custom hook configuration.
 */
export function defineHook(type: string, config: HookConfigInterface) {
	hookManager.defineHook(type, config);
}

/**
 * Register a hook for a given type
 *
 * @param type - The hook type.
 */
export function hasHook<T extends keyof HookMap>(type: T) {
	return hookManager.hasHook(type);
}

/**
 * Register a hook for a given type
 *
 * @param type - The hook type.
 * @param hook - The hook callback.
 */
export function registerHook<T extends keyof HookMap>(type: T, hook: HookInterface<HookMap[T]>) {
	hookManager.registerHook(type, hook);
}

/**
 * Remove a hook for a given type
 *
 * @param type - The hook type.
 * @param hook - The hook callback.
 */
export function removeHook<T extends keyof HookMap>(type: T, hook: HookInterface<HookMap[T]>) {
	hookManager.removeHook(type, hook);
}

/**
 * Apply a hook for a given type
 *
 * @param type - The hook type.
 * @param value -  The value to be transformed, which may be the result of a previous hook.
 * @param metadata - Arbitrary metadata that may be passed to hooks.
 */
export function applyHook<T extends keyof HookMap, V = HookMap[T]['value'], M = HookMap[T]['metadata']>(type: T, value: V, metadata?: M) {
	return hookManager.applyHook(type, value, metadata);
}
