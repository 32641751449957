import { MetricType } from '../enum/MetricType';
import { NotificationInterface } from '../iface';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';

function getKey(value: { id: string, name: string; }) {
	return `${value.id}:${value.name}`;
}

const WARNING = 'Performance API error';

export class MetricCommand extends LogAwareSimpleCommand {

	execute(notification: NotificationInterface) {
		const { type, value } = notification.body.metric;

		if (!performance) {
			return;
		}

		switch (type) {
			case MetricType.MARK:
				try {
					performance.mark?.(getKey(value), { startTime: value.startTime });
				}
				catch (error) {
					this.logger.warn(`${WARNING}: ${error}`);
				}
				break;

			case MetricType.MEASURE:
				try {
					const key = getKey(value);
					performance?.measure?.(key, key + ':start', key + ':end');
				}
				catch (error) {
					this.logger.warn(`${WARNING}: ${error}`);
				}
				break;
		}
	}
}
