export enum ActionKey {
	NONE = 'none',
	PLAY = 'play',
	PAUSE = 'pause',
	PLAY_PAUSE = 'playpause',
	STOP = 'stop',
	FAST_FORWARD = 'fastforward',
	REWIND = 'rewind',
	FULLSCREEN = 'fullscreen',
	SKIP_FORWARD = 'skipforward',
	SKIP_BACKWARD = 'skipbackward',
	CAPTIONS = 'texttrack',
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	TEXT_TRACK = 'texttrack',
	INFO = 'info',
	INCREASE_VOLUME = 'increasevolume',
	DECREASE_VOLUME = 'decreasevolume',
	MUTE_UNMUTE = 'muteunmute',
	PLAYLIST_NEXT = 'playlistnext',
	PLAYLIST_PREVIOUS = 'playlistprevious',
	LEFT = 'left',
	RIGHT = 'right',
	UP = 'up',
	DOWN = 'down',
	SELECT = 'select',
	CANCEL = 'cancel',
	SPACE = 'space',
	BACK = 'back',
	RECORD = 'record',
	LIVE = 'live',
}
