import { AppResources } from '../app/AppResources';
import { QueryString } from '../core/QueryString';
import { request } from '../core/Request';
import { DrmType } from '../enum/DrmType';
import { DrmVendor } from '../enum/DrmVendor';
import { ErrorCode } from '../enum/ErrorCode';
import { PlayerHookType } from '../enum/PlayerHookType';
import { RequestMethod } from '../enum/RequestMethod';
import { ResponseType } from '../enum/ResponseType';
import { ErrorInfoInterface } from '../iface/ErrorInfoInterface';
import { EventHandler } from '../iface/EventHandler';
import { FairPlayDrmInterface } from '../iface/FairPlayDrmInterface';
import { LoggerInterface } from '../iface/LoggerInterface';
import { PlaybackAdapterDelegateInterface } from '../iface/PlaybackAdapterDelegateInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { System } from '../util/System';
import { arrayToString, base64DecodeUint8Array, base64EncodeUint8Array, stringToArray } from './ArrayUtil';
import { clone, merge } from './ObjectUtil';
import { isEmpty } from './Type';

const WEBKIT_NEED_KEY = 'webkitneedkey';
const WEBKIT_KEY_MESSAGE = 'webkitkeymessage';
const WEBKIT_KEY_ADDED = 'webkitkeyadded';
const WEBKIT_KEY_ERROR = 'webkitkeyerror';

export class FairPlay {
	keySystem: DrmType;

	private video: HTMLVideoElement;
	private drmInfo: FairPlayDrmInterface;
	private logger: LoggerInterface;
	private delegate: PlaybackAdapterDelegateInterface;
	private appCertData: Uint8Array;
	private initData: Uint16Array;
	private appCertLoaded: boolean = false;
	private webKitNeedKeyCalled: boolean = false;
	private webkitNeedKeyHandler: EventHandler = (e: any) => this.onWebkitNeedKey(e);
	private onError: (error: ErrorInfoInterface) => void;

	constructor(video: HTMLVideoElement,
		drm: FairPlayDrmInterface,
		logger: LoggerInterface,
		delegate: PlaybackAdapterDelegateInterface,
		onError: (error: ErrorInfoInterface) => void,
	) {

		this.onError = onError;
		this.drmInfo = drm;
		this.keySystem = drm.keySystem || DrmType.FAIRPLAY_1_0;
		this.video = video;
		// @ts-ignore
		this.video.addEventListener(WEBKIT_NEED_KEY, this.webkitNeedKeyHandler);
		this.logger = logger;
		this.delegate = delegate;
	}

	////////////////////
	//Public Methods
	////////////////////
	async initialize(): Promise<void> {
		const value = await this.delegate.applyHook(PlayerHookType.REQUEST, { url: this.drmInfo.appCertUrl, headers: {} });
		const response = await request(merge({ responseType: ResponseType.ARRAY_BUFFER }, value));
		this.appCertData = new Uint8Array(response);
		this.appCertLoaded = true;
		if (this.webKitNeedKeyCalled) {
			this.initializeFairplayCDM();
		}
	}

	destroy(): void {
		this.appCertData = null;
		this.initData = null;
		this.onError = null;
		this.drmInfo = null;
		this.logger = null;

		// @ts-ignore
		this.video.removeEventListener(WEBKIT_NEED_KEY, this.webkitNeedKeyHandler);
		this.video = null;
	}

	////////////////////
	//Events Methods
	////////////////////

	/*
	 * Async dependent on app certdata to continue.
	 */
	private onWebkitNeedKey(event: any) {
		this.initData = event.initData;
		this.webKitNeedKeyCalled = true;
		if (this.appCertLoaded) {
			this.initializeFairplayCDM();
		}
	}

	private onLicenseRequestReady(url: string, event: Event) {
		const drmVendor = this.getDrmVendor(url);
		const session = event.target as any;
		const spcMessage = base64EncodeUint8Array((event as any).message);
		const data = {
			spc: spcMessage,
			contentId: session.contentId,
		};
		const responseType = drmVendor === DrmVendor.IRDETO || drmVendor === DrmVendor.UNKNOWN ?
			ResponseType.ARRAY_BUFFER :
			ResponseType.TEXT;

		const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
		const { header } = this.drmInfo;
		if (!isEmpty(header)) {
			Object.assign(headers, header);
		}

		this.delegate.applyHook(PlayerHookType.REQUEST, { url, method: RequestMethod.POST, responseType, headers, data })
			.then(value => {
				const requestOptions = clone(value);
				const contentType = value.headers['Content-Type'];
				if (contentType === 'application/x-www-form-urlencoded') {
					// @ts-ignore
					requestOptions.data = 'spc=' + encodeURIComponent(value.data.spc) + '&' + value.data.contentId;
				}
				else if (contentType === 'application/json') {
					requestOptions.data = JSON.stringify(value.data);
				}

				return request(requestOptions)
					.then(response => {
						const key = typeof response === 'string' ?
							base64DecodeUint8Array(response) :
							new Uint8Array(response);

						session.update(key);
					})
					.catch(error => {
						this.throwFatalError(ErrorCode.FAIRPLAY_LIC_ERROR, AppResources.messages.FAIRPLAY_LICENSE_ERROR);
					});
			});
	}

	private onWebkitKeyAdded(event: Event) {
		this.logger.info(AppResources.messages.WEBKIT_KEY_ADDED_SUCCESS);
	}

	private onWebkitError(event: Error) {
		this.throwFatalError(ErrorCode.FAIRPLAY_LIC_ERROR, `${AppResources.messages.FAIRPLAY_LICENSE_ERROR}`);
	}

	////////////////////
	//Private Methods
	////////////////////

	private initializeFairplayCDM(): void {
		const licenseServerUrl: string = this.getLicenseServerUrl(this.initData);
		const contentId: string = this.getId(licenseServerUrl, this.initData);
		const video = this.video as any;
		const global = System.global;
		const errMsg = AppResources.messages;

		if (!contentId) {
			this.throwFatalError(ErrorCode.FAIRPLAY_APP_CERT_ERROR, errMsg.FAIRPLAY_NO_CONTENT_ID);
			return;
		}

		if (!video.webkitKeys) {
			const keySystem = this.keySystem;
			if (!global.WebKitMediaKeys.isTypeSupported(keySystem, 'video/mp4')) {
				this.throwFatalError(ErrorCode.FAIRPLAY_APP_CERT_ERROR, errMsg.FAIRPLAY_WEBKIT_ERROR);
				return;
			}
			try {
				video.webkitSetMediaKeys(new global.WebKitMediaKeys(keySystem));
			}
			catch (e) {
				this.throwFatalError(ErrorCode.FAIRPLAY_APP_CERT_ERROR, e.message);
				return;
			}
		}

		const appCertInitData = this.concatInitDataIdAndCertificate(this.initData, contentId, this.appCertData);
		const keySession = video.webkitKeys.createSession('video/mp4', appCertInitData);

		if (!keySession) {
			this.throwFatalError(ErrorCode.FAIRPLAY_APP_CERT_ERROR, errMsg.FAIRPLAY_WEBKIT_ERROR);
			return;
		}

		//TODO update this remove bind and use fat arrow to scope? Port from old code need to understand the flow a bit before change.
		keySession.contentId = contentId;
		keySession.addEventListener(WEBKIT_KEY_MESSAGE, this.onLicenseRequestReady.bind(this, licenseServerUrl), false);
		keySession.addEventListener(WEBKIT_KEY_ADDED, this.onWebkitKeyAdded.bind(this), false);
		keySession.addEventListener(WEBKIT_KEY_ERROR, this.onWebkitError.bind(this), false);
	}

	private concatInitDataIdAndCertificate(initData: Uint16Array, id: Uint16Array | string, cert: Uint8Array): Uint8Array {
		if (typeof id === 'string') {
			id = stringToArray(id);
		}

		// layout is [initData][4 byte: idLength][idLength byte: id][4 byte:certLength][certLength byte: cert]
		let offset = 0;
		const buffer = new ArrayBuffer(initData.byteLength + 4 + id.byteLength + 4 + cert.byteLength);
		const dataView = new DataView(buffer);

		const initDataArray = new Uint8Array(buffer, offset, initData.byteLength);
		initDataArray.set(initData);
		offset += initData.byteLength;

		dataView.setUint32(offset, id.byteLength, true);
		offset += 4;

		const idArray = new Uint16Array(buffer, offset, id.length);
		idArray.set(id);
		offset += idArray.byteLength;

		dataView.setUint32(offset, cert.byteLength, true);
		offset += 4;

		const certArray = new Uint8Array(buffer, offset, cert.byteLength);
		certArray.set(cert);

		return new Uint8Array(buffer, 0, buffer.byteLength);
	}

	private getLicenseServerUrl(initData: Uint16Array): string {
		let url = null;

		if (!isEmpty(this.drmInfo.licenseUrl)) {
			url = this.drmInfo.licenseUrl;
		}
		else {
			const initDataString = String.fromCharCode.apply(null, new Uint16Array(initData.buffer));
			const splitString = initDataString.split('://');
			url = 'https://' + splitString[1];
		}

		return url;
	}

	private extractContentId(initData: Uint16Array) {
		return arrayToString(initData).split('skd://')[1];
	}

	private getId(licenseServerUrl: string, initData: Uint16Array): string | null {
		const obj: StrAnyDict = QueryString.decode(licenseServerUrl);
		return obj.ContentId || obj.contentId || obj.assetId || this.extractContentId(initData);
	}

	/**
	 * license server url needed to parse vendor
	 */
	private getDrmVendor(url: string): string {
		let v = DrmVendor.UNKNOWN;
		if (url.indexOf(DrmVendor.IRDETO) !== -1) {
			v = DrmVendor.IRDETO;
		}
		else if (url.indexOf(DrmVendor.DRM_TODAY) !== -1) {
			v = DrmVendor.DRM_TODAY;
		}
		return v;
	}

	private throwFatalError(code: ErrorCode, message: string) {

		this.logger.error(`${code} ${message}`);

		this.onError({
			code,
			message,
			fatal: true,
		});
	}
}
