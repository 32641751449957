
export enum Browser {
	/** */
	NONE = 'None',

	/** */
	ANDROID = 'Android',

	/** */
	CHROME = 'Chrome',

	/** */
	CHROME_IOS = 'Chrome-iOS',

	/** */
	FIREFOX = 'Firefox',

	/** */
	FIREFOX_IOS = 'Firefox-iOS',

	/** */
	EDGE = 'Edge',

	/** */
	MSIE = 'MSIE',

	/** */
	SAFARI = 'Safari',

	/** */
	OPERA = 'Opera',

	/** */
	OPERA_MOBILE = 'Opera-Mobile',

	/** */
	SILK = 'Silk',

	/** */
	WEBKIT = 'Webkit Browser',

	/** PlayStation 4 WebMaf*/
	PLAYSTATION_4_WEBMAF = 'PlayStation 4 (WebMAF)',

	/** PlayStation 4 MSE*/
	PLAYSTATION_4_MSE = 'PlayStation 4 (Media Source API)',

	/** PlayStation 5 MSE*/
	PLAYSTATION_5 = 'PlayStation 5 (Media Source API)',

	/** The browser is unknown */
	UNKNOWN = 'Unknown',
}
