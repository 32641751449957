/**
 * Player events
 * See {@link VideoPlayerInterface.on} and {@link VideoPlayerInterface.off} for addition and
 * removal, respectively, of event listeners.
 *
 * An event listener has this signature:
 * ```typescript
 * (event: EventInterface) => void;
 * ```
 *
 * i.e., the function accepts a single argument (**`event`**), an event object.
 *
 * An event object has the following general form:
 * ```typescript
 * interface EventInterface {
 *   detail?: object; // an optional event-specific data object, may be null
 *   target: EmitterInterface; // The emitting object
 *   timeStamp: number; // millisecond time stamp; when the event was emitted
 *   type: string; // the event type, an avia.PlayerEvent enum member
 * }
 * ```
 *
 * For more info see [Event Handling](../../../guides/event_handling)
 *
 */
export enum PlayerEvent {
	/**
	 * Emitted when an accessibility hotkey is pressed for
	 * any of the following operations:
	 * Seek (Fwd or Back)
	 * Mute/Unmute
	 * Change volume
	 *
	 * A primary use case for handling at least a subset of these events
	 * is to display accessibility-related UI.
	 *
	 * @see {@link HotkeyActionEventInterface}
	 * @see {@link HotkeyActionEventDetailInterface}
	 * @deprecated use ACTION_KEY event
	 */
	HOTKEY_ACTION = 'hotkeyaction',

	/**
	 * Emitted when an action key is pressed for
	 * any registered action.
	 *
	 * A primary use case for handling at least a subset of these events
	 * is to display accessibility-related UI.
	 *
	 * @see {@link ActionKeyEventInterface}
	 * @see {@link ActionKeyEventDetailInterface}
	 */
	ACTION_KEY = 'actionkey',

	/**
	 * Emitted immediately after an action key event is dispatched allowing
	 * metadata to be added to the `ACTION_KEY` event's metadata object.
	 *
	 *
	 * @see {@link ACTION_KEY}
	 * @see {@link ActionKeyEventInterface}
	 * @see {@link ActionKeyEventDetailInterface}
	 */
	ACTION_KEY_METADATA = 'actionkeymetadata',

	/**
	 * Emitted when the player is ready for external interactions.
	 *
	 * :::note
	 * This event can only be listened to by plugins. Outside of plugins,
	 * the player is ready to use when the `createVideoPlayer` promise
	 * resolves.
	 * :::
	 *
	 * @see {@link createVideoPlayer}
	 */
	READY = 'ready',

	/**
	 * Emitted if/when an ad's playhead passes the 'skipOffset'
	 * time.
	 */
	AD_SKIPPABLE_STATE_CHANGE = 'adskippablestatechange',

	/**
	 * Emitted when the ad break schedule is known; the **data** property
	 * of the event will be an array of ad break start times, in seconds. Note that these
	 * times correspond to **content time**; this is the case for DAI (in-stream) ads as well.
	 *
	 * @see {@link AdCuepointsAvailableEventInterface}
	 * @see {@link AdCuepointsAvailableEventDetailInterface}
	 */
	AD_CUEPOINTS_AVAILABLE = 'adcuepointsavailable',

	/**
	 * Emitted at the start of an advertisement break. Completion of
	 * the break will be signaled with {@link AD_BREAK_COMPLETE}. Data for the break
	 * is not available until {@link AD_BREAK_METADATA} is emitted.
	 *
	 * @see {@link AD_BREAK_COMPLETE}
	 */
	AD_BREAK_START = 'adbreakstart',

	/**
	 * Emitted when metadata for the ad break becomes available.
	 *
	 * @see {@link AdBreakMetadataEventInterface}
	 * @see {@link AdBreakMetadataEventDetailInterface}
	 * @see {@link AdBreakInfoInterface}
	 */
	AD_BREAK_METADATA = 'adbreakmetadata',

	/**
	 * Emitted at the end of an advertisement break.
	 *
	 * @see {@link AD_BREAK_START}
	 */
	AD_BREAK_COMPLETE = 'adbreakcomplete',

	/**
	 * Emitted every time an advertisement clip has fully buffered and begins playback.
	 *
	 * @see {@link AdStartEventInterface}
	 * @see {@link AdStartEventDetailInterface}
	 * @see {@link AdItemInterface}
	 * @see {@link AD_COMPLETE}
	 */
	AD_START = 'adstart',

	/**
	 * Emitted after each advertisement completes playback.
	 *
	 * @see {@link AD_START}
	 */
	AD_COMPLETE = 'adcomplete',

	/**
	 * Emitted after 25 percent of the advertisement has been played.
	 *
	 * @see {@link AD_MIDPOINT}
	 * @see {@link AD_THIRD_QUARTILE}
	 */
	AD_FIRST_QUARTILE = 'adfirstquartile',

	/**
	 * Emitted after 50 percent of the advertisement has been played.
	 *
	 * @see {@link AD_FIRST_QUARTILE}
	 * @see {@link AD_THIRD_QUARTILE}
	 */
	AD_MIDPOINT = 'admidpoint',

	/**
	 * Emitted after 75 percent of the advertisement has been played.
	 * - see {@link AD_COMPLETE} for 100 percent.
	 *
	 * @see {@link AD_FIRST_QUARTILE}
	 * @see {@link AD_MIDPOINT}
	 * @see {@link AD_COMPLETE}
	 */
	AD_THIRD_QUARTILE = 'adthirdquartile',

	/**
	 * Emitted when an ad segment has been encountered.
	 */
	AD_SEGMENT_START = 'adsegmentstart',

	/**
	 * Emitted when an ad segment has ended.
	 */
	AD_SEGMENT_END = 'adsegmentend',

	/**
	 * Emitted when an ad segment of a LTS stream has been
	 * reentered, as may occur on a seek back in time.
	 */
	AD_SEGMENT_REENTERED = 'adsegmentreentered',

	/**
	 * Emitted when a "skippable" ad is skipped by the user.
	 * Applies to skippable IMA ads; emitted when the IMA SDK
	 * determines that the user has elected to skip an ad.
	 */
	AD_SKIPPED = 'adskipped',

	/**
	 * Emitted continuously during advertisement playback.
	 *
	 * The times conveyed in the payload relate only to the playing ad, i.e, a
	 * progress event for a 30 second ad will have a duration of 30 and currentTime
	 * will be be in the range 0-30.
	 *
	 * @see {@link AdProgressEventInterface}
	 * @see {@link AdProgressEventDetailInterface}
	 */
	AD_PROGRESS = 'adprogress',

	/**
	 * Emitted when a playing ad has been paused.
	 */
	AD_PAUSED = 'adpaused',

	/**
	 * Emitted when a paused ad has resumed playing.
	 */
	AD_PLAYING = 'adplaying',

	/**
	 * Emitted when a non-paused ad fails to progress for
	 * more than 5 seconds, i.e., if the ad is "stalled". Applies to IMA ads only.
	 *
	 * In this case the ad break must be discarded and content play continues.
	 */
	AD_STALLED = 'adstalled',

	/**
	 * Emitted if an ad break is aborted for any reason. Applies to IMA ads only.
	 */
	AD_BREAK_DISCARDED = 'adbreakdiscarded',

	/**
	 * Emitted when a playing ad with a clickthrough has been clicked by the user.
	 */
	AD_CLICK = 'adclick',

	/**
	 * Emitted after the audio track has changed. Either at startup when
	 * initially setting the default audio track or after calling api {@link VideoPlayerInterface.audioTrack}
	 * Once this event is emitted, it is safe to change audio tracks again.
	 *
	 * @see {@link AudioTrackChangeEventInterface}
	 * @see {@link AudioTrackChangeEventDetailInterface}
	 *
	 * @see {@link VideoPlayerInterface.audioTrack}
	 */
	AUDIO_TRACK_CHANGE = 'audiotrackchange',

	/**
	 * Emitted when the set of available audio tracks has changed.
	 *
	 * @see {@link AudioTracksChangeEventInterface}
	 * @see {@link AudioTracksChangeEventDetailInterface}
	 *
	 * @see {@link VideoPlayerInterface.audioTracks}
	 */
	AUDIO_TRACKS_CHANGE = 'audiotrackschange',

	/**
	 * Emitted when an adaptive quality stream has changed qualities,
	 * either invoked from auto quality switching logic or by a manual
	 * switch request when setting a new value on {@link VideoPlayerInterface.bitrate}.
	 *
	 * @see {@link QualityChangeEventInterface}
	 * @see {@link QualityChangeEventDetailInterface}
	 *
	 * @see {@link VideoPlayerInterface.bitrate}
	 */
	QUALITY_CHANGE = 'qualitychange',

	/**
	 * Emitted when the set of available stream qualities has changed.
	 *
	 * @see {@link QualitiesChangeEventInterface}
	 * @see {@link QualitiesChangeEventDetailInterface}
	 *
	 * @see {@link VideoPlayerInterface.qualities}
	 */
	QUALITIES_CHANGE = 'qualitieschange',

	/**
	 * Emitted if the content delivery network (CDN) changes.
	 * This is applicable to multi-CDN scenarios.
	 *
	 * @see {@link CdnChangeEventInterface}
	 * @see {@link CdnChangeEventDetailInterface}
	 */
	CDN_CHANGE = 'cdnchange',

	/**
	 * This event is emitted continuously during the lifetime
	 * of a player instance at approximately 100 ms intervals.
	 *
	 * A `count` property in the payload conveys the number of ticks
	 * that have passed since the clock started.
	 *
	 * It is emitted while the player is idle and while playback
	 * is in any state - paused, buffering, playing or otherwise.
	 *
	 * The clock event can be used for any processes that require polling
	 * or continuous updates unrelated to playback state.
	 *
	 * @see {@link ClockEventInterface}
	 * @see {@link ClockEventDetailInterface}
	 */
	CLOCK = 'aviaclock',

	/**
	 * Emitted when content video has **first** started to play.
	 */
	CONTENT_START = 'contentstart',

	/**
	 * Emitted when the content duration is known. The timing of this
	 * event may vary depending on whether client-side or in-stream ads accompany the
	 * presentation.
	 *
	 * @see {@link ContentDurationAvailableEventInterface}
	 * @see {@link ContentDurationAvailableEventDetailInterface}
	 */
	CONTENT_DURATION_AVAILABLE = 'contentdurationavailable',

	/**
	 * Emitted when any content segment has begun playback;
	 * any presentation with ad breaks will have content segments, as
	 * shown below, where 'ad' is an ad break with 1 or more ads.
	 * ```
	 *   ad    content segment    ad
	 * |-----|==================|-----|
	 * ```
	 */
	CONTENT_SEGMENT_START = 'contentsegmentstart',

	/**
	 * Emitted when content has fully buffered and playback has started.
	 *
	 * @see {@link CONTENT_PROGRESS}
	 */
	CONTENT_PLAYING = 'contentplaying',

	/**
	 * Emitted continuously when content is playing and time is moving forward.
	 *
	 * @see {@link ContentProgressEventInterface}
	 * @see {@link ContentProgressEventDetailInterface}
	 */
	CONTENT_PROGRESS = 'contentprogress',

	/**
	 * Emitted when content has been paused.
	 */
	CONTENT_PAUSED = 'contentpaused',

	/**
	 * Emitted when any content segment ends See also {@link CONTENT_SEGMENT_START}.
	 */
	CONTENT_SEGMENT_END = 'contentsegmentend',

	/**
	 * Emitted when the content has completed fully; the resource may have post-roll ads
	 * to play at this point, so 'content complete' is not necessarily synonymous with 'resource end'.
	 */
	CONTENT_COMPLETE = 'contentcomplete',

	/**
	 * Emitted when the content is playing at the live edge.
	 *
	 * @see {@link ContentIsPlayingLiveEventInterface}
	 * @see {@link ContentIsPlayingLiveEventDetailInterface}
	 *
	 * @see {@link VideoPlayerInterface.isPlayingLive}
	 */
	CONTENT_IS_PLAYING_LIVE = 'contentisplayinglive',

	/**
	 * Emitted when the stream type changes.
	 *
	 * @see {@link StreamTypeChangeEventInterface}
	 * @see {@link StreamTypeChangeEventDetailInterface}
	 *
	 * @see {@link StreamType}
	 * @see {@link VideoPlayerInterface.streamType}
	 */
	STREAM_TYPE_CHANGE = 'streamtypechange',

	/**
	 * Emitted when the stream's metadata is loaded, before playback begins.
	 *
	 * @see {@link StreamMetadataEventInterface}
	 * @see {@link StreamMetadataEventDetailInterface}
	 *
	 * @see {@link StreamMetadataInterface}
	 */
	STREAM_METADATA = 'streammetadata',

	/**
	 * Applies to SSAI streams that have a 'stream ID"; emitted if and when the ID becomes
	 * available.
	 *
	 * @see {@link StreamIdAvailableEventInterface}
	 * @see {@link StreamIdAvailableEventDetailInterface}
	 */
	STREAM_ID_AVAILABLE = 'streamidavailable',

	/**
	 * Used mainly for tracking and diagnostics.
	 * This event's data payload will be a string with the DRM key system in use.
	 *
	 * @see {@link DrmKeySystemCreatedEventInterface}
	 * @see {@link DrmKeySystemCreatedEventDetailInterface}
	 */
	DRM_KEY_SYSTEM_CREATED = 'drmkeysystemcreated',

	/**
	 * Emitted for all player errors. The `ERROR` event carries properties
	 * indicating if the error is fatal, the kind of failure, etc.
	 *
	 * See also [Event Handling](../../../guides/event_handling)
	 *
	 * @see {@link ErrorEventInterface}
	 * @see {@link ErrorEventDetailInterface}
	 *
	 * @see {@link ErrorInfoInterface}
	 */
	ERROR = 'error',

	/**
	 * Event emitted when onscreen playback has started. This can either be
	 * ad or content playback.
	 */
	PLAYBACK_STARTED = 'playbackstarted',

	/**
	 * Event emitted following a call to `player.suspendPlayback()`.
	 * Indicates that the tracking session will end (if playback is resumed,
	 * a new tracking session is started).
	 *
	 * @see {@link PlaybackSuspendedEventInterface}
	 * @see {@link PlaybackSuspendedEventDetailInterface}
	 *
	 * @see {@link VideoPlayerInterface.suspendPlayback}
	 */
	PLAYBACK_SUSPENDED = 'playbacksuspended',

	/**
	 * Event emitted if playback is resumed (via `player.resumePlayback()` or
	 * `player.play()`) from a 'suspended' state.
	 *
	 * @see {@link PlaybackResumedEventInterface}
	 * @see {@link PlaybackResumedEventDetailInterface}
	 *
	 * @see {@link PLAYBACK_SUSPENDED}
	 */
	PLAYBACK_RESUMED = 'playbackresumed',

	/**
	 * Emitted when the resource presentation starts. This occurs when all required data
	 * is at hand, and just prior to any attempt to either play content or a pre-roll ad.
	 * As such, this event can not be used as an indication that any video is currently playing.
	 *
	 * This event is primarily of use for tracking, to signal the start of a new tracking session.
	 *
	 * @see {@link ResourceStartEventInterface}
	 * @see {@link ResourceStartEventDetailInterface}
	 */
	RESOURCE_START = 'resourcestart',

	/**
	 * Emitted when resource playback has ended by any means. It does not necessarily signify that
	 * content has been viewed to completion. The payload (a {@link ResourceEndEventDetailInterface} object)
	 * indicates whether playback was interrupted, and whether interruption occurred during content
	 * or during an ad break. For comparison see {@link CONTENT_COMPLETE}.
	 *
	 * @see {@link ResourceEndEventInterface}
	 * @see {@link ResourceEndEventDetailInterface}
	 */
	RESOURCE_END = 'resourceend',

	/**
	 * Emitted when the resource buffering state changes.
	 *
	 * @see {@link ResourceBufferingEventInterface}
	 * @see {@link ResourceBufferingEventDetailInterface}
	 */
	RESOURCE_BUFFERING = 'resourcebuffering',

	/**
	 * Emitted when the video tag is "waiting".
	 */
	WAITING_CHANGE = 'waitingchange',

	/**
	 * Emitted when a seek request has been fulfilled. The playhead time will
	 * be updated after this event has been emitted.
	 *
	 * @see {@link SEEK_START}
	 */
	SEEK_COMPLETE = 'seekcomplete',

	/**
	 * Emitted at the start of a seek request. The playhead time will
	 * not be updated until {@link SEEK_COMPLETE}
	 *
	 * @see {@link SEEK_COMPLETE}
	 */
	SEEK_START = 'seekstart',

	/**
	 * Emitted when a user-initiated seek would move the playhead
	 * past an unplayed ad break (which must be played). For SSAI streams particularly,
	 * this can result in the playhead jumping back to the ad break position.
	 * The `SEEK_REDIRECT_START` event can alert user code to ignore (if desired)
	 * any subsequent content progress events until the ad break has completed.
	 *
	 * @see {@link SeekRedirectStartEventInterface}
	 * @see {@link SeekRedirectStartEventDetailInterface}
	 *
	 * @see {@link SEEK_REDIRECT_COMPLETE}
	 */
	SEEK_REDIRECT_START = 'seekredirectstart',

	/**
	 * Emitted just prior to issuing a "pending" seek to the playhead,
	 * and is only relevant in the context described for `SEEK_REDIRECT_START`
	 *
	 * @see {@link SEEK_REDIRECT_START}
	 */
	SEEK_REDIRECT_COMPLETE = 'seekredirectcomplete',

	/**
	 * Emitted when ID3 data is detected and the ID3 tag "owner" matches
	 * any `id3OwnerIds` provided in the resource configuration.
	 *
	 * :::note
	 * This event carries different payload structures so inspect the data
	 * object of the event.
	 * :::
	 *
	 * @see {@link MetadataCuepointEventInterface}
	 * @see {@link MetadataCuepointEventDetailInterface}
	 *
	 * @see {@link ResourcePlaybackInterface.id3OwnerIds}
	 */
	METADATA_CUEPOINT = 'metadatacuepoint',

	/**
	 * Emitted only when:
	 * - There is at least one valid text track in the presentation.
	 * - the {@link VideoPlayerInterface.contentTime} has reached the start time of the cue.
	 *
	 * @see {@link TextCuepointEventInterface}
	 * @see {@link TextCuepointEventDetailInterface}
	 */
	TEXT_CUEPOINT = 'textcuepoint',

	/**
	 * Emitted once a text track has changed to the new track. This will happen
	 * at startup, if text is detected, while setting the default track.
	 * It will also be emitted after each track switch via {@link VideoPlayerInterface.textTrack}
	 *
	 * @see {@link TextTrackChangeEventInterface}
	 * @see {@link TextTrackChangeEventDetailInterface}
	 *
	 * @see {@link VideoPlayerInterface.textTrack}
	 */
	TEXT_TRACK_CHANGE = 'texttrackchange',

	/**
	 * Emitted when the set of available text tracks has changed.
	 *
	 * @see {@link TextTracksChangeEventInterface}
	 * @see {@link TextTracksChangeEventDetailInterface}
	 *
	 * @see {@link VideoPlayerInterface.textTracks}
	 */
	TEXT_TRACKS_CHANGE = 'texttrackschange',

	/**
	 * Emitted each time {@link VideoPlayerInterface.textTrackEnabled} is called and the mode
	 * has changed. This is useful when rendering text externally. Typically only
	 * used when player option {@link PlayerOptionsInterface.renderTextTrackNatively} is set to `false`.
	 *
	 * @see {@link TextTrackEnabledChangeEventInterface}
	 * @see {@link TextTrackEnabledChangeEventDetailInterface}
	 *
	 * @see {@link VideoPlayerInterface.textTrackEnabled}
	 * @see {@link PlayerOptionsInterface.renderTextTrackNatively}
	 */
	TEXT_TRACK_ENABLED_CHANGE = 'texttrackenabledchange',

	/**
	 * Emitted when there is valid text track. Can be used as an indication
	 * to enable UI controls for caption button or subtitles menu.
	 */
	TEXT_TRACK_AVAILABLE = 'texttrackavailable',

	/**
	 * Emitted when there is valid thumbnail track. Can be used as an indication
	 * to enable UI seek preview scrubber.
	 *
	 * @see {@link ThumbnailTrackAvailableEventInterface}
	 * @see {@link ThumbnailTrackAvailableEventDetailInterface}
	 */
	THUMBNAIL_TRACK_AVAILABLE = 'thumbnailtrackavailable',

	/**
	 * Applies to live (linear) streams only; if the presentation is 'stopped' by the user
	 * video playback ceases, the current resource is discarded and the tracking session ends.
	 *
	 * @see {@link LivePresentationStoppedEventInterface}
	 * @see {@link LivePresentationStoppedEventDetailInterface}
	 */
	LIVE_PRESENTATION_STOPPED = 'livepresentationstopped',

	/**
	 * Emitted when a change in presentation size (width and height) is detected.
	 *
	 * @see {@link PresentationSizeChangeEventInterface}
	 * @see {@link PresentationSizeChangeEventDetailInterface}
	 */
	PRESENTATION_SIZE_CHANGE = 'presentationsizechange',

	/**
	 * Emitted when Fullscreen mode is entered
	 *
	 * @see {@link FullscreenChangeEventInterface}
	 * @see {@link FullscreenChangeEventDetailInterface}
	 */
	FULLSCREEN_CHANGE = 'fullscreenchange',

	/**
	 * Emitted for all log statements originating from a Player instance, regardless of the
	 * log level specified in PlayerOptions. This event is primarily useful for plugin
	 * development.
	 *
	 * @see {@link LogEventInterface}
	 * @see {@link LogEventDetailInterface}
	 */
	LOG_EVENT = 'logevent',

	/**
	 * Emitted from Plugins via the Player.  Plugin events
	 * behave like any other player event, but their `detail` payload
	 * is determined by the given plugin that initiates event emission.
	 *
	 * @see {@link PlayerEventInterface}
	 */
	PLUGIN_EVENT = 'pluginevent',

	/**
	 * Emitted when the language is changed.
	 *
	 * @see {@link LanguageChangeEventInterface}
	 * @see {@link LanguageChangeEventDetailInterface}
	 */
	LANGUAGE_CHANGE = 'languagechange',

	/**
	 * Emitted if autoplay is blocked by the browser.
	 *
	 * @see {@link AutoplayBlockedEventInterface}
	 * @see {@link AutoplayBlockedEventDetailInterface}
	 */
	AUTOPLAY_BLOCKED = 'autoplayblocked',

	/**
	 * Emitted when the player volume changes
	 *
	 * @see {@link VolumeEventInterface}
	 * @see {@link VolumeEventDetailInterface}
	 */
	VOLUME_CHANGE = 'volumechange',

	/**
	 * Emitted when the player is muted/unmuted
	 *
	 * @see {@link MuteChangeEventInterface}
	 * @see {@link MuteChangeEventDetailInterface}
	 */
	MUTE_CHANGE = 'mutechange',

	/**
	 * Emitted when the user starts/stops interacting with the player
	 *
	 * @see {@link ActiveStateChangeEventInterface}
	 * @see {@link ActiveStateChangeEventDetailInterface}
	 */
	ACTIVE_STATE_CHANGE = 'activestatechange',

	/**
	 * Emitted when the the playback state changes.
	 *
	 * @see {@link PlaybackStateChangeEventInterface}
	 * @see {@link PlaybackStateChangeEventDetailInterface}
	 *
	 * @see {@link PlaybackState}
	 */
	PLAYBACK_STATE_CHANGE = 'playbackstatechange',

	/**
	 * A generic metric event
	 *
	 * @see {@link MetricEventInterface}
	 * @see {@link MetricEventDetailInterface}
	 */
	METRIC = 'metric',

	/**
	 * Emitted when a new video element is added to the player DOM.
	 *
	 * @see {@link VideoElementAddedEventInterface}
	 * @see {@link VideoElementAddedEventDetailInterface}
	 *
	 * @see {@link VideoPlayerInterface.video}
	 */
	VIDEO_ELEMENT_ADDED = 'videoelementadded',

	/**
	 * Emitted when an existing video element is removed to the player DOM.
	 *
	 * @see {@link VideoElementRemovedEventInterface}
	 * @see {@link VideoElementRemovedEventDetailInterface}
	 *
	 * @see {@link VideoPlayerInterface.video}
	 */
	VIDEO_ELEMENT_REMOVED = 'videoelementremoved',
}
