import { AppResources } from '../../app/AppResources';
import { HTML5 } from '../../const/HTML5';
import { AdapterRole } from '../../enum/AdapterRole';
import { AudioTrackType } from '../../enum/AudioTrackType';
import { MimeType } from '../../enum/MimeType';
import { PlayerHookType } from '../../enum/PlayerHookType';
import { RequestObjectType } from '../../enum/RequestObjectType';
import { TextTrackEvent } from '../../enum/TextTrackEvent';
import { TextTrackMode } from '../../enum/TextTrackMode';
import { AudioTrackInterface } from '../../iface/AudioTrackInterface';
import { Debounced } from '../../iface/Debounced';
import { ErrorInfoInterface } from '../../iface/ErrorInfoInterface';
import { PlaybackAdapterContextInterface } from '../../iface/PlaybackAdapterContextInterface';
import { QualityInterface } from '../../iface/QualityInterface';
import { StreamMetadataInterface } from '../../iface/StreamMetadataInterface';
import { forEach } from '../../util/ArrayUtil';
import { waitForEvent } from '../../util/Async';
import { FairPlay } from '../../util/FairPlay';
import { getMimeType } from '../../util/File';
import { debounce } from '../../util/FunctionUtil';
import { getResourceMimeType } from '../../util/Resource';
import { findDefaultTrack } from '../../util/TimedText';
import { isValidPlayheadTime } from '../../util/Video';
import { CMCD_VERSION, CmcdObjectType, CmcdStreamingFormat, appendCmcdQuery } from '../../util/cmcd';
import { PlaybackAdapterBase } from './PlaybackAdapterBase';
import { VideoSurfaceEvents } from './enum/VideoSurfaceEvents';

export class Html5Adapter extends PlaybackAdapterBase {
	static get ID() {
		return HTML5;
	}

	static get config() {
		return {
			id: Html5Adapter.ID,
			role: [AdapterRole.PLAYBACK],
			factory: (context: PlaybackAdapterContextInterface, options: any = {}) => {
				return new Html5Adapter(context, options);
			},
			isSupported: (context: PlaybackAdapterContextInterface) => {
				const type = getResourceMimeType(context.resource);

				return context.video?.canPlayType(type) !== '';
			},
		};
	}

	private fairplay: FairPlay;
	private addAudioDebounced: Debounced;
	private changeAudioDebounced: Debounced;
	private isNonZeroStartTime: boolean = false;

	constructor(context: PlaybackAdapterContextInterface, options: any = {}) {
		super(context, options, true);

		const { fairplay } = this.context.resource.location.drm;
		if (fairplay.appCertUrl) {
			this.fairplay = new FairPlay(this.video, fairplay, this.logger, this.delegate, this.onFairPlayError);
			this.fairplay.initialize();
			this.delegate.drmKeySystemCreated(this.fairplay.keySystem);
		}

		const audioTracks = this.domAudioTracks;
		if (audioTracks) {
			this.addAudioDebounced = debounce(this.onAddAudioEvent, 100);
			this.changeAudioDebounced = debounce(this.onChangeAudioEvent, 100);
			audioTracks.addEventListener(TextTrackEvent.ADD_TRACK, this.addAudioDebounced);
			audioTracks.addEventListener(TextTrackEvent.CHANGE, this.changeAudioDebounced);
		}
	}

	get domAudioTracks() {
		// @ts-ignore
		return this.video.audioTracks;
	}

	override getId() {
		return Html5Adapter.ID;
	}

	override destroy(): Promise<void> {
		const audioTracks = this.domAudioTracks;

		if (audioTracks) {
			this.addAudioDebounced.cancel();
			this.changeAudioDebounced.cancel();
			audioTracks.removeEventListener(TextTrackEvent.ADD_TRACK, this.addAudioDebounced);
			audioTracks.removeEventListener(TextTrackEvent.CHANGE, this.changeAudioDebounced);
		}

		if (this.fairplay) {
			this.fairplay.destroy();
			this.fairplay = null;
		}

		this.destroyMediaElement();

		return super.destroy();
	}

	protected destroyMediaElement() {
		const video = this.video;
		// Setting src to an empty string can cause a MEDIA_ERR_SRC_NOT_SUPPORTED error
		// Use removeAttribute instead, https://github.com/w3c/media-source/issues/53
		video.removeAttribute('src');
		// For all browsers to cleanly unload the src we need to call load() here
		// https://www.w3.org/TR/html5/embedded-content-0.html#best-practices-for-authors-using-media-elements
		video.load();
	}

	protected applySrc(src: string, mimeType?: string) {
		this.video.src = src;
	}

	override load(): Promise<StreamMetadataInterface> {
		this.suppressErrors = true;
		super.load();

		const { resource } = this.context;
		let src = resource.location.mediaUrl;
		const mimeType = resource.overrides?.mimeType || getMimeType(src);
		const getFormat = (mimeType: string) => {
			switch (mimeType) {
				case MimeType.HLS:
				case MimeType.HLS_ALT:
					return CmcdStreamingFormat.HLS;

				case MimeType.DASH:
					return CmcdStreamingFormat.DASH;

				default:
					return CmcdStreamingFormat.OTHER;
			}
		};

		const { cmcd } = resource;
		if (cmcd.enabled) {
			src = appendCmcdQuery(src, {
				ot: CmcdObjectType.MANIFEST,
				sf: getFormat(mimeType),
				su: true,
				sid: cmcd.sessionId,
				cid: cmcd.contentId,
				pr: this.video.playbackRate,
				v: CMCD_VERSION,
			});
		}

		return this.delegate.applyHook(PlayerHookType.REQUEST, { url: src }, { objectType: RequestObjectType.MANIFEST })
			.then(value => {
				this.applySrc(value.url, mimeType);
				return waitForEvent(this.video, VideoSurfaceEvents.LOADED_METADATA)
					.then(() => {
						const startTime = resource.playback.startTime;
						if (isValidPlayheadTime(startTime) && startTime > 0) {
							this.isNonZeroStartTime = true;
							const h = () => {
								this.video.removeEventListener(VideoSurfaceEvents.PLAYING, h);
								this.seek(startTime);
							};
							this.video.addEventListener(VideoSurfaceEvents.PLAYING, h);
						}
					})
					.then(() => {
						this.suppressErrors = false;

						return {
							manifest: {
								mimeType,
							},
							fragment: {
								mimeType: undefined,
							},
						};
					})
					.catch(() => {
						throw this.createVideoElementError();
					});
			});
	}

	override setAudioTrack(track: AudioTrackInterface) {
		if (!track) {
			return;
		}

		forEach(this.domAudioTracks, (audioTrack: any, index) => {
			audioTrack.enabled = track.index === index;
		});
		this.textTrackSurface.forcedLanguage = track.language;
	}

	override setQuality(value: QualityInterface) {
		this.logger.info(AppResources.messages.MANUAL_ABR_SWITCHING_UNAVAILABLE);
	}

	override setAutoQualitySwitching(value: boolean) {
		this.logger.info(AppResources.messages.MANUAL_ABR_SWITCHING_UNAVAILABLE);
	}

	override setMinBitrate(value: number) {
		this.logger.info(AppResources.messages.MANUAL_ABR_SWITCHING_UNAVAILABLE);
	}

	override setMaxBitrate(value: number) {
		this.logger.info(AppResources.messages.MANUAL_ABR_SWITCHING_UNAVAILABLE);
	}

	override setTextTrackMode(mode: TextTrackMode): void {
		if (!this.textTrackSurface) {
			return;
		}

		if (this.isNonZeroStartTime && !this.startedPlaying) {
			this.isNonZeroStartTime = false;
			waitForEvent(this.video, VideoSurfaceEvents.SEEKED)
				.then(() => this.textTrackSurface.textTrackMode = mode);
		}
		else {
			this.textTrackSurface.textTrackMode = mode;
		}
	}

	protected calculateUtcTime(time: number) {
		const video = this.video as any;
		if (!video.getStartDate) {
			return NaN;
		}

		const startDate = video.getStartDate().getTime();
		const offset = time * 1000;
		return Math.round(startDate + offset);
	}

	protected override getLiveStreamUtcStart() {
		return this.calculateUtcTime(this.getSeekable().start);
	}

	protected override getLiveStreamUtcTime() {
		return this.calculateUtcTime(this.video.currentTime);
	}

	private onAddAudioEvent = (event: any) => {
		this.audioTracks = [];
		this.audioTrack = null;

		forEach(this.domAudioTracks, (audioTrack: any, index) => {
			if (!audioTrack.label && !audioTrack.language) {
				return;
			}

			const { kind } = audioTrack;
			const track = {
				index,
				id: index.toString(),
				type: (kind === 'alternative' || kind === 'description') ? AudioTrackType.DESCRIPTION : AudioTrackType.MAIN,
				codec: '',
				language: audioTrack.language,
				label: audioTrack.label,
			};

			this.audioTracks.push(track);
		});

		this.delegate.audioTracksChange(this.audioTracks);

		this.setAudioTrack(findDefaultTrack(this.audioTracks, this.context.playerOptions.audioLanguage));

		if (!this.audioTrack) {
			this.changeAudioDebounced();
		}
	};

	private onChangeAudioEvent = (event: any) => {
		const audioTrack: any = Array.from(this.domAudioTracks).find((track: any) => track.enabled);
		this.audioTrack = this.audioTracks.find(track => track.id === audioTrack.id);
		this.delegate.audioTrackChange(this.audioTrack);
	};

	private onFairPlayError = (error: ErrorInfoInterface) => {
		this.delegate.error(error);
	};
}
