import { MediatorName } from '../enum/MediatorName';
import { ProxyName } from '../enum/ProxyName';
import { PlayerDomProxyInterface } from '../iface';
import { ActionKeyMediator } from '../view/ActionKeyMediator';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';

export class ReadyCommand extends LogAwareSimpleCommand {

	execute() {
		// accessibility
		const dom = this.facade.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxyInterface;
		if (dom) {
			const main = dom.getMain();
			main.setAttribute('role', 'region');

			const tabindex = main.getAttribute('tabindex') || '0';
			main.setAttribute('tabindex', tabindex);

			const kcm = this.facade.retrieveMediator(MediatorName.KEY_COMMAND) as ActionKeyMediator;
			kcm.initialize();
		}
	}
}
