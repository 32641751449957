import { AppResources } from '../app/AppResources';
import { AdapterRole } from '../enum/AdapterRole';
import { Autoplay } from '../enum/Autoplay';
import { ErrorCode } from '../enum/ErrorCode';
import { Measurement } from '../enum/Measurement';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { PlayerEvent } from '../events/PlayerEvent';
import { NotificationInterface, PresentationMediatorInterface, VideoProxyInterface } from '../iface';
import { AutoplayInfoInterface } from '../iface/AutoplayInfoInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { AdapterProxy } from '../model/AdapterProxy';
import { ContentPlaybackStateProxy } from '../model/ContentPlaybackStateProxy';
import { ResourceProxy } from '../model/ResourceProxy';
import { AutoplayCapabilitiesService } from '../service/AutoplayCapabilitiesService';
import { System } from '../util/System';
import { isEmpty } from '../util/Type';
import { AdPresentationMediator } from '../view/AdPresentationMediator';
import { ContentPresentationMediator } from '../view/ContentPresentationMediator';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';


export class PrepPlaybackCommand extends LogAwareSimpleCommand {

	execute(notification: NotificationInterface) {
		const sys = this.system;
		const resource = notification.body as ResourceConfigurationInterface;
		if (System.isBrowser && !sys.global?.document) {
			this.sendNotification(NotificationName.VIDEO_START_ERROR, { error: AppResources.messages.ENVIRONMENT_NOT_SUPPORTED, fatal: true });

			return;
		}

		const presoState = this.getModel(ModelName.PresentationState) as PresentationStateInterface;
		const playerOpts = this.getModel(ModelName.PlayerOptions) as PlayerOptionsInterface;

		const playbackStateProxy = new ContentPlaybackStateProxy(ProxyName.ContentPlaybackStateProxy);
		this.facade.registerProxy(playbackStateProxy);

		// AVIAJS-750
		if (resource.playback?.abr?.capQualityToScreenSize === false) {
			playbackStateProxy.qualityCappedToScreenSize = false;
		}

		this.checkAutoplay(playerOpts).then((result: AutoplayInfoInterface) => {
			this.processAutoplaySupport(result, presoState, playerOpts);
			this.createPresentation(resource, playerOpts);
		});
	}

	private async createPresentation(resource: ResourceConfigurationInterface, playerOpts: PlayerOptionsInterface) {
		const presoMed = await this.getPresentationMediator(resource, playerOpts);

		if (!presoMed) {
			return;
		}

		this.facade.registerMediator(presoMed);

		this.sendNotification(NotificationName.START_PRESENTATION);
	}

	private async getPresentationMediator(
		resource: ResourceConfigurationInterface,
		playerOpts: PlayerOptionsInterface,
	): Promise<PresentationMediatorInterface> {
		const video = await this.getVideo(playerOpts, resource);

		let ret: PresentationMediatorInterface = null;

		if (!video) {
			this.sendNotification(NotificationName.RESOURCE_ERROR, {
				code: ErrorCode.UNEXPECTED_CONDITION,
				message: AppResources.messages.VIDEO_PLAYBACK_UNAVAILABLE,
				fatal: true,
			});

			return ret;
		}

		const name = MediatorName.PRESENTATION_MEDIATOR;
		const adEnabled = resource?.ad?.csai || resource?.ad?.ssai;

		if (adEnabled) {
			try {
				this.logger.time(Measurement.AD_ADAPTER_CREATION);

				const mediator = new AdPresentationMediator(name, video);
				mediator.createDelegate(this.facade.appId);
				const adapterProxy = this.facade.retrieveProxy(ProxyName.AdapterProxy) as AdapterProxy;
				const adapter = await adapterProxy.createAdapter(
					AdapterRole.AD,
					resource,
					() => mediator.getDelegate(),
				);
				mediator.adAdapter = adapter;

				this.logger.timeEnd(Measurement.AD_ADAPTER_CREATION);

				this.logger.time(Measurement.AD_RESOURCE_CREATION);
				const resourceOverride = await adapter.getResource?.();
				this.logger.timeEnd(Measurement.AD_RESOURCE_CREATION);

				if (resourceOverride) {
					resource = resourceOverride;
				}

				ret = mediator;
			}
			catch (error) {
				this.sendNotification(NotificationName.AD_ERROR, {
					code: error.code || ErrorCode.AD_ADAPTER_ERROR,
					message: error.message,
					cause: error,
					fatal: true,
				});
			}
		}
		else {
			ret = new ContentPresentationMediator(name, video);
		}

		this.createResourceProxy(resource);

		return ret;
	}

	private createResourceProxy(resource: ResourceConfigurationInterface): void {
		const resourceProxy = new ResourceProxy(ProxyName.ResourceProxy, resource);
		this.facade.registerProxy(resourceProxy);
	}

	private async getVideo(playerOpts: PlayerOptionsInterface, resource: ResourceConfigurationInterface): Promise<any> {
		const sys = this.system;

		if (sys.isWebMaf) {
			return sys.webMafPlayer;
		}
		else if (!sys.isBrowser) {
			return {};
		}

		const videoProxy = this.facade.retrieveProxy(ProxyName.VideoProxy) as VideoProxyInterface;

		if (!videoProxy.getVideo() || playerOpts.reuseVideoElement !== true) {
			await videoProxy.initVideo(resource);
		}

		return videoProxy.getVideo();
	}

	////////////
	// autoplay
	private processAutoplaySupport(
		result: AutoplayInfoInterface,
		presoMdl: PresentationStateInterface,
		playerOpts: PlayerOptionsInterface,
	): void {
		if (presoMdl.isAutoplay) {
			return;
		}

		const apOpt = playerOpts.autoplay;
		const mutedOk = result.supportsMutedAutoplay;
		const unmutedOk = result.supportsUnmutedAutoplay;

		const wantsMuted = apOpt === Autoplay.ATTEMPT_MUTED || apOpt === Autoplay.ATTEMPT_UNMUTED_THEN_MUTED;
		const wantsUnmuted = apOpt === Autoplay.ATTEMPT_UNMUTED;
		const conflict = (wantsMuted && !mutedOk) || (wantsUnmuted && !unmutedOk);
		if (conflict) {
			this.sendNotification(PlayerEvent.AUTOPLAY_BLOCKED, new Error('Autoplay capability conflict.'), NotificationType.INTERNAL);
		}

		presoMdl.isMuteAtPlayStart = (
			(apOpt === Autoplay.ATTEMPT_MUTED && mutedOk) ||
			(apOpt === Autoplay.ATTEMPT_UNMUTED_THEN_MUTED && !unmutedOk && mutedOk)
		);

		presoMdl.isAutoplay = this.shouldAutoplay(apOpt, result);
	}

	private shouldAutoplay(opt: Autoplay, info: AutoplayInfoInterface): boolean {
		if (isEmpty(opt) || opt === Autoplay.NONE) {
			return false;
		}

		const mutedOk = info.supportsMutedAutoplay;
		const unmutedOk = info.supportsUnmutedAutoplay;

		const muteOnly = opt === Autoplay.ATTEMPT_MUTED && mutedOk;
		const soundOnly = opt === Autoplay.ATTEMPT_UNMUTED && unmutedOk;
		const fallback = !soundOnly && (opt === Autoplay.ATTEMPT_UNMUTED_THEN_MUTED && mutedOk);

		return muteOnly || soundOnly || fallback;
	}

	private checkAutoplay(playerOpts: PlayerOptionsInterface): Promise<AutoplayInfoInterface> {
		const apc = this.getService(ServiceName.AutoplayCapabilities) as AutoplayCapabilitiesService;

		return apc.detectCapabilities(playerOpts.overrides?.blankVideoUrl);
	}
}
