import { Autoplay } from '../../enum/Autoplay';
import { LogLevel } from '../../enum/LogLevel';
import { PerformanceMode } from '../../enum/PerformanceMode';
import { ConfigurationKeyboardInterface } from '../../iface/ConfigurationKeyboardInterface';
import { ConfigurationNetworkInterface } from '../../iface/ConfigurationNetworkInterface';
import { DataConfigInterface } from '../../iface/DataConfigInterface';
import { DomReference } from '../../iface/DomReference';
import { LocalizationData } from '../../iface/LocalizationData';
import { ModelInterface } from '../../iface/ModelInterface';
import { OverridesInterface } from '../../iface/OverridesInterface';
import { PlayerOptionsInterface } from '../../iface/PlayerOptionsInterface';
import { PluginConfigInterface } from '../../iface/PluginConfigInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { ConfigurablePropertyContainer } from './ConfigurablePropertyContainer';


export class PlayerOptions implements PlayerOptionsInterface, ModelInterface {

	private model: StrAnyDict;

	constructor(config: DataConfigInterface) {
		this.model = new ConfigurablePropertyContainer(config);
	}

	static get modelName(): string {
		return 'PlayerOptions';
	}

	get data(): PlayerOptionsInterface {
		return this.model.data as PlayerOptionsInterface;
	}

	reset(): void {
		this.model.reset();
	}

	set container(value: DomReference) {
		this.model.container = value;
	}
	get container(): DomReference {
		return this.model.container;
	}

	set plugins(value: PluginConfigInterface[]) {
		this.model.plugins = value;
	}
	get plugins(): PluginConfigInterface[] {
		return this.model.plugins;
	}

	set autoplay(value: Autoplay) {
		this.model.autoplay = value;
	}
	get autoplay(): Autoplay {
		return this.model.autoplay;
	}

	set id(value: string) {
		this.model.id = value;
	}
	get id(): string {
		return this.model.id;
	}

	set network(value: ConfigurationNetworkInterface) {
		this.model.network = value;
	}
	get network(): ConfigurationNetworkInterface {
		return this.model.network;
	}

	set performanceMode(value: PerformanceMode) {
		this.model.performanceMode = value;
	}
	get performanceMode(): PerformanceMode {
		return this.model.performanceMode;
	}

	set renderTextTrackNatively(value: boolean) {
		this.model.renderTextTrackNatively = value;
	}
	get renderTextTrackNatively(): boolean {
		return this.model.renderTextTrackNatively;
	}

	set useNativeFullscreen(value: boolean) {
		this.model.useNativeFullscreen = value;
	}
	get useNativeFullscreen(): boolean {
		return this.model.useNativeFullscreen;
	}

	set keyboard(value: ConfigurationKeyboardInterface) {
		this.model.keyboard = value;
	}
	get keyboard(): ConfigurationKeyboardInterface {
		return this.model.keyboard;
	}

	set playsInline(value: boolean) {
		this.model.playsInline = value;
	}
	get playsInline(): boolean {
		return this.model.playsInline;
	}

	set useNativeControls(value: boolean) {
		this.model.useNativeControls = value;
	}
	get useNativeControls(): boolean {
		return this.model.useNativeControls;
	}

	set overrides(value: OverridesInterface) {
		this.model.overrides = value;
	}
	get overrides(): OverridesInterface {
		return this.model.overrides;
	}

	set logLevel(value: LogLevel) {
		this.model.logLevel = value;
	}
	get logLevel(): LogLevel {
		return this.model.logLevel;
	}

	set language(value: string) {
		this.model.language = value;
	}
	get language(): string {
		return this.model.language;
	}

	set audioLanguage(value: string) {
		this.model.audioLanguage = value;
	}
	get audioLanguage(): string {
		return this.model.audioLanguage;
	}

	set textLanguage(value: string) {
		this.model.textLanguage = value;
	}
	get textLanguage(): string {
		return this.model.textLanguage;
	}

	set localizationLanguage(value: string) {
		this.model.localizationLanguage = value;
	}
	get localizationLanguage(): string {
		return this.model.localizationLanguage;
	}

	set localization(value: LocalizationData) {
		this.model.localization = value;
	}
	get localization(): LocalizationData {
		return this.model.localization;
	}

	set idleDelay(value: number) {
		this.model.idleDelay = value;
	}
	get idleDelay(): number {
		return this.model.idleDelay;
	}

	set enableOm(value: boolean) {
		this.model.enableOm = value;
	}
	get enableOm(): boolean {
		return this.model.enableOm;
	}

	set enableMoat(value: boolean) {
		this.model.enableMoat = value;
	}
	get enableMoat(): boolean {
		return this.model.enableMoat;
	}

	set reuseVideoElement(value: boolean) {
		this.model.reuseVideoElement = value;
	}
	get reuseVideoElement(): boolean {
		return this.model.reuseVideoElement;
	}

	set ignoreBlankTextTracks(value: boolean) {
		this.model.ignoreBlankTextTracks = value;
	}
	get ignoreBlankTextTracks(): boolean {
		return this.model.ignoreBlankTextTracks;
	}

	set sizeCheckInterval(value: number) {
		this.model.sizeCheckInterval = value;
	}
	get sizeCheckInterval(): number {
		return this.model.sizeCheckInterval;
	}
}
