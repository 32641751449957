import { WILDCARD } from '../../../const/WILDCARD';
import { TextTrackEvent } from '../../../enum/TextTrackEvent';
import { TextTrackKind } from '../../../enum/TextTrackKind';
import { TextTrackMode } from '../../../enum/TextTrackMode';
import { DestroyInterface } from '../../../iface/DestroyInterface';
import { LoggerInterface } from '../../../iface/LoggerInterface';
import { MetadataCuepointInterface } from '../../../iface/MetadataCuepointInterface';
import { VideoSurfaceConfigInterface } from '../../../iface/VideoSurfaceConfigInterface';
import { bufferToString, find, forEach, forEachReverse } from '../../../util/ArrayUtil';
import { isEmpty } from '../../../util/Type';
import { getId3Frames } from '../../../util/id3';
import { VideoSurfaceEvents } from '../enum/VideoSurfaceEvents';

export class MetadataSurface implements DestroyInterface {

	private logger: LoggerInterface;
	private video: HTMLVideoElement;
	private onMetadataCuepoint: (cue: MetadataCuepointInterface) => void;
	private daiId3 = /^google_/;
	private id3OwnerIds: string[];
	private lastTickTime: number = -1;

	constructor(config: VideoSurfaceConfigInterface, onMetadataCuepoint: (cue: MetadataCuepointInterface) => void) {
		this.onMetadataCuepoint = onMetadataCuepoint;
		this.video = config.video;

		this.id3OwnerIds = ['com.cbsi.live.sg'].concat(config.resource.playback.id3OwnerIds || []);

		this.addEvents();

		this.logger = config.logger;
		this.logger.info('MetadataSurface created');

		forEach(this.video.textTracks, track => this.onTextTrackAdded({ track }));
	}

	destroy() {
		this.onMetadataCuepoint = null;
		this.removeEvents();
		forEach(this.video.textTracks, (t) => this.cleanupTrack(t));
		this.video = null;
		this.id3OwnerIds = null;
		this.logger = null;
	}

	/*
	 Active cues discovered manually rather than
	 using cue change event. AVIAJS-730
	*/
	onTick() {
		const trk = this.getId3Track();
		const cues = trk.cues;
		const now = this.video.currentTime;
		const lastTickTime = this.lastTickTime;

		if (now === lastTickTime) {
			return;
		}

		for (let i = 0, n = cues.length; i < n; i++) {
			try {
				const cue = cues[i];

				if (cue.endTime < lastTickTime) {
					continue;
				}

				const startTime = cue.startTime;

				if (startTime > now) {
					break;
				}

				if (startTime > lastTickTime && startTime <= now) {
					this.processCue(cue);
				}
			}
			catch (error) {
				this.logger.warn('Failed to process ID3 metadata', error);
			}
		}

		this.lastTickTime = now;
	}

	private addEvents(): void {
		this.video.textTracks.addEventListener(TextTrackEvent.ADD_TRACK, this.onTextTrackAdded);
		this.video.addEventListener(VideoSurfaceEvents.SEEKING, this.onSeeking);
	}

	private removeEvents(): void {
		this.video.textTracks.removeEventListener(TextTrackEvent.ADD_TRACK, this.onTextTrackAdded);
		this.video.removeEventListener(VideoSurfaceEvents.SEEKING, this.onSeeking);
	}

	private cleanupTrack(track: TextTrack) {
		if (track.kind !== TextTrackKind.METADATA) {
			return;
		}

		function cleanupCue(cue: TextTrackCue) {
			try {
				if (cue) {
					track.removeCue(cue);
				}
			}
			catch (error) {
				// ignore errors and continue cleanup
			}
		}

		// Cues must be cleaned up in reverse order. Otherwise half of the cues will be left behind.
		forEachReverse(track.cues, cleanupCue);
		forEachReverse(track.activeCues, cleanupCue);
	}

	private onTextTrackAdded = ({ track }: { track: TextTrack; }): void => {
		if (!this.isValidTrack(track)) {
			return;
		}

		track.mode = TextTrackMode.HIDDEN;
	};

	private onSeeking = () => {
		this.lastTickTime = this.video.currentTime;
	};

	private processCue(cue: any): void {
		if (!cue.value && cue.data) {
			const { data } = cue;

			if (data.constructor === ArrayBuffer) {
				const frames = getId3Frames(new Uint8Array(data));
				frames.forEach(frame => {
					cue.value = frame;
					this.processCue(cue);
				});
				return;
			}
		}

		const { value, startTime, endTime } = cue;
		const { key, data, info = '' } = value;

		this.processId3(key, {
			id: undefined,
			text: typeof data === 'string' ? data : '',
			info,
			data,
			startTime,
			endTime,
			source: cue,
		});
	}

	addId3Metadata(key: string, metadata: MetadataCuepointInterface) {
		try {
			const track = this.getId3Track();
			const { startTime, id, info, data, text } = metadata;
			const cue = new VTTCue(startTime, startTime, text);

			cue.id = id || key;

			// @ts-ignore
			cue.value = { key, data, info };

			track.addCue(cue);
		}
		catch (error) {
			this.logger.warn('Failed to add ID3 metadata', error);
		}
	}

	processId3(key: string, metadata: MetadataCuepointInterface): void {
		const { info, data } = metadata;
		if (key === 'TXXX' && this.daiId3.test(metadata.text)) {
			if (isEmpty(info)) {
				metadata.info = metadata.text;
			}
			metadata.id = 'google_dai';
		}
		else {
			metadata.id = this.id3OwnerIds.find(id => id === WILDCARD || info.includes(id));
		}

		if (!metadata.id) {
			return;
		}

		let text;

		try {
			text = (data instanceof ArrayBuffer) ? bufferToString(data) : data;
		}
		catch (error) {
			text = '';
		}

		// TODO: Deprecate this conversion. Promote the use of the `text` property
		if (key === 'PRIV' && this.id3OwnerIds[0] === info) {
			metadata.data = text;
		}

		metadata.text = text;

		this.onMetadataCuepoint(metadata);
	}

	private getId3Track() {
		return find(this.video.textTracks, t => this.isValidTrack(t)) || this.video.addTextTrack('metadata', 'id3');
	}

	private isValidTrack(track: TextTrack): boolean {
		if (!track) {
			return false;
		}

		if (track.kind !== TextTrackKind.METADATA) {
			return false;
		}

		// @ts-ignore
		if (track.expired) {
			return false;
		}

		return true;
	}
}
