import { PlayerError } from '../error/PlayerError';
import { EventPromiseMap } from '../iface/EventPromiseMap';
import { EventTargetInterface } from '../iface/EventTargetInterface';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';

/**
 * Wait for a specified number of milliseconds
 */
export function waitFor(milliseconds: number): Promise<void> {
	return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function waitUntil(func: () => boolean, interval = 100): Promise<void> {
	return new Promise((resolve, reject) => {
		const i = setInterval(() => {
			try {
				if (func()) {
					clearInterval(i);
					resolve();
				}
			}
			catch (error) {
				clearInterval(i);
				reject(error);
			}
		}, interval);
	});
}

/**
 * Wait for a player, or video tag, to fire an event.
 *
 * @param target -  Player or Video Element
 * @param success - The event to wait for which will result in the promise resolving.
 * @param fail -    An event that represents an error state. Will cause the promise to be rejected.
 * @param timeout - A timeout in milliseconds. Will result in the promise being rejected.
 *
 * @returns The event object
 */
export function waitForEvent<T = any>(target: EventTargetInterface, success: string, fail: string | number = 'error', timeout = NaN): Promise<T> {
	if (typeof fail === 'number') {
		timeout = fail;
	}

	return new Promise((resolve, reject) => {
		let timeoutId: any;
		const undo: (() => void)[] = [];
		const cleanUp = () => undo.forEach(u => u());
		const apply = (action: (value: any) => void, event: string) => {
			const on = target.on ? 'on' : 'addEventListener';
			const off = target.off ? 'off' : 'removeEventListener';
			const complete = (e: T, d?: T) => {
				clearTimeout(timeoutId);
				cleanUp();
				action(d || e);
			};

			target[on](event, complete);
			undo.push(() => target[off](event, complete));
		};

		apply(resolve, success);

		if (typeof fail === 'string') {
			apply(event => reject(PlayerError.eventToError(event)), fail);
		}

		if (timeout > -1) {
			timeoutId = setTimeout(() => {
				cleanUp();
				reject(new Error('timeout'));
			}, timeout);
		}
	});
}

/**
 * Wait for a player, or video tag, to reach a certain playhead time.
 *
 * @param target -  Player or Video Element
 * @param time -    The time in seconds
 * @param prop -    The name of the playhead prop
 */
export function waitForTime(target: HTMLVideoElement | VideoPlayerInterface, time: number, prop?: string) {
	if (!prop) {
		prop = target instanceof HTMLVideoElement ? 'currentTime' : 'contentTime';
	}

	// @ts-ignore
	return waitUntil(() => target[prop] >= time);
}

export interface AsyncQueue<T = any> {
	/**
	 * Run the tasks in the queue.
	 *
	 * @param context - The context data
	 */
	run(context?: T): Promise<T>;

	/**
	 * Flag indicating whether the queue is in the process of executing tasks
	 */
	readonly running: boolean;

	/**
	 * Cancel the execution of tasks in the queue.
	 */
	cancel(): Promise<T>;
}

/**
 * Creates an asynchronous queue. Each item in the queue is executed sequentially.
 *
 * @param tasks - A list of task functions, sync or async, to execute.
 * @returns
 */
export function queue<T = any>(tasks: ((context: T) => T | Promise<T>)[]): AsyncQueue<T> {
	let canceled = false;
	let task: any;
	let running = false;

	return {
		async run(context?: any) {
			running = true;
			let result: any = context;
			for (let i = 0; i < tasks.length; i++) {
				task = tasks[i](result);
				result = await task;
				if (canceled) {
					break;
				}
			}
			task = null;
			running = false;
			return result;
		},

		get running() {
			return running;
		},

		async cancel() {
			canceled = true;
			return await task;
		},
	};
}

/**
 * Convert events to promises
 *
 * @param success - The event(s) that result in promise resolution
 * @param failure - The event(s) that result in promise rejection
 * @param timeout - A timeout in milliseconds
 * @returns The event
 */
export function eventsToPromise<T = any>(success: EventPromiseMap[], failure: EventPromiseMap[], timeout: number = NaN): Promise<T> {
	return new Promise((resolve, reject) => {
		let timeoutId: any;
		const undo: (() => void)[] = [];
		const cleanUp = () => undo.forEach(u => u());
		const apply = (action: (value: any) => void) => ({ target, events }: EventPromiseMap) => {
			const t = target as any;
			const on = t.on ? 'on' : 'addEventListener';
			const off = t.off ? 'off' : 'removeEventListener';
			const complete = (e: T, d?: T) => {
				clearTimeout(timeoutId);
				cleanUp();
				action(d || e);
			};
			events.forEach(event => {
				t[on](event, complete);
				undo.push(() => t[off](event, complete));
			});
		};

		success.forEach(apply(resolve));
		failure.forEach(apply(reject));

		if (timeout > -1) {
			timeoutId = setTimeout(() => {
				cleanUp();
				reject(new Error('timeout'));
			}, timeout);
		}
	});
}
