export enum ErrorMessage {
	ABR_UNAVAILABLE = 'Single bitrate stream detected, adaptive bitrate system is unavailable',
	ABR_MANUAL_SWITCHING_REACHED_LOWER_BOUNDS = 'You have reached the lowest available quality for this presentation.',
	ABR_MANUAL_SWITCHING_REACHED_UPPER_BOUNDS = 'You have reached the highest available quality for this presentation at this screen size',
	ABR_MIN_BITRATE_RESTRICTION_UNAVAILABLE = 'Min bitrate restrictions are not offered with this streaming library',
	ACTION_HANDLER_ERROR = 'An error occurred within a registered action handler',
	AD_STALLED = 'Stalled ad detected',
	ADAPTER_UNAVAILABLE = 'Unable to play the requested resource - mediaUrl not recognized',
	ADAPTER_LIB_UNAVAILABLE = 'Unable to play the requested resource - External library is not loading',
	ADAPTER_NOT_FOUND = 'Unable to find an adapter for the requested resource',
	API_INVALID_ARG = 'API method called with missing or invalid param',
	CAP_LEVEL_MAXBITRATE = 'Max bitrate setting will be ignored since capQualityToPlayerSize is enabled',
	CONTENT_PLAYBACK_SETTING_IGNORED = 'This API is only available after the CONTENT_START event has been dispatched. This setting will be ignored.',
	DAI_MISSING_ASSET_URL = 'Unexpected condition = valid assetUrl not found in DAI stream data',
	DAI_SDK_UNAVAILABLE = 'A DAI SDK is required for playback, but is not found',
	ENVIRONMENT_NOT_SUPPORTED = 'Unable to locate a global "window" object or suitable alternative',
	FAIRPLAY_LICENSE_ERROR = 'Issue with fairplay DRM and the provided license acquisition url',
	FAIRPLAY_APP_CERT_ERROR = 'Issue creating a fairplay keysession with the provided application certificate',
	FAIRPLAY_NO_CONTENT_ID = 'A valid content or asset id was not detected when creating fairplay drm session',
	FAIRPLAY_WEBKIT_ERROR = 'Issue with webkit and keysession creations while using fairplay drm.',
	FATAL_PLAYBACK_MEDIA_ERROR = 'Fatal media error, no option for recovery',
	NONFATAL_PLAYBACK_MEDIA_ERROR = 'Nonfatal media error',
	FATAL_PLAYBACK_NETWORK_ERROR = 'Fatal network error, no option for recovery',
	NONFATAL_PLAYBACK_NETWORK_ERROR = 'Nonfatal network error',
	IMA_SDK_MISSING = 'IMA SDK not found.',
	INVALID_RESOURCE = 'Resource configuration appears to be invalid',
	MANUAL_ABR_SWITCHING_UNAVAILABLE = 'Native HTML5 Video does not allow manual bitrate switching',
	PAUSING_LINEAR_LIVE_STREAM_NOT_ALLOWED = 'StreamType.LIVE is detected. Redirecting to the stop method since a LTS window is not detected.',
	PLUGIN_LOAD_ERROR = 'Unable to load plugin',
	PLUGIN_FACTORY_ERROR = 'Invalid plugin factory',
	PLUGIN_ID_ERROR = 'Invalid plugin ID',
	RESOURCE_MISSING = 'Resource missing',
	RESOURCE_NOT_SUPPORTED = 'Resource is not recognized or is currently unsupported',
	RETRY_PLAYBACK_MEDIA_ERROR = 'Media error detected',
	RETRY_PLAYBACK_NETWORK_ERROR = 'Network error detected',
	UNEXPECTED_CONDITION = 'An unexpected condition was encountered',
	UNSPECIFIED_ERROR = 'Unspecified error',
	VIDEO_PLAYBACK_UNAVAILABLE = 'Unable to locate a video element or suitable alternative',
	WEBKIT_KEY_ADDED_SUCCESS = 'Webkit key added successfully',
	WEBKIT_MEDIA_KEYS_NOT_SUPPORTED = 'WebKitMediaKeys are not supported for this type of media on this user agent',
}
