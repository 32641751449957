import { Deprecated } from '../deprecated/Deprecated';
/**
 * @enum
 */
export const StreamType = {
	VOD: 'VOD',
	LIVE: 'LIVE',
	LTS: 'DVR',
	DVR: 'DVR',
} as const;

[
	'VOD',
	'LIVE',
	'LTS',
	'DVR',
].forEach(p => {
	Object.defineProperty(StreamType, p, {
		get: () => {
			if (p === 'DVR') {
				Deprecated.warn(`StreamType.DVR is deprecated in favor of StreamType.LTS; "DVR" will be removed April 1, 2025.`);
			}
			if (p === 'LTS') {
				return 'DVR';
			}
			return p;
		},
	});
});

export type StreamType = typeof StreamType[keyof typeof StreamType];
