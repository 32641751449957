import { ActiveState } from '../enum/ActiveState';
import { PlayerEvent } from '../events/PlayerEvent';
import { NotificationInterface } from '../iface';
import { LogAwareMediator } from './LogAwareMediator';

type ActivityData = { container: Node, document: Document, idleDelay: number; };

export class ActivityMediator extends LogAwareMediator {
	private data: any = {};
	private document: Document;
	private container: Node;
	private timeout: any;
	private mouseDown: boolean = false;
	private mouseOver: boolean = false;
	private idleEvents = [
		'mousemove',
		'mousedown',
		'keypress',
		'DOMMouseScroll',
		'mousewheel',
		'touchmove',
		'touchstart',
		'MSPointerMove',
		'MSPointerDown',
		'pointermove',
		'pointerdown',
	];

	constructor(name: string, data?: ActivityData) {
		super(name);
		this.data = { activeState: ActiveState.INACTIVE, idleDelay: data.idleDelay };
		this.container = data.container;
		this.document = data.document;
	}

	override handleNotification(notification: NotificationInterface): void {
		// no-op
	}

	override onRegister() {
		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseEnter = this.onMouseEnter.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);
		this.onFocusOut = this.onFocusOut.bind(this);
		this.reset = this.reset.bind(this);
		this.idle = this.idle.bind(this);

		this.container.addEventListener('mouseenter', this.onMouseEnter);
		this.container.addEventListener('mouseleave', this.onMouseLeave);
		this.container.addEventListener('mousedown', this.onMouseDown);
		this.document.addEventListener('mouseup', this.onMouseUp);
		this.document.addEventListener('focusout', this.onFocusOut);
	}

	override onRemove() {
		this.container.removeEventListener('mouseenter', this.onMouseEnter);
		this.container.removeEventListener('mouseleave', this.onMouseLeave);
		this.container.removeEventListener('mousedown', this.onMouseDown);
		this.document.removeEventListener('mouseup', this.onMouseUp);
		this.document.removeEventListener('focusout', this.onFocusOut);

		this.stop();

		this.container = null;
		this.document = null;

		super.onRemove();
	}

	private applyListeners(add = true) {
		const action = (add) ? 'add' : 'remove';
		// @ts-ignore
		this.idleEvents.forEach(event => this.container[`${action}EventListener`](event, this.reset, { passive: true }));
	}

	private start() {
		this.stop();
		this.applyListeners();
		this.timeout = setTimeout(this.idle, this.idleDelay);
	}

	private stop() {
		clearTimeout(this.timeout);
		this.applyListeners(false);
	}

	private idle() {
		this.activeState = (this.mouseOver) ? ActiveState.IDLE : ActiveState.INACTIVE;
	}

	private reset() {
		this.activeState = ActiveState.ACTIVE;
		this.start();
	}

	private onMouseEnter() {
		this.reset();
		this.mouseOver = true;
		this.activeState = ActiveState.ACTIVE;
		this.start();
	}

	private onMouseLeave() {
		this.reset();
		this.mouseOver = false;
		this.activeState = ActiveState.INACTIVE;
	}

	private onMouseDown() {
		this.mouseDown = true;
	}

	private onMouseUp() {
		if (!this.mouseDown) {
			return;
		}

		this.mouseDown = false;
		this.start();
	}

	private onFocusOut(event: FocusEvent) {
		const { relatedTarget } = event;
		const active = this.container.contains(relatedTarget as HTMLElement);
		if (active) {
			this.stop();
		}
		this.activeState = (active) ? ActiveState.ACTIVE : ActiveState.INACTIVE;
	}

	set activeState(value: ActiveState) {
		if (this.mouseDown) {
			value = ActiveState.ACTIVE;
		}

		if (value === this.data.activeState) {
			return;
		}

		this.data.activeState = value;
		this.sendNotification(PlayerEvent.ACTIVE_STATE_CHANGE, { activeState: value });
	}

	get activeState(): ActiveState {
		return this.data.activeState;
	}

	get idleDelay(): number {
		return this.data.idleDelay;
	}
}
