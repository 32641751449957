import { PlayerDom } from '../enum/PlayerDom';
import { PlayerDomInterface } from '../iface/PlayerDomInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { uid8 } from './StringUtil';


export const containerCss: Record<string, any> = {
	position: 'absolute',
	width: '100%',
	height: '100%',
	padding: '0',
	margin: '0',
	overflow: 'hidden',
};

export function initVideoElement(mainContainer: Element, playerOptions: PlayerOptionsInterface, playerId: string) {
	const video = mainContainer.querySelector('video');

	if (video) {
		decorateVideoElement(video, playerOptions, playerId);
	}

	return video || createVideoElement(playerOptions, playerId);
}

export function createVideoElement(playerOptions: PlayerOptionsInterface, playerId: string) {
	const video = document.createElement('video');
	decorateVideoElement(video, playerOptions, playerId);
	return video;
}

function decorateVideoElement(video: HTMLVideoElement, playerOptions: PlayerOptionsInterface, playerId: string) {
	video.style.height = '100%';
	video.style.width = '100%';
	video.style.backgroundColor = '#000';

	video.dataset.elementId = uid8();
	video.dataset.playerId = playerId;

	video.controls = playerOptions.useNativeControls === true;
	video.playsInline = playerOptions.playsInline !== false;
}

export function createAdContainer() {
	const adContainer = createDiv(PlayerDom.AD_CONTAINER, containerCss);
	adContainer.dataset.elementId = uid8();

	return adContainer;
}

export function createDiv(role: string, css: Record<string, string>): HTMLElement {
	const el = document.createElement('div');

	el.setAttribute('data-role', role);
	Object.assign(el.style, css);

	return el;
}

export function createAdClickEl(): HTMLElement {
	const css = {
		display: 'none',
		padding: '4px 8px',
		fontSize: '12px',
		background: '#dedede',
		lineHeight: '14px',
		color: '#000',
		position: 'absolute',
		right: '2px',
		top: '2px',
		textAlign: 'center',
	};
	const el = createDiv('adClickEl', css);

	el.innerHTML = 'Learn More';

	return el;
}

export function createPlayerDom(mainContainer: HTMLElement, skipEls: PlayerDom[], playerOptions: PlayerOptionsInterface, playerId: string): PlayerDomInterface {
	const coll: Record<string, any> = {
		[PlayerDom.MAIN_CONTAINER]: mainContainer,
		[PlayerDom.VIDEO_EL]: null,
	};
	const main = mainContainer;

	const roles: PlayerDom[] = [
		PlayerDom.AD_CONTAINER,
		PlayerDom.CC_CONTAINER,
		PlayerDom.VIDEO_CONTAINER,
	];

	const style = main.style;
	style.position = style.position || 'relative';
	style.padding = style.padding || '0';
	style.margin = style.margin || '0';
	style.width = style.width || '100%';
	style.height = style.height || '100%';
	style.backgroundColor = style.backgroundColor || '#000';
	style.overflow = style.overflow || 'hidden';
	main.classList.add('avia-container');

	//css for timed-text offset
	const styleEl = document.createElement('style');
	main.parentElement.insertAdjacentElement('beforebegin', styleEl);
	styleEl.innerText = ':root{--avia-timed-text-offset: 60px;}.avia-container.avia-cc-offset video::-webkit-media-text-track-container{bottom: var(--avia-timed-text-offset);height: auto;}';

	// containers
	let refNode = main.hasChildNodes() ? main.firstChild : null;
	let el;

	roles.forEach((role) => {
		if (skipEls.includes(role)) {
			return;
		}

		if (role === PlayerDom.AD_CONTAINER) {
			el = createAdContainer();
		}
		else {
			el = createDiv(role, containerCss);
		}

		coll[role] = el;

		if (refNode) {
			main.insertBefore(el, refNode);
		}
		else {
			main.appendChild(el);
		}
		refNode = el;
	});

	if (playerOptions.reuseVideoElement === true) {
		const video = initVideoElement(main, playerOptions, playerId);

		coll[PlayerDom.VIDEO_CONTAINER].appendChild(video);
		coll[PlayerDom.VIDEO_EL] = video;
	}

	coll[PlayerDom.AD_CLICK_EL] = createAdClickEl();
	main.appendChild(coll[PlayerDom.AD_CLICK_EL]);

	return {
		[PlayerDom.MAIN_CONTAINER]: coll[PlayerDom.MAIN_CONTAINER],
		[PlayerDom.VIDEO_EL]: coll[PlayerDom.VIDEO_EL],
		[PlayerDom.VIDEO_CONTAINER]: coll[PlayerDom.VIDEO_CONTAINER],
		[PlayerDom.CC_CONTAINER]: coll[PlayerDom.CC_CONTAINER],
		[PlayerDom.AD_CONTAINER]: coll[PlayerDom.AD_CONTAINER],
		[PlayerDom.AD_CLICK_EL]: coll[PlayerDom.AD_CLICK_EL],
		[PlayerDom.STYLE]: styleEl,
	};
}
