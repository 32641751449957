import { ActionKey } from '../enum/ActionKey';
import { ActionKeyContext } from '../enum/ActionKeyContext';
import { KeyCode } from '../enum/KeyCode';
import { ActionKeyError } from '../error/ActionKeyError';
import { ActionKeyMap } from '../iface/ActionKeyMap';

const defaultContext: ActionKeyMap = {
	[KeyCode.ENTER]: ActionKey.SELECT,
	[KeyCode.ESCAPE]: ActionKey.CANCEL,
	[KeyCode.UP_ARROW]: ActionKey.UP,
	[KeyCode.DOWN_ARROW]: ActionKey.DOWN,
	[KeyCode.LEFT_ARROW]: ActionKey.LEFT,
	[KeyCode.RIGHT_ARROW]: ActionKey.RIGHT,
	[KeyCode.MEDIA_PLAY]: ActionKey.PLAY,
	[KeyCode.MEDIA_PAUSE]: ActionKey.PAUSE,
	[KeyCode.PAUSE]: ActionKey.PAUSE,
	[KeyCode.MEDIA_PLAY_PAUSE]: ActionKey.PLAY_PAUSE,
	[KeyCode.MEDIA_STOP]: ActionKey.STOP,
	[KeyCode.MEDIA_INCREASE_VOLUME]: ActionKey.INCREASE_VOLUME,
	[KeyCode.MEDIA_INCREASE_VOLUME_ALT]: ActionKey.INCREASE_VOLUME,
	[KeyCode.MEDIA_DECREASE_VOLUME]: ActionKey.DECREASE_VOLUME,
	[KeyCode.MEDIA_DECREASE_VOLUME_ALT]: ActionKey.DECREASE_VOLUME,
	[KeyCode.MEDIA_TRACK_NEXT]: ActionKey.PLAYLIST_NEXT,
	[KeyCode.MEDIA_TRACK_PREVIOUS]: ActionKey.PLAYLIST_PREVIOUS,
	[KeyCode.MEDIA_CAPTIONS]: ActionKey.TEXT_TRACK,
	[KeyCode.MEDIA_INFO]: ActionKey.INFO,
	[KeyCode.MEDIA_FAST_FORWARD]: ActionKey.FAST_FORWARD,
	[KeyCode.MEDIA_REWIND]: ActionKey.REWIND,
	[KeyCode.MEDIA_RECORD]: ActionKey.RECORD,
	[KeyCode.BACKSPACE]: ActionKey.BACK,
	[KeyCode.MEDIA_LIVE]: ActionKey.LIVE,
};

const hotkeyContext: ActionKeyMap = {
	[KeyCode.F]: ActionKey.FULLSCREEN,
	[KeyCode.UP_ARROW]: ActionKey.INCREASE_VOLUME,
	[KeyCode.DOWN_ARROW]: ActionKey.DECREASE_VOLUME,
	[KeyCode.LEFT_ARROW]: ActionKey.SKIP_BACKWARD,
	[KeyCode.RIGHT_ARROW]: ActionKey.SKIP_FORWARD,
	[KeyCode.SPACE]: ActionKey.PLAY_PAUSE,
	[KeyCode.M]: ActionKey.MUTE_UNMUTE,
	[KeyCode.SUBTRACT]: ActionKey.MUTE_UNMUTE,
	[KeyCode.MINUS]: ActionKey.MUTE_UNMUTE,
	[KeyCode.C]: ActionKey.TEXT_TRACK,
};

const adContext: ActionKeyMap =  {
	[KeyCode.LEFT_ARROW]: ActionKey.NONE,
	[KeyCode.RIGHT_ARROW]: ActionKey.NONE,
	[KeyCode.MEDIA_FAST_FORWARD]: ActionKey.NONE,
	[KeyCode.MEDIA_REWIND]: ActionKey.NONE,
};

const actionKeyMap: Record<string, ActionKeyMap> = {
	[ActionKeyContext.DEFAULT]: defaultContext,
	[ActionKeyContext.HOTKEY]: hotkeyContext,
	[ActionKeyContext.AD]: adContext,
};

/**
 *
 */
export function registerActionKeyContext(context: ActionKeyContext | string, map: ActionKeyMap) {
	if (!context) {
		throw new ActionKeyError(`Invalid action key context ${context}`);
	}

	if (!actionKeyMap[context]) {
		actionKeyMap[context] = {};
	}

	Object.assign(actionKeyMap[context], map);
}
/**
 *
 */
export function retrieveActionKeyContext(context: ActionKeyContext | string): ActionKeyMap | undefined {
	return actionKeyMap[context];
}

/**
 *
 */
export function removeActionKeyContext(context: ActionKeyContext | string): ActionKeyMap | undefined {
	const map = actionKeyMap[context];
	delete actionKeyMap[context];
	return map;
}
