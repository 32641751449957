import { AviaHookType } from '../enum/AviaHookType';
import { LogLevel } from '../enum/LogLevel';
import { Measurement } from '../enum/Measurement';
import { ServiceName } from '../enum/ServiceName';
import { ServiceCollection } from '../iface';
import { AutoplayInfoInterface } from '../iface/AutoplayInfoInterface';
import { DeviceCapabilitiesInterface } from '../iface/DeviceCapabilitiesInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { SystemInterface } from '../iface/SystemInterface';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';
import { AutoplayCapabilitiesService } from '../service/AutoplayCapabilitiesService';
import { System } from '../util/System';
import { applyHook } from './Hooks';
import { logger } from './Logger';
import { PlayerOptions } from './PlayerOptions';
import { ResourceConfiguration } from './ResourceConfiguration';
import { VideoPlayer } from './VideoPlayer';

let polyfillsChecked: boolean = false;
let initialized: Promise<void>;
let serviceCollection: ServiceCollection = null;

const playerCollection: Record<string, VideoPlayerInterface> = {};
const pendingPlayerCreates: Record<string, Promise<VideoPlayerInterface>> = {};
const pendingPlayerDestroys: Record<string, Promise<void>> = {};

/**
 * Check that the platform supports all necessary ES features used by the player.
 */
export function checkPolyfills() {
	if (polyfillsChecked) {
		return;
	}

	polyfillsChecked = true;

	const tests: Record<string, () => any> = {
		'Array.from': () => Array.from,
		'Array.prototype.find': () => Array.prototype.find,
		'Array.prototype.findIndex': () => Array.prototype.findIndex,
		'Array.prototype.includes': () => Array.prototype.includes,
		'Map': () => typeof Map !== 'undefined',
		'Object.assign': () => Object.assign,
		'Object.entries': () => Object.entries,
		'Object.values': () => Object.values,
		'Promise': () => typeof Promise !== 'undefined',
		'Set': () => typeof Set !== 'undefined',
		'String.prototype.includes': () => String.prototype.includes,
		'String.prototype.repeat': () => String.prototype.repeat,
	};

	Object.keys(tests).forEach((key) => {
		let passed = false;

		try {
			passed = !!tests[key]();
		}
		catch (error) {
			passed = false;
		}
		finally {
			if (!passed) {
				logger.warn(`Polyfill warning: ${key} is not defined`);
			}
		}
	});
}

function initialize(options: PlayerOptionsInterface) {
	if (initialized) {
		return initialized;
	}

	checkPolyfills();

	const autoplayCapabilities = AutoplayCapabilitiesService.getInstance();
	const overrides = options.overrides;
	const deferAutoplayDetection = overrides?.deferAutoplayDetection !== false;

	return initialized = Promise
		.all([
			!deferAutoplayDetection && autoplayCapabilities.detectCapabilities(overrides?.blankVideoUrl),
		])
		.then(() => {
			serviceCollection = {
				[ServiceName.AutoplayCapabilities]: autoplayCapabilities,
			};
		});
}

/**
 * Get a list of all the currently instantiated player IDs.
 */
export function getVideoPlayerIds(): string[] {
	return Object.keys(pendingPlayerCreates)
		.concat(Object.keys(playerCollection))
		.reduce((acc, id) => {
			if (!acc.includes(id)) {
				acc.push(id);
			}
			return acc;
		}, []);
}

/**
 * Get device capabilities
 */
export async function getDeviceCapabilities(): Promise<DeviceCapabilitiesInterface> {
	return await applyHook(AviaHookType.DEVICE_CAPABILITIES, {
		drm: {
			keySystemStatus: [],
			hdcpStatus: [],
		},
		...System.supportedMedia,
	});
}

/**
 * Create an instance of the player.
 *
 * @param playerOptions - The player configuration options.
 */
export function createVideoPlayer(playerOptions: PlayerOptionsInterface): Promise<VideoPlayerInterface> {
	const playerCreateStart = performance.now();
	const options = new PlayerOptions(playerOptions);
	const { id } = options;
	const playerLogger = logger.create({ logLevel: options.logLevel || logger.logLevel, id });

	return pendingPlayerCreates[id] = initialize(options)
		.then(async () => {
			try {
				const opts = await applyHook(AviaHookType.CREATE_PLAYER, options);

				const player = new VideoPlayer({
					globalServices: serviceCollection,
					playerOptions: opts,
					logger: playerLogger,
				});

				playerLogger.time(Measurement.PLAYER_CREATION, playerCreateStart);

				return player.initialize()
					.then((api: VideoPlayerInterface) => ({ api, player }));
			}
			catch (error) {
				if (error.key) {
					error.message = `A player with id "${error.key}" a already exists`;
				}
				throw error;
			}
		})
		.then(({ api, player }) => {
			playerCollection[id] = player;
			delete pendingPlayerCreates[id];
			playerLogger.timeEnd(Measurement.PLAYER_CREATION);
			return api;
		});
}

/**
 * Get an existing player with the given ID.
 *
 * @param playerId - The player ID.
 */
export function retrieveVideoPlayer(playerId: string): Promise<VideoPlayerInterface | null> {
	const player = playerCollection[playerId];

	if (player) {
		return Promise.resolve(player);
	}

	const destroy = pendingPlayerDestroys[playerId];
	if (destroy) {
		return destroy.then(() => null);
	}

	const pending = pendingPlayerCreates[playerId];
	if (pending) {
		return pending;
	}

	return Promise.resolve(null);
}

/**
 * Remove an existing player with the given ID.
 *
 * @param playerId - The player ID.
 */
export function removeVideoPlayer(playerId: string): Promise<void> {
	const pending = pendingPlayerDestroys[playerId];
	if (pending) {
		return pending;
	}

	return Promise.resolve()
		.then(() => {
			if (pendingPlayerCreates[playerId]) {
				return pendingPlayerCreates[playerId];
			}

			return null;
		})
		.then(async () => {
			const player = playerCollection[playerId];
			if (!player) {
				return Promise.resolve();
			}

			const { logLevel } = player.options;
			delete playerCollection[playerId];

			await applyHook(AviaHookType.REMOVE_PLAYER, player);
			return pendingPlayerDestroys[playerId] = player
				.destroy()
				.then(() => {
					delete pendingPlayerDestroys[playerId];

					if (logLevel === LogLevel.INFO || logLevel === LogLevel.DEBUG) {
						logger.info(`[Avia] Player with id "${playerId}" removed.`);
					}
				});
		});
}

/**
 * Create a new resource configuration object with all defaults applied.
 *
 * @param config - A partial configuration object
 */
export function createResourceConfig(config?: ResourceConfigurationInterface): ResourceConfigurationInterface {
	return new ResourceConfiguration(config);
}

/**
 * A collection of system information like device, browser, and supported video features.
 */
export function getSystemInfo(): SystemInterface | null {
	return System;
}

/**
 * Evaluate the autoplay capabilities for the current platform.
 */
export function getAutoplayCapabilities(url?: string): Promise<AutoplayInfoInterface> {
	return AutoplayCapabilitiesService.getInstance().detectCapabilities(url);
}
