import { Debounced } from '../iface/Debounced';

export function debounce(func: (...args: any[]) => void, delay: number): Debounced {
	let timeout: any;
	const pending = () => timeout != null;
	const cancel = () => {
		clearTimeout(timeout);
		timeout = null;
	};
	const run = (...args: any[]) => {
		cancel();
		timeout = setTimeout(func, delay, ...args);
	};
	run.cancel = cancel;
	run.flush = func;
	run.pending = pending;
	return run;
}

export type Throttled = Debounced;

export function throttle(func: (...args: any[]) => void, delay: number): Throttled {
	let timeout: any;
	let pending = false;
	let trigger: any[] = null;

	const cancel = () => {
		clearTimeout(timeout);
		timeout = null;
		pending = false;
		trigger = null;
	};
	const run = (...args: any[]) => {
		if (pending) {
			trigger = args;
			return;
		}

		cancel();
		pending = true;
		func(...args);

		timeout = setTimeout(() => {
			pending = false;
			if (trigger != null) {
				const args = trigger;
				trigger = null;
				run(...args);
			}
		}, delay);
	};
	run.cancel = cancel;
	run.flush = func;
	run.pending = () => pending;

	return run;
}
