import { retrieveActionKeyContext } from '../app/ActionKeys';
import { getConfiguration } from '../app/Configuration';
import { buildInfo } from '../core/BuildInfo';
import { CoreEvent } from '../core/CoreEvent';
import { ActionKey } from '../enum/ActionKey';
import { ActionKeyContext } from '../enum/ActionKeyContext';
import { KeyboardEventType } from '../enum/KeyboardEventType';
import { KeyboardScope } from '../enum/KeyboardScope';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { NotificationType } from '../enum/NotificationType';
import { ProxyName } from '../enum/ProxyName';
import { ActionKeyError } from '../error/ActionKeyError';
import { PlayerEvent } from '../events/PlayerEvent';
import { NotificationInterface } from '../iface';
import { ActionKeyEventContextInterface } from '../iface/ActionKeyEventContextInterface';
import { ConfigurationKeyboardInterface } from '../iface/ConfigurationKeyboardInterface';
import { KeyMapInterface } from '../iface/KeyMapInterface';
import { KeyboardEventTarget } from '../iface/KeyboardEventTarget';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';
import { PlayerDomProxy } from '../model/PlayerDomProxy';
import { System } from '../util/System';
import { getDefaultActions } from '../util/getDefaultActions';
import { AppMediator } from './AppMediator';
import { LogAwareMediator } from './LogAwareMediator';


export class ActionKeyMediator extends LogAwareMediator {
	private scope: KeyboardEventTarget = null;
	private disabled: boolean = false;
	private player: VideoPlayerInterface;
	private defaultKeyEventType: KeyboardEventType;
	private contextStack: (ActionKeyContext | string)[] = [];
	private playerOptions_: PlayerOptionsInterface;

	constructor(name: string, scope: KeyboardEventTarget = KeyboardScope.NONE) {
		super(name);

		this.setScope(scope);

		this.enterActionKeyContext(ActionKeyContext.DEFAULT);
		this.enterActionKeyContext(ActionKeyContext.HOTKEY);
	}

	override onRegister(): void {
		this.defaultKeyEventType = this.getKeyboardOption('eventType', KeyboardEventType.DOWN);

		const app = this.facade.retrieveMediator(MediatorName.APPLICATION) as AppMediator;
		this.player = app.getAppApi();
	}

	override onRemove(): void {
		this.removeListeners();
		this.player = null;

		super.onRemove();
	}

	override handleNotification(notification: NotificationInterface): void {
		// no op
	}

	// aviajs-337; intended to be invoked from ReadyCommand only
	initialize() {
		this.addListeners();
	}

	disable() {
		this.disabled = true;
	}

	enable() {
		if (!this.hasScope) {
			this.logger.warn('Attempted to enable key command mediator that has scope of NONE.');
			return;
		}
		this.disabled = false;
	}

	getKeyEventTarget(): Node | null {
		if (typeof this.scope !== 'string') {
			return this.scope;
		}

		switch (this.scope) {
			case KeyboardScope.PLAYER:
				return this.playerContainer;

			case KeyboardScope.DOCUMENT:
				return System.global.document;

			case KeyboardScope.NONE:
				return null;

			default:
				return System.global.document.querySelector(this.scope);
		}
	}

	enterActionKeyContext(context: ActionKeyContext | string) {
		if (this.contextStack[0] === context) {
			return;
		}

		this.contextStack.unshift(context);
	}

	getActionKeyContext(): (ActionKeyContext | string)[] {
		return this.contextStack;
	}

	exitActionKeyContext(context: ActionKeyContext | string) {
		this.contextStack = this.contextStack.filter(item => item !== context);
	}

	//////////////////////////////////////////
	private setScope(scope: KeyboardEventTarget) {
		this.scope = scope;

		if (this.hasScope) {
			this.enable();
		}
		else {
			this.disable();
		}
	}

	private inScope(element: Node): boolean {
		return this.getKeyEventTarget()?.contains(element);
	}

	private getKeyCode(keyCode: number) {
		const map = this.getKeyboardOption('map', {} as KeyMapInterface);
		return map[keyCode] ?? keyCode;
	}

	private getActionKey(keyCode: number): ActionKey | string | null {
		for (const context of this.getActionKeyContext()) {
			const map = retrieveActionKeyContext(context);
			if (map?.[keyCode]) {
				return map[keyCode];
			}
		}

		return null;
	}

	private getKeyboardOption(name: keyof ConfigurationKeyboardInterface, dflt: any): any {
		return this.playerOptions.keyboard?.[name] ?? getConfiguration().keyboard?.[name] ?? dflt;
	}

	private get playerOptions(): PlayerOptionsInterface {
		if (!this.playerOptions_) {
			this.playerOptions_ = this.getModel(ModelName.PlayerOptions) as PlayerOptionsInterface;
		}

		return this.playerOptions_;
	}

	private onKeydown = async (event: KeyboardEvent) => {
		// ignore if disabled or scope is NONE
		if (this.disabled) {
			return;
		}

		const target = event.target as Node;
		if (!this.inScope(target)) {
			return;
		}

		const keyCode = this.getKeyCode(event.keyCode);
		const actionKey = this.getActionKey(keyCode);
		if (!actionKey || actionKey === ActionKey.NONE) {
			return;
		}

		event.preventDefault();

		const detail = this.createDetail(actionKey);
		const playerEvent = new CoreEvent(PlayerEvent.ACTION_KEY, this.player as any, detail);
		this.sendNotification(PlayerEvent.ACTION_KEY, playerEvent, NotificationType.INTERNAL);

		const domEvent = new CustomEvent(PlayerEvent.ACTION_KEY, {
			bubbles: true,
			cancelable: true,
			composed: true,
			detail,
		});

		if (playerEvent.defaultPrevented) {
			domEvent.preventDefault();
		}

		target.dispatchEvent(domEvent);

		this.sendNotification(PlayerEvent.HOTKEY_ACTION, { action: detail.action, ...detail.metadata }, NotificationType.INTERNAL);

		this.sendNotification(PlayerEvent.ACTION_KEY_METADATA, detail, NotificationType.INTERNAL);
	};

	private onActionKeyDefault = (event: CustomEvent<ActionKeyEventContextInterface>) => {
		if (event.defaultPrevented) {
			return;
		}

		const { action } = event.detail;
		const defaultAction = getDefaultActions()[action];

		if (!defaultAction) {
			return;
		}

		try {
			defaultAction(event.detail);
		}
		catch (error) {
			this.sendNotification(PlayerEvent.ERROR, { error: new ActionKeyError(error.message, error) });
		}
	};

	private addListeners() {
		document.addEventListener(this.defaultKeyEventType, this.onKeydown);
		document.addEventListener(PlayerEvent.ACTION_KEY, this.onActionKeyDefault);
	}

	private removeListeners() {
		document.removeEventListener(this.defaultKeyEventType, this.onKeydown);
		document.removeEventListener(PlayerEvent.ACTION_KEY, this.onActionKeyDefault);
	}

	private get hasScope(): boolean {
		return this.scope && this.scope !== KeyboardScope.NONE;
	}

	private createDetail(action: ActionKey | string): ActionKeyEventContextInterface {
		return {
			action,
			avia: System.appNamespace,
			player: this.player,
			system: System,
			buildInfo,
			metadata: {},
			logger: this.facade.logger,
		};
	}

	private get playerContainer(): HTMLElement {
		const domProxy = this.facade?.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxy;

		return domProxy?.getMain();
	}
}
