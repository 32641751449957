import { ModelName } from '../enum/ModelName';
import { ModelInterface } from '../iface/ModelInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { Proxy } from '../mvc/Proxy';
import { ModelCollection } from './vo/ModelCollection';


export class ModelCollectionProxy extends Proxy {

	/*
			All models:

					ContentPlaybackState    | Full Reset
					- - - - - - - - - - - - - - - - - - - - - - - -
					PresentationState       | Partial reset
					- - - - - - - - - - - - - - - - - - - - - - - -
					BuildInfo               | No reset
					PlayerDom               | No reset
					PlayerOptions           | No reset
	*/

	private resettables: string[] = [
		ModelName.ContentPlaybackState,
	];

	override onRemove(): void {
		this.resettables = null;
		super.onRemove();
	}

	get collection(): ModelCollection {
		return this.data as ModelCollection;
	}

	getModel<T>(name: string): T {
		return this.data.getModel(name) as T;
	}

	reset(): void {
		const list = this.resettables;
		let i = list.length;

		while (i--) {
			(this.getModel(list[i]) as ModelInterface).reset();
		}

		// partial reset for presentation state
		const psi = this.getModel(ModelName.PresentationState) as PresentationStateInterface;
		psi.isAutoplay = true; // coerce true for a reset (only should occur after a resource has played!)
		psi.isTrackingAd = false;
		psi.adSegmentEntered = false;
		psi.started = false;
		psi.streamDuration = NaN;
		psi.streamTime = NaN;
	}
}
