import { MediatorName } from '../../enum/MediatorName';
import { NotificationInterface } from '../../iface';
import { TimerMediator } from '../../view/TimerMediator';
import { LogAwareSimpleCommand } from '../LogAwareSimpleCommand';


export class PrepTimerCommand extends LogAwareSimpleCommand {

	execute(notification: NotificationInterface) {
		this.facade.registerMediator(new TimerMediator(MediatorName.TIMER_MEDIATOR));

		this.logger.debug('Timer mediator registered');
	}
}
