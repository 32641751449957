import { ThumbnailTrackSurface } from '../adapter/playback/surface/ThumbnailTrackSurface';
import { AppResources } from '../app/AppResources';
import { AdapterRole } from '../enum/AdapterRole';
import { Browser } from '../enum/Browser';
import { Measurement } from '../enum/Measurement';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { PlaybackState } from '../enum/PlaybackState';
import { PlayerDom } from '../enum/PlayerDom';
import { PlayerHookType } from '../enum/PlayerHookType';
import { ProxyName } from '../enum/ProxyName';
import { StreamType } from '../enum/StreamType';
import { TextTrackMode } from '../enum/TextTrackMode';
import { CoreError } from '../error/CoreError';
import { PlayerEvent } from '../events/PlayerEvent';
import { NotificationInterface, PlayerDomProxyInterface } from '../iface';
import { AdCuePointInterface } from '../iface/AdCuePointInterface';
import { AudioTrackInterface } from '../iface/AudioTrackInterface';
import { ContentPlaybackStateInterface } from '../iface/ContentPlaybackStateInterface';
import { Debounced } from '../iface/Debounced';
import { ErrorInfoInterface } from '../iface/ErrorInfoInterface';
import { MetadataCuepointInterface } from '../iface/MetadataCuepointInterface';
import { PlaybackAdapterConfigInterface } from '../iface/PlaybackAdapterConfigInterface';
import { PlaybackAdapterDelegateInterface } from '../iface/PlaybackAdapterDelegateInterface';
import { PlaybackAdapterInterface } from '../iface/PlaybackAdapterInterface';
import { PlayerHookMap } from '../iface/PlayerHookMap';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { QualityInterface } from '../iface/QualityInterface';
import { TextCuepointInterface } from '../iface/TextCuepointInterface';
import { TextTrackInterface } from '../iface/TextTrackInterface';
import { TimeRangeInterface } from '../iface/TimeRangeInterface';
import { AdapterProxy } from '../model/AdapterProxy';
import { ContentPlaybackStateProxy } from '../model/ContentPlaybackStateProxy';
import { HookProxy } from '../model/HookProxy';
import { PerformanceProxy } from '../model/PerformanceProxy';
import { ResourceProxy } from '../model/ResourceProxy';
import { TextTrackProxy } from '../model/TextTrackProxy';
import { debounce } from '../util/FunctionUtil';
import { clone, values } from '../util/ObjectUtil';
import { getResourceMimeType } from '../util/Resource';
import { isTextTrack } from '../util/TimedText';
import { isNumber } from '../util/Type';
import { LogAwareMediator } from './LogAwareMediator';
import { TimerMediator } from './TimerMediator';


/**
 * AbstractPresentationMediator interfaces with an appropriate (stream type-dependent)
 * playback adapter, and also captures events from the video element
 * (supplied as 'viewControl' to constructor). Key events invoke a 'respondTo<Context>'
 * method, each of which is abstract and must be implemented in concrete presentation sub-classes.
 *
 * In addition, this base object will listen for timer tic notifications to
 * trigger checks on buffering and size change of the presentation.
 */
export abstract class AbstractPresentationMediator extends LogAwareMediator implements PlaybackAdapterDelegateInterface {

	protected loaded: boolean = false;
	protected startedPlaying: boolean = false;
	protected preloadContent: boolean = true;
	protected isPlayingLive: boolean;
	protected streamType: StreamType;
	protected minLtsThreshold = 1800;
	protected sizeCheckInterval: number = 1000;
	protected lastSizeCheckTime: number = null;
	protected contentPlaybackStateProxy: ContentPlaybackStateProxy;
	protected performanceProxy: PerformanceProxy;
	protected playerOptions: PlayerOptionsInterface;
	protected resourceProxy: ResourceProxy;
	protected textTrackProxy: TextTrackProxy;
	protected presoModel: PresentationStateInterface;
	protected closing: Promise<void>;
	protected initializing: boolean;
	protected adapterTask = Promise.resolve();
	protected seeking_: boolean = false;
	protected paused_: boolean = false;
	protected previousPlaybackState = {
		state: null as any,
		overrides: {} as Record<string, boolean>,
		buffer: NaN,
	};

	protected contentIsBuffering: boolean = false;
	protected pAdapter: PlaybackAdapterInterface;
	protected endFreezeTimeoutHandle: any = null;
	protected timedTextOffset: number = 0;
	protected isMonitoringCueEvents: boolean = false;
	protected bufferingSampleRate: number;
	protected endFreezeThreshold: number = 0.75;
	protected endFreezeDebounceDelay: number = 200;
	protected pendingLoad: Promise<void>;
	protected shouldSendTextTrackAvailable: boolean = false;
	private timedTextContainer_: HTMLElement;
	private respondToVideoEndDebounced: Debounced;

	get adapter(): PlaybackAdapterInterface {
		return this.pAdapter;
	}

	get timedTextContainer(): HTMLElement {
		if (!this.timedTextContainer_) {
			const dp = this.facade.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxyInterface;
			this.timedTextContainer_ = dp.getElement(PlayerDom.CC_CONTAINER) as HTMLElement;
		}

		return this.timedTextContainer_;
	}

	override onRemove(): void {
		this.respondToVideoEndDebounced?.cancel();
		this.pAdapter = null;
		this.contentPlaybackStateProxy = null;
		this.resourceProxy = null;
		this.textTrackProxy = null;
		this.presoModel = null;
		this.playerOptions = null;

		super.onRemove();
	}

	setVolume(value: number): void {
		// TODO - WEBMAF - volume via adapter
		this.presoModel.volume = value;
	}

	load(): Promise<void> {
		return this.loadVideo()
			.catch(error => {
				const detail = (error.type) ? error.data || error.detail : null;
				const cause = (detail) ? detail.error || detail : error;
				throw new CoreError(NotificationName.VIDEO_START_ERROR, cause);
			});
	}

	play(): Promise<void> {
		return this.playVideo();
	}

	pause(): void {
		this.pauseVideo();
	}

	suspend(): void {
		this.pAdapter.suspend();
	}

	resume(): void {
		this.pAdapter.resume();
	}

	offsetTimedText(pixelOffset: number) {
		const isOffset = pixelOffset > 0;

		this.timedTextOffset = pixelOffset;

		if (this.playerOptions.renderTextTrackNatively && this.system.browser === Browser.FIREFOX) {
			this.monitorTtCues(isOffset);
		}
		else if (this.playerOptions.renderTextTrackNatively === false && this.timedTextContainer) {
			this.timedTextContainer.style.bottom = `${pixelOffset}px`;
		}
		else {
			const domProxy = this.facade.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxyInterface;
			const main = domProxy.getMain() as HTMLElement;
			document?.documentElement.style.setProperty('--avia-timed-text-offset', `${pixelOffset}px`);
			main?.classList[isOffset ? 'add' : 'remove']('avia-cc-offset');
		}
	}

	getBuffered(asStreamTime: boolean = false): TimeRangeInterface[] {
		return this.pAdapter?.getBuffered(asStreamTime) || [];
	}

	abstract playOnUserGesture(): void;
	abstract getAdBreakTimes(): AdCuePointInterface[];
	abstract close(): Promise<void>;
	abstract get hasContent(): boolean;
	abstract checkSize(): void;

	protected abstract respondToVideoPlaying(): void;
	protected abstract respondToVideoPaused(): void;
	protected abstract respondToVideoSeeking(): void;
	protected abstract respondToVideoSeeked(): void;
	protected abstract respondToVideoTimeUpdate(streamTime: number): void;
	protected abstract respondToQualityChange(quality?: QualityInterface): void;
	protected abstract respondToVideoEnd(): void;
	protected abstract respondToBufferingStatusCheck(count: number): void;
	protected abstract respondToDurationChange(dur: number): void;
	protected abstract respondToFullscreenChange(state: boolean): void;
	protected abstract respondToError(errorInfo: ErrorInfoInterface): void;
	protected abstract respondToId3Data(d: any): void;
	protected abstract respondToTextTrackModeChange(enabled: boolean): void;

	protected loadVideo(): Promise<void> {
		if (this.pendingLoad) {
			return this.pendingLoad;
		}

		if (this.loaded) {
			return Promise.resolve();
		}

		this.respondToWaiting('loading', true);
		this.logger.time(Measurement.RESOURCE_LOAD);

		return this.pendingLoad = this.pAdapter.load()
			.then(streamMetadata => {
				this.loaded = true;
				this.respondToWaiting('loading', false);
				this.pendingLoad = null;

				const resource = this.resourceProxy.resource;
				this.notify(PlayerEvent.STREAM_METADATA, { streamMetadata });

				const url = resource.location.thumbnailTrackUrl;
				if (!this.contentPlaybackStateProxy.thumbnailTrack && url) {
					ThumbnailTrackSurface.create(url, this.getProxy(ProxyName.HookProxy) as HookProxy)
						.then(thumbnailTrack => {
							this.contentPlaybackStateProxy.thumbnailTrack = thumbnailTrack;
							this.notify(PlayerEvent.THUMBNAIL_TRACK_AVAILABLE, { thumbnailTrack });
						})
						.catch(e => this.logger.warn('Could not load thumbnail track'));
				}

				this.logger.timeEnd(Measurement.RESOURCE_LOAD);
			});
	}

	protected playVideo(): Promise<void> {
		this.logger.time(Measurement.RESOURCE_PLAYBACK);
		return this.pAdapter.play()
			.then(() => {
				this.startedPlaying = true;
				this.logger.timeEnd(Measurement.RESOURCE_PLAYBACK);
			})
			.catch(error => {
				if (error.code !== 0) {
					// The low level streaming libraries will sometimes call video.load() or video.pause() during the
					// initial call to video.play(). This will cause an AbortError to fire. In most cases it's ok to
					// ignore the error as it doesn't effect play back.
					this.logger.warn(`Video element play() error: ${error}`);
					return;
				}

				this.sendNotification(PlayerEvent.AUTOPLAY_BLOCKED, error);
			});
	}

	protected pauseVideo(): void {
		this.pAdapter?.pause();
	}

	protected muteVideo(flag: boolean): void {
		if (!flag && !this.presoModel.userHasUnmuted) {
			this.presoModel.userHasUnmuted = true;
		}
		this.presoModel.isMuted = flag;
	}

	protected seekVideo(position: number): Promise<void> {
		return this.pAdapter.seek(position);
	}

	protected checkVideoBuffering(count: number): void {
		const mod = Math.max(Math.floor(this.bufferingSampleRate / TimerMediator.INTERVAL), 1);
		if (isFinite(mod) && count % mod) {
			return;
		}

		let buffering = false;

		if (!this.paused_) {
			const threshold = (this.previousPlaybackState.buffer + 0.001);
			// Get the video tag's current time, not the adapter's. In LTS scenarios, the adapter's current time will fluctuate.
			const currentTime = this.video.currentTime;
			this.previousPlaybackState.buffer = currentTime;
			const stalled = currentTime < threshold;
			buffering = stalled && !this.seeking_;
		}

		if (this.contentIsBuffering !== buffering) {
			this.contentIsBuffering = buffering;
			this.notify(PlayerEvent.RESOURCE_BUFFERING, { buffering });
		}
	}

	protected get video(): HTMLVideoElement {
		return this.viewControl as HTMLVideoElement;
	}

	protected notify(name: NotificationName | PlayerEvent, data?: any): void {
		this.sendNotification(name, data, NotificationType.INTERNAL);
	}

	protected respondToIsPlayingLiveChange(isLive: boolean): void {
		this.notify(PlayerEvent.CONTENT_IS_PLAYING_LIVE, { isLive });
	}

	protected respondToStreamTypeChange(streamType: StreamType): void {
		if (this.streamType === streamType) {
			return;
		}

		const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;
		this.streamType = streamType;
		cps.streamType = streamType;
		this.notify(PlayerEvent.STREAM_TYPE_CHANGE, { streamType });
	}

	protected respondToWaiting(key: string, value: boolean) {
		this.previousPlaybackState.overrides[key] = value;

		const waiting = !this.previousPlaybackState.state || values(this.previousPlaybackState.overrides).includes(true);

		this.respondToPlaybackStateChange(waiting ? PlaybackState.WAITING : this.previousPlaybackState.state);
	}

	protected respondToPlaybackStateChange(playbackState: PlaybackState): void {
		const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;
		const notWaiting = playbackState !== PlaybackState.WAITING;

		if (cps.state !== playbackState) {
			if (playbackState !== PlaybackState.IDLE && notWaiting) {
				this.previousPlaybackState.state = playbackState;
			}

			if (this.seeking_ && notWaiting) {
				return;
			}

			cps.state = playbackState;
			this.notify(PlayerEvent.PLAYBACK_STATE_CHANGE, { playbackState });
		}
	}

	// Only for Firefox at present
	protected monitorTtCues(flag: boolean): void {
		const ctt = Array.from(this.video.textTracks).find(track => isTextTrack(track.kind) && track.mode === TextTrackMode.SHOWING);
		const cues = ctt?.activeCues;

		this.isMonitoringCueEvents = flag;

		if (!cues) {
			return;
		}

		for (let i = 0, n = cues.length || 0; i < n; i++) {
			this.respondToTtCue(cues[i]);
		}
	}

	// invoked for Firefox only
	protected respondToTtCue(cue: TextTrackCue): void {
		// 64 px ~ equal to line value of 14 (yielding factor of 0.21875)
		(cue as any).line = this.isMonitoringCueEvents ? (this.timedTextOffset * 0.21875) : 'auto';
	}

	//////////////////////////
	// private and mvc internal
	handleNotification(notification: NotificationInterface): void {
		switch (notification.name) {
			case NotificationName.TIMER_TIC:
				this.pAdapter?.onTick?.();
				this.respondToBufferingStatusCheck(notification.body.count);
				if (!this.lastSizeCheckTime || this.shouldCheckSize()) {
					this.lastSizeCheckTime = Date.now();
					this.checkSize();
				}
				break;

			case NotificationName.FULLSCREEN_CHANGE:
				const data = notification.body;
				this.presoModel.isFullscreen = data.isFullscreen;
				this.respondToFullscreenChange(data.isFullscreen);

				break;
		}
	}

	override listNotificationInterests(): string[] {
		return [
			NotificationName.TIMER_TIC,
			NotificationName.FULLSCREEN_CHANGE,
		];
	}

	override onRegister(): void {
		super.onRegister();

		this.contentPlaybackStateProxy = this.facade.retrieveProxy(ProxyName.ContentPlaybackStateProxy) as ContentPlaybackStateProxy;
		this.resourceProxy = this.facade.retrieveProxy(ProxyName.ResourceProxy) as ResourceProxy;
		this.performanceProxy = this.facade.retrieveProxy(ProxyName.PerformanceProxy) as PerformanceProxy;
		this.textTrackProxy = this.facade.retrieveProxy(ProxyName.TextTrackProxy) as TextTrackProxy;
		this.presoModel = this.getModel(ModelName.PresentationState) as PresentationStateInterface;
		this.playerOptions = this.getModel(ModelName.PlayerOptions) as PlayerOptionsInterface;

		if (this.playerOptions.sizeCheckInterval !== undefined) {
			this.sizeCheckInterval = this.playerOptions.sizeCheckInterval;
		}

		const ltsCfg = this.resourceProxy.resource.playback?.lts;
		if (!isNaN(ltsCfg?.threshold) && isNumber(ltsCfg?.threshold)) {
			this.minLtsThreshold = ltsCfg.threshold;
		}

		const optionsOverrides = this.playerOptions.overrides;
		if (optionsOverrides?.endFreezeThreshold) {
			this.endFreezeThreshold = optionsOverrides.endFreezeThreshold;
		}
		if (optionsOverrides?.endFreezeDebounceDelay) {
			this.endFreezeDebounceDelay = optionsOverrides.endFreezeDebounceDelay;
		}

		this.respondToVideoEndDebounced = debounce(() => this.respondToVideoEnd(), this.endFreezeDebounceDelay);

		if (this.presoModel.isMuteAtPlayStart && !this.presoModel.userHasUnmuted) {
			this.muteVideo(true);
			this.presoModel.isMuted = true;
		}

		this.bufferingSampleRate = this.performanceProxy.bufferingSampleRate;
	}

	protected prepareForPlayback(forcePlay: boolean = false): void {
		if (this.initializing) {
			return;
		}

		const config: PlaybackAdapterConfigInterface = {
			system: this.system,
			playerOptions: this.playerOptions,
			resource: this.resourceProxy.resource,
			performanceSettings: this.performanceProxy,
			textTrackSettings: this.textTrackProxy,
			video: this.viewControl,
			logger: this.logger,
		};

		this.adapterTask = this.initializeAdapter(config, forcePlay)
			.catch(this.onLoadError);
	}

	protected sendErrorNotification(name: NotificationName | PlayerEvent, error: any) {
		this.notify(name, error);
	}

	protected async createAdapter(config: PlaybackAdapterConfigInterface): Promise<PlaybackAdapterInterface> {
		const proxy = this.facade.retrieveProxy(ProxyName.AdapterProxy) as AdapterProxy;
		const adapter = await proxy.createAdapter(AdapterRole.PLAYBACK, config.resource, () => this);

		return adapter;
	}

	protected async initializeAdapter(config: PlaybackAdapterConfigInterface, forcePlay: boolean) {
		try {
			this.logger.time(Measurement.PLAYBACK_ADAPTER_CREATION);

			this.initializing = true;

			// bail gracefully if the mediator is in the process of shutting down
			if (this.closing) {
				return;
			}

			// create and configure adapter
			try {
				this.pAdapter = await this.createAdapter(config);

				// check for shutdown after every async process
				if (this.closing) {
					return;
				}
			}
			catch (error) {
				const mimeType = getResourceMimeType(config.resource);
				if (mimeType) {
					error.message += `. Attempting to play a stream of type ${mimeType}`;
				}
				throw new CoreError(NotificationName.RESOURCE_ERROR, error);
			}

			this.logger.timeEnd(Measurement.PLAYBACK_ADAPTER_CREATION);

			// load resource
			if (this.preloadContent) {
				await this.load();
			}

			// play resource
			try {
				// check for shutdown after every async process
				if (this.closing) {
					return;
				}

				if (!(this.presoModel.isAutoplay || forcePlay)) {
					return;
				}

				await this.play();
			}
			catch (error) {
				throw new CoreError(NotificationName.VIDEO_PLAYBACK_ERROR, error);
			}
		}
		finally {
			this.initializing = false;
		}
	}

	protected onLoadError = (error: CoreError) => {
		const { message, cause } = error;
		this.sendErrorNotification(message as NotificationName, cause || error);
	};

	private respondToTextTrackInfoChange(): void {
		const { textTrack, textTracks } = this.contentPlaybackStateProxy;
		if (this.shouldSendTextTrackAvailable && textTrack && textTracks.length) {
			this.notify(PlayerEvent.TEXT_TRACK_AVAILABLE);
			this.shouldSendTextTrackAvailable = false;
		}
	}

	private calculateStreamType(): StreamType {
		if (this.pAdapter.getIsLiveStream()) {
			const liveStreamInfo = this.pAdapter.getLiveStreamInfo();
			const lts = liveStreamInfo.ltsWindowSize;
			const isLiveLinearStream = lts < this.minLtsThreshold || isNaN(lts);
			return (isLiveLinearStream) ? StreamType.LIVE : StreamType.LTS;
		}
		else {
			return StreamType.VOD;
		}
	}

	private updateLiveStreamInfo(): void {
		if (!this.pAdapter.getIsLiveStream()) {
			this.respondToStreamTypeChange(StreamType.VOD);
			return;
		}

		const cps = this.contentPlaybackStateProxy.model;
		const liveStreamInfo = this.pAdapter.getLiveStreamInfo();
		cps.liveStreamInfo = liveStreamInfo;

		const streamType = this.calculateStreamType();
		this.respondToStreamTypeChange(streamType);

		const isPlayingLive = (streamType === StreamType.LIVE) ? true : liveStreamInfo.isPlayingLive;
		if (this.isPlayingLive !== isPlayingLive) {
			this.isPlayingLive = isPlayingLive;
			this.respondToIsPlayingLiveChange(isPlayingLive);
		}

		if (cps.streamType === StreamType.LTS) {
			this.respondToDurationChange(liveStreamInfo.relativeDuration);
		}
	}

	private shouldCheckSize() {
		return (
			this.playerOptions.sizeCheckInterval > 0 &&
			(
				!this.lastSizeCheckTime ||
				Date.now() - this.lastSizeCheckTime >= this.sizeCheckInterval
			)
		);
	}
	////////////////////////
	// Delegate functions //
	////////////////////////
	hasHook(type: PlayerHookType): boolean {
		const hp = this.getProxy(ProxyName.HookProxy) as HookProxy;
		return hp.hasHook(type);
	}

	applyHook<T extends keyof PlayerHookMap, V = PlayerHookMap[T]['value'], M = PlayerHookMap[T]['metadata']>(type: T, value: V, metadata: M): Promise<V | null> {
		const hp = this.getProxy(ProxyName.HookProxy) as HookProxy;
		return hp.applyHook(type, value, metadata)
			.catch(error => {
				this.error(error);
				throw error;
			});
	}

	readonly qualityChange = (quality: QualityInterface) => {
		if (!quality) {
			this.logger.warn('Invalid quality change');
			return;
		}

		const { contentPlaybackStateProxy } = this;

		if (!contentPlaybackStateProxy.qualities?.length) {
			this.logger.warn('Invalid qualities list. Make sure qualitiesChange is called before qualityChange');
			return;
		}

		// Catch the edge case where the playback adapter is playing a previously restricted quality
		if (quality.enabled === false) {
			const qualities = contentPlaybackStateProxy.qualities.map(q => ({ ...q, enabled: q.enabled || q.index === quality.index }));
			this.qualitiesChange(qualities);
		}

		quality = contentPlaybackStateProxy.qualities.find(q => q.index === quality.index);

		if (contentPlaybackStateProxy.quality?.bitrate !== quality.bitrate) {
			contentPlaybackStateProxy.quality = quality;
			contentPlaybackStateProxy.model.bitrate = quality.bitrate;
			this.respondToQualityChange(quality);
		}
	};

	readonly qualitiesChange = (qualities: QualityInterface[]) => {
		const { contentPlaybackStateProxy } = this;
		const { model } = contentPlaybackStateProxy;

		const previousQualities = JSON.stringify(model.qualities || []);
		this.contentPlaybackStateProxy.processQualityProfile(qualities);

		if (!contentPlaybackStateProxy.isAbrSwitchingAvailable) {
			this.logger.info(AppResources.messages.ABR_UNAVAILABLE);
		}

		if (JSON.stringify(model.qualities) !== previousQualities) {
			this.logger.info('Qualities: ', model.qualities);

			this.notify(PlayerEvent.QUALITIES_CHANGE, { qualities: model.qualities });
		}
	};

	readonly error = (err: ErrorInfoInterface) => {
		if (err.fatal) {
			this.adapterTask = Promise.resolve();
		}
		this.respondToError(err);
	};

	readonly audioTrackChange = (audioTrack: AudioTrackInterface) => {
		audioTrack = clone(audioTrack, true);
		this.contentPlaybackStateProxy.audioTrack = audioTrack;
		this.notify(PlayerEvent.AUDIO_TRACK_CHANGE, { audioTrack });
	};

	readonly audioTracksChange = (audioTracks: AudioTrackInterface[]) => {
		audioTracks = clone(audioTracks, true);
		this.contentPlaybackStateProxy.audioTracks = audioTracks;
		this.notify(PlayerEvent.AUDIO_TRACKS_CHANGE, { audioTracks });
	};

	readonly cdnChange = (cdn: string) => {
		const { contentPlaybackStateProxy } = this;
		if (cdn !== contentPlaybackStateProxy.model.cdn) {
			contentPlaybackStateProxy.model.cdn = cdn;
			this.notify(PlayerEvent.CDN_CHANGE, { cdn });
		}
	};

	readonly drmKeySystemCreated = (keysystem: string) => {
		this.contentPlaybackStateProxy.model.drmType = keysystem;
		this.notify(PlayerEvent.DRM_KEY_SYSTEM_CREATED, { keysystem });
	};

	readonly timeUpdate = () => {
		if (!this.pAdapter) {
			return;
		}
		const t = this.pAdapter.getCurrentTime();
		const d = this.presoModel.streamDuration;

		this.updateLiveStreamInfo();
		if (!this.pAdapter.getIsLiveStream() && !isNaN(d) && (d - t <= this.endFreezeThreshold)) {
			this.respondToVideoEndDebounced();
		}
		this.respondToVideoTimeUpdate(t);
	};

	readonly playing = () => {
		this.paused_ = false;
		this.updateLiveStreamInfo();
		this.respondToVideoPlaying();
	};

	readonly paused = () => {
		this.paused_ = true;
		this.respondToVideoPaused();
	};

	readonly seeking = () => {
		if (!this.seeking_) {
			this.seeking_ = true;
			this.respondToVideoSeeking();
			this.respondToWaiting('seeking', true);
		}
	};

	readonly seeked = () => {
		this.seeking_ = false;
		this.respondToVideoSeeked();
		this.respondToWaiting('seeking', false);
	};

	readonly ended = () => {
		this.respondToVideoEndDebounced.cancel();
		this.respondToVideoEnd();
	};

	readonly durationChange = () => {
		// Protect against invalid durations such as NaN
		const duration = this.pAdapter.getDuration();
		if (duration > 0) {
			this.respondToDurationChange(duration);
		}
		this.updateLiveStreamInfo();
	};

	readonly volumeChange = (volume: number, muted?: boolean) => {
		if (volume !== this.presoModel.volume) {
			this.notify(PlayerEvent.VOLUME_CHANGE, { volume });
		}

		if (muted != null && muted !== this.presoModel.isMuted) {
			this.notify(PlayerEvent.MUTE_CHANGE, { muted });
		}
	};

	readonly metadataCuepoint = (data: MetadataCuepointInterface) => {
		this.respondToId3Data(data);
	};

	readonly textCuepoints = (activeCues: TextCuepointInterface[], forced: boolean = false) => {
		if (!this.textTrackProxy.enabled && !forced) {
			return;
		}

		if (this.isMonitoringCueEvents) {
			activeCues.forEach(cue => this.respondToTtCue(cue as any));
		}

		this.notify(PlayerEvent.TEXT_CUEPOINT, { activeCues, forced });
	};

	readonly textTrackDisplayModeChange = (mode: TextTrackMode) => {
		const textTrackEnabled = mode !== TextTrackMode.DISABLED;
		this.textTrackProxy.mode = mode;
		this.notify(PlayerEvent.TEXT_TRACK_ENABLED_CHANGE, { textTrackEnabled });
		this.respondToTextTrackModeChange(textTrackEnabled);
	};

	readonly textTracksChange = (textTracks: TextTrackInterface[]) => {
		textTracks = clone(textTracks, true);
		this.contentPlaybackStateProxy.addTextTracks(textTracks);
		this.notify(PlayerEvent.TEXT_TRACKS_CHANGE, { textTracks });
		this.shouldSendTextTrackAvailable = true;
		this.respondToTextTrackInfoChange();
	};

	readonly textTrackChange = (textTrack: TextTrackInterface) => {
		textTrack = clone(textTrack, true);
		this.contentPlaybackStateProxy.textTrack = textTrack;
		this.notify(PlayerEvent.TEXT_TRACK_CHANGE, { textTrack });
		this.respondToTextTrackInfoChange();
	};

	readonly waitingChange = (waiting: boolean) => {
		this.respondToWaiting('waiting', waiting);
		this.notify(PlayerEvent.WAITING_CHANGE, { waiting });
	};
}
