import { EmitterInterface } from '../iface/EmitterInterface';
import { EventHandler } from '../iface/EventHandler';
import { EventInterface } from '../iface/EventInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { isFunction } from '../util/Type';
import { CoreEvent } from './CoreEvent';
import { LinkedList } from './LinkedList';


export class Emitter<O = StrAnyDict> implements EmitterInterface {

	protected opts: O;
	protected target: any;
	protected eventMap: Record<string, LinkedList<EventHandler<any>>>;

	/**
	 * An event emitter, with methods to add and remove event listeners.
	 * Base class for any class using event-based communication.
	 */
	constructor(options?: O, target?: EmitterInterface) {
		this.target = target || this;
		this.opts = options || {} as O;
		this.eventMap = {};

		const o = this.opts;

		for (const q in o) {
			if (isFunction(o[q]) && /^on[A-Z]/.test(q)) {
				const e = q.charAt(2).toLowerCase() + q.substring(3);
				this.on(e, o[q] as any);
				delete this.opts[q];
			}
		}
	}

	destroy(): void {
		let q;

		if (this.opts) {
			this.opts = null;
		}

		this.offAll(null);

		if (this.eventMap) {
			for (q in this.eventMap) {
				delete this.eventMap[q];
			}
			this.eventMap = null;
		}

		this.target = null;
	}

	/**
	 * Adds an event listener
	 */
	on(name: string, func: EventHandler<any>): void {
		if (!this.eventMap || !name || !isFunction(func)) {
			return;
		}

		const handlers = this.eventMap[name] || (this.eventMap[name] = new LinkedList<EventHandler<any>>());

		if (handlers.has(func)) {
			return;
		}

		handlers.add(func);
	}

	/**
	 * Adds a one time event listener
	 */
	once(name: string, func: EventHandler<any>): void {
		if (!this.eventMap || !name || !isFunction(func)) {
			return;
		}

		const handler = (event: EventInterface) => {
			this.off(name, handler);
			func(event);
		};

		this.on(name, handler);
	}

	/**
	 * Removes the supplied EventHandler, or, if omitted, will remove all
	 * handlers for the supplied (required) event name
	 */
	off(name: string, func?: EventHandler<any>): void {
		!func && this.offAll(name);
		func && this.remove(name, func);
	}

	/**
	 * Removes all event handlers for the supplied (required) event name
	 */
	offAll(name?: string): void {
		if (!this.eventMap) {
			return;
		}

		const map = this.eventMap;

		if (name) {
			this.remove(name, null);

			return;
		}

		for (const q in map) {
			this.remove(q, null);
		}
	}

	hasListenerFor(name: string): boolean {
		return !!(this.eventMap && this.eventMap[name] && !this.eventMap[name].empty);
	}

	emit(name: string, detail?: any): void {
		const e = new CoreEvent(name, this.target || this, detail);
		this.dispatchEvt(e);
	}

	dispatchEvt(event: EventInterface) {
		if (!this.eventMap || !this.eventMap[event.type]) {
			return;
		}
		this.eventMap[event.type].forEach(h => {
			try {
				h(event);
			}
			catch (e) {
				console?.error?.(e);
			}
		});
	}

	private remove(name: string, func: EventHandler<any> = null): void {
		if (!this.eventMap || !this.eventMap[name]) {
			return;
		}

		if (!func) {
			this.eventMap[name].clear();
		}
		else {
			this.eventMap[name].delete(func);
		}
	}
}
