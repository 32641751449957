import { PlaybackState } from '../../enum/PlaybackState';
import { QualityMode } from '../../enum/QualityMode';
import { StreamType } from '../../enum/StreamType';
import { AudioTrackInterface } from '../../iface/AudioTrackInterface';
import { ContentPlaybackStateInterface } from '../../iface/ContentPlaybackStateInterface';
import { DataConfigInterface } from '../../iface/DataConfigInterface';
import { LiveStreamInfoInterface } from '../../iface/LiveStreamInfoInterface';
import { ModelInterface } from '../../iface/ModelInterface';
import { QualityInterface } from '../../iface/QualityInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { TextTrackInterface } from '../../iface/TextTrackInterface';
import { ConfigurablePropertyContainer } from './ConfigurablePropertyContainer';


export class ContentPlaybackState implements ContentPlaybackStateInterface, ModelInterface {

	private model: StrAnyDict;

	constructor(config: DataConfigInterface) {
		this.model = new ConfigurablePropertyContainer(config);
	}

	static get modelName(): string {
		return 'ContentPlaybackState';
	}

	get data(): ContentPlaybackStateInterface {
		return this.model.data as ContentPlaybackStateInterface;
	}

	reset(): void {
		this.model.reset();
	}

	set qualities(value: QualityInterface[]) {
		this.model.qualities = value;
	}
	get qualities(): QualityInterface[] {
		return this.model.qualities;
	}

	set quality(value: QualityInterface) {
		this.model.quality = value;
	}
	get quality(): QualityInterface {
		return this.model.quality;
	}

	set qualityMode(value: QualityMode) {
		this.model.qualityMode = value;
	}
	get qualityMode(): QualityMode {
		return this.model.qualityMode;
	}

	set qualityCappedToScreenSize(value: boolean) {
		this.model.qualityCappedToScreenSize = value;
	}
	get qualityCappedToScreenSize(): boolean {
		return this.model.qualityCappedToScreenSize;
	}

	set audioTracks(value: AudioTrackInterface[]) {
		this.model.audioTracks = value;
	}
	get audioTracks(): AudioTrackInterface[] {
		return this.model.audioTracks;
	}

	set audioTrack(value: AudioTrackInterface) {
		this.model.audioTrack = value;
	}
	get audioTrack(): AudioTrackInterface {
		return this.model.audioTrack;
	}

	set textTracks(value: TextTrackInterface[]) {
		this.model.textTracks = value;
	}
	get textTracks(): TextTrackInterface[] {
		return this.model.textTracks;
	}

	set textTrack(value: TextTrackInterface) {
		this.model.textTrack = value;
	}
	get textTrack(): TextTrackInterface {
		return this.model.textTrack;
	}

	set streamType(value: StreamType) {
		this.model.streamType = value;
	}
	get streamType(): StreamType {
		return this.model.streamType;
	}

	set liveStreamInfo(value: LiveStreamInfoInterface) {
		this.model.liveStreamInfo = value;
	}
	get liveStreamInfo(): LiveStreamInfoInterface {
		return this.model.liveStreamInfo;
	}

	set bufferLength(value: number) {
		this.model.bufferLength = value;
	}
	get bufferLength(): number {
		return this.model.bufferLength;
	}

	set cdn(value: string) {
		this.model.cdn = value;
	}
	get cdn(): string {
		return this.model.cdn;
	}

	set drmType(value: string) {
		this.model.drmType = value;
	}
	get drmType(): string {
		return this.model.drmType;
	}

	set contentSegment(value: number) {
		this.model.contentSegment = value;
	}
	get contentSegment(): number {
		return this.model.contentSegment;
	}

	set maxBandwidth(value: number) {
		this.model.maxBandwidth = value;
	}
	get maxBandwidth(): number {
		return this.model.maxBandwidth;
	}

	set time(value: number) {
		this.model.time = value;
	}
	get time(): number {
		return this.model.time;
	}

	set state(value: PlaybackState) {
		this.model.state = value;
	}
	get state(): PlaybackState {
		return this.model.state;
	}

	set duration(value: number) {
		this.model.duration = value;
	}
	get duration(): number {
		return this.model.duration;
	}

	set bitrate(value: number) {
		this.model.bitrate = value;
	}
	get bitrate(): number {
		return this.model.bitrate;
	}

	set started(value: boolean) {
		this.model.started = value;
	}
	get started(): boolean {
		return this.model.started;
	}

	set averageDroppedFps(value: number) {
		this.model.averageDroppedFps = value;
	}
	get averageDroppedFps(): number {
		return this.model.averageDroppedFps;
	}

	set framerate(value: number) {
		this.model.framerate = value;
	}
	get framerate(): number {
		return this.model.framerate;
	}
}
