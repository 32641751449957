import { buildInfo } from '../core/BuildInfo';
import { ActionKeyContext } from '../enum/ActionKeyContext';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { ProxyName } from '../enum/ProxyName';
import { StreamType } from '../enum/StreamType';
import { PlayerEvent } from '../events/PlayerEvent';
import {
	ApplicationInterface,
	MediatorInterface,
	NotificationInterface,
	PlayerDomProxyInterface,
	PresentationMediatorInterface,
	VideoProxyInterface,
} from '../iface';
import { AdCuePointInterface } from '../iface/AdCuePointInterface';
import { DimensionsInterface } from '../iface/DimensionsInterface';
import { ErrorInfoInterface } from '../iface/ErrorInfoInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { TimeRangeInterface } from '../iface/TimeRangeInterface';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';
import { ContentPlaybackStateProxy } from '../model/ContentPlaybackStateProxy';
import { ModelCollectionProxy } from '../model/ModelCollectionProxy';
import type { ResourceProxy } from '../model/ResourceProxy';
import { PlayerOptions } from '../model/vo/PlayerOptions';
import { clampValue } from '../util/NumberUtil';
import { values } from '../util/ObjectUtil';
import { ActionKeyMediator } from './ActionKeyMediator';
import { AdPresentationMediator } from './AdPresentationMediator';
import { FullscreenMediator } from './FullscreenMediator';
import { LogAwareMediator } from './LogAwareMediator';
import { PluginMediator } from './PluginMediator';


export class AppMediator extends LogAwareMediator implements MediatorInterface {

	private app: ApplicationInterface;

	constructor(name: string, app: ApplicationInterface) {
		super(name);

		this.app = app;
	}

	private get presentationMediator(): PresentationMediatorInterface {
		return this.facade.retrieveMediator(MediatorName.PRESENTATION_MEDIATOR) as PresentationMediatorInterface;
	}

	private get actionKeyMediator(): ActionKeyMediator {
		return this.facade.retrieveMediator(MediatorName.KEY_COMMAND) as any;
	}

	getBuffered(asStreamTime: boolean = false): TimeRangeInterface[] {
		return this.presentationMediator?.getBuffered(asStreamTime) || [];
	}

	primeVideoElement(): Promise<void> {
		const domProxy = this.facade.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxyInterface;
		const opts = this.getModel(ModelName.PlayerOptions) as PlayerOptionsInterface;

		return domProxy.primeVideo(opts.overrides?.blankVideoUrl);
	}

	offsetTimedText(pixelOffset: number) {
		this.presentationMediator?.offsetTimedText(pixelOffset);
	}

	setFullscreenElement(el: HTMLElement): void {
		const fsm = this.facade.retrieveMediator(MediatorName.FULLSCREEN) as FullscreenMediator;
		fsm.fullscreenElement = el;
	}

	getFullscreenElement(): HTMLElement {
		const fsm = this.facade.retrieveMediator(MediatorName.FULLSCREEN) as FullscreenMediator;

		return fsm.fullscreenElement;
	}

	getSessionTime(): number | null {
		return this.presentationMediator?.getSessionTime() || null;
	}

	getPlaybackTime(): number | null {
		return this.presentationMediator?.getPlaybackTime() || null;
	}

	updateDimensions() {
		this.presentationMediator?.checkSize();
	}

	override onRemove() {
		this.app = null;
		super.onRemove();
	}

	getAppApi(): VideoPlayerInterface {
		return this.app.getApi();
	}

	getConfigAsJson(spacing: number = 2): string {
		const model = this.getProxy(ProxyName.ModelCollectionProxy) as ModelCollectionProxy;
		const playerOptions = (model.getModel(ModelName.PlayerOptions) as PlayerOptions).data;

		return JSON.stringify(
			{
				resource: this.getCurrentResource(),
				playerOptions,
				buildInfo,
			},
			(key, value) => (value instanceof HTMLElement) ? undefined : value,
			spacing,
		);
	}

	validateSeek(position: number, duration: number): number | null {
		if (isNaN(position)) {
			this.logger.warn(`Invalid seek() time [${position}] supplied`);

			return null;
		}

		const pm = this.getModel(ModelName.PresentationState) as PresentationStateInterface;
		const pbp = this.getProxy(ProxyName.ContentPlaybackStateProxy) as ContentPlaybackStateProxy;
		const linear = pbp && pbp.model.streamType === StreamType.LIVE;

		if (!pm || !pbp || pm.isTrackingAd || linear) {
			this.logger.warn('seek() may not be called in the current context');

			return null;
		}

		return clampValue(position, 0, duration);
	}

	isPlaybackSuspended(): boolean {
		const pm = this.getModel(ModelName.PresentationState) as PresentationStateInterface;
		return pm.suspended;
	}

	getContainerRect(): ClientRect | null {
		const domProxy = this.getProxy(ProxyName.PlayerDomProxy) as PlayerDomProxyInterface;

		return domProxy ? domProxy.getPresentationRect() : null;
	}

	getCurrentResource(): ResourceConfigurationInterface | null {
		const p = this.getProxy(ProxyName.ResourceProxy);

		return (p as ResourceProxy)?.resource || null;
	}

	killCurrentResource(): Promise<void> {
		const resourceProxy = this.getProxy(ProxyName.ResourceProxy) as ResourceProxy;

		if (!resourceProxy) {
			return Promise.resolve();
		}

		const resource = resourceProxy.resource;
		const playbackProxy = this.getProxy(ProxyName.ContentPlaybackStateProxy) as ContentPlaybackStateProxy;
		const isLive = playbackProxy.model.streamType === StreamType.LIVE || playbackProxy.model.streamType === StreamType.LTS;
		const pm = this.presentationMediator;

		this.logger.info('Killing current resource');

		if (pm) {
			return pm.close()
				.then(() => {
					isLive && this.sendNotification(PlayerEvent.LIVE_PRESENTATION_STOPPED, { resource });
				})
				.catch((e: Error) => {
					this.logger.error(e);
				});
		}
		else {
			return Promise.resolve();
		}
	}

	skipAd() {
		const pm = this.presentationMediator as unknown;
		(pm as AdPresentationMediator).skipAd();
	}

	getPlugin(name: string): any {
		const pim = this.facade.retrieveMediator(MediatorName.PLUGIN_MEDIATOR) as PluginMediator;

		return pim ? pim.getPlugin(name) : null;
	}

	dispatchPluginEvent(data: StrAnyDict) {
		this.app.sendEvent(PlayerEvent.PLUGIN_EVENT, data);
	}

	getMuteState(): boolean {
		const presoModel = this.getModel(ModelName.PresentationState) as PresentationStateInterface;
		return presoModel ? presoModel.isMuted : null;
	}

	getVolume(): number {
		const presoModel = this.getModel(ModelName.PresentationState) as PresentationStateInterface;
		return presoModel ? presoModel.volume : NaN;
	}

	getDimensions(): DimensionsInterface {
		const domProxy = this.getProxy(ProxyName.PlayerDomProxy) as PlayerDomProxyInterface;
		return domProxy ? domProxy.getDimensions() : null;
	}

	getAdBreakTimes(): AdCuePointInterface[] {
		return this.presentationMediator?.getAdBreakTimes() || null;
	}

	grabFrame(): HTMLImageElement | null {
		const videoProxy = this.getProxy<VideoProxyInterface>(ProxyName.VideoProxy);
		const vidEl = videoProxy?.getVideo();

		if (!vidEl) {
			return null;
		}

		const cvs = document.createElement('canvas');

		const ctx = cvs.getContext('2d');
		const w = cvs.width = vidEl.videoWidth;
		const h = cvs.height = vidEl.videoHeight;
		ctx.drawImage(vidEl, 0, 0, w, h);
		const dUrl = cvs.toDataURL();

		const img = document.createElement('img');
		img.setAttribute('src', dUrl);

		return img;
	}

	getKeyEventTarget(): Node | null {
		return this.actionKeyMediator?.getKeyEventTarget();
	}

	disableKeyCommands(flag: boolean = true): void {
		if (flag) {
			this.actionKeyMediator?.disable();
		}
		else {
			this.actionKeyMediator?.enable();
		}
	}

	enterActionKeyContext(context: ActionKeyContext | string) {
		this.actionKeyMediator?.enterActionKeyContext(context);
	}

	getActionKeyContext(): (ActionKeyContext | string)[] {
		return this.actionKeyMediator?.getActionKeyContext();
	}

	exitActionKeyContext(context: ActionKeyContext | string) {
		this.actionKeyMediator?.exitActionKeyContext(context);
	}

	override listNotificationInterests(): string[] {
		return values(PlayerEvent);
	}

	sendErrorEvent(type: string, error: ErrorInfoInterface): void {
		this.app.sendEvent(type, error);
	}

	handleNotification(notification: NotificationInterface): void {
		this.app.sendEvent(notification.name, notification.body);
	}

	override onRegister(): void {
		super.onRegister();
	}
}
