import { assert } from '../error/assert';
import { HookError } from '../error/HookError';
import { ExtensionContextInterface } from '../iface/ExtensionContextInterface';
import { HookConfigInterface } from '../iface/HookConfigInterface';
import { HookContextInterface } from '../iface/HookContextInterface';
import { HookInterface } from '../iface/HookInterface';
import { HookManagerInterface } from '../iface/HookManagerInterface';
import { HookMap } from '../iface/HookMap';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';

type HookType = keyof HookMap;

export class HookManager implements HookManagerInterface {

	private hookMap: Record<string, HookInterface[]> = {};
	private hookConfig: Record<string, HookConfigInterface> = null;
	private context: ExtensionContextInterface = null;
	private player: VideoPlayerInterface = null;

	constructor(config: Record<string, any>, context: ExtensionContextInterface, player?: VideoPlayerInterface) {
		this.hookConfig = config;
		this.context = context;
		this.player = player;
	}

	destroy() {
		this.hookMap = null;
		this.hookConfig = null;
		this.context = null;
		this.player = null;
	}

	hasHook(type: HookType) {
		const hooks = this.hookMap[type];

		return hooks?.length > 0;
	}

	defineHook(type: string, config: HookConfigInterface = { multiple: true }) {
		assert(!this.hookConfig[type], `Hook type already exists: ${type}`);

		this.hookConfig[type] = config;
	}

	private validateHookType(type: HookType) {
		assert(type in this.hookConfig, `Invalid hook type: ${type}`);
	}

	registerHook<T extends HookType>(type: T, hook: HookInterface<HookMap[T]>): void {
		this.validateHookType(type);

		if (!this.hookMap[type]) {
			this.hookMap[type] = [];
		}

		if (this.hookConfig[type]?.multiple === false && this.hookMap[type].length) {
			// hook type disallows multiple hooks
			return;
		}

		if (this.hookMap[type].includes(hook)) {
			// hook already exists
			return;
		}

		this.hookMap[type].push(hook);
	}

	removeHook<T extends HookType>(type: T, hook: HookInterface<HookMap[T]>): void {
		this.validateHookType(type);

		if (!this.hookMap[type]) {
			return;
		}

		this.hookMap[type] = this.hookMap[type].filter(h => h !== hook);
	}

	async applyHook<T extends HookType, V = HookMap[T]['value'], M = HookMap[T]['metadata']>(type: T, data: V, metadata?: M): Promise<V | null> {
		const hooks = this.hookMap?.[type];

		if (!hooks || hooks.length === 0) {
			return data;
		}

		let cancelled = false;
		const cancel = () => cancelled = true;
		const context: HookContextInterface<V, M> = { type, value: null, metadata, cancel, ...this.context, player: this.player };
		const exec = async (value: V, index = 0): Promise<V> => {
			if (index >= hooks.length) {
				return value;
			}

			const transform = hooks[index];
			if (typeof transform !== 'function') {
				throw new HookError(type, 'Hook must be a valid function');
			}

			context.value = value;

			try {
				await transform(context);
			}
			catch (error) {
				throw new HookError(type, error.toString(), error);
			}

			return (cancelled) ? null : exec(context.value, ++index);
		};

		return exec(data);
	}
}
