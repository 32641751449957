import { LoggerInterface } from '../iface/LoggerInterface';
import { AbstractMediator } from '../mvc/AbstractMediator';


export abstract class LogAwareMediator extends AbstractMediator {

	// It may be useful to pass the logger to lower level components
	// (which don't have facade access), if logging is desired at that level.
	get logger(): LoggerInterface {
		return this.facade.logger;
	}
}
