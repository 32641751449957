import { request } from '../core/Request';
import * as ArrayUtil from '../util/ArrayUtil';
import { eventsToPromise } from '../util/Async';
import * as File from '../util/File';
import * as FunctionUtil from '../util/FunctionUtil';
import * as Language from '../util/Language';
import * as Net from '../util/Net';
import * as NumberUtil from '../util/NumberUtil';
import * as ObjectUtil from '../util/ObjectUtil';
import * as Quality from '../util/Quality';
import * as Resource from '../util/Resource';
import * as StringUtil from '../util/StringUtil';
import * as Thumbnail from '../util/Thumbnail';
import * as TimedText from '../util/TimedText';
import * as Type from '../util/Type';
import * as Video from '../util/Video';

/**
 * @namespace
 */
export const Util = {
	...ArrayUtil,
	eventsToPromise,
	...File,
	...FunctionUtil,
	...Language,
	...Net,
	...NumberUtil,
	...ObjectUtil,
	...Quality,
	request,
	...Resource,
	...StringUtil,
	...Thumbnail,
	...TimedText,
	...Type,
	...Video,
};
