import { StrAnyDict } from '../../iface/StrAnyDict';
import { Transformer } from '../vo/Transformer';
import { Validator } from '../vo/Validator';


export const presentation_state_config: StrAnyDict = {

	streamTime: {
		type: 'number',
		defaultValue: 0,
		setterTransformer: (value: any) => {
			return Validator.isPositiveNumber(value) ? value : 0;
		},
	},
	
	streamDuration: {
		type: 'number',
		defaultValue: NaN,
		setterTransformer: (value: any) => {
			return Validator.isPositiveNumber(value) ? value : NaN;
		},
	},

	adTime: {
		type: 'number',
		defaultValue: NaN,
		setterTransformer: (value: any) => {
			return Validator.isPositiveNumber(value) ? value : NaN;
		},
	},

	adDuration: {
		type: 'number',
		defaultValue: NaN,
		setterTransformer: (value: any) => {
			return Validator.isPositiveNumber(value) ? value : NaN;
		},
	},

	breakTime: {
		type: 'number',
		defaultValue: NaN,
		setterTransformer: (value: any) => {
			return Validator.isPositiveNumber(value) ? value : NaN;
		},
	},

	breakDuration: {
		type: 'number',
		defaultValue: NaN,
		setterTransformer: (value: any) => {
			return Validator.isPositiveNumber(value) ? value : NaN;
		},
	},

	isAutoplay: {
		type: 'boolean',
		defaultValue: false,
	},

	isTrackingAd: {
		type: 'boolean',
		defaultValue: false,
	},

	adSegmentEntered: {
		type: 'boolean',
		defaultValue: false,
	},

	isFullscreen: {
		type: 'boolean',
		defaultValue: false,
	},

	isMuted: {
		type: 'boolean',
		defaultValue: false,
	},

	isMuteAtPlayStart: {
		type: 'boolean',
		defaultValue: false,
	},

	started: {
		type: 'boolean',
		defaultValue: false,
	},

	userHasUnmuted: {
		type: 'boolean',
		defaultValue: false,
	},

	volume: {
		type: 'number',
		defaultValue: 1,
		setterTransformer: (value: any) => {
			return Transformer.clampValue(value, 0, 1);
		},
	},

	usesSsai: {
		type: 'boolean',
		defaultValue: false,
	},

	streamId: {
		type: 'string',
		defaultValue: null,
	},

	suspended: {
		type: 'boolean',
		defaultValue: false,
	},
};
