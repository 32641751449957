import { ResourceAdInterface } from '../iface/ResourceAdInterface';
import { ResourceCmcdInterface } from '../iface/ResourceCmcdInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { ResourceLocationInterface } from '../iface/ResourceLocationInterface';
import { ResourceOverridesInterface } from '../iface/ResourceOverridesInterface';
import { ResourcePlaybackInterface } from '../iface/ResourcePlaybackInterface';
import { merge } from '../util/ObjectUtil';
import { getMaxHeightForQualityCategory } from '../util/Quality';
import { isEmpty } from '../util/Type';
import { AppResources } from './AppResources';

export class ResourceConfiguration implements ResourceConfigurationInterface {

	static validate(resource: ResourceConfigurationInterface) {
		if (isEmpty(resource)) {
			throw new Error(AppResources.messages.RESOURCE_MISSING);
		}

		const missing: string[] = [];

		Object.keys(defaultResource).forEach(k => {
			if (!(k in resource)) {
				missing.push(k);
			}
		});

		if (missing.length) {
			throw new Error(`One or more configuration properties not found: ${missing.join(', ')}`);
		}
	}

	constructor(config: ResourceConfigurationInterface = {}) {
		merge(this, config);

		const { abr } = this.playback;
		const { maxCategory, maxHeight } = abr;
		if (maxCategory && !Number.isFinite(maxHeight)) {
			abr.maxHeight = getMaxHeightForQualityCategory(maxCategory);
		}
	}

	sessionId: string = null;

	contentId: string = null;

	ad: ResourceAdInterface = {
		adapter: null,
		showPrerollOnNonZeroStart: false,
		viewabilityTracking: {
			partner: null,
			viewGuid: null,
			pageType: null,
		},
		csai: null,
		ssai: null,
	};

	location: ResourceLocationInterface = {
		mediaUrl: null,
		textTrackUrl: null,
		thumbnailTrackUrl: null,
		posterImageUrl: null,
		drm: {
			playready: {
				url: null,
				header: null,
				priority: null,
				audioRobustness: null,
				videoRobustness: null,
			},
			widevine: {
				url: null,
				header: null,
				priority: null,
				audioRobustness: null,
				videoRobustness: null,
			},
			fairplay: {
				appCertUrl: null,
				licenseUrl: null,
				header: null,
			},
			aes: {
				header: null,
				provider: null,
			},
		},
	};

	playback: ResourcePlaybackInterface = {
		id3OwnerIds: null,
		startTime: NaN,
		enableAdvancedCodecs: false,
		liveEdgeSyncFragmentCount: 3,
		abr: {
			maxBitrate: NaN,
			minBitrate: NaN,
			startBitrate: NaN,
			maxHeight: Infinity,
			maxCategory: null,
			capQualityToScreenSize: true,
			ignoreDevicePixelRatio: true,
		},
		lts: {
			restrictionTimestamp: NaN,
			threshold: 1800,
			clearOutOfBoundsLtsBuffer: false,
		},
	};

	metadata: Record<string, any> = {};

	tracking: Record<string, any> = {};

	cmcd: ResourceCmcdInterface = {
		enabled: true,
		sessionId: null,
		contentId: null,
		useHeaders: false,
	};

	overrides: ResourceOverridesInterface = {};
}

const defaultResource = new ResourceConfiguration();
