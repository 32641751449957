import { isFunction } from './Type';

export function base64DecodeUint8Array(input: string): Uint8Array {
	const raw = atob(input);
	const rawLength = raw.length;
	const array = new Uint8Array(new ArrayBuffer(rawLength));

	for (let i = 0; i < rawLength; i++) {
		array[i] = raw.charCodeAt(i);
	}

	return array;
}

export function base64EncodeUint8Array(input: Uint8Array) {
	const str = String.fromCharCode.apply(null, input);
	return btoa(str);
}

export type ArrayFunc<T, R> = (item: T, index: number, array: ArrayLike<T>) => R;

function isValid(list: ArrayLike<any>, func: ArrayFunc<any, any>): boolean {
	return list?.length && isFunction(func);
}

export function find<T>(list: ArrayLike<T>, func: ArrayFunc<T, boolean>): T | null {
	if (!isValid(list, func)) {
		return null;
	}

	for (let i = 0, len = list.length; i < len; i++) {
		const result = func(list[i], i, list);
		if (result) {
			return list[i];
		}
	}

	return null;
}


export function forEach<T>(list: ArrayLike<T>, func: ArrayFunc<T, void>): void {
	if (!isValid(list, func)) {
		return;
	}

	for (let i = 0, len = list.length; i < len; i++) {
		func(list[i], i, list);
	}
}

export function forEachReverse<T>(list: ArrayLike<T>, func: ArrayFunc<T, void>): void {
	if (!isValid(list, func)) {
		return;
	}

	for (let i = list.length - 1; i > -1; i--) {
		func(list[i], i, list);
	}
}

export function bufferToString(buffer: ArrayBuffer) {
	return (typeof TextDecoder !== 'undefined') ? new TextDecoder().decode(buffer) : String.fromCharCode.apply(null, new Uint8Array(buffer));
}

export function arrayToString(array: Uint16Array) {
	const uint16array = new Uint16Array(array.buffer);
	return String.fromCharCode.apply(null, uint16array);
}

export function stringToArray(string: string): Uint16Array {
	const buffer = new ArrayBuffer(string.length * 2); // 2 bytes for each char
	const array = new Uint16Array(buffer);

	for (let i = 0, strLen = string.length; i < strLen; i++) {
		array[i] = string.charCodeAt(i);
	}

	return array;
}
