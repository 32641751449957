import { ConfigurationInterface } from '../iface/ConfigurationInterface';
import { merge } from '../util/ObjectUtil';
import { updateSystem } from '../util/System';
import { logger } from './Logger';

const configuration: ConfigurationInterface = {};

/**
 * Set global configuration options.
 *
 * @beta
 */
export function configure(config: ConfigurationInterface) {
	merge(configuration, config);

	if (config.system) {
		updateSystem(config.system);
	}

	if (config.logLevel) {
		logger.logLevel = config.logLevel;
	}
}

/**
 * Get global configuration options.
 *
 * @beta
 */
export function getConfiguration(): ConfigurationInterface {
	return configuration;
}
