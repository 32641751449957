import { Emitter } from '../core/Emitter';
import { LoggerEvent } from '../enum/LoggerEvent';
import { MediatorName } from '../enum/MediatorName';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { ApplicationInterface, ApplicationOptionsInterface, FacadeInterface, ServiceCollection } from '../iface';
import { EventInterface } from '../iface/EventInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';
import { ModelCollectionProxy } from '../model/ModelCollectionProxy';
import { Facade } from '../mvc/Facade';
import { LogService } from '../service/LogService';
import { values } from '../util/ObjectUtil';
import { AppMediator } from '../view/AppMediator';

/**
 * @internal
 */
export abstract class AbstractApplication extends Emitter implements ApplicationInterface {

	private pFacade: FacadeInterface;
	private readonly pAppId: string;
	private mdlCollProxy: ModelCollectionProxy;

	protected constructor(options: ApplicationOptionsInterface) {
		super(options || {});

		this.pAppId = options.id;
		this.pFacade = Facade.createFacade(
			this.pAppId,
			options.commandMap || null,
		);

		const { logger } = options;
		this.pFacade.registerService(new LogService(ServiceName.Logging, logger));

		values(LoggerEvent).forEach(type => logger.on(type, (e: EventInterface) => this.sendEvent(e.type, e.detail)));
	}

	/**
	* @internal
	*/
	override destroy() {
		super.destroy();

		Facade.removeCore(this.pAppId);
		this.mdlCollProxy = null;
		this.pFacade = null;
	}

	/**
	* @internal
	*/
	get appId(): string {
		return this.pAppId;
	}

	abstract sendEvent(name: string, data: StrAnyDict): void;
	abstract getApi(): VideoPlayerInterface;

	protected sendNotification(name: string, body?: StrAnyDict, type?: string) {
		this.pFacade.sendNotification(name, body, type);
	}

	protected registerGlobalServices(gServices: ServiceCollection): void {
		const f = this.pFacade;

		for (const s in gServices) {
			f.registerService(gServices[s]);
		}
	}

	protected get facade(): FacadeInterface {
		return this.pFacade;
	}

	protected get modelCollectionProxy(): ModelCollectionProxy {
		if (!this.mdlCollProxy) {
			this.mdlCollProxy = this.facade.retrieveProxy(ProxyName.ModelCollectionProxy) as ModelCollectionProxy;
		}
		return this.mdlCollProxy;
	}

	protected get appMediator(): AppMediator {
		return this.pFacade.retrieveMediator(MediatorName.APPLICATION) as AppMediator;
	}
}
