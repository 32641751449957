import { request } from '../../../core/Request';
import { PlayerHookType } from '../../../enum/PlayerHookType';
import { RequestObjectType } from '../../../enum/RequestObjectType';
import { HookManagerInterface } from '../../../iface/HookManagerInterface';
import { ThumbnailCueInterface } from '../../../iface/ThumbnailCueInterface';
import { ThumbnailTrackInterface } from '../../../iface/ThumbnailTrackInterface';
import { forEach } from '../../../util/ArrayUtil';
import { renderThumbnail } from '../../../util/Thumbnail';
import { WebVTTParser } from '../../../util/WebVTTParser';


export class ThumbnailTrackSurface implements ThumbnailTrackInterface {

	url: string;
	cues: ThumbnailCueInterface[];

	constructor(url: string, cues: ThumbnailCueInterface[]) {
		this.url = url;
		this.cues = cues;
	}

	getCueAt(time: number) {
		return this.cues.find(cue => cue.startTime <= time && cue.endTime >= time);
	}

	renderCueAt(time: number, container: HTMLElement) {
		renderThumbnail(this.getCueAt(time), container);
	}

	static create(url: string, hookManager: HookManagerInterface) {
		const a = document.createElement('a');
		a.href = url;
		const absolute = a.protocol + '//' + a.hostname;
		const relative = absolute + a.pathname.split('/').slice(0, -1).join('/') + '/';

		return hookManager.applyHook(PlayerHookType.REQUEST, { url }, { objectType: RequestObjectType.OTHER })
			.then(req => request(req))
			.then(response => hookManager.applyHook(PlayerHookType.RESPONSE, { url, data: response }))
			.then(response => response.data)
			.then(response => {
				const cues = WebVTTParser.parse(response);
				cues.forEach((cue) => {
					const [file, params] = cue.text.split('#');
					let src = file;
					if (!/^(https?:)?\/\//.test(src)) {
						if (/^\//.test(src)) {
							src = absolute + src;
						}
						else {
							src = relative + src;
						}
					}
					const kv = params.split('=');
					const keys = kv[0];
					const values = kv[1].split(',');
					const style: Record<string, string> = {
						backgroundImage: `url(${src})`,

					};
					const data: Record<string, any> = { url: src };

					forEach(keys, (key: string, i: number) => {
						const value = values[i];

						switch (key) {
							case 'w':
								data.width = parseInt(value);
								style.width = `${value}px`;
								break;

							case 'h':
								data.height = parseInt(value);
								style.height = `${value}px`;
								break;

							case 'x':
								data.x = parseInt(value);
								style.backgroundPositionX = `-${value}px`;
								break;

							case 'y':
								data.y = parseInt(value);
								style.backgroundPositionY = `-${value}px`;
						}
					});

					cue.style = style;
					cue.data = data;
				});

				return new ThumbnailTrackSurface(url, cues);
			});
	}
}
