import { getConfiguration } from '../app/Configuration';
import { applyHook } from '../app/Hooks';
import { ScriptLoader, ScriptLoadOptions } from '../dataservice/ScriptLoader';
import { AviaHookType } from '../enum/AviaHookType';
import { RequestCredentials } from '../enum/RequestCredentials';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { RequestInterface } from '../iface/RequestInterface';
import { entries, merge } from './ObjectUtil';
import { System } from './System';

export function createRequest(url: string, headers: Record<string, string> = {}, credentials = RequestCredentials.SAME_ORIGIN): RequestInterface {
	return { url, headers, credentials };
}

export function applyRequestToXhr(request: RequestInterface, xhr: XMLHttpRequest) {
	entries(request.headers).forEach(([key, value]) => {
		xhr.setRequestHeader(key, value);
	});

	xhr.withCredentials = request.credentials === RequestCredentials.INCLUDE;
}

export function getHeader(headerName: string, rawHeaders: string) {
	if (!headerName || !rawHeaders) {
		return null;
	}

	const matchHeader = new RegExp(`${headerName}:([^\\n\\r]+)`, 'i');
	return rawHeaders.match(matchHeader)?.[1].trim();
}

/**
 * Helper function for loading external JS files.
 */
export async function loadScript<T>(options: ScriptLoadOptions, playerOptions?: PlayerOptionsInterface, getter?: () => T | null): Promise<any> {
	const { overrides, network } = merge({ network: {} }, getConfiguration(), playerOptions);

	await ScriptLoader.load({
		url: makeUrl(options.url),
		timeout: overrides?.streamingLibLoaderTimeout || network.timeout,
		errorRecovery: merge({}, network, options.errorRecovery),
	});

	const value = getter?.() || {};
	const metadata = {
		url: options.url,
	};

	return applyHook(AviaHookType.SCRIPT_LOAD, value, metadata);
}

/**
 * Utility method that constructs the url by prepending the page's
 * protocol. Ex: "https://www.cbssports.com/live"
 *
 * @param url -
 * @returns The url
 */
export function makeUrl(url: string): string {
	const urlData = new URL(url, window.location.toString());
	if (System.isWebOs || (urlData.protocol === 'file:' && /^\/\//.test(url))) {
		urlData.protocol = 'https:';
	}
	return urlData.toString();
}
