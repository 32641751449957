export enum ErrorCategory {
	AD = 'Ad',
	RESOURCE = 'Resource',
	PLAYBACK = 'Playback',
	VIDEO_START = 'Video start',
	HOOK = 'Hook',
	API = 'API',
	EVENT = 'Event',
	PLUGIN = 'Plugin',
	ACTION_KEY = 'Action Key',
}
