/* Based on Copyright(c) 2006-2018 Futurescale, Inc. */
/* TypeScript port and service tier mod by Video Technology Group, CBSi Inc. */

import { ServiceName } from '../enum/ServiceName';
import {
	ControllerInterface,
	FacadeInterface,
	FacadeMap, LogServiceInterface, MediatorInterface,
	MvcModelInterface,
	NotificationCommandMapInterface,
	NotificationInterface,
	ProxyInterface, ServiceCollection, ServiceContainerInterface, ServiceInterface,
	ViewInterface,
} from '../iface';
import { LoggerInterface } from '../iface/LoggerInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { ServiceContainer } from '../service/ServiceContainer';
import { Controller } from './Controller';
import { Model } from './Model';
import { MvcMessages } from './MvcMessages';
import { Notification } from './Notification';
import { View } from './View';


export class Facade implements FacadeInterface {

	protected static facadeMap: FacadeMap = {};

	protected model: MvcModelInterface = null;
	protected view: ViewInterface = null;
	protected controller: ControllerInterface = null;
	protected serviceContainer: ServiceContainerInterface = null;

	protected multiCoreKey: string;
	private initialized: boolean = false;

	protected constructor(key: string, commandMap: NotificationCommandMapInterface) {
		this.multiCoreKey = key;

		this.initializeFacade();

		this.registerCommands(commandMap);
	}

	static removeCore(key: string): void {
		if (!this.facadeMap[key]) {
			return;
		}

		Model.removeModel(key);
		View.removeView(key);
		Controller.removeController(key);
		ServiceContainer.removeServiceContainer(key);

		this.facadeMap[key].destroy();

		delete this.facadeMap[key];
	}

	static hasCore(key: string): boolean {
		return Facade.facadeMap[key] != null;
	}

	// used internally by mvc actors
	static getInstance(key: string): FacadeInterface {
		if (!key) {
			throw new Error(MvcMessages.ERROR_NO_KEY);
		}

		return this.facadeMap[key];
	}

	static createFacade(key: string, commandMap: NotificationCommandMapInterface): FacadeInterface {
		if (!key) {
			throw new Error(MvcMessages.ERROR_NO_KEY);
		}

		if (this.facadeMap[key]) {
			const error = new Error(`Not creating facade - key "${key}" already exists!`);
			// @ts-ignore
			error.key = key;
			throw error;
		}

		this.facadeMap[key] = new Facade(key, commandMap);

		return this.facadeMap[key];
	}

	///////////////////////////////////////////////
	// Instance members

	destroy(): void {
		this.model = null;
		this.view = null;
		this.controller = null;
		this.serviceContainer = null;
	}

	get key(): string {
		return this.multiCoreKey;
	}

	// supplied as a semantic alternative to get key()
	get appId(): string {
		return this.multiCoreKey;
	}

	get logger(): LoggerInterface {
		return (this.retrieveService(ServiceName.Logging) as LogServiceInterface).logger;
	}

	// Controller: Commands
	registerCommand(notificationName: string, commandClassRef: any): void {
		this.controller.registerCommand(notificationName, commandClassRef);
	}

	removeCommand(notificationName: string): void {
		this.controller.removeCommand(notificationName);
	}

	hasCommand(notificationName: string): boolean {
		return this.controller.hasCommand(notificationName);
	}

	// Model: Proxies
	registerProxy(proxy: ProxyInterface): void {
		this.model.registerProxy(proxy);
	}

	retrieveProxy(proxyName: string): ProxyInterface {
		return this.model ? this.model.retrieveProxy(proxyName) : null;
	}

	removeProxy(proxyName: string): void {
		this.model && this.model.removeProxy(proxyName);
	}

	hasProxy(proxyName: string) {
		return this.model.hasProxy(proxyName);
	}

	// View: Mediators
	registerMediator(mediator: MediatorInterface): void {
		this.view.registerMediator(mediator);
	}

	retrieveMediator(mediatorName: string): MediatorInterface {
		return this.view ? this.view.retrieveMediator(mediatorName) : null;
	}

	removeMediator(mediatorName: string): void {
		this.view && this.view.removeMediator(mediatorName);
	}

	hasMediator(mediatorName: string): boolean {
		return this.view.hasMediator(mediatorName);
	}

	// ServiceContainer: Services
	registerService(service: ServiceInterface): void {
		this.serviceContainer.registerService(service);
	}

	retrieveService(serviceName: string): ServiceInterface {
		return this.serviceContainer ? this.serviceContainer.retrieveService(serviceName) : null;
	}

	removeService(serviceName: string): void {
		this.model && this.serviceContainer.removeService(serviceName);
	}

	hasService(serviceName: string) {
		return this.serviceContainer.hasService(serviceName);
	}

	registerGlobalServices(svcCollection: ServiceCollection) {
		for (const s in svcCollection) {
			this.registerService(svcCollection[s]);
		}
	}

	// send notification
	/* eslint-disable*/
	sendNotification(name: string, type: string): void;
	sendNotification(name: string, body: StrAnyDict, type: string): void;

	sendNotification(name: string, param1?: StrAnyDict | string, param2?: string): void {
		let notification: Notification;

		if (typeof param1 === 'string') {
			notification = new Notification(name, {}, param1);
		}

		else {
			notification = new Notification(name, param1, param2);
		}

		this.notifyObservers(notification);
	}
	/* eslint-enable*/

	protected notifyObservers(notification: NotificationInterface): void {
		this.view != null && this.view.notifyObservers(notification);
	}

	// command reg
	protected registerCommands(map: NotificationCommandMapInterface) {
		if (!map) {
			return;
		}

		for (const q in map) {
			!!map[q] && this.registerCommand(q, map[q]);
		}
	}

	// init
	protected initializeServiceContainer(): void {
		this.serviceContainer = ServiceContainer.getInstance(this.multiCoreKey);
	}

	protected initializeController(): void {
		this.controller = Controller.getInstance(this.multiCoreKey);
	}

	protected initializeModel(): void {
		this.model = Model.getInstance(this.multiCoreKey);
	}

	protected initializeView(): void {
		this.view = View.getInstance(this.multiCoreKey);
	}

	protected initializeFacade(): void {
		if (this.initialized) {
			throw new Error('Unexpected Condition: Facade already initialized');
		}

		this.initializeModel();
		this.initializeController();
		this.initializeView();
		this.initializeServiceContainer();

		this.initialized = true;
	}
}
