import { MediatorName } from '../../enum/MediatorName';
import { NotificationInterface } from '../../iface';
import { PluginMediator } from '../../view/PluginMediator';
import { LogAwareSimpleCommand } from '../LogAwareSimpleCommand';


export class PrepPluginsCommand extends LogAwareSimpleCommand {

	execute(notification: NotificationInterface) {
		this.facade.registerMediator(new PluginMediator(MediatorName.PLUGIN_MEDIATOR));

		this.logger.debug('Plugins Prepared');
	}
}
