import { StrAnyDict } from '../iface/StrAnyDict';

/**
 * Sidefill for ES6 `Object.values` function. See https://tc39.es/ecma262/#sec-object.values
 *
 * ES2017
 */
export function values<T>(obj: Record<string, T>): T[] {
	return Object.keys(obj).reduce((a, k, i) => {
		a[i] = obj[k];
		return a;
	}, []);
}

/**
 * Sidefill for ES6 `Object.entries` function. See https://tc39.es/ecma262/#sec-object.entries
 *
 * ES2017
 */
export function entries<K extends string | number | symbol, V = any>(obj: Record<K, V>): [K, V][] {
	return Object.keys(obj).reduce((a, k, i) => {
		a[i] = [k, (obj as any)[k]];
		return a;
	}, []);
}

/**
 * Sidefill for ES6 `Object.fromEntries` function. See https://tc39.es/ecma262/#sec-object.fromentries
 *
 * ES2019
 */
export function fromEntries<K extends string | number | symbol, V = any>(entries: [K, V][]): Record<K, V> {
	return entries.reduce((o, [k, v]) => {
		o[k] = v;
		return o;
	}, {} as Record<K, V>);
}


/**
 * Copy an object or array of objects
 */
export function clone(obj: any, freeze: boolean = false): any {
	const result = Array.isArray(obj) ? obj.map((o: any) => clone(o, freeze)) : Object.assign({}, obj);
	return freeze ? Object.freeze(result) : result;
}

/**
 * Freeze an object or array of objects
 */
export function freeze(obj: any): any {
	if (Array.isArray(obj)) {
		obj.forEach((o: any) => freeze(o));
	}
	return Object.freeze(obj);
}

/**
 * Merge the properties of a list of object. Similar to Object.assign, with the following exceptions:
 * - Performs a deep merge
 * - `undefined` values will not override defined values.
 */
export function merge(...args: any[]): any {
	const override = (base: any, overrides: any): any => {
		if (base == null) {
			return overrides;
		}
		else if (overrides == null) {
			return base;
		}

		const isNode = (obj: any): boolean => {
			try {
				return obj instanceof Node;
			}
			catch (error) {
				return false;
			}
		};

		Object.getOwnPropertyNames(overrides).forEach(key => {
			const value = overrides[key];
			const baseValue = base[key];
			const type = typeof value;

			switch (type) {
				case 'undefined':
					return;

				case 'number':
				case 'string':
				case 'boolean':
				case 'symbol':
				case 'function':
				case 'bigint':
					base[key] = value;
					break;

				case 'object':
					if (Array.isArray(value)) {
						base[key] = value.slice();
					}
					// TODO: Add better support for circular references and non-cloneable objects
					else if (value === null || isNode(value)) {
						base[key] = value;
					}
					else {
						const target = (baseValue == null) ? {} : baseValue;
						base[key] = override(target, value);
					}

					break;
			}
		});

		return base;
	};

	const [base, ...rest] = args;
	return rest.reduce((acc, obj) => override(acc, obj), base);
}

/**
 * Map properties from one object to another.
 *
 * @param from - Source object
 * @param to - Destination object
 * @param map - Mapping of source to destination properties where the key is
 * 							the source property and the value is the destination property
 */
export function mapProperties(from: StrAnyDict, to: StrAnyDict, map: StrAnyDict): void {
	Object.keys(map).forEach((fromKey) => {
		const toKey = map[fromKey];
		to[toKey] = from[fromKey];
	});
}

const cached: StrAnyDict = {};

/**
 * Cache the result of a function call
 *
 * @param key - Cache key
 * @param factory - Factory function to call if the key is not cached
 */
export function cache<T>(key: string, factory: () => T): T {
	if (!cached[key]) {
		cached[key] = factory();
	}

	return cached[key];
}
