import { AppResources } from '../../app/AppResources';
import { ErrorCode } from '../../enum/ErrorCode';
import { MimeType } from '../../enum/MimeType';
import { TextTrackEvent } from '../../enum/TextTrackEvent';
import { TextTrackKind } from '../../enum/TextTrackKind';
import { TextTrackMode } from '../../enum/TextTrackMode';
import { PlayerError } from '../../error/PlayerError';
import { AudioTrackInterface } from '../../iface/AudioTrackInterface';
import { DestroyInterface } from '../../iface/DestroyInterface';
import { ErrorInfoInterface } from '../../iface/ErrorInfoInterface';
import { EventHandler } from '../../iface/EventHandler';
import { EventTargetInterface } from '../../iface/EventTargetInterface';
import { LiveStreamInfoInterface } from '../../iface/LiveStreamInfoInterface';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { MetadataCuepointInterface } from '../../iface/MetadataCuepointInterface';
import { PlaybackAdapterContextInterface } from '../../iface/PlaybackAdapterContextInterface';
import { PlaybackAdapterDelegateInterface } from '../../iface/PlaybackAdapterDelegateInterface';
import { PlaybackAdapterInterface } from '../../iface/PlaybackAdapterInterface';
import { PlaybackMetricsInterface } from '../../iface/PlaybackMetricsInterface';
import { QualityInterface } from '../../iface/QualityInterface';
import { RangeInterface } from '../../iface/RangeInterface';
import { StreamMetadataInterface } from '../../iface/StreamMetadataInterface';
import { TextTrackInterface } from '../../iface/TextTrackInterface';
import { ThumbnailDataInterface } from '../../iface/ThumbnailDataInterface';
import { TimeRangeInterface } from '../../iface/TimeRangeInterface';
import { waitForEvent } from '../../util/Async';
import { clampValue, inRange, mapToRange } from '../../util/NumberUtil';
import { entries } from '../../util/ObjectUtil';
import { validateQuality } from '../../util/Quality';
import { getResourceMetadata, getResourceMimeType } from '../../util/Resource';
import { clearAllCues, createSidecarTextTrack, dedupeCues, isTextTrack } from '../../util/TimedText';
import { isFunction } from '../../util/Type';
import { isValidPlayheadTime } from '../../util/Video';
import { TextTrackSurfaceEvents } from './enum/TextTrackSurfaceEvents';
import { VideoSurfaceEvents } from './enum/VideoSurfaceEvents';
import { MetadataSurface } from './surface/MetadataSurface';
import { TextTrackSurface } from './surface/TextTrackSurface';
import { WaitingSurface } from './surface/WaitingSurface';

export class PlaybackAdapterBase implements PlaybackAdapterInterface {

	static canPlay(context: PlaybackAdapterContextInterface, mimeTypes: MimeType[]) {
		const { avia, resource } = context;
		const systemInfo = avia.getSystemInfo();
		const mimeType = getResourceMimeType(resource) as MimeType;
		const validMimeType = mimeTypes.includes(mimeType);

		return systemInfo.hasMediaSource && validMimeType;
	}

	protected video: HTMLVideoElement;
	protected metadataSurface: MetadataSurface | null;
	protected metadataLoaded: boolean = false;
	protected textTrackSurface: TextTrackSurface | null;
	protected context: PlaybackAdapterContextInterface;
	protected suppressErrors: boolean = false;
	protected options: any;
	protected logger: LoggerInterface;
	protected delegate: PlaybackAdapterDelegateInterface;
	protected segmentDuration: number = 6;
	protected pauseTime: number = NaN;
	protected eventsEnabled: boolean = false;
	protected startedPlaying: boolean = false;
	protected liveStreamInfo: LiveStreamInfoInterface = {
		isPlayingLive: false,
		liveEdgeOffset: NaN,
		ltsWindowSize: NaN,
		relativeTime: NaN,
		relativeDuration: NaN,
		absoluteStart: NaN,
		absoluteTime: NaN,
		absoluteDuration: NaN,
	};

	protected audioTrack: AudioTrackInterface | null = null;
	protected audioTracks: AudioTrackInterface[] = [];

	protected quality: QualityInterface | null = null;
	protected qualities: QualityInterface[] = null;

	protected textTrack: TextTrackInterface | null = null;
	protected textTracks: TextTrackInterface[] = [];

	protected minBitrate: number = 0;
	protected maxBitrate: number = Infinity;
	protected maxHeight: number = Infinity;
	protected qualityCappedToScreenSize: boolean = null;

	protected waitingSurface: DestroyInterface;
	protected videoEventMap: Record<string, EventHandler<any>>;

	protected restrictedStartTime: number;
	protected restrictedStartTimeUtc: number;
	protected isLtsRestricted: boolean;
	protected pendingSeekTime: number;

	constructor(context: PlaybackAdapterContextInterface, options: any = {}, useTextSurface: boolean = true) {
		// Some classes need to override this function and call super, which you can't do from inside an arrow function.
		this.onCueChange = this.onCueChange.bind(this);
		this.onMetadataCuepoint = this.onMetadataCuepoint.bind(this);
		this.onWaitingChange = this.onWaitingChange.bind(this);

		this.videoEventMap = {
			[VideoSurfaceEvents.PLAYING]: this.onPlaying = this.onPlaying.bind(this),
			[VideoSurfaceEvents.PAUSE]: this.onPause = this.onPause.bind(this),
			[VideoSurfaceEvents.SEEKING]: this.onSeeking = this.onSeeking.bind(this),
			[VideoSurfaceEvents.SEEKED]: this.onSeeked = this.onSeeked.bind(this),
			[VideoSurfaceEvents.TIME_UPDATE]: this.onTimeUpdate = this.onTimeUpdate.bind(this),
			[VideoSurfaceEvents.VOLUME_CHANGE]: this.onVolumeChange = this.onVolumeChange.bind(this),
			[VideoSurfaceEvents.ENDED]: this.onEnded = this.onEnded.bind(this),
			[VideoSurfaceEvents.ERROR]: this.onVideoError = this.onVideoError.bind(this),
		};

		this.context = context;
		this.options = options;
		this.video = context.video;
		this.delegate = this.context.getDelegate();
		this.logger = context.logger;
		this.metadataSurface = this.createMetadataSurface();
		this.waitingSurface = this.createWaitingSurface();

		const playback = context.resource.playback;
		this.isLtsRestricted = this.isValidRestrictionTimestamp(playback.lts.restrictionTimestamp);
		this.qualityCappedToScreenSize = playback.abr.capQualityToScreenSize;

		const abr = playback?.abr;
		if (abr) {
			if (abr.maxBitrate) {
				this.maxBitrate = abr.maxBitrate;
			}

			if (abr.minBitrate) {
				this.minBitrate = abr.minBitrate;
			}

			if (abr.maxHeight) {
				this.maxHeight = abr.maxHeight;
			}
		}

		if (useTextSurface) {
			this.textTrackSurface = this.createTextTrackSurface();
		}

	}

	destroy(): Promise<void> {
		this.video.removeEventListener(VideoSurfaceEvents.LOADED_METADATA, this.onLoadedMetadata);
		this.video.removeEventListener(VideoSurfaceEvents.DURATION_CHANGE, this.onDurationChange);
		this.removeEvents(this.video, this.videoEventMap);
		this.listenToTextTracks(false, true);

		this.metadataSurface?.destroy();
		this.metadataSurface = null;

		this.textTrackSurface?.destroy();
		this.textTrackSurface = null;

		this.waitingSurface?.destroy();
		this.waitingSurface = null;

		this.context = null;
		this.options = null;
		this.delegate = null;
		this.logger = null;
		this.video = null;

		return Promise.resolve();
	}

	onTick() {
		this.metadataSurface?.onTick();
	}

	getIsLiveStream() {
		return this.video.duration === Infinity;
	}

	setAutoQualitySwitching(auto: boolean): void {
		throw new Error('setAutoQualitySwitching Method not implemented.');
	}

	setQuality(quality: QualityInterface): void {
		throw new Error('setQuality Method not implemented.');
	}

	setMinBitrate(value: number): void {
		this.minBitrate = value;
	}

	setMaxBitrate(value: number): void {
		this.maxBitrate = value;
	}

	setQualityCappedToScreenSize(value: boolean): void {
		this.qualityCappedToScreenSize = value;
		this.resize();
	}

	setTextTrack(value: TextTrackInterface): void {
		if (!this.textTrackSurface) {
			return;
		}

		const tracks = Array.from(this.video.textTracks);
		const track = tracks.find(track => value.language === track.language && value.kind === track.kind && value.label === track.label);
		this.textTrackSurface.textTrack = track;
	}

	setTextTrackMode(mode: TextTrackMode): void {
		if (!this.textTrackSurface) {
			return;
		}

		this.textTrackSurface.textTrackMode = mode;
	}

	setAudioTrack(track: AudioTrackInterface): void {
		throw new Error('setAudioTrack Method not implemented.');
	}

	getId(): string {
		throw new Error('getId Method not implemented.');
	}

	getCurrentTime(): number {
		return this.video.currentTime;
	}

	getDuration(): number {
		return this.video.duration;
	}

	getBuffered(asStreamTime: boolean = false): TimeRangeInterface[] {
		const { buffered } = this.video;
		const result = [];
		const offset = asStreamTime ? 0 : this.getSeekable().start;

		for (let i = 0, len = buffered.length; i < len; i++) {
			result.push({
				startTime: buffered.start(i) - offset,
				endTime: buffered.end(i) - offset,
			});
		}

		return result;
	}

	protected getLiveStreamUtcStart() {
		return NaN;
	}

	protected getLiveStreamUtcTime() {
		return NaN;
	}

	protected isValidRestrictionTimestamp(timestamp: number) {
		return timestamp === -1 || (timestamp !== null && !isNaN(timestamp) && timestamp >= 0);
	}

	protected calculateStart(
		currentTime = this.video.currentTime,
		start = this.getSeekable().start,
		liveStreamUtcStart = this.getLiveStreamUtcStart(),
		liveStreamUtcTime = this.getLiveStreamUtcTime(),
	) {
		if (this.getIsLiveStream()) {
			const lts = this.context.resource.playback?.lts;
			if (this.isLtsRestricted && !this.restrictedStartTime && currentTime > 0) {
				const restrictionTimestamp = lts?.restrictionTimestamp;
				const useTimestamp = restrictionTimestamp !== -1;

				if (useTimestamp && restrictionTimestamp > liveStreamUtcStart) {
					this.restrictedStartTimeUtc = restrictionTimestamp;
					this.restrictedStartTime = currentTime - ((liveStreamUtcTime - restrictionTimestamp) * 0.001);
				}
				else if (!useTimestamp) {
					// otherwise, set start to time stream joined, i.e., "now"
					this.restrictedStartTime = currentTime;
					this.restrictedStartTimeUtc = liveStreamUtcTime;
				}
				if (lts?.startTimestamp) {
					// if defined, the startTimestamp specifies a non-live edge start position
					this.setLtsStartTime(lts.startTimestamp, this.restrictedStartTime, currentTime, liveStreamUtcTime);
				}
			}
			else if (lts?.startTimestamp && currentTime > 0 && this.pendingSeekTime === undefined) {
				this.setLtsStartTime(lts.startTimestamp, start, currentTime, liveStreamUtcTime);
			}
		}

		const useRestricted = !isNaN(this.restrictedStartTime) && this.restrictedStartTimeUtc > liveStreamUtcStart;

		return {
			videoStart: useRestricted ? this.restrictedStartTime : start,
			utcStart: useRestricted ? this.restrictedStartTimeUtc : liveStreamUtcStart,
		};
	}

	setLtsStartTime(startTimestamp: number, videoStart: number, currentTime: number, currentUtcTime: number) {
		if (this.pendingSeekTime === undefined) {
			const relativeTime = Math.max(0, currentTime - videoStart);
			this.pendingSeekTime = Math.max(0, relativeTime - ((currentUtcTime - startTimestamp) * 0.001));
		}
	}

	getLiveStreamInfo(): LiveStreamInfoInterface {
		const details: LiveStreamInfoInterface = this.liveStreamInfo;
		const currentTime = this.video.currentTime;

		const liveStreamUtcStart = this.getLiveStreamUtcStart();
		const liveStreamUtcTime = this.getLiveStreamUtcTime();

		const { start, end } = this.getSeekable();
		const { videoStart, utcStart } = this.calculateStart(currentTime, start, liveStreamUtcStart, liveStreamUtcTime);

		const duration = end - videoStart;
		const offset = this.getLiveEdgeOffset();
		const relativeTime = Math.max(currentTime - videoStart, 0);

		details.relativeTime = relativeTime;
		details.relativeDuration = duration;
		details.absoluteStart = utcStart;

		details.absoluteDuration = utcStart + (duration * 1000);
		details.absoluteTime = liveStreamUtcTime;

		details.ltsWindowSize = Math.floor(duration);
		details.liveEdgeOffset = offset;
		details.isPlayingLive = Math.ceil(currentTime) >= this.calculateLiveBoundary(end);

		return details;
	}

	getThumbnails(time: number): Promise<ThumbnailDataInterface[]> {
		return Promise.resolve([]);
	}

	load(): Promise<StreamMetadataInterface> {
		this.enableSurfaceEvents(true);
		this.suppressErrors = true;
		return Promise.resolve(getResourceMetadata(this.context.resource));
	}

	play(): Promise<void> {
		return Promise.resolve()
			.then((): void | Promise<void> => {
				if (!isNaN(this.pauseTime)) {
					this.pauseTime = NaN;

					// check if playhead has fallen behind the trailing edge
					if (this.pauseTime < this.liveStreamInfo.absoluteStart) {
						this.logger.info(`Playhead outside of LTS window bounds. Moving to start of LTS window.`);
						return this.seek(0);
					}
				}

				// check if playhead has landed in the no-seek window
				if (this.getIsLiveStream()) {
					return this.seek(this.liveStreamInfo.relativeTime);
				}
			})
			.then(() => this.video.play());
	}

	pause(): void {
		if (this.getIsLiveStream() && this.context.resource.playback?.lts?.clearOutOfBoundsLtsBuffer === true) {
			this.pauseTime = this.liveStreamInfo.absoluteTime;
		}

		this.video.pause();
	}

	suspend(): void {
		// no-op
	}

	resume(): void {
		// no-op
	}

	seek(position: number): Promise<void> {
		if (isNaN(position)) {
			return Promise.resolve();
		}
		const seekable = this.getSeekable();
		let start = seekable.start;
		const end = seekable.end;

		// TODO aviajs-472 debug here for timestamp case

		if (this.getIsLiveStream()) {
			const { startTime, endTime } = this.getNoSeekRange(end);

			// check if playhead has landed in the no-seek window
			if (inRange(position, startTime, endTime)) {
				const currentTime = this.liveStreamInfo.relativeTime;
				position = position < currentTime ? startTime : endTime;
			}

			// protect against seeking too close to edges of the LTS window
			position = clampValue(position, this.getLiveEdgeOffset(), endTime);

			// apply LTS restrictions
			start = this.calculateStart().videoStart;
		}

		const duration = end - start;
		const time = mapToRange(position, 0, duration, start, end);

		if (isValidPlayheadTime(time)) {
			this.video.currentTime = time;

			return waitForEvent(this.video, VideoSurfaceEvents.SEEKED);
		}

		return Promise.resolve();
	}

	resize(): void {
		// no-op
	}

	clearText(): void {
		this.textTrackSurface?.clearCue();
	}

	getMetrics(): PlaybackMetricsInterface {
		const { video } = this as any;
		const result = {
			bandwidth: NaN,
			framerate: NaN,
			droppedVideoFrames: Number.NaN,
			totalVideoFrames: Number.NaN,
		};

		if ('getVideoPlaybackQuality' in video) {
			Object.assign(result, video.getVideoPlaybackQuality());
		}
		else if ('webkitDroppedFrameCount' in video && 'webkitDecodedFrameCount' in video) {
			result.droppedVideoFrames = video.webkitDroppedFrameCount;
			result.totalVideoFrames = video.webkitDroppedFrameCount + video.webkitDecodedFrameCount;
		}

		const frames = video.webkitDecodedFrameCount || video.mozPresentedFrames;
		if (frames) {
			result.framerate = frames / video.currentTime;
		}

		return result;
	}

	protected getLiveEdgeOffset(): number {
		const segmentDuration = this.getSegmentDuration();
		const count = this.context.resource.playback.liveEdgeSyncFragmentCount;

		return Math.max(segmentDuration, segmentDuration * count);
	}

	protected getRefreshInterval() {
		return this.getSegmentDuration() * 2;
	}

	protected calculateLiveBoundary(duration: number): number {
		return duration - this.getLiveEdgeOffset() - this.getRefreshInterval();
	}

	protected getNoSeekRange(duration: number): TimeRangeInterface {
		const boundary = this.calculateLiveBoundary(duration);
		const padding = this.getRefreshInterval();
		return {
			startTime: boundary - padding,
			endTime: boundary + padding,
		};
	}

	protected createMetadataSurface(): MetadataSurface {
		return new MetadataSurface(this.context, this.onMetadataCuepoint);
	}

	protected createTextTrackSurface(): TextTrackSurface {
		return new TextTrackSurface(this.context, this.onTextEvent);
	}

	protected createWaitingSurface(): DestroyInterface {
		return new WaitingSurface(this.context, this.onWaitingChange);
	}

	protected enableSurfaceEvents(enabled: boolean) {
		this.eventsEnabled = true;
		this.video.addEventListener(VideoSurfaceEvents.LOADED_METADATA, this.onLoadedMetadata = this.onLoadedMetadata.bind(this));
		this.video.addEventListener(VideoSurfaceEvents.DURATION_CHANGE, this.onDurationChange = this.onDurationChange.bind(this));
	}

	protected addEvents<T>(adapter: EventTargetInterface, map: Record<string, EventHandler<T>>): void {
		const action = isFunction(adapter.on) ? 'on' : 'addEventListener';
		entries(map).forEach(entry => adapter[action](entry[0], entry[1]));
	}

	protected removeEvents<T>(adapter: EventTargetInterface, map: Record<string, EventHandler<T>>): void {
		const action = isFunction(adapter.off) ? 'off' : 'removeEventListener';
		entries(map).forEach(entry => {
			(entry[1] as any).cancel?.();
			adapter[action](entry[0], entry[1]);
		});
	}

	protected createVideoElementError(): ErrorInfoInterface {
		const error = this.video.error;
		let message = Object.keys(MediaError)[error.code - 1] || AppResources.messages.UNSPECIFIED_ERROR;
		let code = ErrorCode.UNSPECIFIED_VIDEO_PLAYBACK_ERROR;

		switch (error.code) {
			case MediaError.MEDIA_ERR_ABORTED:
				message = error.toString();
				code = ErrorCode.MEDIA_ABORTED;
				break;
			case MediaError.MEDIA_ERR_NETWORK:
				code = ErrorCode.HTML5_NETWORK_ERROR;
				break;
			case MediaError.MEDIA_ERR_DECODE:
				code = ErrorCode.HTML5_MEDIA_ERROR;
				break;
			case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
				code = ErrorCode.HTML5_SRC_NOT_SUPPORTED;
				break;
			default:
				code = ErrorCode.UNSPECIFIED_VIDEO_PLAYBACK_ERROR;
		}

		return { code, message, fatal: true, cause: error };
	}

	protected onLoadedMetadata(): void {
		this.metadataLoaded = true;
		this.addEvents(this.video, this.videoEventMap);
	}

	protected onDurationChange(): void {
		this.delegate.durationChange();
	}

	protected async onPlaying(): Promise<void> {
		if (this.pendingSeekTime) {
			const t = this.pendingSeekTime;
			this.pendingSeekTime = null;
			await this.seek(t);
		}
		this.startedPlaying = true;
		this.delegate.playing();
	}

	protected onPause(): void {
		this.delegate.paused();
	}

	protected onSeeking(): void {
		this.delegate.seeking();
	}

	protected onSeeked(): void {
		this.delegate.seeked();
	}

	protected onTimeUpdate(): void {
		this.delegate.timeUpdate();
	}

	protected onVolumeChange(): void {
		this.delegate.volumeChange(this.video.volume, this.video.muted);
	}

	protected onEnded(): void {
		this.delegate.ended();
	}

	protected onVideoError(): void {
		if (!this.suppressErrors) {
			this.error(this.createVideoElementError());
		}
	}

	protected onTextEvent = (type: string, data: any) => {
		if (!this.eventsEnabled) {
			return;
		}

		const { delegate } = this;

		switch (type) {
			case TextTrackSurfaceEvents.TEXT_CUEPOINT:
				delegate.textCuepoints(data.activeCues, data.forced);
				break;

			case TextTrackSurfaceEvents.TEXT_TRACK_DISPLAY_MODE_CHANGE:
				delegate.textTrackDisplayModeChange(data.mode);
				break;

			case TextTrackSurfaceEvents.TEXT_TRACK_ADDED:
				this.textTracks.push({
					id: this.textTracks.length.toString(),
					kind: data.kind,
					language: data.language,
					label: data.label || data.language,
				});
				delegate.textTracksChange(this.textTracks);
				break;

			case TextTrackSurfaceEvents.TEXT_TRACK_AVAILABLE:
				delegate.textTracksChange(this.textTracks);
				break;

			case TextTrackSurfaceEvents.TEXT_TRACK_CHANGE:
				const track = this.textTracks.find(track => data.language === track.language && data.kind === track.kind && (data.label === track.label || data.language === track.label));
				this.textTrack = track;
				delegate.textTrackChange(track);
				break;
		}
	};

	protected getSegmentDuration(): number {
		return this.segmentDuration;
	}

	protected getSeekable(): RangeInterface {
		const result = { start: 0, end: 0 };
		const range = this.video.seekable;
		const index = range.length - 1;
		if (index >= 0) {
			result.start = range.start(index);
			result.end = range.end(index);
		}

		return result;
	}

	protected getErrorMessage(msg: string, isFatal: boolean, retry: string = 'n/a'): string {
		return `${msg} fatal: ${isFatal} retry: ${retry}`;
	}

	protected createError(code: ErrorCode, message: string, cause: any, fatal: boolean = true) {
		return new PlayerError(code, message, cause, fatal);
	}

	protected throwError(code: ErrorCode, message: string, cause: any, fatal: boolean = true) {
		this.error(this.createError(code, message, cause, fatal));
	}

	protected error(error: ErrorInfoInterface) {
		this.delegate.error(error);
	}

	protected createSidecarTextTrack() {
		const { resource, video } = this.context;
		if (!resource.location?.textTrackUrl) {
			return;
		}

		createSidecarTextTrack(resource, video);
	}

	protected updateQualities(qualities: QualityInterface[] = this.qualities) {
		if (!qualities) {
			return;
		}

		qualities.sort((a, b): number => a.bitrate - b.bitrate);

		const cap = this.qualityCappedToScreenSize;
		const bounds = cap && this.video.getBoundingClientRect();

		qualities.forEach(quality => {
			// @ts-ignore - enabled is readonly in the public API, but settable internally
			quality.enabled = validateQuality(quality, this.minBitrate, this.maxBitrate, this.maxHeight, bounds);
		});

		if (!qualities.some(quality => quality.enabled)) {
			this.logger.warn('Invalid qualities array. None of the values meet the current size/bitrate constraints.');
		}

		this.delegate.qualitiesChange(qualities);
	}

	protected listenToTextTracks(flag: boolean, clearCues: boolean = false) {
		const verb = flag ? 'addEventListener' : 'removeEventListener';

		Array.from(this.context.video.textTracks).forEach((textTrack) => {
			if (!isTextTrack(textTrack.kind)) {
				return;
			}

			textTrack[verb](TextTrackEvent.CUE_CHANGE, this.onCueChange);

			if (clearCues) {
				clearAllCues(textTrack);
			}
		});
	}

	protected onCueChange(event: Event, forced?: boolean): void {
		const track = event.target as TextTrack;
		const activeCues = dedupeCues(track, this.video.currentTime);
		// @ts-ignore
		const forcedTrack = event.target.forced === true;
		forced ??= (track.kind as any) === TextTrackKind.FORCED || (event as any).forced || forcedTrack;

		this.delegate.textCuepoints(activeCues, forced);
	}

	protected onMetadataCuepoint(metadata: MetadataCuepointInterface) {
		this.delegate.metadataCuepoint(metadata);
	}

	protected onWaitingChange(waiting: boolean) {
		this.delegate.waitingChange(waiting);
	}
}
