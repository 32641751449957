import { Deprecated } from '../deprecated/Deprecated';
import { Autoplay } from '../enum/Autoplay';
import { LogLevel } from '../enum/LogLevel';
import { Network } from '../enum/Network';
import { PerformanceMode } from '../enum/PerformanceMode';
import { ConfigurationKeyboardInterface } from '../iface/ConfigurationKeyboardInterface';
import { ConfigurationNetworkInterface } from '../iface/ConfigurationNetworkInterface';
import { DomReference } from '../iface/DomReference';
import { LocalizationData } from '../iface/LocalizationData';
import { OverridesInterface } from '../iface/OverridesInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { PluginConfigInterface } from '../iface/PluginConfigInterface';
import { merge } from '../util/ObjectUtil';
import { uid8 } from '../util/StringUtil';
import { System } from '../util/System';
import { getConfiguration } from './Configuration';

export class PlayerOptions implements PlayerOptionsInterface {
	plugins: PluginConfigInterface[] = [];
	autoplay: Autoplay = Autoplay.NONE;
	network: ConfigurationNetworkInterface = {
		retryAttempts: 0,
		retryIntervals: [],
		timeout: Network.TIMEOUT,
	};
	performanceMode: PerformanceMode = PerformanceMode.AUTO;
	renderTextTrackNatively: boolean = true;
	useNativeFullscreen: boolean = true;
	playsInline: boolean = true;
	useNativeControls: boolean = false;
	overrides: OverridesInterface = {};
	enableOm: boolean = false;
	enableMoat: boolean = false;
	keyboard: ConfigurationKeyboardInterface = {};
	language: string = navigator.language;
	localization: LocalizationData = {};
	idleDelay: number = 3000;
	ignoreBlankTextTracks: boolean = false;

	// defaults set in constructor after merge
	container: DomReference;
	id: string;
	audioLanguage: string;
	textLanguage: string;
	localizationLanguage: string;
	reuseVideoElement: boolean;
	pluginFilter: (plugin: PluginConfigInterface) => boolean;

	// default is intentionally undefined. Inherited from parent logger.
	logLevel: LogLevel;

	constructor(options: Partial<PlayerOptionsInterface> = {}) {
		const { container, ...opts } = options;

		const config = getConfiguration();

		Deprecated.checkPlayerOptions(opts);

		opts.network = merge({}, config.network, {
			timeout: opts.networkTimeout,
			retryAttempts: opts.networkErrorRecovery?.retryAttempts,
			retryIntervals: opts.networkErrorRecovery?.retryIntervals,
		}, opts.network);

		opts.keyboard = merge({}, config.keyboard, {
			scope: opts.keyboardScope,
		}, opts.keyboard);

		if (System.isBrowser) {
			if (
				container == null ||
				!(
					container.constructor === HTMLDivElement ||
					(typeof container === 'object' && typeof container.getBoundingClientRect === 'function') ||
					(typeof container === 'string' && document.querySelector(container) != null)
				)
			) {
				throw new Error('Invalid player configuration: Missing presentation container.');
			}
		}

		merge(this, opts);

		this.id ??= uid8();
		this.container = container;
		this.audioLanguage ??= this.language;
		this.textLanguage ??= this.language;
		this.localizationLanguage ??= this.language;
		this.reuseVideoElement ??= System.isIos;
	}
}
