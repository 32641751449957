import { ErrorCategory } from '../enum/ErrorCategory';
import { ErrorCode } from '../enum/ErrorCode';
import { ErrorMessage } from '../enum/ErrorMessage';
import { PluginConfigInterface } from '../iface/PluginConfigInterface';
import { PlayerError } from './PlayerError';

export class PluginError extends PlayerError {
	override readonly name: string = 'PluginError';

	readonly config: PluginConfigInterface;

	constructor(code: ErrorCode, message: ErrorMessage | string, config: PluginConfigInterface, cause?: Error) {
		super(code, message, cause, true, ErrorCategory.PLUGIN);
		this.config = config;
	}
}
