import { request } from '../core/Request';
import { Browser } from '../enum/Browser';
import { ResponseType } from '../enum/ResponseType';
import { TextTrackKind } from '../enum/TextTrackKind';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { TextCuepointInterface } from '../iface/TextCuepointInterface';
import { appendCmcdQuery, CMCD_VERSION, CmcdObjectType } from './cmcd';
import { parseLanguageTag } from './Language';
import { getNumLines, hmsToSec } from './StringUtil';
import { System } from './System';
import { isEmpty } from './Type';

export function clearCue(textTrack: TextTrack, time: number): void {
	try {
		const Cue = VTTCue || TextTrackCue;
		textTrack.addCue(new Cue(time - 0.1, time + 0.1, ' '));
	}
	catch (error) {
		// Do nothing
	}
}

export function clearAllCues(textTrack: TextTrack) {
	const clearCues = (cues: TextTrackCueList) => {
		if (!cues) {
			return;
		}

		Array.from(cues).forEach(cue => {
			try {
				textTrack.removeCue(cue);
			}
			catch (error) {
				// no-op
			}
		});
	};

	clearCues(textTrack.cues);
	clearCues(textTrack.activeCues);
}

export function dedupeCues(track: TextTrack, time: number): TextCuepointInterface[] {
	const map: Record<string, any> = {};
	const cues: TextCuepointInterface[] = [];

	processCues(track, time);

	if (!track.activeCues) {
		return cues;
	}

	Array.from(track.activeCues).forEach((cue: any): void => {
		try {
			if (!cue) {
				return;
			}

			const { text, startTime, endTime, line } = cue;

			if (!text) {
				return;
			}

			const mapped = map[text];

			if (!mapped) {
				map[text] = cue;
			}
			else if (mapped.startTime === startTime) {
				if (mapped.endTime !== endTime) {
					mapped.endTime = Math.max(mapped.endTime, endTime);
				}

				if (mapped.line !== line) {
					mapped.line = (mapped.line === 'auto' || line === 'auto') ? 'auto' : mapped.line;
				}

				track.removeCue(cue);
				return;
			}

			cues.push(cue);
		}
		catch (error) {
			// Ignore errors to avoid interrupting playback
		}
	});

	return cues;
}

export function processCues(textTrack: TextTrack, playhead: number): void {
	const map: Record<string, Record<string, VTTCue>> = {};
	const time = Math.max(0, playhead - 15);

	if (!textTrack.cues) {
		return;
	}

	Array.from(textTrack.cues).forEach((cue: any) => {
		try {
			const { startTime, endTime, text } = cue;

			if (endTime < time) {
				return;
			}

			if (!map[text]) {
				map[text] = {};
			}

			const prev = map[text][endTime];

			// check for duplicate cues
			if (prev && prev.line === cue.line) {
				textTrack.removeCue(cue);
				return;
			}

			// check for split cues
			const split = map[text][startTime];
			if (split && cue.line === split.line) {
				split.endTime = endTime;
				delete map[text][startTime];
				textTrack.removeCue(cue);
				cue = split;
			}

			map[text][endTime] = cue;
		}
		catch (error) {
			// Ignore errors to avoid interrupting playback
		}
	});
}

export function isTextTrack(kind: any) {
	return kind === TextTrackKind.CAPTIONS || kind === TextTrackKind.SUBTITLES;
}

/**
 * Helper function for converting a SMPTE file to a VTT cue array
 */
export async function smpteToVtt(url: string, textTrack: TextTrack) {
	const xml: XMLDocument = await request({ url, responseType: ResponseType.DOCUMENT });
	const smpteCues = xml.querySelectorAll('tt body div p');

	if (!smpteCues.length) {
		throw new Error(`No cues found in ${url}`);
	}

	const align = System.browser === Browser.SAFARI ? 'middle' : 'center';
	const lineOffset = System.browser === Browser.SAFARI ? -1 : 0;
	const isVTTCueSupported = System.global.VTTCue !== undefined;
	const Cue: typeof VTTCue = isVTTCueSupported ? System.global.VTTCue : System.global.TextTrackCue;

	smpteCues.forEach((item, i, cues) => {
		if (isEmpty(item.textContent)) {
			return;
		}

		// Convert old <span> tags.
		const text = item.textContent
			.replace(/(.*)<span.*tts:fontStyle="italic">(.*)<\/span>(.*)/g, '$1<i>$2</i>$3')
			.replace(/(.*)<span.*tts:fontWeight="bold">(.*)<\/span>(.*)/g, '$1<b>$2</b>$3')
			.replace(/(.*)<span.*tts:textDecoration="under">(.*)<\/span>(.*)/g, '$1<u>$2</u>$3');

		// Count lines for positioning.
		const lc = getNumLines(text);
		// @ts-ignore
		item._lineCountPrev = item._lineCountPrev || 0;
		// @ts-ignore
		item._lineCountNext = item._lineCountNext || 0;

		let n = i + 1;
		let nextItem = cues[n];

		const begin = item.getAttribute('begin');

		// Determine number of lines for each start time.
		while (nextItem?.getAttribute('begin') === begin) {
			// @ts-ignore
			nextItem._lineCountPrev = lc + item._lineCountNext;
			// @ts-ignore
			item._lineCountNext += getNumLines(nextItem.textContent);
			nextItem = cues[++n];
		}

		const cue = new Cue(hmsToSec(begin), hmsToSec(item.getAttribute('end')), text);
		try {
			const ttsAlign = item.getAttribute('tts:textAlign') as AlignSetting;
			// @ts-ignore
			cue.align = (!ttsAlign) ? align : ttsAlign;
		}
		catch (error) {
			// @ts-ignore
			cue.align = 'middle';
		}

		// Can only add position data to VTTCue, Edge only supports TextTrackCue
		if (isVTTCueSupported) {
			// @ts-ignore
			const metadata = item.querySelector('metadata');
			// TODO: This should be normalized with _setPosition?? FROM OLD PLAYER TODO
			if (metadata) {
				cue.snapToLines = true; // Ensures lines don't overlap.

				const col = parseInt(metadata.getAttribute('cccol'));
				const row = parseInt(metadata.getAttribute('ccrow'));

				// NOTE: `cccol` has a max of 35.
				const position = Math.round(col / 35 * 100);
				cue.position = position;

				// VTG-1399: Safari v12.1 does not accept 'middle' - must be 'center; but previous version need middle.
				try {
					// @ts-ignore - middle is not an AlignSetting, but old safari does not conform with center!!
					cue.align = position < 45 ? 'start' : position > 55 ? 'end' : align;
				}
				catch (error) {
					cue.align = 'center';
				}

				cue.positionAlign = position < 45 ? 'line-left' : position > 55 ? 'line-right' : 'center';

				// NOTE: `ccrow` has a max of 15. Subtract 2 lines to bring text
				//       above control bar. Negative numbers indicate bottom
				//       up, where -1 is absolute bottom.
				cue.line = row - 17 + lineOffset;
			}
		}

		try {
			textTrack.addCue(cue);
		}
		catch (error) {
			// ignore errors
		}
	});
}

export function createSidecarTextTrack(resource: ResourceConfigurationInterface, video: HTMLVideoElement, language = 'en', label = 'English'): Promise<TextTrack> {
	let url = resource.location?.textTrackUrl;
	if (!url) {
		return Promise.reject('Invalid text track url');
	}

	const isVtt = url.indexOf('.vtt') >= 0;
	const { cmcd } = resource;
	if (cmcd.enabled) {
		url = appendCmcdQuery(url, {
			ot: CmcdObjectType.CAPTION,
			su: true,
			sid: cmcd.sessionId,
			cid: cmcd.contentId,
			pr: video.playbackRate,
			v: CMCD_VERSION,
		});
	}

	if (isVtt) {
		const track = document.createElement('track');
		track.kind = TextTrackKind.CAPTIONS;
		track.label = label;
		track.srclang = language;
		track.id = 'sidecar-vtt';
		track.src = url;
		video.appendChild(track);
		return Promise.resolve(track.track);
	}
	else {
		const track = video.addTextTrack(TextTrackKind.CAPTIONS, label, language);
		return smpteToVtt(url, track)
			.then(() => track);
	}
}

export function findLanguageTracks<T extends { language: string; }>(tracks: T[], language: string, exact: boolean = false): T[] {
	const regex = (language: string) => new RegExp(`^${language}`, 'i');
	const exactMatch = regex(language);
	let matches = tracks.filter(t => exactMatch.test(t.language));
	if (!exact) {
		const shortMatch = regex(parseLanguageTag(language).language);
		matches = matches.concat(tracks.filter(t => !exactMatch.test(t.language) && shortMatch.test(t.language)));
	}
	return matches;
}

export function findDefaultTrack<T extends { language: string; default?: boolean; }>(tracks: T[], language: string): T {
	const results = findLanguageTracks(tracks, language)
		.sort((a, b) => (a.default === b.default) ? 0 : a.default === true ? -1 : b.default === true ? 1 : 0);

	return results[0] || tracks.find(t => t.default) || tracks[0];
}
