import { extensionContext } from '../app/Extensions';
import { applyHook, hasHook } from '../app/Hooks';
import { PlayerHookType } from '../enum/PlayerHookType';
import { ProxyName } from '../enum/ProxyName';
import { HookConfigInterface } from '../iface/HookConfigInterface';
import { HookInterface } from '../iface/HookInterface';
import { HookManagerInterface } from '../iface/HookManagerInterface';
import { PlayerHookMap } from '../iface/PlayerHookMap';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';
import { player_hook_config } from '../model/hook_configs';
import { Proxy } from '../mvc/Proxy';
import { HookManager } from './HookManager';


export class HookProxy extends Proxy implements HookManagerInterface {

	private pHookManager: HookManagerInterface = null; // player-level hook mgt

	constructor(player: VideoPlayerInterface) {
		super(ProxyName.HookProxy, null);
		this.pHookManager = new HookManager(player_hook_config, extensionContext, player);
	}

	override onRemove() {
		this.pHookManager?.destroy();
		this.pHookManager = null;

		super.onRemove();
	}

	defineHook(type: string, config: HookConfigInterface): void {
		this.pHookManager.defineHook(type, config);
	}

	hasHook(type: PlayerHookType): boolean {
		return this.pHookManager.hasHook(type) || hasHook(type);
	}

	registerHook<T extends keyof PlayerHookMap>(type: T, hook: HookInterface<PlayerHookMap[T]>): void {
		this.pHookManager.registerHook(type, hook);
	}

	removeHook<T extends keyof PlayerHookMap>(type: T, hook: HookInterface<PlayerHookMap[T]>): void {
		this.pHookManager.removeHook(type, hook);
	}

	async applyHook<T extends keyof PlayerHookMap, V = PlayerHookMap[T]['value'], M = PlayerHookMap[T]['metadata']>(type: T, value: V, metadata?: M): Promise<V | null> {
		const val = await applyHook(type, value, metadata);

		return this.pHookManager.applyHook(type, val, metadata);
	}
}
