import { avia } from '../avia';
import { buildInfo } from '../core/BuildInfo';
import { QueryString } from '../core/QueryString';
import { PlayerDom } from '../enum/PlayerDom';
import { PluginServicesOptions } from '../iface';
import { BuildInfoInterface } from '../iface/BuildInfoInterface';
import { HookManagerInterface } from '../iface/HookManagerInterface';
import { LocalizationInterface } from '../iface/LocalizationInterface';
import { LoggerInterface } from '../iface/LoggerInterface';
import { PlayerDomInterface } from '../iface/PlayerDomInterface';
import { PluginServicesInterface } from '../iface/PluginServicesInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { SystemInterface } from '../iface/SystemInterface';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';
import { System } from '../util/System';

export class PluginServices implements PluginServicesInterface {

	// TODO  namespace needed?
	event = {
		dispatchPluginEvent: (data: StrAnyDict) => {
			this.options.dispatch(data);
		},
	};

	// TODO  need here?
	util = {
		queryStrFromObj(obj: StrAnyDict, encode?: string[]): string {
			return QueryString.encode(obj);
		},
	};

	private options: PluginServicesOptions;

	constructor(options: PluginServicesOptions) {
		this.options = options;
	}

	get player(): VideoPlayerInterface {
		return this.options.player;
	}

	get logger(): LoggerInterface {
		return this.options.logger;
	}

	get system(): SystemInterface {
		return System;
	}

	get appNamespace(): any {
		return this.system.appNamespace;
	}

	get avia() {
		return this.system.appNamespace as avia;
	}

	get playerOptions() {
		return this.options.playerOptions;
	}

	get buildInfo(): BuildInfoInterface {
		return buildInfo;
	}

	get localization(): LocalizationInterface {
		return this.options.localization;
	}

	get dom(): PlayerDomInterface {
		const { domProxy, videoProxy } = this.options;
		return {
			main: domProxy?.getMain(),
			get video() {
				return videoProxy.getVideo();
			},
			videoContainer: domProxy?.getElement(PlayerDom.VIDEO_CONTAINER),
			ccContainer: domProxy?.getElement(PlayerDom.CC_CONTAINER),
			adContainer: domProxy?.getElement(PlayerDom.AD_CONTAINER),
			adClickEl: domProxy?.getElement(PlayerDom.AD_CLICK_EL),
			style: domProxy?.getElement(PlayerDom.STYLE),
		};
	}

	get hookManager(): HookManagerInterface {
		return this.options.hookManager;
	}
}
