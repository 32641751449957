
/**
 * Error codes convey a specific area of failure.
 *
 * @see {@link PlayerEvent.ERROR}
 */
export enum ErrorCode {

	/*
		ConcurrencyLimitViolation
		DomainBlocked
		Expired
		GeoLocationBlocked
		IpBlocked
		NotAvailableRestriction
		SatelliteProviderBlocked
		UnknownServerError
		UserAgentBlocked
	*/

	//********************
	// RESOURCE ERROR
	//********************

	/**
	 * Resource validation error, retrieval error/CMS error.
	 */
	INVALID_RESOURCE_FORMAT = '1000',


	// CMS-specific errors parsed from MPX response XML.
	/**
	 * MPX: Responds with a 400 error typically 404.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	RESOURCE_UNAVAILABLE = '1100',

	/**
	 * MPX: Geo-restricted content.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	GEO_LOCATION_BLOCKED = '1101',

	/**
	 * MPX: Domain is blocked from content access.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	DOMAIN_BLOCKED = '1102',

	/**
	 * MPX:  IP is blocked from content access.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	IP_BLOCKED = '1103',

	/**
	 * MPX:  Provider is blocked from content access.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	SATELLITE_PROVIDER_BLOCKED = '1104',

	/**
	 * MPX:  Provider is blocked from content access.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	EXPIRED = '1105',

	/**
	 * MPX: No response received; typically a failed XHR call with no explicit error.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	SELECTOR_CALL_ERROR = '1106',

	/**
	 * The SDK is either absent or its loading has been blocked.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	IMA_SDK_MISSING = '1200',

	/**
	 * The SDK is either absent or its loading has been blocked.
	 * - Retry is performed based on network settings.
	 * - This error is fatal and playback will not begin.
	 */
	DAI_SDK_MISSING = '1201',

	/**
	 * Dash low level streaming engine code is not compiled into Avia.
	 * It is loaded "just in time".
	 * - Retry is performed based on network settings.
	 * - This error is fatal and playback will not begin.
	 */
	DASH_SDK_MISSING = '1202',

	/**
	 * HLS low level streaming engine code is not compiled into Avia.  It is loaded "just in time".
	 * - Retry is performed based on network settings.
	 * - This error is fatal and playback will not begin.
	 */
	HLS_SDK_MISSING = '1203',

	/**
	 * Shaka Player low level streaming engine code is not compiled into Avia.  It is loaded "just in time".
	 * - Retry is performed based on network settings.
	 * - This error is fatal and playback will not begin.
	 */
	SHAKA_SDK_MISSING = '1204',

	/**
	 * Twitch Low Latency streaming engine code is not compiled into Avia.  It is loaded "just in time".
	 * - Retry is performed based on network settings.
	 * - This error is fatal and playback will not begin.
	 */
	TWITCH_SDK_MISSING = '1205',

	/**
	 * The FreeWheel AdManager SDK is loaded on demand.
	 * - Retry is performed based on network settings.
	 * - If unable to load the SDK, the error is fatal and playback will not begin.
	 */
	FW_SDK_MISSING = '1206',
	//********************
	// VIDEO_START_ERROR
	//********************

	/**
	 * HLS parser can not parse the manifest for some reason.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	HLSJS_PARSE_ERROR = '2101',

	/**
	 * DASH parser can not parse the manifest for some reason.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	DASHJS_PARSE_ERROR = '2102',

	/**
	 * Shaka Player parser can not parse the manifest for some reason.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	SHAKA_PARSE_ERROR = '2103',

	/**
	 * Twitch parser can not parse the manifest for some reason.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	TWITCH_PARSE_ERROR = '2104',

	/**
	 * Sony WebMAF Video Start Error; applies to WebMAF \< 3.0.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	WEBMAF_START_ERROR = '2105',

	/**
	 * Video.play() invoked illegally when a user click is
	 * required to initiate play
	 */
	USER_GESTURE_REQUIRED = '2300',

	//********************
	// VIDEO_PLAYBACK_ERROR
	//********************

	/**
	 * Native HTML5 playback network error of any type.
	 * Could not download something needed for playback.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	HTML5_NETWORK_ERROR = '3001',

	/**
	 * HLS low level lib network error. Could not download
	 * something needed for playback.
	 * - Thrown only after all retry logic has been performed.
	 * - This error is fatal and playback will stop.
	 */
	HLSJS_NETWORK_ERROR = '3002',

	/**
	 * DASH low level lib network error. Could not download
	 * something needed for playback.
	 * - Thrown only after all retry logic has been performed.
	 * - This error is fatal and playback will stop.
	 */
	DASHJS_NETWORK_ERROR = '3003',

	/**
	 * PlayStation WebMAF lib network error. Could not download
	 * something needed for playback. Applies to WebMAF prior to
	 * version 3.0.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	WEBMAF_NETWORK_ERROR = '3004',

	/**
	 * Shaka Player lib network error. Could not download
	 * something needed for playback.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	SHAKA_NETWORK_ERROR = '3005',

	/**
	 * Twitch low level lib network error. Could not download
	 * something needed for playback.
	 * - Thrown only after all retry logic has been performed.
	 * - This error is fatal and playback will stop.
	 */
	TWITCH_NETWORK_ERROR = '3006',

	/**
	 * OIPF adapter playback network error of any type.
	 * Could not download something needed for playback.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	OIPF_NETWORK_ERROR = '3007',

	/**
	 * The fetching process for the media resource was aborted
	 * by the user agent at the user's request.
	 */
	MEDIA_ABORTED = '3100',

	/**
	 * Native HTML5 media resource was not suitable.
	 * - Thrown when media is not supported due to codec or other reason.
	 * - Retry logic is not performed.
	 *  - This error is fatal and playback will stop.
	 */
	HTML5_SRC_NOT_SUPPORTED = '3101',

	/**
	 * HLS media resource was not suitable.
	 * - Thrown when media is not supported due to codec or other reason.
	 * - Retry logic is not performed.
	 *  - This error is fatal and playback will stop.
	 */
	HLSJS_SRC_NOT_SUPPORTED = '3102',

	/**
	 * DASH media resource was not suitable.
	 * - Thrown when media is not supported due to codec or other reason.
	 * - Retry logic is not performed.
	 *  - This error is fatal and playback will stop.
	 */
	DASHJS_SRC_NOT_SUPPORTED = '3103',

	/**
	 * NOT IN USE
	 * - Thrown when media is not supported due to codec or other reason.
	 * - Retry logic is not performed.
	 *  - This error is fatal and playback will stop.
	 */
	//WebMAF_SRC_NOT_SUPPORTED = '3104',

	/**WebMAF_NETWORK_ERROR
	 * Any media resource was not suitable.
	 * - Thrown when media is not supported due to codec or other reason.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	SHAKA_SRC_NOT_SUPPORTED = '3105',

	/**
	 * Twitch media resource was not suitable.
	 * - Thrown when media is not supported due to codec or other reason.
	 * - Retry logic is not performed.
	 *  - This error is fatal and playback will stop.
	 */
	TWITCH_SRC_NOT_SUPPORTED = '3106',

	/**
	 * OIPF HTML5 media resource was not suitable.
	 * - Thrown when media is not supported due to codec or other reason.
	 * - Retry logic is not performed.
	 *  - This error is fatal and playback will stop.
	 */
	OIPF_SRC_NOT_SUPPORTED = '3107',

	/**
	 * Native HTML5 media error.  An error occurred while decoding the media resource,
	 * after the resource was established to be usable.
	 * - Thrown when corrupted chunks of media are encountered.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	HTML5_MEDIA_ERROR = '3200',

	/**
	 * HLS media error. An error occurred while decoding the media resource,
	 * after the resource was established to be usable.
	 * - Thrown when corrupted chunks of media are encountered.
	 * - Thrown only after all retry logic has been performed.
	 * - This error is fatal and playback will stop.
	 */
	HLSJS_MEDIA_ERROR = '3201',

	/**
	 * HLS.js trans-mux error.
	 * - Thrown when when memory allocation fails during remuxing
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	HLSJS_MUX_ERROR = '3202',

	/**
	 * DASH media error. An error occurred while decoding the media resource,
	 * after the resource was established to be usable.
	 * - Thrown when corrupted chunks of media are encountered.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	DASHJS_MEDIA_ERROR = '3203',

	/**
	 * **NOT AVAILABLE YET**
	 *
	 * PlayStation WEBMAF media error. An error occurred while decoding the media resource,
	 * after the resource was established to be usable.
	 * - Thrown when corrupted chunks of media are encountered.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	//WEBMAF_MEDIA_ERROR = '3204',

	/**
	 * Shaka Player media error. An error occurred while decoding the media resource,
	 * after the resource was established to be usable.
	 * - Thrown when corrupted chunks of media are encountered.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	SHAKA_MEDIA_ERROR = '3205',

	/**
	 * Twitch media error. An error occurred while decoding the media resource,
	 * after the resource was established to be usable.
	 * - Thrown when corrupted chunks of media are encountered.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	TWITCH_MEDIA_ERROR = '3206',

	/**
	 * OIPF media error.  An error occurred while decoding the media resource,
	 * after the resource was established to be usable.
	 * - Thrown when corrupted chunks of media are encountered.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	OIPF_MEDIA_ERROR = '3207',

	/**
	 * Fairplay Application Certificate DRM error.
	 * - Thrown when any issue is found with the application cert url, the request or certificate decoding.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	FAIRPLAY_APP_CERT_ERROR = '3300',

	/**
	 * Fairplay License Acquisition DRM error.
	 * - Thrown when any issue is found with the License Server url, request or setting the license on the key system.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	FAIRPLAY_LIC_ERROR = '3301',

	/**
	 * Widevine or Playready DRM error.
	 * - Thrown for any reason DRM failed during the initialization process.
	 * - Thrown only after all retry logic has been performed.
	 * - This error is fatal and playback will not begin.
	 */
	DASHJS_DRM_ERROR = '3302',

	/**
	 * **NOT AVAILABLE YET**
	 *
	 * Playready DRM error.
	 * - Thrown for any reason DRM failed during the initialization process.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	//WEBMAF_DRM_ERROR = '3303',

	/**
	 * Widevine or Playready DRM error.
	 * - Thrown for any reason DRM failed during the initialization process.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will not begin.
	 */
	SHAKA_DRM_ERROR = '3304',

	/**
	 * Widevine or Playready DRM error.
	 * - Thrown for any reason DRM failed during the initialization process.
	 * - Thrown only after all retry logic has been performed.
	 * - This error is fatal and playback will not begin.
	 */
	OIPF_DRM_ERROR = '3305',

	/**
	 * All other HTML5 Video errors or any unknown error.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	UNSPECIFIED_VIDEO_PLAYBACK_ERROR = '3400',

	/**
	 * All other HLS errors or any unknown error.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	UNSPECIFIED_HLSJS_ERROR = '3401',

	/**
	 * All other DASH Video errors or any unknown error.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	UNSPECIFIED_DASHJS_ERROR = '3402',

	/**
	 * **NOT AVAILABLE YET**
	 *
	 * All other PlayStation WEBMAF errors or any unknown error.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	//UNSPECIFIED_WEBMAF_ERROR = '3403',

	/**
	 * All other Shaka errors or any unknown error.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	UNSPECIFIED_SHAKA_ERROR = '3404',

	/**
	 * All other Twitch errors or any unknown error.
	 * - Retry logic is not performed.
	 * - This error is fatal and playback will stop.
	 */
	UNSPECIFIED_TWITCH_ERROR = '3405',

	//********************
	// AD_ERROR
	//********************

	/**
	 * Applies to IMA only; if ad progress fails to advance for period of 5 seconds,
	 * the ad is considered 'stalled', the ad break is discarded and content play resumes if applicable.
	 */
	AD_STALLED = '4000',

	/**
	 * An attempt to initialize the IMA AdsManager object resulted in an error.
	 * In this case, no ads will play for the session.
	 *
	 * **This is a rare error.**
	 */
	IMA_INIT_FAILURE = '4100',

	/**
	 * An error from the IMA SDK. There are numerous causes for an ad error;
	 * the AdErrorEvent emitted by the JS player can be interrogated for details.
	 */
	IMA_AD_ERROR = '4101',

	/**
	 * An error from the IMA SDK. There are numerous causes for an ad error;
	 * the AdErrorEvent emitted by the JS player can be interrogated for details.
	 */
	DAI_DATA_ERROR = '4200',

	/**
	 * A response was not received from the DAI back end.
	 */
	DAI_NETWORK_ERROR = '4201',

	/**
	 * DOC-TODO
	 */
	AD_BLOCKING_DETECTED = '4300',

	/**
	 * An error occurred while creating an ad adapter.
	 */
	AD_ADAPTER_ERROR = '4400',

	//********************
	// ADAPTER
	//********************

	/**
	 * An adapter could not be found for the given resource.
	 */
	ADAPTER_NOT_FOUND = '5000',

	//********************
	// HOOKS
	//********************

	/**
	 * An error occurred while executing a hook.
	 */
	HOOK_ERROR = '6000',

	/**
	 * An error occurred while executing an event hook.
	 */
	EVENT_HOOK = '6001',

	/**
	 * An error occurred while executing an ad break hook.
	 */
	AD_BREAK_HOOK = '6002',

	/**
	 * An error occurred while executing an ad hook.
	 */
	AD_HOOK = '6003',

	/**
	 * An error occurred while executing an attach resource hook.
	 */
	ATTACH_RESOURCE_HOOK = '6004',

	/**
	 * An error occurred while executing a request hook.
	 */
	REQUEST_HOOK = '6005',

	/**
	 * An error occurred while executing a response hook.
	 */
	RESPONSE_HOOK = '6006',

	/**
	 * An error occurred while executing  player creation hook.
	 */
	CREATE_PLAYER_HOOK = '6007',

	/**
	 * An error occurred while executing player removal hook.
	 */
	REMOVE_PLAYER_HOOK = '6008',

	/**
	 * An error occurred while executing a device capabilities hook.
	 */
	DEVICE_CAPABILITIES = '6009',

	/**
	 * An error occurred while executing a script load hook.
	 */
	SCRIPT_LOAD = '6010',

	//********************
	// Plugins
	//********************

	/**
	 * Generic plugin error.
	 */
	PLUGIN_ERROR = '7000',

	/**
	 * Invalid plugin factory function.
	 */
	PLUGIN_FACTORY_ERROR = '7001',

	/**
	 * Invalid plugin id.
	 */
	PLUGIN_ID_ERROR = '7002',

	/**
	 * An error occurred while loading a plugin.
	 */
	PLUGIN_LOAD_ERROR = '7003',

	//********************
	// Player API
	//********************

	/**
	 * Indicates that an API method encountered an invalid or missing argument.
	 */
	INVALID_ARGUMENT = '9000',

	/**
	 * Player API called after it has been destroyed.
	 */
	CALL_AFTER_DESTROY = '9001',

	//********************
	// Action Handling
	//********************

	/**
	 * Indicates that an error occurred within a registered action handler
	 */
	ACTION_KEY = '9111',

	//********************
	// UNEXPECTED
	//********************

	/**
	 * Cases of developer error or other errors that do not fall into a well-described category.
	 */
	UNEXPECTED_CONDITION = '9999',
}
