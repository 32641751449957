import { Autoplay } from '../../enum/Autoplay';
import { LogLevel } from '../../enum/LogLevel';
import { PerformanceMode } from '../../enum/PerformanceMode';
import { StrAnyDict } from '../../iface/StrAnyDict';


export const player_options_config: StrAnyDict = {

	container: {
		type: ['object', 'string'],
		defaultValue: null,
	},

	plugins: {
		type: 'object',
		defaultValue: null,
	},

	autoplay: {
		type: 'string',
		defaultValue: Autoplay.NONE,
	},

	id: {
		type: 'string',
		defaultValue: null,
	},

	network: {
		type: 'object',
		defaultValue: null,
	},

	performanceMode: {
		type: 'string',
		defaultValue: PerformanceMode.AUTO,
	},

	renderTextTrackNatively: {
		type: 'boolean',
		defaultValue: true,
	},

	useNativeFullscreen: {
		type: 'boolean',
		defaultValue: true,
	},

	playsInline: {
		type: 'boolean',
		defaultValue: true,
	},

	useNativeControls: {
		type: 'boolean',
		defaultValue: false,
	},

	overrides: {
		type: 'object',
		defaultValue: {},
	},

	logLevel: {
		type: 'string',
		defaultValue: LogLevel.OFF,
	},

	keyboard: {
		type: 'object',
		defaultValue: null,
	},

	language: {
		type: 'string',
		defaultValue: null,
	},

	audioLanguage: {
		type: 'string',
		defaultValue: null,
	},

	textLanguage: {
		type: 'string',
		defaultValue: null,
	},

	localizationLanguage: {
		type: 'string',
		defaultValue: null,
	},

	localization: {
		type: 'object',
		defaultValue: null,
	},

	idleDelay: {
		type: 'number',
		defaultValue: 3000,
	},

	enableOm: {
		type: 'boolean',
		defaultValue: false,
	},

	enableMoat: {
		type: 'boolean',
		defaultValue: false,
	},

	reuseVideoElement: {
		type: 'boolean',
		defaultValue: false,
	},

	ignoreBlankTextTracks: {
		type: 'boolean',
		defaultValue: false,
	},

	sizeCheckInterval: {
		type: 'number',
		defaultValue: 1000,
	},
};
