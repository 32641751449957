export enum LogLevel {
	/**
	 * Turn off all logging
	 */
	OFF = 'off',

	/**
	 * Only Errors will be logged
	 */
	ERROR = 'error',

	/**
	 * Warn and all levels below will be logged
	 */
	WARN = 'warn',

	/**
	 * Info and all levels below will be logged
	 */
	INFO = 'info',

	/**
	 * Debug and all levels below will be logged
	 */
	DEBUG = 'debug',

	/**
	 * Verbose and all levels below will be logged
	 */
	VERBOSE = 'verbose',
}
