import { AppResources } from '../app/AppResources';
import { MediatorName } from '../enum/MediatorName';
import { NotificationName } from '../enum/NotificationName';
import { ProxyName } from '../enum/ProxyName';
import { QualityCategory } from '../enum/QualityCategory';
import { QualityMode } from '../enum/QualityMode';
import { NotificationInterface, PresentationMediatorInterface } from '../iface';
import { PlaybackAdapterInterface } from '../iface/PlaybackAdapterInterface';
import { ContentPlaybackStateProxy } from '../model/ContentPlaybackStateProxy';
import { inRange } from '../util/NumberUtil';
import { getMaxBitrateIndex, getMinAndMaxValuesForCategory } from '../util/Quality';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';

export class AbrSwitchCommand extends LogAwareSimpleCommand {

	execute(notification: NotificationInterface) {

		const presoMediator = this.facade.retrieveMediator(MediatorName.PRESENTATION_MEDIATOR) as PresentationMediatorInterface;
		const stateProxy = this.facade.retrieveProxy(ProxyName.ContentPlaybackStateProxy) as ContentPlaybackStateProxy;
		const adapter: PlaybackAdapterInterface = presoMediator.adapter;
		const value: any = notification.body.value;

		//Support SBR streams VTG-2024
		if (!stateProxy.isAbrSwitchingAvailable) {
			stateProxy.qualitySwitchingMode = QualityMode.UNAVAILABLE;
			return;
		}

		// All ABR addressed directly via preso mediator's adapter
		switch (notification.name) {

			case NotificationName.SWITCH_BITRATE:
				const idx = getMaxBitrateIndex(stateProxy.qualities, value);
				this.switchToIndex(idx, stateProxy, adapter);
				break;

			case NotificationName.SWITCH_QUALITY_CATEGORY:
				stateProxy.userQualityCategory = value;
				this.switchQualityCategory(stateProxy, value, adapter);
				break;

			case NotificationName.AUTO_QUALITY_SWITCHING:
				adapter.setAutoQualitySwitching(value);
				stateProxy.qualitySwitchingMode = this.getAbrMode(value);
				break;

			case NotificationName.MIN_BITRATE:
				adapter.setMinBitrate(value);
				break;

			case NotificationName.MAX_BITRATE:
				adapter.setMaxBitrate(value);
				break;
		}
	}

	private switchToIndex(index: number, stateProxy: ContentPlaybackStateProxy, adapter: PlaybackAdapterInterface) {
		const minIndex = stateProxy.minIndex;
		const maxIndex = stateProxy.maxIndex;
		if (inRange(index, minIndex, maxIndex)) {
			adapter.setAutoQualitySwitching(false);
			stateProxy.qualitySwitchingMode = this.getAbrMode(false);
			adapter.setQuality(stateProxy.qualities[index]);
		}
		else {
			this.logger.warn(index >= maxIndex ?
				AppResources.messages.ABR_MANUAL_SWITCHING_REACHED_UPPER_BOUNDS :
				AppResources.messages.ABR_MANUAL_SWITCHING_REACHED_LOWER_BOUNDS);
		}
	}

	private switchQualityCategory(stateProxy: ContentPlaybackStateProxy, category: string, adapter: PlaybackAdapterInterface) {
		if (category === QualityCategory.AUTO) {
			adapter.setAutoQualitySwitching(true);
			adapter.setMinBitrate(NaN);
			adapter.setMaxBitrate(NaN);
			return;
		}

		const qia = stateProxy.qualities;
		const { minBitrate, maxBitrate, minIndex, maxIndex } = getMinAndMaxValuesForCategory(qia, category);

		if (minIndex === maxIndex) {
			this.switchToIndex(minIndex, stateProxy, adapter);
		}
		else {
			adapter.setMinBitrate(minBitrate);
			adapter.setMaxBitrate(maxBitrate);
		}
	}

	private getAbrMode(enabled: boolean): QualityMode {
		return enabled ? QualityMode.AUTO : QualityMode.MANUAL;
	}
}
