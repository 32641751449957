/**
 * @deprecated  use MediaAction instead
 */
export enum AccessibilityAction {
  ENTER_FULLSCREEN = 'mediaenterfullscreen',
  INCREASE_VOLUME = 'mediaincreasevolume',
  DECREASE_VOLUME = 'mediadecreasevolume',
  SEEK_BACK =  'mediaskipbackward',
  SEEK_FORWARD =  'mediaskipforward',
  TOGGLE_PLAY = 'mediaplaypause',
  TOGGLE_TT = 'mediatexttrack',
  TOGGLE_MUTE = 'mediamuteunmute',
  PLAYLIST_NEXT = 'mediaplaylistnext',
  PLAYLIST_PREV = 'mediaplaylistprevious',
  STOP = 'mediastop',
}
