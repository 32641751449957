import { MimeType } from '../enum/MimeType';

export const FileExtensionToMimeType: Record<string, MimeType> = {
	VTT: MimeType.VTT,
	XML: MimeType.TTML,
	M3U8: MimeType.HLS,
	MPD: MimeType.DASH,
	TS: MimeType.TS_VIDEO,
	MP4: MimeType.MP4_VIDEO,
	MPV: MimeType.MP4_VIDEO,
	MPA: MimeType.MP4_AUDIO,
	WEBM: MimeType.WEBM_VIDEO,
	ISM: MimeType.ISM,
};
