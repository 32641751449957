import { AppResources } from '../app/AppResources';
import { DOM_VIDEO } from '../const/DOM_VIDEO';
import { AdapterRole } from '../enum/AdapterRole';
import { LocalizationId } from '../enum/LocalizationId';
import { ModelName } from '../enum/ModelName';
import { PlayerDom } from '../enum/PlayerDom';
import { ProxyName } from '../enum/ProxyName';
import { PlayerDomProxyInterface } from '../iface';
import { AdapterConfigInterface } from '../iface/AdapterConfigInterface';
import { DimensionsInterface } from '../iface/DimensionsInterface';
import { PlayerDomInterface } from '../iface/PlayerDomInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { VideoAdapterInterface } from '../iface/VideoAdapterInterface';
import { Proxy } from '../mvc/Proxy';
import { createAdClickEl, createAdContainer, createVideoElement, initVideoElement } from '../util/PlayerDom';
import { AdapterProxy } from './AdapterProxy';
import { LocalizationProxy } from './LocalizationProxy';
import { ModelCollectionProxy } from './ModelCollectionProxy';


export class PlayerDomProxy extends Proxy implements PlayerDomProxyInterface {

	private controlVisCssClass: string = 'cvui-ctrlbar-vis';
	private playerOptions: PlayerOptionsInterface;
	private video: HTMLVideoElement;
	private useInitialVideo: boolean = false;
	private adapterConfig: AdapterConfigInterface<VideoAdapterInterface, null> = {
		id: DOM_VIDEO,
		role: [AdapterRole.VIDEO],
		factory: () => ({
			getId: () => DOM_VIDEO,
			isSupported: () => true,
			createVideo: () => {
				if (this.useInitialVideo) {
					this.useInitialVideo = false;
					return this.video;
				}

				this.recreateVideo();
				return this.video;
			},
		}),
	};

	constructor(name: ProxyName, data: PlayerDomInterface, playerOptions: PlayerOptionsInterface) {
		super(name, data);
		this.playerOptions = playerOptions;
	}

	setModel(coll: StrAnyDict): void {
		for (const q in coll) {
			if (q === PlayerDom.VIDEO_EL && coll[q]) {
				this.useInitialVideo = true;
				this.video = coll[q] as HTMLVideoElement;
			}
			else {
				this.model[q] = coll[q];
			}
		}
	}

	override onRegister(): void {
		super.onRegister();

		const adapterProxy = this.facade.retrieveProxy(ProxyName.AdapterProxy) as AdapterProxy;
		adapterProxy.registerDefaultAdapter(AdapterRole.VIDEO, this.adapterConfig);
	}

	override onRemove(): void {
		const main = this.getMain();
		const children = main.childNodes;
		let i = children.length;

		while (i--) {
			main.removeChild(children[i]);
		}

		const style = this.getElement(PlayerDom.STYLE);
		style?.parentElement?.removeChild(style);

		super.onRemove();
	}

	setControlVisibility(controlsVisible: boolean): void {
		const el = this.getMain();
		if (!el) {
			return;
		}

		const m = controlsVisible ? 'add' : 'remove';

		el.classList[m](this.controlVisCssClass);
	}

	getElement(n: PlayerDom): HTMLElement {
		const el = this.model[n];
		return el || null;
	}

	getSafeZIndex(): number {
		const el = this.getElement(PlayerDom.AD_CONTAINER);
		if (!el) {
			return 10;
		}

		return Number(el.style.zIndex) + 10;
	}

	getTextTrackContainer(): HTMLElement {
		return this.getElement(PlayerDom.CC_CONTAINER);
	}

	getMain(): HTMLElement {
		return this.getElement(PlayerDom.MAIN_CONTAINER);
	}

	showAdClickElement(flag?: boolean): void {
		const el = this.getElement(PlayerDom.AD_CLICK_EL);

		if (el) {
			const l10nProxy = this.facade.retrieveProxy(ProxyName.LocalizationProxy) as LocalizationProxy;
			const txt = l10nProxy.localize(LocalizationId.LEARN_MORE);
			if (txt) {
				el.innerHTML = txt;
			}
			(el.style.display = flag !== false ? 'block' : 'none');
		}
	}

	getDimensions(): DimensionsInterface {
		const r = this.getPresentationRect();

		return r ? { width: r.width, height: r.height } : null;
	}

	showContainer(name: PlayerDom, flag: boolean): void {
		const el = this.getElement(name);

		if (el) {
			el.style.display = flag ? 'inline-block' : 'none';
		}
	}

	showAdContainer(flag: boolean) {
		this.showContainer(PlayerDom.AD_CONTAINER, flag);
	}

	getPresentationRect(): ClientRect | null {
		const m = this.getMain(),
			r = m && m.getBoundingClientRect();

		return r || null;
	}

	primeVideo(src = AppResources.blankDataUrl): Promise<any> {
		return Promise.resolve()
			// @ts-ignore
			.then(() => {
				const { video } = this;
				if (video) {
					video.src = src;
					video.load();

					return video.play()
						// @ts-ignore
						.catch(e => {
							if (video.error && video.src !== AppResources.blankDataUrl) {
								return this.primeVideo(AppResources.blankDataUrl);
							}
						});
				}
			});
	}

	// Recreates ad container and ad click element as well
	// (a co-requisite for GAM client-side ads)
	private recreateVideo() {
		const previous = this.video;
		const parent = this.getElement(PlayerDom.VIDEO_CONTAINER);
		const mdlC = this.facade.retrieveProxy(ProxyName.ModelCollectionProxy) as ModelCollectionProxy;
		const presentationState = mdlC.getModel(ModelName.PresentationState) as PresentationStateInterface;

		let video: HTMLVideoElement;
		let vol: number;
		let muted: boolean;

		if (previous) {
			const exclude = ['src', 'data-element-id'];
			video = createVideoElement(this.playerOptions, this.facade.appId);
			vol = previous.volume;
			muted = previous.muted;

			Array.from(previous.attributes)
				.filter(attribute => !exclude.includes(attribute.name))
				.forEach(attribute => video.setAttribute(attribute.name, attribute.value));

			parent.replaceChild(video, previous);
		}
		else {
			video = initVideoElement(this.getElement(PlayerDom.MAIN_CONTAINER), this.playerOptions, this.facade.appId);
			vol = presentationState.volume;
			muted = presentationState.isMuted;
			parent.appendChild(video);
		}

		presentationState.volume = vol;
		video.muted = muted;
		video.volume = vol;

		this.recreateAdElements();

		this.video = video;
	}

	// Ad elements are created by default to make their replacement
	// more straightforward; they should *always* be in the DOM
	private recreateAdElements() {
		const adContainer = createAdContainer();
		const adClickEl = createAdClickEl();

		const parent = this.getMain();
		const prevAdCtr = this.getElement(PlayerDom.AD_CONTAINER) as HTMLVideoElement;
		const prevAdClickEl = this.getElement(PlayerDom.AD_CLICK_EL) as HTMLVideoElement;

		parent.replaceChild(adContainer, prevAdCtr);
		parent.replaceChild(adClickEl, prevAdClickEl);

		this.setModel({
			[PlayerDom.AD_CONTAINER]: adContainer,
			[PlayerDom.AD_CLICK_EL]: adClickEl,
		});
	}

	private get model(): PlayerDomInterface {
		return this.data as PlayerDomInterface;
	}
}
