import { ActionKey } from '../enum/ActionKey';
import { StreamType } from '../enum/StreamType';
import { ActionKeyEventContextInterface } from '../iface/ActionKeyEventContextInterface';
import { clampValue } from './NumberUtil';

export function increaseVolume(context: ActionKeyEventContextInterface) {
	const { player, metadata } = context;
	const v = clampValue(player.volume + 0.05, 0, 1);
	metadata.volume = v;
	player.volume = v;
}

export function decreaseVolume(context: ActionKeyEventContextInterface) {
	const { player, metadata } = context;
	const v = clampValue(player.volume - 0.05, 0, 1);
	metadata.volume = v;
	player.volume = v;
}

export function skipBackward(context: ActionKeyEventContextInterface) {
	const { player, metadata } = context;
	const d = player.contentDuration;
	const v = clampValue(player.contentTime - (0.05 * d), 0, d);
	metadata.position = v;
	metadata.duration = d;
	player.seek(v);
}

export function skipForward(context: ActionKeyEventContextInterface) {
	const { player, metadata } = context;
	const d = player.contentDuration;
	const v = clampValue(player.contentTime + (0.05 * d), 0, d);
	metadata.position = v;
	metadata.duration = d;
	player.seek(v);
}

export function toggleFullscreen(context: ActionKeyEventContextInterface) {
	const { player } = context;
	if (player.isFullscreen) {
		player.exitFullscreen();
	}
	else {
		player.enterFullscreen();
	}
}

export function togglePlay(context: ActionKeyEventContextInterface) {
	const { player } = context;

	if (player.streamType !== StreamType.LIVE) {
		player.togglePlayPause();
	}
}

export function toggleMute(context: ActionKeyEventContextInterface) {
	const { player, metadata } = context;

	player.muted = !player.muted;
	metadata.muted = player.muted;
}

export function toggleText(context: ActionKeyEventContextInterface) {
	const { player } = context;

	player.textTrackEnabled = !player.textTrackEnabled;
}

export function play(context: ActionKeyEventContextInterface) {
	const { player } = context;

	player.play();
}

export function pause(context: ActionKeyEventContextInterface) {
	const { player } = context;

	player.pause();
}

export function stop(context: ActionKeyEventContextInterface) {
	const { player } = context;

	player.pause();
}

export function live(context: ActionKeyEventContextInterface) {
	const { player } = context;

	if (player.streamType === StreamType.LIVE) {
		player.goLive();
	}
}

export type ActionMap = Partial<Record<ActionKey | string, (context: ActionKeyEventContextInterface) => void>>;

export function getDefaultActions(): ActionMap {
	return {
		[ActionKey.PLAY]: play,
		[ActionKey.PAUSE]: pause,
		[ActionKey.PLAY_PAUSE]: togglePlay,
		[ActionKey.TEXT_TRACK]: toggleText,
		[ActionKey.SKIP_BACKWARD]: skipBackward,
		[ActionKey.SKIP_FORWARD]: skipForward,
		[ActionKey.STOP]: stop,
		[ActionKey.LIVE]: live,
		[ActionKey.INCREASE_VOLUME]: increaseVolume,
		[ActionKey.DECREASE_VOLUME]: decreaseVolume,
		[ActionKey.MUTE_UNMUTE]: toggleMute,
		[ActionKey.FULLSCREEN]: toggleFullscreen,
	};
}
