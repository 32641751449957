import { MimeType } from '../enum/MimeType';
import { VideoFormat } from '../enum/VideoFormat';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { StreamMetadataInterface } from '../iface/StreamMetadataInterface';
import { getMimeType } from './File';

export function getResourceMimeType(resource: ResourceConfigurationInterface): MimeType {
	const override = resource.overrides?.mimeType;

	if (override) {
		return override.split(';').shift() as MimeType;
	}

	const format = resource.ad?.ssai?.format;

	if (format) {
		switch (format) {
			case VideoFormat.DASH:
				return MimeType.DASH;

			case VideoFormat.HLS:
				return MimeType.HLS;
		}
	}

	return getMimeType(resource.location?.mediaUrl);
}

export function getResourceMetadata(resource: ResourceConfigurationInterface): StreamMetadataInterface {
	return {
		manifest: {
			mimeType: getResourceMimeType(resource),
		},
		fragment: {
			mimeType: '',
		},
	};
}
