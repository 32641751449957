import { AppResources } from '../app/AppResources';
import { buildInfo } from '../core/BuildInfo';
import { Logger } from '../core/Logger';
import { Metric } from '../core/Metric';
import { ErrorCode } from '../enum/ErrorCode';
import { Measurement } from '../enum/Measurement';
import { MetricType } from '../enum/MetricType';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { PlaybackState } from '../enum/PlaybackState';
import { ProxyName } from '../enum/ProxyName';
import { StreamType } from '../enum/StreamType';
import type { ContentProgressEventDetailInterface } from '../events/ContentProgressEventInterface';
import { PlayerEvent } from '../events/PlayerEvent';
import { NotificationInterface, PlayerDomProxyInterface, VideoProxyInterface } from '../iface';
import { AdCuePointInterface } from '../iface/AdCuePointInterface';
import { ContentPlaybackStateInterface } from '../iface/ContentPlaybackStateInterface';
import { ErrorInfoInterface } from '../iface/ErrorInfoInterface';
import { OverridesInterface } from '../iface/OverridesInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { QualityInterface } from '../iface/QualityInterface';
import { isEmpty, isString } from '../util/Type';
import { AbstractPresentationMediator } from './AbstractPresentationMediator';

type TimeSpent = {
	startTime: number;
	lastTime: number;
	sessionTime: number;
	playbackTime: number;
	elapsedTime: number;
};

// provides base impl for abstract methods of AbstractPresentationMediator
export class CommonPresentationMediator extends AbstractPresentationMediator {

	private currentRect: ClientRect;
	protected contentDurationReleased: boolean = false;
	protected resumeTimeMaxProximityToAdBreak: number = 5.0;
	protected isClickToPlay: boolean = false;
	protected fullscreenRestrictedDuringAdPlay: boolean = false;
	protected _hasContent: boolean = true;
	protected contentComplete: boolean = false;
	protected timeSpent = {
		startTime: null as any,
		lastTime: 0,
		sessionTime: 0,
		playbackTime: 0,
		elapsedTime: 0,
	};

	override onRemove() {
		super.onRemove();
	}

	start() {
		const mUrl = this.resourceProxy.location.mediaUrl;
		this._hasContent = isString(mUrl) && !isEmpty(mUrl);
	}

	get hasContent(): boolean {
		return this._hasContent;
	}

	startSession() {
		this.updateTimeSpent();
	}

	close(): Promise<void> {
		if (this.closing) {
			return this.closing;
		}
		const contentPlaybackState: ContentPlaybackStateInterface = this.contentPlaybackStateProxy?.model;
		const contentTime = contentPlaybackState?.time || 0;
		const contentDuration = contentPlaybackState?.duration || 0;
		const adPlaying = this.isAdPlaying();
		const resource = this.resourceProxy.resource;

		this.respondToPlaybackStateChange(PlaybackState.STOPPED);
		this.contentPlaybackStateProxy.isReady = false;

		return this.closing = this.adapterTask
			.then(() => {
				if (this.startedPlaying) {
					this.pause();
				}
				this.logger.info('Closing Presentation');

				this.closeAds();
			})
			.then(() => this.destroyAdapters())
			.then(() => {
				const contentInterrupted = !adPlaying && this.hasContent && !this.isContentComplete();
				const adInterrupted = adPlaying;

				this.notify(PlayerEvent.RESOURCE_END, {
					resource,
					contentTime,
					contentDuration,
					contentInterrupted,
					adInterrupted,
					interrupted: contentInterrupted || adInterrupted,
				});
				/////////////////////////////////////////////////////////////////////
				// Note - No code may come after the INTERRUPTED or END notification
				/////////////////////////////////////////////////////////////////////
			})
			.catch((e) => {
				Logger.error(e);
				throw e;
			})
			.then(() => {
				this.closing = null;
			});
	}

	protected async destroyAdapters(): Promise<void> {
		await this.adapter?.destroy();
	}

	protected getPlayerVersionInfo() {
		return `${buildInfo.playerName}_${buildInfo.playerVersion}`;
	}

	protected startContentSegment() {
		// no op
	}
	closeAds(): void {
		// no-op
	}
	beforePlayOnUserGesture() {
		// no-op
	}

	playOnUserGesture(): void {
		this.notify(NotificationName.VIDEO_LOAD_START);
		this.prepareForPlayback(true);
	}

	mute(flag: boolean): void {
		this.muteVideo(flag);
	}

	override play() {
		if (this.contentComplete) {
			this.contentComplete = false;
		}

		return super.play();
	}

	seek(position: number): Promise<void> {
		if (this.contentComplete) {
			this.contentComplete = false;
		}

		return this.seekVideo(position);
	}

	override setVolume(value: number): void {
		this.videoProxy.setVideoVolume(value);
		super.setVolume(value);
	}

	getAdBreakTimes(): AdCuePointInterface[] {
		return [];
	}

	getSessionTime(): number | null {
		if (this.timeSpent.startTime == null) {
			return null;
		}

		return this.updateTimeSpent().sessionTime;
	}

	getPlaybackTime(): number | null {
		if (this.timeSpent.startTime == null) {
			return null;
		}

		return this.updateTimeSpent().playbackTime;
	}

	checkSize(): void {
		if (!this.domProxy) {
			return;
		}

		const rect = this.domProxy.getPresentationRect();

		if (rect.width !== this.currentRect.width || rect.height !== this.currentRect.height) {
			this.currentRect = rect;
			this.respondToSizeChange();

			this.notify(PlayerEvent.PRESENTATION_SIZE_CHANGE, {
				width: this.currentRect.width,
				height: this.currentRect.height,
			});
		}
	}

	protected releaseContentDuration(duration: number) {
		this.contentDurationReleased = true;
		this.notify(PlayerEvent.CONTENT_DURATION_AVAILABLE, {
			contentDuration: duration,
		});
	}

	protected updateTimeSpent(): TimeSpent {
		const now = performance.now();

		// Set the start the fist time this function is called
		if (this.timeSpent.startTime == null) {
			this.timeSpent.startTime = now;

			const name = Measurement.FIRST_FRAME;
			const mark = { id: this.facade.appId, name, startTime: now };
			this.sendNotification(PlayerEvent.METRIC, { metric: new Metric(name, mark, MetricType.MARK) });

			this.sendNotification(PlayerEvent.PLAYBACK_STARTED);
		}

		// Reset the last time after pausing
		if (this.timeSpent.lastTime == null) {
			this.timeSpent.lastTime = now;
		}

		const elapsed = now - this.timeSpent.startTime;
		this.timeSpent.elapsedTime = elapsed;
		this.timeSpent.sessionTime = elapsed / 1000;

		const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;
		if (cps.state !== PlaybackState.PAUSED) {
			this.timeSpent.playbackTime += (now - this.timeSpent.lastTime) / 1000;
			this.timeSpent.lastTime = now;
		}

		return this.timeSpent;
	}

	protected adjustStartTimeForAdBreakProximity(requestedStartTime: number, breaks: AdCuePointInterface[]): number {
		for (let i = 0, n = breaks.length; i < n; i++) {
			const bs = breaks[i].startTime,
				diff = bs - requestedStartTime;

			if (diff >= 0 && diff < this.resumeTimeMaxProximityToAdBreak) {
				return requestedStartTime - this.resumeTimeMaxProximityToAdBreak;
			}
		}

		return requestedStartTime;
	}

	protected get domProxy(): PlayerDomProxyInterface {
		return this.facade.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxyInterface;
	}

	protected get videoProxy(): VideoProxyInterface {
		return this.facade.retrieveProxy(ProxyName.VideoProxy) as VideoProxyInterface;
	}

	protected override muteVideo(flag: boolean) {
		super.muteVideo(flag);
		this.videoProxy.muteVideo(flag);
	}

	protected override respondToPlaybackStateChange(playbackState: PlaybackState): void {
		super.respondToPlaybackStateChange(playbackState);
		this.timeSpent.lastTime = null;
	}

	protected respondToFullscreenChange(fullscreen: boolean) {
		this.checkSize();
		this.notify(PlayerEvent.FULLSCREEN_CHANGE, { fullscreen });
	}

	protected respondToVideoPaused(): void {
		this.respondToPlaybackStateChange(PlaybackState.PAUSED);
	}

	protected respondToVideoPlaying(): void {
		if (!this.presoModel.started) {
			this.presoModel.started = true;
		}

		this.respondToPlaybackStateChange(PlaybackState.PLAYING);
	}

	protected respondToVideoSeeking(): void {
		this.notify(PlayerEvent.SEEK_START);
	}

	protected respondToVideoSeeked(): void {
		this.notify(PlayerEvent.SEEK_COMPLETE);
	}

	protected respondToQualityChange(quality: QualityInterface = null): void {
		this.notify(PlayerEvent.QUALITY_CHANGE, { quality });
	}

	protected respondToError(data: ErrorInfoInterface) {
		// TODO: Replace with Short-Circuiting Assignment Operator ||= once upgrade to TS 4.0 is complete
		if (!data.message) {
			data.message = AppResources.messages.UNSPECIFIED_ERROR;
		}

		if (!data.code) {
			data.code = ErrorCode.UNSPECIFIED_VIDEO_PLAYBACK_ERROR;
		}

		const name = this.startedPlaying || !data.category ? NotificationName.VIDEO_PLAYBACK_ERROR : NotificationName.PLAYER_ERROR;
		this.sendErrorNotification(name, data);
	}

	/*
			Resource end only applies when the resource has completed
	*/
	protected respondToVideoEnd(): void {
		this.notify(NotificationName.RESOURCE_COMPLETE);
		//////////////////////////////////////////////////
		// Note: Code must stop here at RESOURCE_COMPLETE
		//////////////////////////////////////////////////
	}

	protected respondToBufferingStatusCheck(count: number): void {
		const t = this.presoModel.streamTime;
		!isNaN(t) && t > 0 && this.checkVideoBuffering(count);
	}

	protected respondToId3Data(d: any): void {
		this.notify(PlayerEvent.METADATA_CUEPOINT, { cue: d });
	}

	protected updateContentProgress(streamTime: number): ContentProgressEventDetailInterface {
		const contentPlaybackState = this.contentPlaybackStateProxy.model;
		const { duration, liveStreamInfo, streamType, time } = contentPlaybackState;
		const data: ContentProgressEventDetailInterface = {
			contentTime: time,
			contentDuration: duration,
			streamTime,
			streamDuration: this.presoModel.streamDuration,
			sessionTime: this.timeSpent.sessionTime,
			playbackTime: this.timeSpent.playbackTime,
		};

		if (streamType !== StreamType.VOD) {
			const { relativeTime, relativeDuration } = liveStreamInfo;

			data.contentTime = relativeTime;
			data.contentDuration = relativeDuration;
			data.streamTime = relativeTime;
			data.streamDuration = relativeDuration;
			data.elapsedTimeMs = this.timeSpent.elapsedTime;

			this.presoModel.streamTime = relativeTime;
			this.presoModel.streamDuration = relativeDuration;
			contentPlaybackState.time = relativeTime;
			contentPlaybackState.duration = relativeDuration;
		}

		return data;
	}

	protected respondToVideoTimeUpdate(streamTime: number): void {
		this.updateTimeSpent();

		const data = this.updateContentProgress(streamTime);

		this.notify(PlayerEvent.CONTENT_PROGRESS, data);
	}

	protected respondToSizeChange(): void {
		if (!this.adapter) {
			return;
		}

		this.adapter.resize();
	}

	protected respondToDurationChange(dur: number): void {
		// no op
	}
	protected respondToTextTrackModeChange(enabled: boolean): void {
		// no op
	}

	// helpers
	protected setForClickToPlay(): void {
		const po = this.getModel(ModelName.PlayerOptions) as PlayerOptionsInterface;
		if (po.useNativeControls) {
			const m = this.domProxy.getMain();
			const lr = () => {
				m.removeEventListener('click', lr);
				this.notify(NotificationName.PLAY_ON_USER_GESTURE);
			};
			m.addEventListener('click', lr);
		}
	}

	protected isContentComplete(): boolean {
		if (this.contentComplete) {
			return true;
		}
		const cps = this.contentPlaybackStateProxy.model;
		return cps.streamType !== StreamType.LIVE && cps.streamType !== StreamType.LTS && !(isNaN(cps.time) || cps.time < cps.duration);
	}

	// TODO: Move this to an AdPresentationMediator base class
	protected isAdPlaying(): boolean {
		return this.presoModel.isTrackingAd;
	}

	override handleNotification(notification: NotificationInterface): void {
		super.handleNotification(notification);
	}

	override listNotificationInterests(): string[] {
		return super.listNotificationInterests().concat([]);
	}

	override onRegister(): void {
		super.onRegister();

		const o: OverridesInterface = this.playerOptions.overrides;

		if (o.resumeTimeMaxProximityToAdBreak) {
			this.resumeTimeMaxProximityToAdBreak = o.resumeTimeMaxProximityToAdBreak;
		}

		this.isClickToPlay = !this.presoModel.isAutoplay;

		const sys = this.system;
		this.fullscreenRestrictedDuringAdPlay = sys.isIos || sys.isAndroid;

		if (this.domProxy) {
			this.currentRect = this.domProxy.getPresentationRect();
		}
	}
}
