import { LogLevel } from '../enum/LogLevel';
import { LoggerEvent } from '../enum/LoggerEvent';
import { LogInterface } from '../iface/LogInterface';
import { LoggerInterface } from '../iface/LoggerInterface';
import { LoggerOptionsInterface } from '../iface/LoggerOptionsInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { msToHms, uid8 } from '../util/StringUtil';
import { isObject } from '../util/Type';
import { Emitter } from './Emitter';


const getPath = (logger: LoggerInterface): string =>
	(logger.parent?.id) ? `${getPath(logger.parent)}/${logger.id}` : logger.id;

const Levels = {
	[LogLevel.OFF]: 0,
	[LogLevel.ERROR]: 100,
	[LogLevel.WARN]: 200,
	[LogLevel.INFO]: 300,
	[LogLevel.DEBUG]: 400,
	[LogLevel.VERBOSE]: 500,
};

export class Logger extends Emitter<LoggerOptionsInterface> implements LoggerInterface {
	static maxDeprecatedWarnings: number = 1;
	static deprecatedCounts: Record<string, number> = {};

	static error(e: any): void {
		console && console.error(e);
	}

	static warn(m: any): void {
		console && console.warn(m);
	}

	static log(m: any): void {
		console && console.log(m);
	}

	/**
	 * @deprecated Log deprecation message
	 */
	static deprecated(msg: string) {
		if (Logger.deprecatedCounts[msg] >= Logger.maxDeprecatedWarnings) {
			return;
		}

		Logger.warn(msg);
		Logger.deprecatedCounts[msg] = (Logger.deprecatedCounts[msg] || 0) + 1;
	}

	private useConsole_: boolean = false;
	private logLevel_: LogLevel;
	private level_: number;
	private id_: string;
	private path_: string;
	private startTime_: Record<string, number> = {};
	private parent_: LoggerInterface | undefined;

	constructor(opts: LoggerOptionsInterface) {
		super(opts);

		this.parent_ = this.opts.parent;
		this.useConsole_ = isObject(console);
		this.id_ = opts.id || (opts.id === '' && !this.parent_) ? opts.id : uid8();
		this.logLevel = this.opts.logLevel ? this.opts.logLevel : this.opts.debug ? LogLevel.DEBUG : null;
		this.path_ = getPath(this);
	}

	get id(): string {
		return this.id_;
	}

	get parent(): LoggerInterface | undefined {
		return this.parent_;
	}

	get logLevel(): LogLevel {
		return this.logLevel_ || this.parent?.logLevel || LogLevel.OFF;
	}

	set logLevel(level: LogLevel) {
		this.logLevel_ = level;
		this.level_ = Levels[this.logLevel];
	}

	override emit(name: string, detail?: any): void {
		super.emit(name, detail);
		this.parent?.emit(name, detail);
	}

	create(options: LoggerOptionsInterface): LoggerInterface {
		options.parent ??= this;
		return new Logger(options);
	}

	assert(expression: boolean | any, label?: string): void {
		if (!expression) {
			this.error(`Assertion failed! - "${label || ''}"`);
		}
	}

	log(logLevel: LogLevel, ...items: any[]): void {
		for (let i = 0, n = items.length; i < n; i++) {
			this.emit(LoggerEvent.LOG_EVENT, this.assembleEvent(items[i], logLevel));
		}

		if (!this.shouldLog(logLevel)) {
			return;
		}

		items.unshift(this.getStamp());
		console.log(...items);
	}

	dir(logLevel: LogLevel, obj: StrAnyDict, label: string): void {
		const lbl = label || 'Unnamed Object';
		this.emit(LoggerEvent.LOG_EVENT, this.assembleEvent(obj, logLevel, lbl));

		if (!this.shouldLog(logLevel)) {
			return;
		}

		console.log(this.getStamp() + `Object: ${lbl}`);
		console.dir(obj);
	}

	error(...msg: any[]): void {
		this.log(LogLevel.ERROR, ...msg);
	}

	warn(...msg: any[]): void {
		this.log(LogLevel.WARN, ...msg);
	}

	info(...msg: any[]): void {
		this.log(LogLevel.INFO, ...msg);
	}

	debug(...msg: any[]): void {
		this.log(LogLevel.DEBUG, ...msg);
	}

	verbose(...msg: any[]): void {
		this.log(LogLevel.VERBOSE, ...msg);
	}

	time(label: string, startTime = performance.now()): void {
		if (this.startTime_[label]) {
			this.warn(`Timer ${label} already exists`);
			return;
		}

		this.startTime_[label] = startTime;

		this.emit(LoggerEvent.TIME_EVENT, this.assembleEvent({ startTime }, LogLevel.INFO, label));
	}

	timeEnd(label: string): void {
		if (!this.startTime_[label]) {
			this.warn(`Timer ${label} does not exist`);
			return;
		}

		const startTime = this.startTime_[label];
		delete this.startTime_[label];

		const duration = performance.now() - startTime;
		this.emit(LoggerEvent.TIME_END_EVENT, this.assembleEvent({ startTime, duration }, LogLevel.INFO, label));

		this.info(`Timer: ${label} ${Math.round(duration)}ms`);
	}

	private assembleEvent(item: any, level: LogLevel, label: string = null): LogInterface {
		return {
			id: this.id,
			item,
			level,
			label,
		};
	}

	private shouldLog(level: LogLevel) {
		return this.useConsole_ && this.level_ >= Levels[level];
	}

	private getStamp() {
		return `[${this.path_}] ${msToHms(performance.now())} > `;
	}

	override destroy() {
		this.parent_ = null;
		this.opts = null;
		this.startTime_ = null;

		super.destroy();
	}
}
