import { TextTrackEvent } from '../../../enum/TextTrackEvent';
import { TextTrackKind } from '../../../enum/TextTrackKind';
import { TextTrackMode } from '../../../enum/TextTrackMode';
import { Debounced } from '../../../iface/Debounced';
import { LoggerInterface } from '../../../iface/LoggerInterface';
import { VideoSurfaceConfigInterface } from '../../../iface/VideoSurfaceConfigInterface';
import { forEach, forEachReverse } from '../../../util/ArrayUtil';
import { debounce } from '../../../util/FunctionUtil';
import { clearCue, createSidecarTextTrack, dedupeCues, findDefaultTrack } from '../../../util/TimedText';
import { TextTrackSurfaceEvents } from '../enum/TextTrackSurfaceEvents';
import { VideoSurfaceEvents } from '../enum/VideoSurfaceEvents';

export class TextTrackSurface {
	private logger: LoggerInterface;
	private video: HTMLVideoElement;
	private config: VideoSurfaceConfigInterface;

	private pTextTracks: TextTrackList = null;
	private currentTextTrack: TextTrack = null;
	private currentTextTrackMode: TextTrackMode = TextTrackMode.DISABLED;
	private existingTrack: Array<TextTrack> = [];
	private addedTracks: Array<TextTrack> = [];
	private emit: (type: TextTrackSurfaceEvents, data?: any) => void;
	private processDebounced: Debounced;
	private addDebounced: Debounced;
	private pForcedLanguage: string;
	private ignoreBlankTracks: boolean = false;
	private processCues: boolean = true;

	constructor(config: VideoSurfaceConfigInterface, emit: (type: TextTrackSurfaceEvents, data: any) => void) {
		this.config = config;
		this.video = config.video;
		this.pTextTracks = this.video.textTracks;
		this.currentTextTrackMode = config.textTrackSettings.mode;
		this.pForcedLanguage = config.textTrackSettings.language;
		this.ignoreBlankTracks = config.playerOptions.ignoreBlankTextTracks;
		this.processCues = config.playerOptions.overrides?.processCues !== false;

		this.emit = emit || (() => { });

		// These functions have the potential to be called multiple times in rapid succession.
		// Debounce to allow multiple changes to be applied in a single pass.
		this.processDebounced = debounce(this.processTracks, 100);
		this.addDebounced = debounce(this.addTracks, 25);

		this.addEvents();

		this.logger = config.logger;
		this.logger.info('TextTrackSurface created');

		if (this.config.resource.location.textTrackUrl) {
			createSidecarTextTrack(this.config.resource, this.video);
		}
	}

	destroy() {
		this.processDebounced.cancel();
		this.addDebounced.cancel();
		this.emit = null;
		this.removeEvents();
		forEach(this.pTextTracks, (t) => this.cleanupTrack(t));
		forEach(this.video.querySelectorAll('track'), (element) => this.video.removeChild(element));
	}

	clearCue() {
		clearCue(this.currentTextTrack, this.video.currentTime);
	}

	private cleanupTrack(track: TextTrack) {
		if (!this.isTextTrack(track.kind)) {
			return;
		}

		function cleanupCue(cue: TextTrackCue) {
			try {
				if (cue) {
					track.removeCue(cue);
				}
			}
			catch (error) {
				// ignore errors and continue cleanup
			}
		}

		// Cues must be cleaned up in reverse order. Otherwise half of the cues will be left behind.
		forEachReverse(track.cues, cleanupCue);
		forEachReverse(track.activeCues, cleanupCue);

		//@ts-ignore
		track.expired = true;

		//hls.js disablement
		//@ts-ignore
		track.textTrack1 = true;
		//@ts-ignore
		track.textTrack2 = true;

		//dashjs disablement
		//@ts-ignore
		track.isTTML = true; //forces dash.js to use the new track it creates for vtt or ttml sideload on next load.
		//@ts-ignore
		track.isEmbedded = false;

		//general disablement
		try {
			//@ts-ignore
			track.mode = TextTrackMode.DISABLED;
		}
		catch (error) {
			// ignore errors and continue disabling
		}
	}

	set textTrackMode(mode: TextTrackMode) {
		if (this.currentTextTrack) {
			this.setTrackMode(mode);
		}
		else {
			this.logger.warn('No text track detected');
		}
	}

	set textTrack(newTrack: TextTrack) {
		if (!this.isValidTrack(newTrack) || newTrack === this.currentTextTrack) {
			return;
		}

		// disable old track
		if (this.currentTextTrack && this.currentTextTrack.mode !== TextTrackMode.DISABLED) {
			this.currentTextTrack.mode = TextTrackMode.DISABLED;
		}

		this.currentTextTrack = newTrack;

		// re-apply the track mode to the new text track
		this.setTrackMode(this.currentTextTrackMode).then(() => {
			this.logger.info(`${newTrack.language} is being set as the current text track`);
			this.emit(TextTrackSurfaceEvents.TEXT_TRACK_CHANGE, newTrack);
		});
	}

	get textTrack(): TextTrack {
		return this.currentTextTrack;
	}

	get textTracks(): TextTrack[] {
		return Array.from(this.pTextTracks).filter(t => this.isValidTrack(t));
	}

	set forcedLanguage(value: string) {
		this.pForcedLanguage = value;
		this.updateForcedNarrative();
	}

	get forcedLanguage(): string {
		return this.pForcedLanguage;
	}

	private setTrackMode(mode: TextTrackMode): Promise<void> {
		return new Promise((resolve, reject) => {
			const modeChanged = (mode !== this.currentTextTrackMode);
			const applyMode = (): void => {
				this.currentTextTrack.mode = mode;

				this.updateForcedNarrative();

				if (modeChanged) {
					// Dispatch the event after the promise resolves
					setTimeout(() => this.emit(TextTrackSurfaceEvents.TEXT_TRACK_DISPLAY_MODE_CHANGE, { mode }), 0);
				}

				// Logging
				let msg: string = TextTrackMode.DISABLED;
				mode === TextTrackMode.HIDDEN && (msg = 'enabled for event driven external custom rendering');
				mode === TextTrackMode.SHOWING && (msg = 'enabled for native rendering by the user agent');
				this.logger.info(`The ${this.currentTextTrack.kind} track for language code ${this.currentTextTrack.language} is being ${msg} `);

				resolve();
			};

			this.currentTextTrackMode = mode;

			// HACK: FF has issue with setting showing from disabled need to set to hidden then showing with timeout.
			if (mode === TextTrackMode.SHOWING &&
				this.currentTextTrack.mode === TextTrackMode.DISABLED) {

				// Temporarily set to hidden to get around FF issue
				this.currentTextTrack.mode = TextTrackMode.HIDDEN;
				setTimeout(applyMode, 10);
			}
			else {
				applyMode();
			}
		});
	}

	private updateForcedNarrative() {
		const isForced = (track: any) => track.kind === TextTrackKind.FORCED;
		const tracks = Array.from(this.pTextTracks).filter(isForced);

		// Reset all forced tracks
		tracks.forEach(track => track.mode = TextTrackMode.DISABLED);

		// Only enabled forced narrative tracks if normal text is disabled
		const disabled = this.currentTextTrackMode === TextTrackMode.DISABLED;
		if (!disabled) {
			return;
		}

		// Enabled the forced narrative track for the selected language
		const forced = tracks.find(track => track.language === this.pForcedLanguage);
		if (!forced) {
			return;
		}

		forced.addEventListener(TextTrackEvent.CUE_CHANGE, this.onCueChange);
		forced.mode = this.config.textTrackSettings.enabledMode;
	}

	// Events
	private onVideoSeeked = (e: any) => {
		// Force Safari to refresh the forced narrative cues list
		this.updateForcedNarrative();
	};

	private onVideoTextTrackAdded = (e: TrackEvent): void => {
		// hlsjs reuses tracks for 608/708, so remove the expired flag.
		const track = e.track;
		//@ts-ignore
		track.expired = false;

		this.onTextTrackAdded(e);
	};

	private onTextTrackAdded = (e: TrackEvent): void => {
		const track = e.track;

		// VTG-2215 - hlsjs creates an empty "subtitles" track. Ignore it.
		const isEmpty = track.kind === TextTrackKind.SUBTITLES && !track.language && !track.label;
		const isMetadata = track.kind === TextTrackKind.METADATA;

		if (isMetadata || isEmpty) {
			return;
		}

		this.addDebounced();
	};

	private onTextTrackChange = (e: Event): void => {
		this.processDebounced();
	};

	private onCueChange = (e: Event): void => {
		const track = e.target as TextTrack;
		const forced = (track.kind as any) === TextTrackKind.FORCED;
		if (!this.config.textTrackSettings.enabled && !forced) {
			return;
		}

		const activeCues = this.processCues ? dedupeCues(track, this.video.currentTime) : Array.from(track.activeCues || []);
		this.emit(TextTrackSurfaceEvents.TEXT_CUEPOINT, { activeCues, forced });
	};

	private addTrack(track: TextTrack): void {
		if (this.addedTracks.includes(track)) {
			return;
		}

		this.addedTracks.push(track);

		if (!this.isValidTrack(track)) {
			return;
		}

		track.addEventListener(TextTrackEvent.CUE_CHANGE, this.onCueChange);

		this.logger.info(`A ${track.kind} text track was added for ${track.language}`);
		this.emit(TextTrackSurfaceEvents.TEXT_TRACK_ADDED, track);
	}

	private addTracks = (): void => {
		forEach(this.pTextTracks, (t) => {
			const invalid = this.isExpired(t) || this.isDuplicateTrack(t);
			t.mode = (!invalid) ? TextTrackMode.HIDDEN : TextTrackMode.DISABLED;

			if (invalid) {
				return;
			}

			this.addTrack(t);
		});

		// If this is the first time through, select the best track from the list
		if (!this.currentTextTrack) {
			this.textTrack = findDefaultTrack(this.textTracks, this.config.textTrackSettings.language);
			if (!this.textTrack) {
				return;
			}
			this.textTrack.mode = this.config.textTrackSettings.mode;
			this.emit(TextTrackSurfaceEvents.TEXT_TRACK_AVAILABLE);
		}

		// Native hls playback in Safari won't trigger a text track change event, so do it manually
		this.processTracks();
	};

	private processTracks = (): void => {
		const { enabled, native, enabledMode } = this.config.textTrackSettings;

		// Only search valid tracks
		const tracks = this.textTracks;

		// hlsjs sometimes enables expired tracks. Ensure all expired tracks are disabled.
		forEach(this.pTextTracks, t => {
			if (this.isExpired(t) && t.mode !== TextTrackMode.DISABLED) {
				t.mode = TextTrackMode.DISABLED;
			}
		});

		// Handle non-native text rendering separately
		if (!native) {
			if (enabled) {
				// Streaming libraries sometimes set the mode to "showing"
				const track = tracks.find(t => t.mode === TextTrackMode.SHOWING);
				if (track) {
					track.mode = enabledMode;
				}
			}
			else {
				// Streaming libraries sometimes set the mode to "hidden"
				const track = tracks.find(t => t.mode !== TextTrackMode.DISABLED);
				if (track) {
					track.mode = TextTrackMode.DISABLED;
				}
			}
			return;
		}

		// Check for change to the text track settings via native UIs or DOM APIs
		const track = tracks.find(t => t.mode === TextTrackMode.SHOWING);

		if (enabled) {
			// no change
			if (track === this.currentTextTrack) {
				return;
			}

			// If no enabled track was found, then the mode has changed
			if (!track) {
				this.textTrackMode = TextTrackMode.DISABLED;
			}
			else {
				// Otherwise, the a different track was enabled
				this.textTrack = track;
			}
		}
		else {
			// no change
			if (!track) {
				return;
			}

			// Update the track if it has changed
			if (track !== this.currentTextTrack) {
				this.textTrack = track;
			}
			// Update the mode
			this.textTrackMode = TextTrackMode.SHOWING;
		}
	};

	private addEvents(): void {
		this.pTextTracks.addEventListener(TextTrackEvent.ADD_TRACK, this.onTextTrackAdded);
		this.pTextTracks.addEventListener(TextTrackEvent.CHANGE, this.onTextTrackChange);

		// HACK: Workaround for a bug in hlsjs where the `addtrack` event is dispatched from
		// the video element instead of the text track list when switching to a resource
		// with 608/708 captions.
		this.video.addEventListener(TextTrackEvent.ADD_TRACK, this.onVideoTextTrackAdded);

		this.video.addEventListener(VideoSurfaceEvents.SEEKED, this.onVideoSeeked);
	}

	private removeEvents(): void {
		this.pTextTracks.removeEventListener(TextTrackEvent.ADD_TRACK, this.onTextTrackAdded);
		this.pTextTracks.removeEventListener(TextTrackEvent.CHANGE, this.onTextTrackChange);

		// HACK: hlsjs 608 workaround
		this.video.removeEventListener(TextTrackEvent.ADD_TRACK, this.onVideoTextTrackAdded);

		this.video.removeEventListener(VideoSurfaceEvents.SEEKED, this.onVideoSeeked);

		forEach(this.pTextTracks, (t) => t.removeEventListener(TextTrackEvent.CUE_CHANGE, this.onCueChange));
	}

	// Util
	private isDuplicateTrack(t: TextTrack): boolean {
		// Tracks that have been previously processed are not duplicates
		if (this.existingTrack.includes(t)) {
			return false;
		}

		// Check for duplicate tracks generated by dashjs when switching periods on a stream with 608 captions
		const result = this.existingTrack.some(track => t.language === track.language && t.label === track.label && t.kind === track.kind);
		if (!result) {
			this.existingTrack.push(t);
		}
		return result;
	}

	private isTextTrack(type: string) {
		return type === TextTrackKind.CAPTIONS || type === TextTrackKind.SUBTITLES;
	}

	private isExpired(track: TextTrack): boolean {
		//@ts-ignore
		return track.expired;
	}

	private isValidTrack(track: TextTrack): boolean {
		if (!track) {
			return false;
		}

		if (!this.isTextTrack(track.kind)) {
			return false;
		}

		if (this.ignoreBlankTracks && !track.label && !track.language) {
			return false;
		}

		if (this.isExpired(track)) {
			return false;
		}

		return true;
	}
}
