import screenfull from 'screenfull';
import { Browser } from '../enum/Browser';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { Os } from '../enum/Os';
import { ProxyName } from '../enum/ProxyName';
import { PlayerEvent } from '../events/PlayerEvent';
import { NotificationInterface, PlayerDomProxyInterface, VideoProxyInterface } from '../iface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { isFunction } from '../util/Type';
import { LogAwareMediator } from './LogAwareMediator';


type ScreenfullInterface = typeof screenfull;

interface IosFsPropsInterface extends Record<string, string> {
	ENTER_EVENT: string;
	EXIT_EVENT: string;
	ENTER_METHOD: string;
	EXIT_METHOD: string;
	SUPPORTED: string;
}

export class FullscreenMediator extends LogAwareMediator {

	private isFullscreen: boolean = false;
	private fsElement: HTMLElement = null;
	private enabled: boolean = false;
	private pIsAndroidFirefox: boolean | null = null;
	private fsApiSupported: boolean = true;
	private fsMgr: ScreenfullInterface = null;
	private changeHandler: (e?: Event) => void;
	private useCustomFullscreen = true;
	private metadataLoadedDelayHandle: ReturnType<typeof setTimeout> = null;

	private iosPropNames: IosFsPropsInterface = {
		ENTER_EVENT: 'webkitbeginfullscreen',
		EXIT_EVENT: 'webkitendfullscreen',
		ENTER_METHOD: 'webkitEnterFullscreen',
		EXIT_METHOD: 'webkitExitFullscreen',
		SUPPORTED: 'webkitSupportsFullscreen',
	};

	constructor(name: string, viewControl?: any) {
		super(name, viewControl);
		this.fsMgr = screenfull;
		this.changeHandler = (e?: Event) => this.hChange(e);
	}

	set fullscreenElement(el: HTMLElement) {
		if (!(el instanceof HTMLElement)) {
			return;
		}
		const sys = this.system;
		// VTG-2222: support alt fs element for non-mobile
		if (!sys.isIos && !sys.isAndroid) {
			this.fsElement = el;
		}
	}

	get fullscreenElement(): HTMLElement {
		return this.fsElement;
	}

	override onRemove(): void {
		clearTimeout(this.metadataLoadedDelayHandle);

		if (!this.fsApiSupported && this.fsElement) {
			this.fsElement.removeEventListener(this.iosPropNames.ENTER_EVENT, this.changeHandler);
			this.fsElement.removeEventListener(this.iosPropNames.EXIT_EVENT, this.changeHandler);
		}
		// Service #185: 'off' method undefined on iPhone (cause ?)
		if (this.fsMgr && isFunction(this.fsMgr.off)) {
			this.fsMgr.off('change', this.changeHandler);
		}

		this.fsElement = null;
		this.fsMgr = null;

		super.onRemove();
	}

	enter(): void {
		if (!this.enabled) {
			return;
		}

		if (this.useCustomFullscreen) {
			this.isFullscreen = true;
			this.sendFullscreenNotification();

			return;
		}

		this.fsApiSupported ? this.fsMgr.request(this.fsElement) : this.enterFsIos();
	}

	exit(): void {
		if (!this.enabled) {
			return;
		}

		if (this.useCustomFullscreen) {
			this.isFullscreen = false;
			this.sendFullscreenNotification();

			return;
		}

		this.fsApiSupported ? this.fsMgr.exit() : this.exitFsIos();
	}

	isFullScreenPermitted(): boolean {
		return this.enabled;
	}

	isFullScreen(): boolean {
		return this.isFullscreen;
	}

	///////////////////////////////////
	// PRIVATE / MVC

	private get isAndroidFirefox(): boolean {
		if (this.pIsAndroidFirefox == null) {
			const sys = this.system;
			this.pIsAndroidFirefox = sys.os === Os.ANDROID && sys.browser === Browser.FIREFOX;
		}
		return this.pIsAndroidFirefox;
	}

	handleNotification(notification: NotificationInterface): void {
		const n = notification.name;

		if (n === PlayerEvent.VIDEO_ELEMENT_ADDED) {
			this.onRegister();
			return;
		}

		if (n === PlayerEvent.STREAM_METADATA) {
			this.metadataLoadedDelayHandle = setTimeout(() => this.handleMetadataLoaded(), 20);
			return;
		}

		if (!this.enabled) {
			return;
		}

		const enter = n === NotificationName.ENTER_FULLSCREEN_REQUEST;
		enter ? this.enter() : this.exit();
	}

	override listNotificationInterests(): string[] {
		return [
			PlayerEvent.VIDEO_ELEMENT_ADDED,
			PlayerEvent.STREAM_METADATA,
			NotificationName.ENTER_FULLSCREEN_REQUEST,
			NotificationName.EXIT_FULLSCREEN_REQUEST,
		];
	}

	private handleMetadataLoaded(): void {
		this.enabled = this.enabled || this.webkitIosEnabled();
		this.enabled && this.sendNotification(NotificationName.FULLSCREEN_AVAILABLE, null);
	}

	private hChange(e?: Event): void {
		this.isFullscreen = this.fsApiSupported ? screenfull.isFullscreen : this.checkWebkitIosState(e);

		if (this.isAndroidFirefox) {
			// controls must be explicitly set for this case
			this.isFullscreen && this.fsElement.setAttribute('controls', '');
			!this.isFullscreen && this.fsElement.removeAttribute('controls');
		}

		this.sendFullscreenNotification();
	}

	private sendFullscreenNotification() {
		this.sendNotification(
			NotificationName.FULLSCREEN_CHANGE,
			{ isFullscreen: this.isFullscreen },
			NotificationType.INTERNAL,
		);
	}

	private enterFsIos(): void {
		(this.fsElement as any)[this.iosPropNames.ENTER_METHOD]();
	}

	private exitFsIos(): void {
		(this.fsElement as any)[this.iosPropNames.EXIT_METHOD]();
	}

	private webkitIosEnabled(): boolean {
		return this.system.isIos && (this.fsElement as any)[this.iosPropNames.SUPPORTED];
	}

	private checkWebkitIosState(e: Event): boolean {
		return e.type === this.iosPropNames.ENTER_EVENT;
	}

	override onRegister(): void {
		this.useCustomFullscreen = !(this.getModel(ModelName.PlayerOptions) as PlayerOptionsInterface).useNativeFullscreen;

		if (this.useCustomFullscreen) {
			this.enabled = true;
			return;
		}
		const dp = this.facade.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxyInterface;
		const videoProxy = this.facade.retrieveProxy(ProxyName.VideoProxy) as VideoProxyInterface;
		const supportsFsApi = this.fsMgr.isEnabled;

		if (!supportsFsApi) {
			this.fsApiSupported = false;
			this.fsElement = videoProxy.getVideo();
		}
		else {
			// otherwise, the main container is used as the fullscreen element
			this.fsElement = dp.getMain();
		}

		if (this.fsApiSupported && this.fsElement && this.fsMgr.isEnabled) {
			this.enabled = true;
			this.fsMgr.on('change', this.changeHandler);
		}
		else if (this.fsElement && !this.fsApiSupported) {
			this.fsElement.addEventListener(this.iosPropNames.ENTER_EVENT, this.changeHandler);
			this.fsElement.addEventListener(this.iosPropNames.EXIT_EVENT, this.changeHandler);
		}
	}
}
