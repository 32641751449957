import { AdapterRole } from '../enum/AdapterRole';
import { MediatorName } from '../enum/MediatorName';
import { ProxyName } from '../enum/ProxyName';
import { PlayerEvent } from '../events/PlayerEvent';
import { VideoProxyInterface } from '../iface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { VideoAdapterInterface } from '../iface/VideoAdapterInterface';
import { Proxy } from '../mvc/Proxy';
import { System } from '../util/System';
import { AppMediator } from '../view/AppMediator';
import { AdapterProxy } from './AdapterProxy';

export type VideoProxyData = {
	video: HTMLVideoElement;
};

export class VideoProxy extends Proxy implements VideoProxyInterface {
	private adapter: VideoAdapterInterface;

	constructor(name: string, data: VideoProxyData) {
		super(name, data);
	}

	override onRemove(): void {
		this.setVideo(null);
		this.adapter = null;
		this.data.logger = null;

		super.onRemove();
	}

	async initVideo(resource: ResourceConfigurationInterface) {
		if (this.adapter?.destroy) {
			await this.adapter.destroy();
		}

		const adapterProxy = this.facade.retrieveProxy(ProxyName.AdapterProxy) as AdapterProxy;
		this.adapter = await adapterProxy.createAdapter(AdapterRole.VIDEO, resource, null);
		const video = await this.adapter.createVideo();
		this.setVideo(video);
		return video;
	}

	getAdapter(): VideoAdapterInterface {
		return this.adapter;
	}

	getVideo(): HTMLVideoElement {
		return this.data.video;
	}

	private setVideo(video: HTMLVideoElement) {
		if (this.data.video) {
			this.applyProps(this.data.video, { player: null, avia: null });
			this.sendNotification(PlayerEvent.VIDEO_ELEMENT_REMOVED, { video: this.data.video });
		}

		this.data.video = video;

		if (video) {
			const appMediator = this.facade.retrieveMediator(MediatorName.APPLICATION) as AppMediator;
			this.applyProps(video, { player: appMediator.getAppApi(), avia: System.appNamespace });
			this.sendNotification(PlayerEvent.VIDEO_ELEMENT_ADDED, { video: this.data.video });
		}
	}

	setVideoVolume(volume: number) {
		const video = this.getVideo();
		if (!video || this.getVideoVolume() === volume) {
			return;
		}

		video.volume = volume;
	}

	getVideoVolume(): number {
		const video = this.getVideo();
		return video ? video.volume : null;
	}

	muteVideo(flag: boolean): void {
		const video = this.getVideo();

		if (!video) {
			return;
		}

		video.muted = flag;
	}

	private applyProps(video: any, props: Record<string, any>) {
		for (const key in props) {
			try {
				video[key] = props[key];
			}
			catch (error) {
				this.facade.logger.debug(`Error applying video property ${key}`, error);
			}
		}
	}
}
