import { request } from '../core/Request';
import { LocalizationId } from '../enum/LocalizationId';
import { ResponseType } from '../enum/ResponseType';
import type { LanguageTagInterface } from '../iface/LanguageTagInterface';
import type { LocaleData } from '../iface/LocaleData';
import type { LocalizationData } from '../iface/LocalizationData';
import type { LocalizationInterface } from '../iface/LocalizationInterface';
import type { LoggerInterface } from '../iface/LoggerInterface';
import type { StrAnyDict } from '../iface/StrAnyDict';
import type { StrStrDict } from '../iface/StrStrDict';
import { parseLanguageTag } from '../util/Language';
import { entries, fromEntries, merge } from '../util/ObjectUtil';
import { template } from '../util/StringUtil';
import { System } from '../util/System';
import { isEmpty } from '../util/Type';
import { logger as defaultLogger } from './Logger';

export const DEFAULT_LANGUAGE = System.global.navigator?.language || 'en';

const externalData: Record<string, Promise<StrAnyDict>> = {};

function load(url: string): Promise<Record<string, string>> {
	if (externalData[url] == null) {
		externalData[url] = request({ url, responseType: ResponseType.JSON });
	}
	return externalData[url];
}

const localizationData: LocalizationData = {
	en: {
		[LocalizationId.MEDIA_PLAYER]: 'Media Player',
		[LocalizationId.LEARN_MORE]: 'Learn More',
	},
	es: {
		[LocalizationId.MEDIA_PLAYER]: 'Reproductor multimedia',
		[LocalizationId.LEARN_MORE]: 'Aprende más',
	},
};

type LocaleEntry = [string, Record<string, string>];

export interface LocalizationOptionsInterface {
	parent?: LocalizationInterface;
	logger?: LoggerInterface
}

export class Localization implements LocalizationInterface {
	private parent_: LocalizationInterface;
	private language_: string;
	private languageTag_: LanguageTagInterface;
	private localizationData_: LocalizationData[] = [];
	private locale_: Record<string, StrStrDict> = {};
	private logger_: LoggerInterface;

	constructor(options: LocalizationOptionsInterface = {}) {
		this.parent_ = options.parent;
		this.logger_ = options.logger || defaultLogger;
	}

	get defaultLanguage() {
		return DEFAULT_LANGUAGE;
	}

	get parent(): LocalizationInterface | undefined {
		return this.parent_;
	}

	get language(): string {
		return this.language_ || DEFAULT_LANGUAGE;
	}

	get languageTag(): LanguageTagInterface {
		return this.languageTag_;
	}

	getValue(id: string, language: string = this.language): string | undefined {
		const find = (lang: string): string => {
			const locale = (this.locale_?.[lang] || {}) as StrStrDict;
			return locale[id];
		};

		return find(language) || find(this.languageTag_.language);
	}

	changeLanguage(language: string): Promise<LocaleData> {
		this.language_ = language;
		this.languageTag_ = parseLanguageTag(this.language);
		const tag = new RegExp(`^${this.languageTag_.language}`, 'i');
		const loader = ([k, v]: [string, string]): Promise<LocaleEntry> => {
			return load(v)
				.then(data => [k, data] as LocaleEntry)
				.catch(error => {
					// Errors loading localization files should not halt playback
					this.logger_.error(`Could not load locale file ${v}`, error);
					return [k, {}] as LocaleEntry;
				});
		};

		// create an array of entry arrays
		const data = this.localizationData_.map(data => {
			const locale = entries(data)
				// only operate on data for the current language
				.filter(entry => tag.test(entry[0]))

				// start loading external data, or return the entry array
				.map(entry => (typeof entry[1] === 'string') ? loader(entry as [string, string]) : entry as LocaleEntry);

			return Promise.all(locale);
		});

		// wait for all external data to finish loading
		return Promise.all(data)
			.then(entries => {
				// convert entry arrays back into objects
				const objs = entries.map(entry => fromEntries(entry));

				// Merge into a single object
				const data = merge(...objs);

				// Cache the data and return the final object
				return this.locale_ = data;
			});
	}

	create(options: LocalizationOptionsInterface): LocalizationInterface {
		options.parent ??= this;
		return new Localization(options);
	}

	registerLocalizationData(data: LocalizationData): void {
		if (isEmpty(data)) {
			return;
		}
		this.localizationData_.push(data);
		this.changeLanguage(this.language);
	}

	localize(id: string, context?: any, language = this.language): string {
		const value = this.getValue(id, language) || this.parent?.localize(id, context, language);
		return template(value || id, context);
	}

	destroy() {
		this.parent_ = null;
		this.locale_ = null;
		this.languageTag_ = null;
		this.localizationData_ = null;
	}
}

export const localization = new Localization();
localization.registerLocalizationData(localizationData);

export function registerLocalizationData(data: LocalizationData): void {
	return localization.registerLocalizationData(data);
}

export function changeLanguage(language: string): Promise<LocaleData> {
	return localization.changeLanguage(language);
}

export function localize(id: string, context?: any, language = this.language): string {
	return localization.localize(id, context, language);
}
