
import { PluginServices } from '../app/PluginServices';
import { ErrorCode } from '../enum/ErrorCode';
import { ErrorMessage } from '../enum/ErrorMessage';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { PluginPriority } from '../enum/PluginPriority';
import { ProxyName } from '../enum/ProxyName';
import { PluginError } from '../error/PluginError';
import { PlayerEvent } from '../events/PlayerEvent';
import { LoadPluginsNotificationInterface, NotificationInterface, PlayerDomProxyInterface, PluginServicesOptions, VideoProxyInterface } from '../iface';
import { ExtensionConfigInterface } from '../iface/ExtensionConfigInterface';
import { PluginConfigInterface } from '../iface/PluginConfigInterface';
import { PluginInterface } from '../iface/PluginInterface';
import { PluginServicesInterface } from '../iface/PluginServicesInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { HookProxy } from '../model/HookProxy';
import { LocalizationProxy } from '../model/LocalizationProxy';
import { PlayerOptions } from '../model/vo/PlayerOptions';
import { isFunction } from '../util/Type';
import { AppMediator } from './AppMediator';
import { LogAwareMediator } from './LogAwareMediator';


export class PluginMediator extends LogAwareMediator {
	private plugins: StrAnyDict = {};
	private pendingLoads: PluginConfigInterface[] = null;
	private services: PluginServicesOptions = null;

	constructor(name: MediatorName) {
		super(name);
	}

	override async destroy() {
		await Promise.all(Object.keys(this.plugins).map(async id => {
			try {
				await this.killPlugin(id);
			}
			catch (error) {
				this.logger.warn(`Error destroying plugin ${id}: ${error}`);
			}
		}));
	}

	override onRemove(): void {
		this.plugins = null;
		this.pendingLoads = null;
		this.services = null;

		super.onRemove();
	}

	removePlugin(id: string): void {
		this.killPlugin(id);
	}

	getPlugin(id: string): any {
		return this.plugins[id] || null;
	}

	loadPlugin(cfg: PluginConfigInterface): Promise<PluginInterface> {
		return this.createPlugin(cfg);
	}

	override listNotificationInterests(): string[] {
		return [
			NotificationName.LOAD_PLUGINS,
			PlayerEvent.AD_BREAK_START,
			PlayerEvent.CONTENT_START,
			NotificationName.REMOVE_PLUGIN,
		];
	}

	handleNotification(notification: NotificationInterface): void {
		switch (notification.name) {

			case NotificationName.LOAD_PLUGINS:
				this.loadPlugins(notification.body as LoadPluginsNotificationInterface);
				break;

			case NotificationName.REMOVE_PLUGIN:
				this.removePlugin(notification.body.name);
				break;

			// intentional fall-thru
			case PlayerEvent.AD_BREAK_START:
			case PlayerEvent.CONTENT_START:
				this.loadPendingPlugins();
				break;
		}
	}

	createPluginServices(config: ExtensionConfigInterface): PluginServicesInterface {
		const { id, options = {} } = config;

		return new PluginServices({
			...this.services,
			logger: this.logger.create({ id, debug: options.debug, logLevel: options.logLevel }),
		});
	}

	private get playerOptions() {
		return (this.getModel(ModelName.PlayerOptions) as PlayerOptions).data;
	}

	private initPluginServices(): void {
		if (this.services) {
			return;
		}

		const am = this.facade.retrieveMediator(MediatorName.APPLICATION) as AppMediator;
		this.services = {
			player: am.getAppApi(),
			domProxy: this.facade.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxyInterface,
			videoProxy: this.facade.retrieveProxy(ProxyName.VideoProxy) as VideoProxyInterface,
			localization: (this.facade.retrieveProxy(ProxyName.LocalizationProxy) as LocalizationProxy).getApi(),
			playerOptions: this.playerOptions,
			dispatch: (data: StrAnyDict) => {
				am.dispatchPluginEvent(data);
			},
			hookManager: this.facade.retrieveProxy(ProxyName.HookProxy) as HookProxy,
			logger: null,
		};
	}

	private createPlugins(plugins: PluginConfigInterface[]): Promise<any> {
		return Promise
			.all(plugins.map(plugin => this.createPlugin(plugin)));
	}

	private async createPlugin(config: PluginConfigInterface): Promise<any> {
		const { id, factory, options } = config;

		if (!isFunction(factory)) {
			throw new PluginError(ErrorCode.PLUGIN_FACTORY_ERROR, ErrorMessage.PLUGIN_FACTORY_ERROR, config);
		}

		let plugin;

		try {
			const context = this.createPluginServices(config);
			plugin = await factory(context, options);
		}
		catch (error) {
			throw new PluginError(ErrorCode.PLUGIN_LOAD_ERROR, ErrorMessage.PLUGIN_LOAD_ERROR, config, error);
		}

		if (!plugin) {
			return;
		}

		const pluginId = plugin.getId?.();

		if (!pluginId) {
			throw new PluginError(ErrorCode.PLUGIN_ID_ERROR, ErrorMessage.PLUGIN_ID_ERROR, config);
		}

		this.plugins[id] = plugin;

		this.logger.info(`Created plugin '${id}'`);
		return plugin;
	}

	private async killPlugin(id: string): Promise<void> {
		const plugin = this.plugins[id];

		if (!plugin) {
			return;
		}

		if (isFunction(plugin.destroy)) {
			await plugin.destroy();
		}

		delete this.plugins[id];
	}

	private loadPendingPlugins(): void {
		if (!this.pendingLoads) {
			return;
		}

		this.createPlugins(this.pendingLoads)
			.catch(error => {
				this.sendNotification(NotificationName.PLAYER_ERROR, error);
			});

		this.pendingLoads = null;
	}

	private loadPlugins(obj: LoadPluginsNotificationInterface): void {
		this.initPluginServices();

		const { callback, plugins } = obj;

		if (!plugins?.length) {
			callback();
			return;
		}

		const ps = this.getModel(ModelName.PresentationState) as PresentationStateInterface;
		const notStarted = !ps.started;
		const low = plugins.filter(plugin => plugin.priority === PluginPriority.LOW);
		const high = plugins.filter(plugin => plugin.priority !== PluginPriority.LOW);

		if (notStarted) {
			this.pendingLoads = low;
		}

		const queue = (notStarted) ? high : low.concat(high);

		this.createPlugins(queue)
			.then(() => callback())
			.catch(error => callback(error));
	}
}
